import { getGuestToken, getToken } from '../utils/authUtil';
import { createOrderTypes } from './createOrder';

const MODULE_NAME = 'user/';

export const userActionTypes = {
  CHECK_USER_LOGGED_IN: MODULE_NAME + 'CHECK_USER_LOGGED_IN',

  OPEN_SIGN_IN_POPUP: MODULE_NAME + 'OPEN_SIGN_IN_POPUP',
  CLOSE_SIGN_IN_POPUP: MODULE_NAME + 'CLOSE_SIGN_IN_POPUP',

  SIGN_IN_REQUEST: MODULE_NAME + 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: MODULE_NAME + 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: MODULE_NAME + 'SIGN_IN_FAILURE',

  LOAD_GUEST_TOKEN_REQUEST: MODULE_NAME + 'LOAD_GUEST_TOKEN_REQUEST',
  LOAD_GUEST_TOKEN_SUCCESS: MODULE_NAME + 'LOAD_GUEST_TOKEN_SUCCESS',
  LOAD_GUEST_TOKEN_FAILURE: MODULE_NAME + 'LOAD_GUEST_TOKEN_FAILURE',

  SIGN_IN_SOCIAL_REQUEST: MODULE_NAME + 'SIGN_IN_SOCIAL_REQUEST',
  SIGN_IN_SOCIAL_SUCCESS: MODULE_NAME + 'SIGN_IN_SOCIAL_SUCCESS',
  SIGN_IN_SOCIAL_FAILURE: MODULE_NAME + 'SIGN_IN_SOCIAL_FAILURE',

  GET_USER_PROFILE_REQUEST: MODULE_NAME + 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: MODULE_NAME + 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: MODULE_NAME + 'GET_USER_PROFILE_FAILURE',

  EDIT_USER_PROFILE_REQUEST: MODULE_NAME + 'EDIT_USER_PROFILE_REQUEST',
  EDIT_USER_PROFILE_SUCCESS: MODULE_NAME + 'EDIT_USER_PROFILE_SUCCESS',
  EDIT_USER_PROFILE_FAILURE: MODULE_NAME + 'EDIT_USER_PROFILE_FAILURE',

  UPDATE_AVATAR_REQUEST: MODULE_NAME + 'UPDATE_AVATAR_REQUEST',
  UPDATE_AVATAR_SUCCESS: MODULE_NAME + 'UPDATE_AVATAR_SUCCESS',
  UPDATE_AVATAR_FAILURE: MODULE_NAME + 'UPDATE_AVATAR_FAILURE',

  UPDATE_AVATAR_BASE_REQUEST: MODULE_NAME + 'UPDATE_AVATAR_BASE_REQUEST',
  UPDATE_AVATAR_BASE_SUCCESS: MODULE_NAME + 'UPDATE_AVATAR_BASE_SUCCESS',
  UPDATE_AVATAR_BASE_FAILURE: MODULE_NAME + 'UPDATE_AVATAR_BASE_FAILURE',

  DEACTIVATE_ACCOUNT_REQUEST: MODULE_NAME + 'DEACTIVATE_ACCOUNT_REQUEST',
  DEACTIVATE_ACCOUNT_SUCCESS: MODULE_NAME + 'DEACTIVATE_ACCOUNT_SUCCESS',
  DEACTIVATE_ACCOUNT_FAILURE: MODULE_NAME + 'DEACTIVATE_ACCOUNT_FAILURE',

  ACTIVATE_ACCOUNT_REQUEST: MODULE_NAME + 'ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_SUCCESS: MODULE_NAME + 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILURE: MODULE_NAME + 'ACTIVATE_ACCOUNT_FAILURE',

  OPEN_CHANGE_PASSWORD_POPUP: MODULE_NAME + 'OPEN_CHANGE_PASSWORD_POPUP',
  CLOSE_CHANGE_PASSWORD_POPUP: MODULE_NAME + 'CLOSE_CHANGE_PASSWORD_POPUP',

  LOGOUT: MODULE_NAME + 'LOGOUT',

  GET_USER_NOTIFICATIONS_REQUEST:
    MODULE_NAME + 'GET_USER_NOTIFICATIONS_REQUEST',
  GET_USER_NOTIFICATIONS_SUCCESS:
    MODULE_NAME + 'GET_USER_NOTIFICATIONS_SUCCESS',
  GET_USER_NOTIFICATIONS_FAILURE:
    MODULE_NAME + 'GET_USER_NOTIFICATIONS_FAILURE',

  CHANGE_NOTIFICATIONS_REQUEST: MODULE_NAME + 'CHANGE_NOTIFICATIONS_REQUEST',
  CHANGE_NOTIFICATIONS_SUCCESS: MODULE_NAME + 'CHANGE_NOTIFICATIONS_SUCCESS',
  CHANGE_NOTIFICATIONS_FAILURE: MODULE_NAME + 'CHANGE_NOTIFICATIONS_FAILURE',

  SET_USER_NOTIFICATIONS_REQUEST:
    MODULE_NAME + 'SET_USER_NOTIFICATIONS_REQUEST',
  SET_USER_NOTIFICATIONS_SUCCESS:
    MODULE_NAME + 'SET_USER_NOTIFICATIONS_SUCCESS',
  SET_USER_NOTIFICATIONS_FAILURE:
    MODULE_NAME + 'SET_USER_NOTIFICATIONS_FAILURE',

  OTHER_SIGNED_ID_REQUEST: MODULE_NAME + 'OTHER_SIGNED_ID_REQUEST',
  OTHER_SIGNED_ID_SUCCESS: MODULE_NAME + 'OTHER_SIGNED_ID_SUCCESS',
  OTHER_SIGNED_ID_FAILURE: MODULE_NAME + 'OTHER_SIGNED_ID_FAILURE',

  GET_SERVER_TIME_REQUEST: MODULE_NAME + 'GET_SERVER_TIME_REQUEST',
  GET_SERVER_TIME_SUCCESS: MODULE_NAME + 'GET_SERVER_TIME_SUCCESS',
  GET_SERVER_TIME_FAILURE: MODULE_NAME + 'GET_SERVER_TIME_FAILURE',

  SET_SIGNIN_MODE_SIGNIN: MODULE_NAME + 'SET_SIGNIN_MODE_SIGNIN',
  SET_SIGNIN_MODE_SIGNUP: MODULE_NAME + 'SET_SIGNIN_MODE_SIGNUP',

  SET_SESSION: MODULE_NAME + 'SET_SESSION',

  SET_PHONE: MODULE_NAME + 'SET_PHONE',

  SET_TERMS_AND_CONDITIONS_URLS: MODULE_NAME + 'SET_TERMS_AND_CONDITIONS_URLS',
};

const initialState = {
  signInPopupOpened: false,

  userLoggedIn: false,
  guestTokenLoaded: false,
  loginLoading: false,

  profile: {},
  profileLoading: false,
  deactivated: false,
  blocked: false,

  editProfileLoading: true,
  avatarLoading: false,

  changePasswordOpened: false,

  notifications: [],
  notificationsLoading: false,

  changeNotificationsField: {},

  saveNotificationsLoading: false,

  logoutLoader: false,

  serverTime: null,
  serverTimeLoading: false,
  timeDifferenceWithServer: 0,

  phone: '',
  termsAndConditionsUrls: {},
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};
  switch (action.type) {
    case userActionTypes.CHECK_USER_LOGGED_IN:
      return {
        ...state,
        userLoggedIn: action.userLoggedIn,
        guestTokenLoaded: action.guestTokenLoaded,
      };

    case userActionTypes.OPEN_SIGN_IN_POPUP:
      return {
        ...state,
        signInPopupOpened: true,
      };

    case userActionTypes.CLOSE_SIGN_IN_POPUP:
      return {
        ...state,
        signInPopupOpened: false,
      };

    case userActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case userActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        userLoggedIn: true,
        loginLoading: false,
        profile: payload.user,
        deactivated: payload.status === 'DEACTIVATED',
        blocked: payload.status === 'BLOCKED',
      };
    case userActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        loginLoading: false,
      };

    case userActionTypes.SIGN_IN_SOCIAL_SUCCESS:
      return {
        ...state,
        userLoggedIn: true,
        signInPopupOpened: false,
        profile: payload.user,
        deactivated: payload.status === 'DEACTIVATED',
        blocked: payload.status === 'BLOCKED',
      };

    case userActionTypes.LOAD_GUEST_TOKEN_SUCCESS:
      return {
        ...state,
        guestTokenLoaded: true,
      };

    case userActionTypes.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };

    case userActionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,

        profile: payload.profile,
        profileLoading: false,
        deactivated: payload.profile.deactivated === 'DEACTIVATED',
        blocked: payload.profile.blocked,
      };

    case createOrderTypes.LOAD_EDITING_ORDER_FAILURE:
      return {
        ...state,
        deactivated: action.deactivated,
      };

    case userActionTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        profileLoading: false,
      };

    case userActionTypes.EDIT_USER_PROFILE_REQUEST:
      return {
        ...state,
        editProfileLoading: true,
      };

    case userActionTypes.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userStatus: payload.status,
        profile: payload.profile,
        editProfileLoading: false,
      };

    case userActionTypes.EDIT_USER_PROFILE_FAILURE:
      return {
        ...state,
        editProfileLoading: false,
      };

    case userActionTypes.UPDATE_AVATAR_REQUEST:
      return {
        ...state,
        avatarLoading: true,
      };

    case userActionTypes.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, avatar: payload.image },
        avatarLoading: false,
      };

    case userActionTypes.UPDATE_AVATAR_FAILURE:
      return {
        ...state,
        avatarLoading: false,
      };

    case userActionTypes.UPDATE_AVATAR_BASE_REQUEST:
      return {
        ...state,
        avatarLoading: true,
      };

    case userActionTypes.UPDATE_AVATAR_BASE_SUCCESS:
      return {
        ...state,
        profile: { ...state.profile, avatar: payload.image },
        avatarLoading: false,
      };

    case userActionTypes.UPDATE_AVATAR_BASE_FAILURE:
      return {
        ...state,
        avatarLoading: false,
      };

    case userActionTypes.OPEN_CHANGE_PASSWORD_POPUP:
      return {
        ...state,
        changePasswordOpened: true,
      };

    case userActionTypes.CLOSE_CHANGE_PASSWORD_POPUP:
      return {
        ...state,
        changePasswordOpened: false,
      };

    case userActionTypes.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deactivated: false,
        userLoggedIn: true,
      };

    case userActionTypes.LOGOUT:
      return {
        ...state,
        userLoggedIn: false,
        logoutLoader: true,
      };

    case userActionTypes.GET_USER_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsLoading: true,
      };

    case userActionTypes.GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
        notificationsLoading: false,
      };

    case userActionTypes.CHANGE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      };

    case userActionTypes.CHANGE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
      };

    case userActionTypes.CHANGE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      };

    case userActionTypes.SET_USER_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        saveNotificationsLoading: true,
      };
    }

    case userActionTypes.SET_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        saveNotificationsLoading: false,
      };
    }

    case userActionTypes.SET_USER_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        saveNotificationsLoading: false,
      };
    }

    case userActionTypes.OTHER_SIGNED_ID_REQUEST: {
      return {
        ...state,
      };
    }

    /* *** Get server time *** */

    case userActionTypes.GET_SERVER_TIME_REQUEST: {
      return {
        ...state,
        timeDifferenceWithServer: 0,
        serverTime: null,
        serverTimeLoading: true,
      };
    }

    case userActionTypes.GET_SERVER_TIME_SUCCESS: {
      return {
        ...state,
        timeDifferenceWithServer: payload.differenceTime,
        serverTime: payload.timestamp,
        serverTimeLoading: false,
      };
    }

    case userActionTypes.GET_SERVER_TIME_FAILURE: {
      return {
        ...state,
        timeDifferenceWithServer: 0,
        serverTime: false,
        serverTimeLoading: false,
      };
    }

    case userActionTypes.SET_SIGNIN_MODE_SIGNIN: {
      return {
        ...state,
        signinMode: 'SIGN_IN',
      };
    }

    case userActionTypes.SET_SIGNIN_MODE_SIGNUP: {
      return {
        ...state,
        signinMode: 'SIGN_UP',
      };
    }

    case userActionTypes.SET_SESSION: {
      return {
        ...state,
        session: payload.session,
      };
    }

    case userActionTypes.SET_PHONE: {
      return {
        ...state,
        phone: payload.phone,
      };
    }

    case userActionTypes.SET_TERMS_AND_CONDITIONS_URLS: {
      return {
        ...state,
        termsAndConditionsUrls: payload.termsAndConditionsUrls,
      };
    }

    default:
      return state;
  }
};

export const userActions = {
  checkUserLoggedIn: () => ({
    type: userActionTypes.CHECK_USER_LOGGED_IN,
    userLoggedIn: !!getToken(),
    guestTokenLoaded: !!getGuestToken(),
  }),

  loadGuestToken: () => ({
    type: userActionTypes.LOAD_GUEST_TOKEN_REQUEST,
  }),

  openSignInPopup: () => ({
    type: userActionTypes.OPEN_SIGN_IN_POPUP,
  }),

  closeSignInPopup: () => ({
    type: userActionTypes.CLOSE_SIGN_IN_POPUP,
  }),

  signIn: (values, resolve, reject) => ({
    type: userActionTypes.SIGN_IN_REQUEST,
    payload: { values, resolve, reject },
  }),

  signInSuccess: (payload) => ({
    type: userActionTypes.SIGN_IN_SUCCESS,
    payload,
  }),

  signInSocialSuccess: (payload) => ({
    type: userActionTypes.SIGN_IN_SOCIAL_SUCCESS,
    payload,
  }),

  signInFacebook: (data) => ({
    type: userActionTypes.SIGN_IN_SOCIAL_REQUEST,
    payload: {
      ...data,
      social: 'FACEBOOK',
    },
  }),

  signInGoogle: (data) => ({
    type: userActionTypes.SIGN_IN_SOCIAL_REQUEST,
    payload: {
      ...data,
      first_name: data.profileObj.givenName,
      last_name: data.profileObj.familyName,
      email: data.profileObj.email,
      social: 'GOOGLE',
    },
  }),

  getUserProfile: () => ({
    type: userActionTypes.GET_USER_PROFILE_REQUEST,
  }),

  getUserProfileSuccess: (data) => ({
    type: userActionTypes.GET_USER_PROFILE_SUCCESS,
    payload: data,
  }),

  getUserProfileFailure: (error) => ({
    type: userActionTypes.GET_USER_PROFILE_FAILURE,
    error,
  }),

  logout: () => ({
    type: userActionTypes.LOGOUT,
  }),

  editUserProfile: (values, resolve, reject) => ({
    type: userActionTypes.EDIT_USER_PROFILE_REQUEST,
    payload: { values, resolve, reject },
  }),

  updateProfile: (file) => ({
    type: userActionTypes.UPDATE_AVATAR_REQUEST,
    payload: { file },
  }),

  updateProfileBase: (file) => ({
    type: userActionTypes.UPDATE_AVATAR_BASE_REQUEST,
    payload: { file },
  }),

  openChangePasswordPopup: () => ({
    type: userActionTypes.OPEN_CHANGE_PASSWORD_POPUP,
  }),

  closeChangePasswordPopup: () => ({
    type: userActionTypes.CLOSE_CHANGE_PASSWORD_POPUP,
  }),

  deactivateAccount: () => ({
    type: userActionTypes.DEACTIVATE_ACCOUNT_REQUEST,
  }),

  activateAccount: () => ({
    type: userActionTypes.ACTIVATE_ACCOUNT_REQUEST,
  }),

  getUserNotifications: () => ({
    type: userActionTypes.GET_USER_NOTIFICATIONS_REQUEST,
  }),

  getUserLoadedNotifications: () => ({
    type: userActionTypes.GET_USER_NOTIFICATIONS_REQUEST,
  }),

  changeNotificationsField: (e) => ({
    type: userActionTypes.CHANGE_NOTIFICATIONS_REQUEST,
    field: {
      name: e.target.name,
      checked: e.target.checked,
    },
  }),

  saveNotifications: () => ({
    type: userActionTypes.SET_USER_NOTIFICATIONS_REQUEST,
  }),

  otherSignedIn: () => ({
    type: userActionTypes.OTHER_SIGNED_ID_REQUEST,
  }),

  /* *** Get server time *** */

  getServerTime: () => ({
    type: userActionTypes.GET_SERVER_TIME_REQUEST,
  }),

  getServerTimeSuccess: (timestamp, differenceTime) => ({
    type: userActionTypes.GET_SERVER_TIME_SUCCESS,
    payload: {
      timestamp,
      differenceTime,
    },
  }),

  getServerTimeFailure: ({ error }) => ({
    type: userActionTypes.GET_SERVER_TIME_FAILURE,
    payload: {
      error,
    },
  }),

  setSignInModeSignIn: () => ({
    type: userActionTypes.SET_SIGNIN_MODE_SIGNIN,
    payload: {
      signinMode: 'SIGN_IN',
    },
  }),

  setSignInModeSignUp: () => ({
    type: userActionTypes.SET_SIGNIN_MODE_SIGNUP,
    payload: {
      signinMode: 'SIGN_UP',
    },
  }),

  setSession: (session) => ({
    type: userActionTypes.SET_SESSION,
    payload: {
      session,
    },
  }),

  setPhone: (phone) => ({
    type: userActionTypes.SET_PHONE,
    payload: {
      phone,
    },
  }),

  setTermsAndConditionsUrls: function (termsAndConditionsUrls) {
    return {
      type: userActionTypes.SET_TERMS_AND_CONDITIONS_URLS,
      payload: {
        termsAndConditionsUrls,
      },
    };
  },
};

export const userSelectors = {
  isUserLoggedIn: (state) => state.user.userLoggedIn,

  isGuestTokenLoaded: (state) => state.user.guestTokenLoaded,

  isUserDeactivated: (state) => state.user.deactivated,

  isUserBlocked: (state) => state.user.blocked,

  selectUserProfile: (state) => state.user.profile,

  selectUserProfileLoading: (state) => state.user.profileLoading,

  editProfileLoading: (state) => state.user.editProfileLoading,

  selectUserAvatar: (state) => state.user.profile.avatar,

  selectIsAvatarLoading: (state) => state.user.avatarLoading,

  getChangePasswordPopupOpened: (state) => state.user.changePasswordOpened,

  isSignInPopupOpened: (state) => state.user.signInPopupOpened,

  getUserProfile: (state) => state.user.profile,

  getUserNotifications: (state) => state.user.notifications,

  getUserNotificationsLoading: (state) => state.user.notificationsLoading,

  getSaveUserNotificationsLoading: (state) =>
    state.user.saveNotificationsLoading,

  getLogoutLoader: (state) => state.user.logoutLoader,

  getServerTime: (state) => state.user.serverTime,

  getServerTimeLoading: (state) => state.user.serverTimeLoading,

  getTimeDifferenceWithServer: (state) => state.user.timeDifferenceWithServer,

  getSignInMode: (state) => state.user.signinMode,

  getSession: (state) => state.user.session,

  getPhone: (state) => state.user.phone,

  getTermsAndConditionsUrls: (state) => state.user.termsAndConditionsUrls,
};
