import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';
import AddressForm from './AddressForm';
import { createOrderAddressActions } from '../store';
import { createOrderSelectors } from '../../../reducers/createOrder';

export const ADDRESS_FORM = 'addressForm';

class AddressesCreateEdit extends Component {
  getFullAddressObject = (values) => {
    const { address } = this.props;

    return {
      ...address,
      ...values,
      saved: true,
    };
  };

  onSubmit = (values) => {
    const fullAddressObject = this.getFullAddressObject(values);

    this.props.saveAddressForList({
      address: fullAddressObject,
      isEditing: false,
      isOrderCreate: true,
    });

    this.props.saveAddressForOrder(this.props.orderId, fullAddressObject);
  };

  render() {
    const { address, t } = this.props;

    if (address) {
      return (
        <Fragment>
          <Helmet>
            <title>{t('createAddress')}</title>
          </Helmet>
          <AddressForm
            form={ADDRESS_FORM}
            initialValues={address}
            onSelectAddress={this.props.selectAddress}
            onLocationChange={this.props.changeSelectedAddressLocation}
            onSubmit={this.onSubmit}
          />
        </Fragment>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  orderId: createOrderSelectors.getOrderId(state),
  address: addressesSelectors.getSelectedAddress(state),
});

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, {
    saveAddressForList: addressesActions.saveAddress,
    saveAddressForOrder: createOrderAddressActions.saveAddress,
  }),
)(AddressesCreateEdit);
