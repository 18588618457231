import { get, post } from './baseService';

// export const signUp = values => post('/signup/customer', values);

export const loadGuestToken = () => get('/auth/guest');

// export const confirmPhoneCodeSignUp = values => post('/signup/verify', values);

export const getTermsAndConditions = (lang) =>
  get(`/misc/terms-and-conditions?format=html&lang=${lang}`, true);

export const agreementTermsAndConditions = (session) =>
  post('/signup/agreement', session);

export const resendPhoneCode = (values) => post('/verify/resend', values);

// export const signIn = values => post('/auth/login', { ...values, role: 'CUSTOMER' });

export const signInSocial = (values) =>
  post('/auth/social', { ...values, role: 'CUSTOMER' });

export const signUpSocial = (values) => post('/signup/social', values);

export const restorePassword = (phone) =>
  post('/auth/restore', { phone, role: 'CUSTOMER' });

export const confirmCode = (values) => post('/verify/check', values);

export const resendCode = (values) => post('/verify/resend', values);

export const restoreFinish = (values) => post('/auth/restore-finish', values);

export const checkPhoneExists = (values) =>
  post('/simple-signup/check-customer', values);

export const signUp = (values) => post('/simple-signup/customer', values);

export const confirmSignUpPhone = (values) =>
  post('/simple-signup/verify', values);

export const addCusomterDetails = (values) =>
  post('/simple-signup/add-customer-details', values);

export const sendConfirmationCode = (values) =>
  post('/simple-signup/send-confirmation-code', values);

export const signIn = (values) => post('/auth/simple-login', values);

export const getTermsAndConditionsUrls = (lang) =>
  get(`/misc/terms-and-conditions`);
