import React from 'react';
import classNames from 'classnames';

import styles from './GreenButtonTransparent.module.scss';
import buttonsPropTypes from './buttonsPropTypes';
import InitialButton from './InitialButton';

const GreenButtonTransparent = (props) => (
  <InitialButton
    className={classNames(styles.button, {
      [styles.buttonDisabled]: props.disabled,
    })}
    {...props}
  />
);

GreenButtonTransparent.propTypes = buttonsPropTypes;

export default GreenButtonTransparent;
