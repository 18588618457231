export enum ServiceTypesEnum {
  REGULAR = 'REGULAR',
  HOUSE_MAID = 'HOUSE_MAID',
  UP_SALE = 'UP_SALE',
  COMPANION_SERVICE = 'COMPANION_SERVICE',
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
}

export enum CleaningSubCategoryTypesEnum {
  CLEANING_WINDOWS_CLEANING = 'CLEANING_WINDOWS_CLEANING',
  CLEANING_HOUSE_MAID = 'CLEANING_HOUSE_MAID',
  CLEANING_REGULAR = 'CLEANING_REGULAR',
}

export enum SelectedCategoryTypesEnum {
  CLEANING = 'CLEANING',
  HANDYMAN = 'HANDYMAN',
}
export enum OrderStatusesEnum {
  CREATED = 'CREATED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAYMENT_IN_PROCESS = 'PAYMENT_IN_PROCESS',
  PAID = 'PAID',
  VIEWED = 'VIEWED',
  TRANSPORTATION = 'TRANSPORTATION',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_CONFIRMATION = 'WAITING_CONFIRMATION',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}
