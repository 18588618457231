export enum AddressActionEnum {
  GET_ADDRESSES = 'address_new/GET_ADDRESSES',
  GET_ADDRESSES_SUCCESS = 'address_new/GET_ADDRESSES_SUCCESS',
  GET_ADDRESSES_FAILURE = 'address_new/GET_ADDRESSES_FAILURE',

  DELETE_ADDRESS = 'address_new/DELETE_ADDRESS',
  ADD_IN_FAVORITE = 'address_new/ADD_IN_FAVORITE',

  SET_ACTIVE_ADDRESS_INDEX = 'address_new/SET_ACTIVE_ADDRESS_INDEX',

  DELETE_ORDER_ITEM = 'address_new/DELETE_ORDER_ITEM',
  DELETE_ORDER_ITEM_SUCCESS = 'address_new/DELETE_ORDER_ITEM_SUCCESS',
  DELETE_ORDER_ITEM_FAILURE = 'address_new/DELETE_ORDER_ITEM_FAILURE',
}

// TYPE DESCRIPTION
export type AddressActionsType = {
  getAddresses: () => {
    type: AddressActionEnum.GET_ADDRESSES;
  };
  deleteAddress: (
    payload: number,
  ) => {
    type: AddressActionEnum.DELETE_ADDRESS;
    payload;
  };
  addInFavorites: (
    payload: number,
  ) => {
    type: AddressActionEnum.ADD_IN_FAVORITE;
    payload;
  };
  activeAddressIndex: (
    payload: number,
  ) => {
    type: AddressActionEnum.SET_ACTIVE_ADDRESS_INDEX;
    payload;
  };
  deleteOrderItem: (
    payload: number,
  ) => {
    type: AddressActionEnum.DELETE_ORDER_ITEM;
    payload: number;
  };
};

// ACTIONS
export const addressActions: AddressActionsType = {
  getAddresses: () => ({
    type: AddressActionEnum.GET_ADDRESSES,
  }),
  deleteAddress: (payload: number) => ({
    type: AddressActionEnum.DELETE_ADDRESS,
    payload,
  }),
  addInFavorites: (payload) => ({
    type: AddressActionEnum.ADD_IN_FAVORITE,
    payload,
  }),
  activeAddressIndex: (payload) => ({
    type: AddressActionEnum.SET_ACTIVE_ADDRESS_INDEX,
    payload,
  }),
  deleteOrderItem: (payload) => ({
    type: AddressActionEnum.DELETE_ORDER_ITEM,
    payload,
  }),
};
