import React, { Component } from 'react';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { withProps, compose } from 'recompose';
import PropTypes from 'prop-types';

import { checkIfMapsApiLoaded } from '../../../utils/mapUtils';
import { LabeledTextInput } from '../text';
import { withReduxFormProps } from '../../../containers/hoc';

const CHECK_FOR_GOOGLE_MAPS_API_LOADED_INTERVAL = 50;

class AsideMapSearchBox extends Component {
  timeoutId = null;

  constructor(props) {
    super(props);
    this.searchBox = React.createRef();
  }

  componentDidMount() {
    this.timeoutId = setInterval(
      () => checkIfMapsApiLoaded(this.onMapsApiLoad),
      CHECK_FOR_GOOGLE_MAPS_API_LOADED_INTERVAL,
    );
  }

  onMapsApiLoad = () => {
    clearInterval(this.timeoutId);
    this.timeoutId = null;

    this.forceUpdate();
  };

  handleAddressChange = () => {
    const places = this.searchBox.current.getPlaces();

    if (places.length !== 0 && places[0].formatted_address) {
      const place = places[0];

      const location = place.geometry.location.toJSON();
      const address = places[0].formatted_address;

      this.props.onChange(address);
      this.props.onLocationChange(location);
      this.props.onSelectAddress(address);
      this.props.onSetNewAddressLocation(location);
    }
  };

  render() {
    const { value } = this.props;

    if (window.google) {
      return (
        <div>
          <StandaloneSearchBox
            ref={this.searchBox}
            onPlacesChanged={this.handleAddressChange}
          >
            <LabeledTextInput
              {...this.props}
              value={value || ''}
              onChange={(e) => this.props.onChange(e.target.value)}
              placeholder=" "
            />
          </StandaloneSearchBox>
        </div>
      );
    }
    return null;
  }

  componentWillUnmount() {
    clearInterval(this.timeoutId);
  }
}

AsideMapSearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  onSelectAddress: PropTypes.func,
  onSetNewAddressLocation: PropTypes.func,
};

AsideMapSearchBox.defaultProps = {
  value: {
    addresss: '',
    location: {},
  },
  onChange: () => {},
  onLocationChange: () => {},
  onSelectAddress: () => {},
  onSetNewAddressLocation: () => {},
};

export default compose(
  withReduxFormProps,
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
)(AsideMapSearchBox);
