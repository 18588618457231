import React, { Component } from 'react';
import { reduxForm, Field, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { getPathForUserAddresses } from '../routing/constants/pathHelpers';
import { LabeledTextInput } from '../../components/inputs/text';
import { AsideMapSearchBox } from '../../components/inputs/map';
import {
  PinkButton,
  GreenButton,
  GrayButtonTransparent,
} from '../../components/buttons';
import { addressesSelectors } from '../../reducers/addresses';
import { LabeledTextArea } from '../../components/inputs/text';

import styles from './ProfileAddressForm.module.scss';
import { LabeledCheckbox } from '../../components/inputs/checkbox';

class ProfileAddressForm extends Component {
  renderDelete() {
    const { isEditing, onDelete, initialValues, t } = this.props;
    if (isEditing) {
      return (
        <div>
          <PinkButton
            medium
            minWidth={'112'}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(initialValues.id);
            }}
          >
            {t('deleteAddress')}
          </PinkButton>
        </div>
      );
    }
    return null;
  }

  isSaveEnabled() {
    const { isValid, selectedAddress } = this.props;
    return isValid && selectedAddress.location;
  }

  render() {
    const { isEditing, handleSubmit, t } = this.props;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.verticalScrollContainer}>
          <div className={styles.title}>
            {isEditing ? t('editAddress') : t('createAddress')}
          </div>

          <div className={styles.fields}>
            <Field
              component={LabeledTextInput}
              label={t('name')}
              maxlength={40}
              name="name"
            />
            <Field
              component={AsideMapSearchBox}
              onSelectAddress={this.props.onSelectAddress}
              onLocationChange={this.props.onLocationChange}
              onChangeAddressObj={this.props.onChangeAddressObj}
              onSetNewAddressLocation={this.props.onSetNewAddressLocation}
              label={t('address')}
              name="address"
            />
            <Field
              component={LabeledTextArea}
              big={true}
              autoHeight={true}
              label={t('addressComment')}
              twoLinePlaceholder={true}
              name="comment"
            />

            <Field
              component={LabeledCheckbox}
              label={t('setAPrimary')}
              name="favorite"
              type="checkbox"
            />
          </div>
        </div>

        <div className={styles.btnWrapper}>
          <div className={styles.btn}>
            <Link to={getPathForUserAddresses()}>
              <GrayButtonTransparent medium>
                {t('cancel')}
              </GrayButtonTransparent>
            </Link>
          </div>

          <div className={styles.btn}>{this.renderDelete()}</div>

          <div className={styles.btn}>
            <GreenButton medium minWidth={'112'} type="submit">
              {t('save')}
            </GreenButton>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!values.name) errors.name = t('enterAddressName');
  if (!values.address) errors.address = t('pleaseSelectAddress');
  if (!values.comment) errors.comment = t('pleaseProvideCommentToAddress');

  return errors;
};

export default compose(
  withTranslation(),
  reduxForm({ validate }),
  connect((state, ownProps) => ({
    isValid: isValid(ownProps.form)(state),
    selectedAddress: addressesSelectors.getSelectedAddress(state),
  })),
)(ProfileAddressForm);
