import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buttonsPropTypes from './buttonsPropTypes';
import { Svg } from '../ui';
import classNames from 'classnames';

import styles from './InitialButton.module.scss';

class InitialButton extends Component {
  onClick = (e) => {
    const {
      onClick,
      stopPropagationRequired,
      disabled,
      onDisabledClick,
    } = this.props;

    if (stopPropagationRequired) {
      e.stopPropagation();
    }

    if (disabled) {
      e.preventDefault();

      if (typeof onDisabledClick === 'function') {
        onDisabledClick(e);
      }
      return;
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  renderIcon = (iconName, iconPos, currentPos, iconNoFill, iconSmall) => {
    if (!iconName) return;
    if (iconPos === currentPos) {
      return (
        <div
          className={classNames(styles.iconContainer, [
            styles[currentPos],
            {
              [styles.iconNoFill]: iconNoFill,
              [styles.iconSmall]: iconSmall,
            },
          ])}
        >
          <Svg src={iconName} />
        </div>
      );
    }
  };

  render() {
    const {
      className,
      type,
      iconName,
      iconPos,
      iconNoFill,
      iconSmall,
      minWidth,
      small,
      medium,
      big,
    } = this.props;
    return (
      <button
        onClick={this.onClick}
        className={classNames(styles.button, [className], {
          [styles.small]: small,
          [styles.medium]: medium,
          [styles.big]: big,
        })}
        type={type}
        style={minWidth && { minWidth: `${minWidth}px` }}
      >
        {this.renderIcon(iconName, iconPos, 'left', iconNoFill, iconSmall)}
        {this.props.children}
        {this.renderIcon(iconName, iconPos, 'right', iconNoFill, iconSmall)}
      </button>
    );
  }
}

InitialButton.propTypes = {
  ...buttonsPropTypes,
  className: PropTypes.string,
};

export default InitialButton;
