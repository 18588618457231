import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { orderActions } from '../../../reducers/order';
import { createOrderActions } from '../../../reducers/createOrder';
import { PinkButton } from '../../../components/buttons';

import styles from './ConfirmOrderDelete.module.scss';

class ConfirmOrderDelete extends Component {
  onDelete = () => {
    const { order, deletingFromOrderInfo } = this.props;

    if (deletingFromOrderInfo) {
      this.props.deleteOrderFromOrderInfo(order.id);
    } else {
      this.props.deleteEditingOrder();
    }
    ReactGA.event({
      category: 'event',
      action: 'click',
      label: 'gauqmeba',
    });

    this.props.onClose();
  };

  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('deleteTheOrder')}</div>
        <div className={styles.text}>
          {t('areYouSureYouWantToDeleteTheOrder')}
        </div>
        <div className={styles.btn}>
          <PinkButton onClick={this.onDelete}>{t('delete')}</PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    deleteOrderFromOrderInfo: orderActions.deleteOrder,
    deleteEditingOrder: createOrderActions.deleteEditingOrder,
  }),
)(ConfirmOrderDelete);
