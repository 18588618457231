const MODULE_PREFIX = 'maps/';

export const mapsTypes = {
  GET_TASKER_LOCATION: MODULE_PREFIX + 'GET_TASKER_LOCATION'
};

const initialState = {
  taskerLocation: null,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};
  switch (action.type) {
    case mapsTypes.GET_TASKER_LOCATION:
      return {
        ...state,
        taskerLocation: payload.location,
      };
    default:
      return state;
  }
};

export const mapsActions = {
  getTaskerLocation: (orderId) => ({
    type: mapsTypes.GET_TASKER_LOCATION,
    payload: {
      orderId,
    },
  }),
};

export const mapsSelectors = {
  getTaskerLocation: (state) => state.taskerLocation,
};
