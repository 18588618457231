import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { orderActions, orderSelectors } from '../../../reducers/order';
import { Loader } from '../../../components/ui';
import Chat from '../../chat/Chat';
import { getPathForOrderInfo } from '../../routing/constants/pathHelpers';
import { dialogActions } from '../../dialog/store';
import { chatSelectors } from '../../../reducers/chat';

class ChatWithTasker extends Component {
  state = {
    chat: null,
  };

  getOrderId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    if (this.props.order && this.props.order.tasker.blocked) {
      this.props.history.push(getPathForOrderInfo(this.props.order.id));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatList !== this.props.chatList && this.props.order) {
      const findChatById = (chat, index) => {
        if (chat.id !== this.props.order.chat_id) return false;
        return index;
      };

      const indexClosedChat = this.props.chatList.findIndex(findChatById);
      this.setState({
        chat: this.props.chatList[indexClosedChat],
      });
    }
  }

  render() {
    const { order, orderLoading } = this.props;
    const chatClosed =
      (this.state.chat && this.state.chat.closed) ||
      (order && order.tasker.blocked);

    if (chatClosed)
      this.props.history.push(getPathForOrderInfo(this.props.order.id));

    return (
      <Loader loading={orderLoading}>
        <Chat chatId={order.chat_id} order={order} />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  order: orderSelectors.getOrder(state),
  orderLoading: orderSelectors.getOrderLoading(state),
  chatList: chatSelectors.getChatList(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    loadOrder: orderActions.loadOrder,
    openChatIsNotAvailablePopup: dialogActions.openChatIsNotAvailable,
  }),
  withRouter,
)(ChatWithTasker);
