import { OrderStatusesEnum } from 'utils/enum';

export const isCurrentOrder = (order: OrderType) => {
  const regex = new RegExp(
    `${OrderStatusesEnum.IN_PROGRESS}|${OrderStatusesEnum.VIEWED}|${OrderStatusesEnum.PAID}|${OrderStatusesEnum.TRANSPORTATION}`,
    'g',
  );
  if (regex.test(order.status)) return true;
  else return false;
};
