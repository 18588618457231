import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import styles from './Chat.module.scss';
import InputBlock from './InputBlock';
import ChatHeader from './ChatHeader';
import { chatActions, chatSelectors } from '../../reducers/chat';
import { bindActionCreators } from 'redux';
import { wsActions } from '../../sagas/websocketSaga';
import { v1, v4 } from 'uuid';
import MessageListBlock from './MessageListBlock';
import { dialogActions } from '../dialog/store';
import { userSelectors } from '../../reducers/user';

class Chat extends PureComponent {
  chatWrapper = React.createRef();

  componentDidMount() {
    this.startChat();
    window.scroll(0, 100);
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isConnected && this.props.isConnected) {
      this.props.enterChat({ room: this.props.chatId });
    }
  }

  componentWillUnmount() {
    this.props.openChatPopupById(this.props.chatId);
    this.props.leaveChat({ room: this.props.chatId });
    this.props.clearMessages();
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false,
    );
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.leaveChat();
    } else {
      this.startChat();
    }
  };

  startChat = () => {
    const { supportChat, chatId, isConnected } = this.props;

    this.props.setCurrentChat(chatId);

    if (!supportChat || (supportChat && supportChat.id !== chatId)) {
      this.props.getMessages({ chatId });
    }

    if (isConnected) {
      this.props.enterChat({ room: chatId });
    }
  };

  leaveChat = () => {
    this.props.leaveChat({ room: this.props.chatId });
  };

  sendMessage = (messageText = '') => {
    const { chatId } = this.props;

    const message = {
      type: 'TEXT',
      guid: v4(),
      room: chatId,
      content: messageText,
      timezone: '+03:00',
      // created_at: Math.floor(Date.now()) + timeDifferenceWithServer * 1000
    };

    this.props.sendMessage(message);
  };

  onDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      const { chatId, timeDifferenceWithServer } = this.props;

      accepted.forEach((file) => {
        if (file instanceof File) {
          if (file.size > 10485760 * 2) {
            this.props.openUploadImageErrorPopup({ maxSize: 20 });
          } else {
            this.props.uploadChatImage({
              file: file,
              clientGeneratedId: v1(),
              chatId,
              createdAt:
                Math.floor(Date.now()) + timeDifferenceWithServer * 1000,
              userId: this.props.profile ? this.props.profile.id : null,
            });
          }
        }
      });
    }

    if (rejected.length > 0) {
      this.props.openUploadImageErrorPopup();
    }
  };

  render() {
    const { chatId, supportChat, order } = this.props;
    let isChatWithSupport;

    if (supportChat) {
      isChatWithSupport = chatId === supportChat.id;
    } else {
      isChatWithSupport = false;
    }

    return (
      <div
        ref={(ref) => (this.chatWrapper = ref)}
        className={styles.chatWrapper}
      >
        <div className={styles.chat}>
          <ChatHeader order={order} />

          <MessageListBlock
            chatRoom={chatId}
            isSupportChat={isChatWithSupport}
          />

          <InputBlock onSubmit={this.sendMessage} onDrop={this.onDrop} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConnected: chatSelectors.isConnectionInstalled(state),
  supportChat: chatSelectors.getSupportChat(state),
  profile: userSelectors.getUserProfile(state),
  messages: chatSelectors.getMessageList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMessages: chatActions.getMessageList,
      setCurrentChat: chatActions.setCurrentChat,
      clearMessages: chatActions.clearMessages,
      openChatPopupById: chatActions.openChatPopupById,
      uploadChatImage: chatActions.uploadChatImage,
      sendMessage: wsActions.sendMessage,
      enterChat: wsActions.enterChat,
      leaveChat: wsActions.leaveChat,
      openUploadImageErrorPopup: dialogActions.openUploadImageErrorPopup,
    },
    dispatch,
  );

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Chat);
