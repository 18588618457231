import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withAuthData } from '../../hoc';
import { AddressesList } from '../../../components/common/addresses/index';
import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import {
  createOrderAddressActions,
  createOrderAddressSelectors,
} from './../store';
import {
  GrayButtonTransparent,
  GreenButton,
  GreenButtonTransparent,
} from '../../../components/buttons';
import { AsideMapSearchBox } from '../../../components/inputs/map';
import { getPathForEditOrder } from '../../routing/constants/pathHelpers';
import { getPathForOrderAddressCreate } from '../../routing/constants/pathHelpers';

import styles from './../CreateOrderMap.module.scss';
import Loader from '../../../components/ui/Loader';
import { bindActionCreators } from 'redux';
import LabeledTextArea from '../../../components/inputs/text/LabeledTextArea';

class AddressesView extends Component {
  state = {
    currentAddress: '',
    currentComment: '',
    address: {},
    showErrors: false,
  };

  componentDidMount() {
    this.props.loadEditingOrder();

    if (this.props.userLoggedIn) {
      this.props.getAddresses();
    }

    if (this.props.orderAddress.address === null) return;

    if (this.props.editingAddress === undefined) return;
    this.setState({
      currentAddress: this.props.editingAddress.address
        ? this.props.editingAddress.address
        : '',
      currentComment: this.props.editingAddress.comment
        ? this.props.editingAddress.comment
        : '',
      address: { ...this.props.editingAddress },
    });

    if (this.props.selectedAddress.address === null) return;

    this.setState({
      currentAddress: this.props.selectedAddress.address
        ? this.props.selectedAddress.address
        : '',
      currentComment: this.props.selectedAddress.comment
        ? this.props.selectedAddress.comment
        : '',
      address: { ...this.props.selectedAddress },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
      this.props.getAddresses();
    }

    if (
      prevProps.editingAddress === undefined &&
      this.props.editingAddress === undefined
    )
      return;

    if (
      prevProps.editingAddress.address !== this.props.editingAddress.address
    ) {
      this.setState({
        currentAddress: this.props.editingAddress.address
          ? this.props.editingAddress.address
          : '',
        currentComment: this.props.editingAddress.comment
          ? this.props.editingAddress.comment
          : '',
        address: { ...this.props.editingAddress },
      });
    }

    if (
      prevProps.selectedAddress.address === null &&
      this.props.selectedAddress.address === null
    )
      return;

    if (
      prevProps.selectedAddress.address !== this.props.selectedAddress.address
    ) {
      this.setState({
        currentAddress: this.props.selectedAddress.address
          ? this.props.selectedAddress.address
          : '',
        currentComment: this.props.selectedAddress.comment
          ? this.props.selectedAddress.comment
          : '',
        address: { ...this.props.selectedAddress },
      });
    }

    if (
      prevProps.selectedAddress.comment !== this.props.selectedAddress.comment
    ) {
      this.setState({
        currentAddress: this.props.selectedAddress.address
          ? this.props.selectedAddress.address
          : '',
        currentComment: this.props.selectedAddress.comment
          ? this.props.selectedAddress.comment
          : '',
        address: { ...this.props.selectedAddress },
      });
    }
  }

  isSelectedFromExisting() {
    const { addresses, editingAddress, selectedAddress } = this.props;

    if (editingAddress.id) {
      return true;
    }

    return (
      !!addresses.find(
        (address) => editingAddress.address === address.address,
      ) ||
      !!addresses.find((address) => selectedAddress.address === address.address)
    );
  }

  isAttachingAddressDisabled() {
    const { userLoggedIn } = this.props;

    if (!userLoggedIn) {
      return false;
    }

    return !this.isSelectedFromExisting();
  }

  renderAddToMyPlacesLink = () => {
    const { userLoggedIn, t } = this.props;

    if (!userLoggedIn) return;

    return (
      <div className={classNames(styles.btnWrapper, styles.MarginBottom)}>
        <Link
          to={getPathForOrderAddressCreate()}
          disabled={!this.isAttachingAddressDisabled()}
        >
          <GreenButtonTransparent
            small={true}
            disabled={!this.isAttachingAddressDisabled()}
          >
            {'+' + t('addToMyPlaces')}
          </GreenButtonTransparent>
        </Link>
      </div>
    );
  };

  isDisabledSaveAddress = () => {
    return (
      this.state.address.address === undefined ||
      this.state.address.address === null ||
      this.state.address.comment === undefined ||
      this.state.address.comment === null ||
      this.state.address.comment.length === 0
    );
  };

  isExistingToAddresses = (addressesList, searchAddress) => {
    return addressesList.find((address) => address.address === searchAddress);
  };

  onChangeComment = (value) => {
    this.setState({ currentComment: value });
    this.props.changeSelectedAddressComment(value);
  };

  showErrors = () => {
    if (!this.state.showErrors) {
      this.setState({ showErrors: !this.state.showErrors });
    }
  };

  showAddressError = () => {
    if (this.state.currentAddress || !this.state.showErrors) return null;
    const { t } = this.props;

    return t('pleaseSelectAddress');
  };

  showCommentError = () => {
    if (this.state.currentComment || !this.state.showErrors) return null;
    const { t } = this.props;

    return t('pleaseProvideCommentToAddress');
  };

  onSaveAddress = () => {
    const { orderId } = this.props;

    this.props.saveAddress(orderId, this.state.address);
  };

  onCangeAsideMapSearchBoxValue = (value) => {
    this.setState({ currentAddress: value });
    this.props.changeSearchBoxValue(value);
  };

  render() {
    const {
      addressesLoading,
      addresses,
      orderId,
      history,
      t,
      errors,
      changeLocationFromSearchBox,
      selectAddress,
      setNewAddressLocation,
      changeAddressObj,
    } = this.props;

    return (
      <Loader loading={addressesLoading}>
        <div className={styles.verticalScrollContainer}>
          <AsideMapSearchBox
            label={t('address')}
            value={this.state.currentAddress}
            error={errors.addressError}
            onChange={this.onCangeAsideMapSearchBoxValue}
            onLocationChange={changeLocationFromSearchBox}
            onSelectAddress={selectAddress}
            onChangeAddressObj={changeAddressObj}
            onSetNewAddressLocation={setNewAddressLocation}
          />

          <LabeledTextArea
            required={true}
            big={true}
            autoHeight={true}
            label={t('addressComment')}
            twoLinePlaceholder={true}
            length={true}
            maxlength={255}
            maxHeight={250}
            error={this.showCommentError()}
            value={this.state.currentComment}
            onChange={(e) => this.onChangeComment(e.target.value)}
          />

          {this.renderAddToMyPlacesLink()}

          {addresses && (
            <AddressesList
              title={t('myPlaces')}
              addresses={addresses}
              history={history}
              isExistingToAddresses={this.isExistingToAddresses}
              editingAddress={this.state.address}
              setNewAddressLocation={this.props.setNewAddressLocation}
              changeSelectedAddressComment={
                this.props.changeSelectedAddressComment
              }
              changeAddress={this.props.changeAddressObj}
            />
          )}
        </div>

        <div className={styles.btnWrapper}>
          <Link to={getPathForEditOrder(orderId)}>
            <GrayButtonTransparent
              iconName={'arrowLeft'}
              iconPos={'left'}
              iconSmall
            >
              {t('back')}
            </GrayButtonTransparent>
          </Link>

          <div onClick={this.getError}>
            <GreenButton
              onClick={this.onSaveAddress}
              onDisabledClick={() => this.showErrors()}
              disabled={this.isDisabledSaveAddress()}
            >
              {t('selectAddress')}
            </GreenButton>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: addressesSelectors.selectAddresses(state),
  addressesLoading: addressesSelectors.selectAddressesLoading(state),
  history: addressesSelectors.getAddressesHistory(state),
  orderId: createOrderSelectors.getOrderId(state),
  orderAddress: createOrderSelectors.getOrderAddress(state),
  editingAddress: createOrderAddressSelectors.getAddress(state),
  errors: createOrderAddressSelectors.getErrors(state),
  selectedAddress: addressesSelectors.getSelectedAddress(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ...createOrderAddressActions,
      loadEditingOrder: createOrderActions.loadEditingOrder,
      getAddresses: addressesActions.getAddresses,
      selectAddress: addressesActions.selectAddress,
      setNewAddressLocation: addressesActions.setNewAddressLocation,
      changeSelectedAddressComment:
        addressesActions.changeSelectedAddressComment,
      changeSelectedAddressLocation:
        addressesActions.changeSelectedAddressLocation,
    },
    dispatch,
  ),
});

export default compose(
  withTranslation(),
  withRouter,
  withAuthData,
  connect(mapStateToProps, mapDispatchToProps),
)(AddressesView);
