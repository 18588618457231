import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';

import { saveOrderAddress } from '../../services/ordersListService';
import { getAddressByLocation } from '../../utils/mapUtils';
import { getPathForEditOrder } from '../routing/constants/pathHelpers';
import { dialogActions } from '../dialog/store';
import { createOrderSelectors } from '../../reducers/createOrder';

/* ACTION TYPES */

const MODULE_PREFIX = 'address/';

export const types = {
  INITIALIZE_ORDER_ADDRESS_STATE:
    MODULE_PREFIX + 'INITIALIZE_ORDER_ADDRESS_STATE',

  CHANGE_ADDRESS_OBJECT: MODULE_PREFIX + 'CHANGE_ADDRESS_OBJECT',
  CLEAR_ADDRESS_OBJECT: MODULE_PREFIX + 'CLEAR_ADDRESS_OBJECT',
  CHANGE_COMMENT: MODULE_PREFIX + 'CHANGE_COMMENT',
  CHANGE_SEARCH_BOX_VALUE: MODULE_PREFIX + 'CHANGE_SEARCH_BOX_VALUE',
  CHANGE_LOCATION_FROM_SEARCH_BOX_SUBMIT:
    MODULE_PREFIX + 'CHANGE_LOCATION_FROM_SEARCH_BOX_SUBMIT',

  SAVE_ADDRESS_REQUEST: MODULE_PREFIX + 'SAVE_ADDRESS_REQUEST',
  SAVE_ADDRESS_SUCCESS: MODULE_PREFIX + 'SAVE_ADDRESS_SUCCESS',
  SAVE_ADDRESS_FAILURE: MODULE_PREFIX + 'SAVE_ADDRESS_FAILURE',

  CHANGE_ADDRESS_FROM_MAP_REQUEST:
    MODULE_PREFIX + 'CHANGE_ADDRESS_FROM_MAP_REQUEST',
  CHANGE_ADDRESS_FROM_MAP_SUCCESS:
    MODULE_PREFIX + 'CHANGE_ADDRESS_FROM_MAP_SUCCESS',
  CHANGE_ADDRESS_FROM_MAP_FAILURE:
    MODULE_PREFIX + 'CHANGE_ADDRESS_FROM_MAP_FAILURE',

  CLEAR_CREATE_ORDER_ADDRESSES_STATE:
    MODULE_PREFIX + 'CLEAR_CREATE_ORDER_ADDRESSES_STATE',
};

/* INITIAL STATE */

const initialErrors = {
  addressError: '',
  commentError: '',
};

const initialState = {
  address: {
    address: '',
    error: null,
    id: null,
    location: null,
    comment: '',
    timestamp: 0,
  },
  addressLoading: false,
};

/* REDUCER HELPERS */

const helpers = {
  changeAddressObj: (state, { address }) => ({
    ...state,
    addressError: null,
    address: { ...address, timestamp: Date.now() },
  }),

  deleteAddressObj: (state) => ({
    ...state,
    address: initialState.address,
  }),

  changeStateOnSearchBoxValueChnage: (state, { value }) => ({
    ...state,
    addressError: null,
    address: {
      comment: state.address.comment,
      address: value,
      location: state.address.location,
    },
  }),

  changeLocationCoordinates: (state, { location }) => ({
    ...state,
    address: {
      address: state.address.address,
      location,
    },
  }),

  changeAddressFromMapRequest: (state, { location }) => ({
    ...state,
    ...initialErrors,
    address: {
      location,
      address: '',
      comment: state.address.id ? '' : state.address.comment,
      new: true,
    },
  }),

  changeAddressComment: (state, { comment }) => ({
    ...state,
    commentError: '',
    address: {
      ...state.address,
      comment,
    },
  }),

  changeAddressFromMapSuccess: (state, { address }) => ({
    ...state,
    address: {
      ...state.address,
      address,
      comment: state.address.comment,
    },
  }),

  changeAddressFromMapFailure: (state, { error }) => ({
    ...state,
    addressError: error,
    address: {
      ...state.address,
      comment: state.address.comment,
      error,
    },
  }),
};

/* REDUCER */

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_ADDRESS_OBJECT:
      return helpers.changeAddressObj(state, action);

    case types.CLEAR_ADDRESS_OBJECT:
      return helpers.deleteAddressObj(state, action);

    case types.CHANGE_COMMENT:
      return helpers.changeAddressComment(state, action);

    case types.CHANGE_SEARCH_BOX_VALUE:
      return helpers.changeStateOnSearchBoxValueChnage(state, action);

    case types.CHANGE_LOCATION_FROM_SEARCH_BOX_SUBMIT:
      return helpers.changeLocationCoordinates(state, action);

    case types.CHANGE_ADDRESS_FROM_MAP_REQUEST:
      return helpers.changeAddressFromMapRequest(state, action);

    case types.CHANGE_ADDRESS_FROM_MAP_SUCCESS:
      return helpers.changeAddressFromMapSuccess(state, action);

    case types.CHANGE_ADDRESS_FROM_MAP_FAILURE:
      return helpers.changeAddressFromMapFailure(state, action);

    case types.CLEAR_CREATE_ORDER_ADDRESSES_STATE:
      return initialState;

    default:
      return state;
  }
};

/* ACTION CREATORS */

const initializeOrderAddressState = (address) => ({
  type: types.INITIALIZE_ORDER_ADDRESS_STATE,
  address,
});

const changeAddressObj = (address) => ({
  type: types.CHANGE_ADDRESS_OBJECT,
  address,
});

const deleteAddressObj = () => ({
  type: types.CLEAR_ADDRESS_OBJECT,
});

const changeSearchBoxValue = (value) => ({
  type: types.CHANGE_SEARCH_BOX_VALUE,
  value,
});

const changeAddressComment = (e) => ({
  type: types.CHANGE_COMMENT,
  comment: e.target.value,
});

const changeLocationFromSearchBox = (location) => ({
  type: types.CHANGE_LOCATION_FROM_SEARCH_BOX_SUBMIT,
  location,
});

const changeAddressFromMap = (location) => ({
  type: types.CHANGE_ADDRESS_FROM_MAP_REQUEST,
  location,
});

const changeAddressFromMapSuccess = (address) => ({
  type: types.CHANGE_ADDRESS_FROM_MAP_SUCCESS,
  address,
});

const changeAddressFromMapFailure = (error) => ({
  type: types.CHANGE_ADDRESS_FROM_MAP_FAILURE,
  error,
});

const saveAddress = (orderId, address) => ({
  type: types.SAVE_ADDRESS_REQUEST,
  orderId,
  address,
});

const clearCreateOrderAddressState = () => ({
  type: types.CLEAR_CREATE_ORDER_ADDRESSES_STATE,
});

export const createOrderAddressActions = {
  initializeOrderAddressState,
  changeAddressComment,
  changeAddressObj,
  deleteAddressObj,
  changeSearchBoxValue,
  changeLocationFromSearchBox,
  changeAddressFromMap,
  saveAddress,
  clearCreateOrderAddressState,
};

/* SELECTORS */

export const createOrderAddressSelectors = {
  getAddress: (state) => state.createOrderMap.address,

  getAddressComment: (state) => state.createOrderMap.address.comment,

  getErrors: (state) => ({
    addressError: state.createOrderMap.addressError,
    commentError: state.createOrderMap.commentError,
  }),
};

/* SAGAS */

function* onAddressChangeFromMap({ location }) {
  try {
    const address = yield call(getAddressByLocation, location);

    yield put(changeAddressFromMapSuccess(address));
  } catch (error) {
    yield put(changeAddressFromMapFailure(error));
  }
}

const formatAddressForBackend = (address) => {
  if (address.id) {
    return { address_id: address.id };
  }

  return {
    address: {
      address: address.address,
      comment: address.comment,
      location: address.location,
      favorite: address.favorite,
      save: false,
    },
  };
};

function* onOrderErrors(error) {
  console.log('%c IN onOrderErrors = ', 'color: blue', error);

  if (error.code && error.code === 'ORDER_IS_ALREADY_CREATED') {
    yield put(dialogActions.openSubmitOrderErrorPopup());
  }
}

function* onSaveAddress({ orderId, address, fromProfile }) {
  try {
    debugger;

    const selectedAddress =
      address || (yield select(createOrderAddressSelectors.getAddress));

    const formattedAddress = yield call(
      formatAddressForBackend,
      selectedAddress,
    );

    const result = yield call(saveOrderAddress, orderId, formattedAddress);

    yield put({ type: types.SAVE_ADDRESS_SUCCESS, result });

    yield put(push(getPathForEditOrder(orderId)));
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.SAVE_ADDRESS_FAILURE, error });
  }
}

export const createOrderMapSagas = {
  watchCreateOrderAddressInitialization: function* () {
    while (true) {
      yield take(types.INITIALIZE_ORDER_ADDRESS_STATE);
      const order = yield select(createOrderSelectors.getOrder);

      if (order.address && order.address.address && order.address.location) {
        yield put(changeAddressObj(order.address));
      }
    }
  },

  watchSelectingFromMap: function* () {
    yield takeLatest(
      types.CHANGE_ADDRESS_FROM_MAP_REQUEST,
      onAddressChangeFromMap,
    );
  },

  watchChangeComment: function* () {
    yield takeLatest(types.CHANGE_COMMENT, changeAddressComment);
  },

  watchOrderAddressSave: function* () {
    yield takeLatest(types.SAVE_ADDRESS_REQUEST, onSaveAddress);
  },
};
