import { take, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { change } from 'redux-form';

import {
  getAddresses,
  getAddress,
  createAddress,
  deleteAddress,
  editAddress,
  makeFavorite,
} from '../services/addressService';
import { addressesTypes, addressesActions } from '../reducers/addresses';
import { getAddressByLocation } from '../utils/mapUtils';
import { getPathForUserAddresses } from '../containers/routing/constants/pathHelpers';
import { PROFILE_ADDRESS_FORM } from '../containers/addresses/ProfileAddressesCreateEdit';
import { ADDRESS_FORM } from '../containers/createOrderMap/components/AddressesCreateEdit';
import { ADDRESS_FORM_NEW } from '../containers/createOrderMapNew/components/AddressesCreateEdit';
import createTaskRoutes from 'containers/createTaskFullFlow/helper/createTaskRoutes';
import { goBack } from 'react-router-redux';

export function* watchLoadAddresses() {
  while (true) {
    try {
      yield take(addressesTypes.GET_ADDRESSES_LIST_REQUEST);

      const { collection, history } = yield call(getAddresses);

      yield put({
        type: addressesTypes.GET_ADDRESSES_LIST_SUCCESS,
        payload: {
          addresses: collection,
          history,
        },
      });
    } catch (e) {
      yield put({ type: addressesTypes.GET_ADDRESSES_LIST_FAILURE });
    }
  }
}

export function* watchLoadAddress() {
  while (true) {
    try {
      const { payload } = yield take(addressesTypes.GET_ADDRESS_REQUEST);

      const { model } = yield call(getAddress, payload.id);

      yield put({
        type: addressesTypes.GET_ADDRESS_SUCCESS,
        payload: {
          address: model,
        },
      });
    } catch (e) {
      yield put({ type: addressesTypes.GET_ADDRESS_FAILURE });
    }
  }
}

export function* watchSetNewAddressLocation() {
  while (true) {
    try {
      const { payload } = yield take(
        addressesTypes.SET_NEW_ADDRESS_LOCATION_REQUEST,
      );

      const address = yield call(getAddressByLocation, payload.location);

      console.log(
        '%c watchSetNewAddressLocation address = ',
        'color: blue',
        address,
      );

      yield put(change(PROFILE_ADDRESS_FORM, 'address', address));
      yield put(change(ADDRESS_FORM, 'address', address));
      yield put(change(ADDRESS_FORM_NEW, 'address', address));

      yield put({
        type: addressesTypes.SET_NEW_ADDRESS_LOCATION_SUCCESS,
        payload: {
          address,
          mapClick: payload.mapClick || false,
        },
      });
    } catch (error) {
      yield put(change(PROFILE_ADDRESS_FORM, 'address', ''));

      yield put(change(ADDRESS_FORM, 'address', ''));

      yield put({
        type: addressesTypes.SET_NEW_ADDRESS_LOCATION_FAILURE,
        payload: {
          error,
        },
      });
    }
  }
}

export function* watchChangeSelectedAddressComment() {
  while (true) {
    try {
      const { payload } = yield take(
        addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_REQUEST,
      );
      yield put({
        type: addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_SUCCESS,
        payload: {
          comment: payload.comment,
        },
      });
    } catch (error) {
      yield put({
        type: addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_FAILURE,
        error,
      });
    }
  }
}

export function* watchSaveAddress() {
  while (true) {
    try {
      const {
        payload: {
          address,
          isEditing,
          isOrderCreate,
          newVersion,
          isSave,
          favorite,
          fromProfile,
        },
      } = yield take(addressesTypes.SAVE_ADDRESS_REQUEST);

      let data;
      if (isEditing) {
        data = yield call(editAddress, address);
      } else {
        data = yield call(createAddress, address);
      }

      if (isSave) {
        // yield put({type : types.USER_ADDRESSES_REQUEST})
        yield put(addressesActions.getAddresses());
      }
      if (newVersion) {
        if (favorite) {
          yield call(makeFavorite, data.model);
        }
        if (fromProfile) {
          yield put(goBack());
        } else {
          yield put(push(createTaskRoutes.address));
        }

        // if (!isOrderCreate) {
        //   yield put(push(getPathForUserAddresses()));
        // }
      }

      yield put({ type: addressesTypes.SAVE_ADDRESS_SUCCESS, data });
    } catch (e) {
      yield put({ type: addressesTypes.SAVE_ADDRESS_FAILURE });
    }
  }
}

export function* watchDeleteAdddress() {
  while (true) {
    try {
      const { payload } = yield take(addressesTypes.DELETE_ADDRESS_REQUEST);
      yield call(deleteAddress, payload.id);

      yield put({ type: addressesTypes.DELETE_ADDRESS_SUCCESS });
      yield put(addressesActions.getAddresses());

      if (!payload.newVersion) yield put(push(getPathForUserAddresses()));
    } catch (error) {
      yield put({ type: addressesTypes.DELETE_ADDRESS_FAILURE });
    }
  }
}

export function* watchMakeFavorite() {
  while (true) {
    try {
      const {
        payload: { address },
      } = yield take(addressesTypes.MAKE_FAVORITE_REQUEST);
      yield call(makeFavorite, address);

      yield put({ type: addressesTypes.MAKE_FAVORITE_REQUEST });
      yield put({ type: addressesTypes.GET_ADDRESSES_LIST_REQUEST });
    } catch (error) {
      yield put({ type: addressesTypes.MAKE_FAVORITE_FAILURE });
    }
  }
}
