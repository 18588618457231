import {PageContent} from "../index";
import {Helmet} from "react-helmet";
import React from "react";

const BankCardSuccess = () => {
    return (
        <PageContent noBg>
            <Helmet>
                <title>Adding card</title>
            </Helmet>
           <h4>Card added successfully</h4>
        </PageContent>
    );
}

export default BankCardSuccess;
