import React from 'react';
import styles from './CheckList.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type CheckListPropsType = {
  tasks: TaskType[] | undefined;
  fromOrderDetails?: boolean;
  discountNumber: number;
  priceForPaid: number | undefined;
  additional_services: Array;
};

const CheckList = ({
  tasks,
  fromOrderDetails,
  discountNumber,
  priceForPaid,
  additional_services
}: CheckListPropsType) => {
  const { t } = useTranslation();

  const discount = discountNumber === 0 ? 0 : `-${discountNumber}`;

  return (
    <div
      className={`${styles.orderList}  ${
        fromOrderDetails ? styles.fromOrderDetails : ''
      }`}
    >
      <h5>{t('receipt')}</h5>
      <ul>
        {tasks?.map((val) => (
          <li className={classNames(styles.orderListItem)} key={val.id}>
            <div className={styles.text}>
              <p className={styles.selectedService}>
                {val.service.name} (x{val.quantity ?? '-'})
              </p>
            </div>
            <div className={styles.price}>
              <p className={styles.orderListPrice}>{val.price} ₾</p>
            </div>
          </li>
        ))}

        
        {
          additional_services.length > 0 ?
          // return (
          <li className={classNames(styles.orderListItem)}>
            <div className={styles.text}>
              <p className={styles.selectedService}>
                {t('roundedToMinimum')}
              </p>
            </div>
            <div className={styles.price}>
              <p className={styles.orderListPrice}>{additional_services[0].value} ₾</p>
            </div>
          </li>
          :
          // return (
            <></>
          // )
        }
        <li className={classNames(styles.orderListItem)}>
          <div className={styles.text}>
            <p className={styles.selectedService}>
              {t('promotionalCodeDiscount')}
            </p>
          </div>
          <div className={styles.price}>
            <p className={styles.orderListPrice}>{discount} ₾</p>
          </div>
        </li>

       
        <li
          className={classNames(
            styles.orderListItem,
            styles.bold,
            styles.withoutBorde,
          )}
        >
          <div className={styles.text}>
            <p className={styles.selectedService}>{t('total')}</p>
          </div>
          <div className={styles.price}>
            <p className={styles.orderListPrice}>{priceForPaid} ₾</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CheckList;
