import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PhoneConfirmForm } from '../../../components/common/phoneConfirm';
import { restorePasswordSelectors, restorePasswordActions } from '../store';

const CONFIRM_CODE_FORM = 'confirmCodeForm';

class RestoreConfirmPhone extends Component {
  onSubmit = (values) => {
    return new Promise((resolve, reject) =>
      this.props.confirmCode(values.code, reject),
    );
  };

  render() {
    const { smsCode, phone, codeConfirmLoading } = this.props;

    return (
      <PhoneConfirmForm
        form={CONFIRM_CODE_FORM}
        onSubmit={this.onSubmit}
        onResend={this.props.resendCodeRestore}
        phone={phone}
        codeConfirmLoading={codeConfirmLoading}
        smsCode={smsCode}
      />
    );
  }
}

export default connect(
  (state) => ({
    smsCode: restorePasswordSelectors.getSmsCode(state),
    phone: restorePasswordSelectors.getPhone(state),
    codeConfirmLoading: restorePasswordSelectors.getCodeConfirmLoading(state),
  }),
  restorePasswordActions,
)(RestoreConfirmPhone);
