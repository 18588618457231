import React, { Component } from 'react';
import BankCardSuccess from "../../components/ui/pages/BankCardSuccess"
import BankCardFailure from "../../components/ui/pages/BankCardFailure";
import {withRouter} from "react-router-dom";


class AddBankResult extends Component {

    isSuccess() {
        return this.props.match.path.includes('success');
    }

    render() {
        return this.isSuccess() ? <BankCardSuccess /> : <BankCardFailure />;
    }
}

export default withRouter(AddBankResult);
