import React from 'react';
import classNames from 'classnames';

import styles from './GrayButtonTransparent.module.scss';
import InitialButton from './InitialButton';

const GrayButtonTransparent = (props: BaseButtonPropsType) => (
  <InitialButton
    className={classNames(styles.button, {
      [styles.buttonDisabled]: props.disabled,
    })}
    {...props}
  />
);

export default GrayButtonTransparent;
