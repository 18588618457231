import { get, post, del } from './baseService';

export const getCardsList = () => get('/billing/cards');

export const createCard = () => post('/billing/create-card');

export const checkCard = (id) => post(`/billing/check-attempt/${id}`);

export const deleteCard = (id) => del(`/billing/cards/${id}`);

export const makeDefaultCard = (id) => post(`/billing/card-primary/${id}`);
