import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Svg } from 'components/ui';
import classNames from 'classnames';

import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';
import AddressForm from './AddressForm';
import { createOrderAddressActions } from '../store';
import styles from './AddressesCreateEdit.module.scss';
import { formValueSelector } from 'redux-form';
import createTaskRoutes from 'containers/createTaskFullFlow/helper/createTaskRoutes';
import { RootReduxState } from 'store';
import { addressActions } from 'containers/createTaskFullFlow/containers/chooseAddress/state/actions';
import { userSelectors } from 'reducers/user';
import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';

export const ADDRESS_FORM_NEW = 'addressForNew';

const adressTypes = [];
const selector = formValueSelector(ADDRESS_FORM_NEW);

type AddressesCreateEditPropType = {
  selectedId?: string;
  fromProfile: boolean;
};

const AddressesCreateEdit = ({
  selectedId,
  fromProfile,
}: AddressesCreateEditPropType) => {
  const [addressType, setAddressType] = useState(0);
  const [favorite, setFavorite] = useState(false);
  const { t } = useTranslation();
  const { replace } = useHistory();

  const dispatch = useDispatch();
  const { userLoggedIn, orderId, address, addresses, formValues } = useSelector(
    (state: RootReduxState) => ({
      userLoggedIn: userSelectors.isUserLoggedIn(state),
      orderId: state.createTaskFullFlow.currentOrder?.model?.id,
      address: addressesSelectors.getSelectedAddress(state),
      selectAddresses: addressesSelectors.selectAddresses(state),
      formValues: selector(state, 'name', 'address', 'comment'),
      addresses: state.addressStore.addresses,
    }),
  );

  useEffect(() => {
    if (userLoggedIn) {
      !addresses && dispatch(addressActions.getAddresses());
    }
  }, [userLoggedIn, addresses, dispatch]);

  const getFullAddressObject = (values) => {
    return {
      ...address,
      ...values,
      saved: true,
    };
  };

  const onSubmit = (values) => {
    const fullAddressObject = getFullAddressObject(values);
    if (userLoggedIn) {
      dispatch(
        addressesActions.saveAddress({
          address: fullAddressObject,
          isEditing: !!selectedId,
          isSave: true,
          isOrderCreate: true,
          newVersion: true,
          favorite,
          fromProfile,
        }),
      );
      // dispatch(addressActions.getAddresses());
      // dispatch(addressesActions.clearNewAddress());
    } else {
      orderId &&
        dispatch(
          createOrderAddressActions.saveAddress(orderId, fullAddressObject),
        );
      dispatch(createTaskActions.changeActiveStep(3));
    }
  };

  const onAddressTypeSelect = (addressType) => {
    setAddressType(addressType);
  };

  const initialAddress = useMemo(
    () =>
      selectedId
        ? addresses?.collection.find(({ id }) => id === +selectedId) ??
          undefined
        : undefined,
    [addresses, selectedId],
  );

  if (addresses && !initialAddress && !!selectedId) {
    replace(createTaskRoutes.address);
    return null;
  }

  return (
    <div className={classNames(styles.content, styles.expanded)}>
      <Helmet>
        <title>{t('createAddress')}</title>
      </Helmet>
      <div className={classNames(styles.contentWrapper)}>
        <div className={styles.collapsedContentContainer}>
          <div
            className={styles.imageContainer}
            style={{
              backgroundColor: adressTypes[addressType]?.backgroundColor,
            }}
          >
            {!!adressTypes[addressType]?.svg && (
              <Svg src={adressTypes[addressType]?.svg} className={styles.svg} />
            )}
          </div>
          <div className={styles.addressTextsContainer}>
            <h1 className={styles.addressTitle}>
              {/* {formValues.name ?? t('name')} */}
            </h1>
            {/* <p className={styles.addressDescription}>
              {formValues.address ?? t('address')}
            </p> */}
          </div>

          <div className={classNames(styles.hotButtonContainer)}>
            <div
              className={styles.hotButtonBackground}
              title={'close'}
              key={'asd'}
              onClick={(e) => {
                e.stopPropagation();
                replace(createTaskRoutes.address);
              }}
            >
              <Svg src={'close'} className={styles.svg} />
            </div>
          </div>
        </div>
      </div>
      <AddressForm
        form={ADDRESS_FORM_NEW}
        initialValues={initialAddress}
        onSubmit={onSubmit}
        addressType={addressType}
        onAddressTypeSelect={onAddressTypeSelect}
        setFavorite={setFavorite}
        favorite={favorite}
        isEditing={!!selectedId}
        fromProfile={fromProfile}
        addresses={addresses}
      />
    </div>
  );
};

export default React.memo(AddressesCreateEdit);
