import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { replace } from 'connected-react-router';
import {
  getPathForCreateNewTask,
  getPathForPaymentPage,
} from '../routing/constants/pathHelpers';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../reducers/createOrder';

export default (WrappedComponent) => {
  class WithCurrentOrderHOC extends Component {
    componentDidMount() {
      const { order } = this.props;
      if (!order.id) {
        this.props.loadEditingOrder();
      }
    }

    componentDidUpdate(prevProps) {
      const { tasksLoaded, tasks, order } = this.props;
      if (tasksLoaded && tasks.length === 0) {
        this.props.replace(getPathForCreateNewTask());
        // debugger;
      }
      if (!prevProps.order.id && order.id) {
        this.onOrderLoad();
        // debugger;
      }
    }

    onOrderLoad() {
      const { replace, order, match } = this.props;

      if (order.tasker) {
        replace(getPathForPaymentPage());
      }

      if (!order.tasker && match.path.includes('payment')) {
        replace('/');
      }
    }

    render() {
      const { order, tasksLoaded } = this.props;

      if (order.id && tasksLoaded) {
        return <WrappedComponent {...this.props} />;
      }
      return null;
    }
  }

  return compose(
    withRouter,
    connect(
      (state) => ({
        order: createOrderSelectors.getOrder(state),
        tasks: createOrderSelectors.getTasks(state),
        tasksLoaded: createOrderSelectors.areTasksLoaded(state),
      }),
      { ...createOrderActions, replace },
    ),
  )(WithCurrentOrderHOC);
};
