import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GreenBorderButton } from '../../../components/buttons';

import styles from './InviteUser.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootReduxState } from 'store';
import { userActions, userSelectors } from 'reducers/user';
import { Helmet } from 'react-helmet';
import { Loader } from 'components/ui';
import inviteFriend from 'assets/images/icons/invite-friend.png';
import LabeledTextInputNew from 'components/inputs/text/LabeledTextInputNew';

type InviteUserPropsType = {
  onClose: () => void;
};

let timeout;

const InviteUser = ({ onClose }: InviteUserPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const copyInput = useRef<HTMLInputElement>();
  const [isCopy, setIsCopy] = useState(false);

  const { profile, profileLoading } = useSelector((state: RootReduxState) => ({
    profile: userSelectors.selectUserProfile(state),
    profileLoading: userSelectors.selectUserProfileLoading(state),
  }));

  const hideMessage = () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setIsCopy(false);
  };

  useEffect(() => {
    if (!profile) dispatch(userActions.getUserProfile());

    return () => {
      hideMessage();
    };
  }, [dispatch, profile]);

  const onClickCopy = () => {
    copyInput.current?.select?.();

    try {
      document.execCommand('copy');

      setIsCopy(true);

      timeout = setTimeout(hideMessage, 5000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{t('inviteFriends')}</title>
      </Helmet>
      <img src={inviteFriend} className={styles.img} />
      <div className={styles.title}>{t('inviteFriends')}</div>
      <div className={styles.text}>{t('inviteuserText')}</div>
      <Loader loading={profileLoading} height={140}>
        <LabeledTextInputNew
          inputref={copyInput}
          readOnly={true}
          noReadOnlyStyles={true}
          value={profile.referral_code || t('notFilled')}
          className={styles.input}
          onCopyClick={onClickCopy}
        />
        <span className={styles.message} onClick={hideMessage}>
          {isCopy && t('copiedToClipboard')}
        </span>
        <div className={styles.btnWrapper}>
          <div className={styles.btnMessage}>
            <GreenBorderButton type={'button'} onClick={onClose}>
              {t('close')}
            </GreenBorderButton>
          </div>
        </div>
      </Loader>
    </div>
  );
};
export default InviteUser;
