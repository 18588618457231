import PropTypes from 'prop-types';
import { Lari } from '../../../components/ui';
import React from 'react';
import styles from './PhantomTask.module.scss';

const PhantomTask = ({ task }) => {
  return (
    <div key={task.label} className={styles.item}>
      <div className={styles.itemTitle}>
        {task.quantity && <span>{`(x${task.quantity}) `}</span>}

        {task.label}
      </div>
      <div className={styles.itemCost}>
        {task.value}
        <Lari big={true} />
      </div>
    </div>
  );
};

PhantomTask.propTypes = {
  task: PropTypes.object,
};

export default PhantomTask;
