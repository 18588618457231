import React, { Component } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { withReduxFormProps } from '../../../containers/hoc';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
// eslint-disable-next-line import/no-webpack-loader-syntax
// import '!style-loader!css-loader!./customDataPicker.module.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
// import '!style-loader!css-loader!./customPopupDatePicker.module.scss';
import './customPopupDatePicker.scss';

import { ReactSelect } from '../select';

class PopupDatePicker extends Component {
  state = {
    focused: this.props.autoFocus,
    date: moment(this.props.value),
  };

  onDateChange = (date) => {
    this.setState({ date: date });
  };

  onFocusChange = () => {
    this.setState({ focused: !this.state.focused });
  };

  renderReactSelectMonths = (month, onChange) => {
    const options = [];
    moment
      .months()
      .map((label, value) => options.push({ value: value, label: label }));

    return (
      <ReactSelect
        value={month}
        onChange={this.props.onChange}
        options={options}
        placeholder={'Month...'}
      />
    );
  };

  renderSelectMonths = (month, onChange) => {
    return (
      <select value={month} onChange={onChange}>
        {moment.months().map((label, value) => (
          <option value={value}>{label}</option>
        ))}
      </select>
    );
  };

  renderOption = (value) => {
    return <option value={value}>{value}</option>;
  };

  renderSelectYears = (count, year, onChange) => {
    const options = [];
    for (let i = 0; i < count; i++) {
      options.push(this.renderOption(moment().year() - i));
    }

    return (
      <select value={year} onChange={onChange}>
        {options}
      </select>
    );
  };

  renderReactSelectYears = (count, year) => {
    const options = [];
    for (let i = 0; i < count; i++) {
      const year = moment().year() - i;
      options.push({ value: year, label: year });
    }

    return (
      <ReactSelect
        value={year}
        onChange={this.props.onChange}
        options={options}
        placeholder={'Year...'}
      />
    );
  };

  render() {
    const { onChange, daySize, numberOfMonths, enableOutsideDays } = this.props;

    return (
      <SingleDatePicker
        date={this.state.date}
        focused={this.state.focused}
        onChange={onChange}
        onDateChange={this.onDateChange}
        onFocusChange={this.onFocusChange}
        daySize={daySize}
        numberOfMonths={numberOfMonths}
        enableOutsideDays={enableOutsideDays}
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div className="popupDatePickerHeader">
            <div className="popupDatePickerYear">
              {
                this.renderSelectYears(100, month.year(), (e) =>
                  onYearSelect(month, e.target.value),
                )
                // this.renderReactSelectYears(
                //   100,
                //   month.year(),
                //   onMonthSelect
                // )
              }
            </div>
            <div className="popupDatePickerMonth">
              {
                // this.renderSelectMonths(
                //   month.months(),
                //   (e) => onMonthSelect(month, e.target.value)
                // )
                this.renderReactSelectMonths(month.months(), onYearSelect)
              }
            </div>
          </div>
        )}
      />
    );
  }
}

PopupDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
  numberOfMonths: PropTypes.number,
};

PopupDatePicker.defaultProps = {
  numberOfMonths: 1,
  enableOutsideDays: true,
};

export default withReduxFormProps(PopupDatePicker);
