import React, { useRef } from 'react';
import styles from './SuperCategoryItem.module.scss';
import { Link } from 'react-router-dom';
import { useHover } from 'utils/hooks';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import createTaskRoutes from 'containers/createTaskFullFlow/helper/createTaskRoutes';

type TaskItemProps = {
  name: string | null;
  description: string | null;
  icon: string | null;
  onSelect?: () => void;
};
const SuperCategoryItem = ({
  name,
  description,
  icon,
  onSelect,
}: TaskItemProps) => {
  const { t } = useTranslation();

  const imageHoverRef = useRef(null);
  const isImageHover = useHover(imageHoverRef);
  const btnHoverRef = useRef(null);
  const isBtnHover = useHover(btnHoverRef);

  return (
    <Link
      to={createTaskRoutes.category}
      className={styles.superCategoryItemContainer}
      onClick={() => onSelect?.()}
    >
      <div className={styles.imageContainer} ref={imageHoverRef}>
        <div
          className={classNames(
            styles.imgBgr,
            isImageHover || isBtnHover ? styles.greenBg : null,
          )}
        />
        <img src={icon ?? ''} alt={'room'} className={styles.image} />
      </div>
      <p className={styles.title}>{name}</p>
      <span className={styles.subtitle}>{description}</span>
      <div className={styles.btnContainer} ref={btnHoverRef}>
        <button
          className={classNames(
            styles.btn,
            isImageHover || isBtnHover ? styles.greenBgBlackColor : null,
          )}
        >
          {t('continue')}
        </button>
      </div>
    </Link>
  );
};

export default SuperCategoryItem;
