import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SuperCategoryItem from 'containers/createTaskFullFlow/components/SuperCategoryItem';
import styles from './SuperCategory.module.scss';
import { RootReduxState } from 'store';
import { NewLoader } from 'components/ui';
import { createTaskActions } from '../../state/actions';
import { ServiceActions } from '../selectService/state/actions';
import { useHistory } from 'react-router-dom';

const SuperCategory = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { superCategories, taskFlow } = useSelector(
    (state: RootReduxState) => ({
      ...state.createTaskFullFlow,
    }),
  );

  useEffect(() => {
    if (history.action === 'POP') {
      dispatch(createTaskActions.changeActiveStep(9));
    }
  }, []);

  useEffect(() => {
    dispatch(ServiceActions.resetStore());
  }, [dispatch]);

  useEffect(() => {
    if (superCategories != null) {
      for (let i = 0; i < superCategories.length; i++) {
        dispatch(createTaskActions.fetchCategory(i, superCategories[i].id));
      }
    }
  }, [superCategories, dispatch]);

  const selectMainCategory = (index) => {
    dispatch(createTaskActions.selectSuperCategory(index));
    history.push('/product/category');
  };

  return (
    <div className={styles.content}>
      <h1 className={styles.title}>{t('repairEverything')}</h1>
      <div className={styles.itemContainer}>
        {/* {taskFlow.length < 3 ? ( */}
        {!superCategories ? (
          <NewLoader />
        ) : (
          superCategories?.map(({ name, icon, description, id }, index) => (
            <SuperCategoryItem
              key={id}
              name={name}
              icon={icon}
              onSelect={() => {
                selectMainCategory(index);
                // dispatch(createTaskActions.selectSuperCategory(index));
              }}
              description={description}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SuperCategory;
