import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Loader, Svg } from '../../components/ui';
import Linkify from 'react-linkify';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import styles from './ChatMessage.module.scss';

// eslint-disable-next-line import/no-webpack-loader-syntax
import './CustomLightbox.module.scss';
// import '!style-loader!css-loader!./CustomLightbox.module.scss';

class ChatMessage extends Component {
  state = {
    imageLoading: false,
    photoIndex: 0,
    isOpen: false,
  };

  componentDidMount() {
    if (this.props.type === 'IMAGE') {
      this.setState({
        imageLoading: true,
      });
    }
  }

  onLoadImage = (event) => {
    this.setState({
      imageLoading: false,
    });
  };

  getLoading = () => {
    const { content } = this.props;

    return content.indexOf('blob') === 0;
  };

  onOpenLightbox = (event) => {
    this.setState({ isOpen: true });
  };

  onCloseLightbox = (event) => {
    this.setState({ isOpen: false });
  };

  renderMessageContent = () => {
    const { uploadImages, type, content } = this.props;

    let uploadingImage = uploadImages.indexOf(content) > -1;

    switch (type) {
      case 'IMAGE':
        return (
          <div
            className={classNames(styles.messageBody, styles.messageImage)}
            onClick={this.onOpenLightbox}
          >
            <div className={styles.messageContent}>
              <img
                className={styles.messageImg}
                src={content}
                alt=""
                onLoad={this.onLoadImage}
              />
              <Loader
                loading={
                  this.getLoading() ? uploadingImage : this.state.imageLoading
                }
                className={styles.imageLoader}
              >
                {' '}
              </Loader>

              {this.state.isOpen && (
                <Lightbox
                  mainSrc={content}
                  onCloseRequest={this.onCloseLightbox}
                />
              )}

              <div className={styles.date}>{this.getTime()}</div>
            </div>
          </div>
        );

      case 'TEXT':
        return (
          <div className={styles.messageBody}>
            <div className={styles.messageContent}>
              <Linkify properties={{ target: '_blank' }}>{content}</Linkify>

              <div className={styles.date}>{this.getTime()}</div>
            </div>
          </div>
        );

      default:
        return '';
    }
  };

  getTime = () => {
    const { type, own, createdAt } = this.props;

    let date;

    if (type === 'IMAGE' && own) {
      date = new Date();
    } else {
      date = new Date(createdAt);
    }

    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  render() {
    const { own, seen, t } = this.props;

    const containerClassName = classNames(styles.message, {
      [styles.messageMy]: own,
    });

    return (
      <div className={classNames(containerClassName)}>
        {this.renderMessageContent()}

        {seen && (
          <div className={styles.seen}>
            <Svg src={'checkSimple'} className={styles.seenIcon} />
            {t('seen')}
          </div>
        )}
      </div>
    );
  }
}

ChatMessage.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string,
  seen: PropTypes.bool,
  own: PropTypes.bool,
};

export default withTranslation()(ChatMessage);
