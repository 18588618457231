import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { PinkButton } from '../../../components/buttons';
import { createOrderActions } from '../../../reducers/createOrder';
import ReactGA from 'react-ga';

import styles from './ConfirmTaskDelete.module.scss';

class ConfirmTaskDelete extends Component {
  render() {
    const { tasksCount, taskId, deleteTask, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('deleteTheTask')}</div>
        <div className={styles.text}>
          {t('areYouSureYouWantToDeleteTheTask')}
        </div>
        <div className={styles.btn}>
          <PinkButton
            onClick={() => {
              deleteTask(taskId, tasksCount);
              onClose();
              ReactGA.event({
                category: 'event',
                action: 'click',
                label: 'gauqmeba',
              });
            }}
          >
            {t('delete')}
          </PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, { deleteTask: createOrderActions.deleteTaskInOrder }),
)(ConfirmTaskDelete);
