import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import greenLogo from '../../assets/images/icons/greenLogo.png';
import TaskerCard from '../order/components/TaskerCard';
import styles from './ChatHeader.module.scss';

class ChatHeader extends Component {
  renderUser = () => {
    const { order, t } = this.props;

    if (order)
      return (
        <TaskerCard
          order={order}
          small={true}
          noRating={true}
          nameLight={true}
          avatarGreen={true}
        />
      );

    return (
      <div className={styles.container}>
        <div
          className={styles.avatar}
          style={{
            background: `url(${greenLogo}) center / cover no-repeat`,
          }}
        />
        <div className={styles.name}>{t('supportService')}</div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.header}>
        <div className={styles.tasker}>{this.renderUser()}</div>
      </div>
    );
  }
}

export default withTranslation()(ChatHeader);
