import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import DefaultTextArea from './DefaultTextArea';
import styles from './LabeledTextArea.module.scss';
import { withReduxFormProps } from '../../../containers/hoc';

class LabeledTextArea extends Component {
  renderOptional = (optional) => {
    if (!optional) return;
    return <span className={styles.labelOptional}>optional</span>;
  };

  renderRequired = (required) => {
    if (!required) return;

    return <span className={styles.labelRequired}>*</span>;
  };

  errorShouldBeRendered() {
    const { touched, error } = this.props;
    return !(touched === false || !error);
  }

  render() {
    const {
      required,
      value,
      label,
      error,
      optional,
      big,
      length,
      maxlength,
      maxHeight,
      twoLinePlaceholder,
      noMargin,
      t,
    } = this.props;

    return (
      <div>
        <label
          className={classNames(styles.label, {
            [styles.big]: big,
            [styles.noMargin]: noMargin,
          })}
        >
          <div
            className={classNames({
              [styles.maxHeight]: maxHeight,
            })}
            style={{ maxHeight: maxHeight ? `${maxHeight}px` : `auto` }}
          >
            <DefaultTextArea
              {...this.props}
              className={classNames(styles.textarea, {
                [styles.inputError]: this.errorShouldBeRendered(),
                [styles.big]: big,
                [styles.required]: required,
              })}
            />
          </div>
          <span
            className={classNames(styles.labelText, {
              [styles.labelTextFocus]: value !== '',
              [styles.big]: big,
              [styles.twoLinePlaceholder]: twoLinePlaceholder,
            })}
          >
            {label}
            {length &&
              ` (${value ? value.length : 0}/${maxlength} ${t('symbols')})`}
          </span>

          {this.renderOptional(optional)}

          {this.renderRequired(required)}

          {this.errorShouldBeRendered() && (
            <div className={styles.errorLabel}>{error}</div>
          )}
        </label>
      </div>
    );
  }
}

export default compose(withTranslation(), withReduxFormProps)(LabeledTextArea);
