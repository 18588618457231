import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { GreenButton, PinkButton } from '../../../components/buttons';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { Loader } from '../../../components/ui';

import styles from './ConfirmTaskDelete.module.scss';

class ConfirmTaskCreate extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.editingOrderTasks !== this.props.editingOrderTasks) {
      if (this.props.editingOrderTasks.length === 0) {
        this.props.onClose();
      }
    }

    if (prevProps.detachTaskerLoader && !this.props.detachTaskerLoader) {
      this.props.onClose();
    }
  }

  handleDismiss = () => {
    this.props.onClose();
    this.props.deleteEditingOrder();
  };

  handleContinue = () => {
    const { detachTaskerAndRedirect, editingOrderId } = this.props;

    detachTaskerAndRedirect(editingOrderId);
  };

  render() {
    const { editingOrderTasksLoading, detachTaskerLoader, t } = this.props;


    return (
      <div className={styles.container}>
        <Loader
          loading={editingOrderTasksLoading || detachTaskerLoader}
          height={285}
        >
          <div>
            <div className={styles.title}>{t('youHaveUnfinishedOrder')}</div>
            <div className={styles.text}>
              {t('doYouWantToContinueItOrDismissAndStartNewOne')}
            </div>
            <div className={styles.btnWrapper}>
              <PinkButton onClick={this.handleDismiss}>
                {t('dismiss')}
              </PinkButton>

              <GreenButton onClick={this.handleContinue}>
                {t('continue')}
              </GreenButton>
            </div>
          </div>
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  editingOrderId: createOrderSelectors.getOrderId(state),
  editingOrderTasks: createOrderSelectors.getTasks(state),
  editingOrderTasksLoading: createOrderSelectors.areTasksLoading(state),
  detachTaskerLoader: createOrderSelectors.getDetachTaskerLoader(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    deleteEditingOrder: createOrderActions.deleteEditingOrder,
    detachTaskerAndRedirect: createOrderActions.detachTaskerAndRedirect,
  }),
)(ConfirmTaskCreate);
