/* ACTION TYPES */

import { types as taskTypes } from '../containers/createTask/store';
import { types as taskerTypes } from '../containers/createOrderTaskersNew/store';

const MODULE_PREFIX = 'createOrder/';

export const createOrderTypes = {
  START_CREATING_NEW_ORDER: MODULE_PREFIX + 'START_CREATING_NEW_ORDER',

  CREATE_EMPTY_ORDER_REQUEST: MODULE_PREFIX + 'CREATE_EMPTY_ORDER_REQUEST',
  CREATE_EMPTY_ORDER_SUCCESS: MODULE_PREFIX + 'CREATE_EMPTY_ORDER_SUCCESS',
  CREATE_EMPTY_ORDER_FAILURE: MODULE_PREFIX + 'CREATE_EMPTY_ORDER_FAILURE',

  LOAD_EDITING_ORDER_REQUEST: MODULE_PREFIX + 'LOAD_EDITING_ORDER_REQUEST',
  LOAD_EDITING_ORDER_SUCCESS: MODULE_PREFIX + 'LOAD_EDITING_ORDER_SUCCESS',
  LOAD_EDITING_ORDER_FAILURE: MODULE_PREFIX + 'LOAD_EDITING_ORDER_FAILURE',

  LOAD_TASKS_FOR_ORDER_REQUEST: MODULE_PREFIX + 'LOAD_TASKS_FOR_ORDER_REQUEST',
  LOAD_TASKS_FOR_ORDER_SUCCESS: MODULE_PREFIX + 'LOAD_TASKS_FOR_ORDER_SUCCESS',
  LOAD_TASKS_FOR_ORDER_FAILURE: MODULE_PREFIX + 'LOAD_TASKS_FOR_ORDER_FAILURE',

  SELECT_CARD_FOR_ORDER_REQUEST:
    MODULE_PREFIX + 'SELECT_CARD_FOR_ORDER_REQUEST',
  SELECT_CARD_FOR_ORDER_SUCCESS:
    MODULE_PREFIX + 'SELECT_CARD_FOR_ORDER_SUCCESS',
  SELECT_CARD_FOR_ORDER_FAILURE:
    MODULE_PREFIX + 'SELECT_CARD_FOR_ORDER_FAILURE',

  DELETE_EDITING_ORDER_REQUEST: MODULE_PREFIX + 'DELETE_EDITING_ORDER_REQUEST',
  DELETE_EDITING_ORDER_SUCCESS: MODULE_PREFIX + 'DELETE_EDITING_ORDER_SUCCESS',
  DELETE_EDITING_ORDER_FAILURE: MODULE_PREFIX + 'DELETE_EDITING_ORDER_FAILURE',

  DELETE_TASK_IN_ORDER_REQUEST: MODULE_PREFIX + 'DELETE_TASK_IN_ORDER_REQUEST',
  DELETE_TASK_IN_ORDER_SUCCESS: MODULE_PREFIX + 'DELETE_TASK_IN_ORDER_SUCCESS',
  DELETE_TASK_IN_ORDER_FAILURE: MODULE_PREFIX + 'DELETE_TASK_IN_ORDER_FAILURE',

  DETACH_TASKER_REQUEST: MODULE_PREFIX + 'DETACH_TASKER_REQUEST',
  DETACH_TASKER_SUCCESS: MODULE_PREFIX + 'DETACH_TASKER_SUCCESS',
  DETACH_TASKER_FAILURE: MODULE_PREFIX + 'DETACH_TASKER_FAILURE',

  SUBMIT_ORDER_REQUEST: MODULE_PREFIX + 'SUBMIT_ORDER_REQUEST',
  SUBMIT_ORDER_SUCCESS: MODULE_PREFIX + 'SUBMIT_ORDER_SUCCESS',
  SUBMIT_ORDER_FAILURE: MODULE_PREFIX + 'SUBMIT_ORDER_FAILURE',

  ATTACH_PROMO_CODE_REQUEST: MODULE_PREFIX + 'ATTACH_PROMOCODE_REQUEST',
  ATTACH_PROMO_CODE_SUCCESS: MODULE_PREFIX + 'ATTACH_PROMOCODE_SUCCESS',
  ATTACH_PROMO_CODE_FAILURE: MODULE_PREFIX + 'ATTACH_PROMOCODE_FAILURE',

  DETACH_PROMO_CODE_REQUEST: MODULE_PREFIX + 'DETACH_PROMO_CODE_REQUEST',
  DETACH_PROMO_CODE_SUCCESS: MODULE_PREFIX + 'DETACH_PROMO_CODE_SUCCESS',
  DETACH_PROMO_CODE_FAILURE: MODULE_PREFIX + 'DETACH_PROMO_CODE_FAILURE',

  CLEAR_CREATE_ORDER_STATE: MODULE_PREFIX + 'CLEAR_CREATE_ORDER_STATE',
};

/* INITIAL STATE */

const initialOrder = {
  id: null,
  status: null,
  created_at: null,
  price: null,
  price_for_paid: null,
  tasker: null,
  card: {},
  address: {
    address: null,
    location: null,
  },
};

const inititalState = {
  order: initialOrder,
  orderLoading: false,
  submitErrors: null,

  tasks: [],
  tasksLoading: false,
  tasksLoaded: false,

  selectedCardInProcess: null,
  selectCardLoading: false,

  promoCodeLoading: false,
  promoCodeSuccess: null,
  promoCodeFailure: null,

  submitOrderLoader: false,

  detachTaskerLoader: false,
};

/* REDUCER HELPERS */

const helpers = {
  replaceOrderObj: (state, order) => ({
    ...state,
    orderLoading: false,
    order,
  }),

  deleteTaskInOrder: (state, taskId) => ({
    ...state,
    tasks: state.tasks.filter((task) => task.id !== taskId),
  }),

  loadTaskArraySuccess: (state, tasks) => ({
    ...state,
    tasks,
    tasksLoading: false,
    tasksLoaded: true,
  }),

  addTaskerToOrder: (state, action) => ({
    ...state,
    order: {
      ...state.order,
      tasker: action.tasker,
      price: action.price,
    },
  }),

  replaceEditedTaskObj: (state, action) => ({
    ...state,
    tasks: state.tasks.map((task) =>
      task.id === action.task.id ? action.task : task,
    ),
  }),
};

/* REDUCER */

export default (state = inititalState, action) => {
  switch (action.type) {
    case createOrderTypes.CREATE_EMPTY_ORDER_SUCCESS:
      return helpers.replaceOrderObj(state, action.order);

    case createOrderTypes.LOAD_EDITING_ORDER_REQUEST:
      return { ...state, orderLoading: true };

    case createOrderTypes.LOAD_EDITING_ORDER_SUCCESS:
      return helpers.replaceOrderObj(state, action.order);

    case createOrderTypes.LOAD_EDITING_ORDER_FAILURE:
      return { ...state, orderLoading: false };

    case createOrderTypes.DELETE_TASK_IN_ORDER_SUCCESS:
      return helpers.deleteTaskInOrder(state, action.taskId);

    case createOrderTypes.REJECT_TASK_IN_ORDER_SUCCESS:
      return helpers.rejectTaskInOrder(state, action.taskId);

    case taskTypes.SAVE_TASK_SUCCESS:
      return { ...state, tasks: [...state.tasks, action.task] };

    case taskTypes.EDIT_TASK_SUCCESS:
      return helpers.replaceEditedTaskObj(state, action);

    case createOrderTypes.LOAD_TASKS_FOR_ORDER_REQUEST:
      return { ...state, tasksLoading: true };

    case createOrderTypes.LOAD_TASKS_FOR_ORDER_SUCCESS:
      return helpers.loadTaskArraySuccess(state, action.tasks);

    case createOrderTypes.LOAD_TASKS_FOR_ORDER_FAILURE:
      return { ...state, tasksLoading: false };

    case createOrderTypes.SELECT_CARD_FOR_ORDER_REQUEST:
      return {
        ...state,
        selectedCardInProcess: action.cardId,
        selectCardLoading: true,
      };

    case createOrderTypes.SELECT_CARD_FOR_ORDER_SUCCESS:
      return {
        ...state,
        order: action.model,
        selectedCardInProcess: null,
        selectCardLoading: false,
      };

    case createOrderTypes.SELECT_CARD_FOR_ORDER_FAILURE:
      console.error('SELECT_CARD_FOR_ORDER_FAILURE action = ', action);
      return {
        ...state,
        selectedCardInProcess: null,
        selectCardLoading: false,
      };

    case taskerTypes.ATTACH_TASKER_SUCCESS:
      return helpers.addTaskerToOrder(state, action);

    case createOrderTypes.ATTACH_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCodeSuccess: null,
        promoCodeFailure: null,
        promoCodeLoading: true,
      };

    case createOrderTypes.ATTACH_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeSuccess: action.payload,
        promoCodeFailure: null,
        promoCodeLoading: false,
      };

    case createOrderTypes.ATTACH_PROMO_CODE_FAILURE:
      return {
        ...state,
        promoCodeSuccess: null,
        promoCodeFailure: action.error,
        promoCodeLoading: false,
      };

    case createOrderTypes.DETACH_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCodeLoading: true,
      };

    case createOrderTypes.DETACH_PROMO_CODE_SUCCESS:
      console.log(
        '%C DETACH_PROMO_CODE_SUCCESS action = ',
        'color: green',
        action,
      );
      return {
        ...state,
        promoCodeSuccess: null,
        promoCodeFailure: null,
        promoCodeLoading: false,
      };

    case createOrderTypes.DETACH_PROMO_CODE_FAILURE:
      console.log(
        '%c DETACH_PROMO_CODE_FAILURE action = ',
        'color: blue',
        action,
      );
      return {
        ...state,
        promoCodeSuccess: null,
        promoCodeFailure: null,
        promoCodeLoading: false,
      };

    case createOrderTypes.SUBMIT_ORDER_REQUEST:
      return {
        ...state,
        submitOrderLoader: true,
      };

    case createOrderTypes.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        submitOrderLoader: false,
      };

    case createOrderTypes.SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        submitOrderLoader: false,
      };

    case createOrderTypes.DETACH_TASKER_REQUEST:
      return {
        ...state,
        detachTaskerLoader: true,
      };

    case createOrderTypes.DETACH_TASKER_SUCCESS:
      return {
        ...state,
        detachTaskerLoader: false,
      };

    case createOrderTypes.DETACH_TASKER_FAILURE:
      return {
        ...state,
        detachTaskerLoader: false,
      };

    case createOrderTypes.CLEAR_CREATE_ORDER_STATE:
      return inititalState;

    default:
      return state;
  }
};

/* ACTION CREATORS */

const createEmptyOrder = () => ({
  type: createOrderTypes.CREATE_EMPTY_ORDER_REQUEST,
});

const createEmptyOrderSuccess = (order) => ({
  type: createOrderTypes.CREATE_EMPTY_ORDER_SUCCESS,
  order,
});

const createEmptyOrderFailure = (error) => ({
  type: createOrderTypes.CREATE_EMPTY_ORDER_FAILURE,
  error,
});

const loadEditingOrder = (afterTaskDelete?: any, paymentChange?: any) => ({
  type: createOrderTypes.LOAD_EDITING_ORDER_REQUEST,
  afterTaskDelete,
  paymentChange,
});

const loadTasksForOrder = (orderId) => ({
  type: createOrderTypes.LOAD_TASKS_FOR_ORDER_REQUEST,
  orderId,
});

/* *** select card *** */
const selectCardRequest = (cardId, newVersion = false) => ({
  type: createOrderTypes.SELECT_CARD_FOR_ORDER_REQUEST,
  cardId,
  newVersion,
});

const selectCardSuccess = (payload) => ({
  type: createOrderTypes.SELECT_CARD_FOR_ORDER_SUCCESS,
  payload,
});

const selectCardFailure = (payload) => ({
  type: createOrderTypes.SELECT_CARD_FOR_ORDER_SUCCESS,
  payload,
});
/* *** *** */

const clearCreateOrderState = () => ({
  type: createOrderTypes.CLEAR_CREATE_ORDER_STATE,
});

const deleteEditingOrder = () => ({
  type: createOrderTypes.DELETE_EDITING_ORDER_REQUEST,
});

const deleteTaskInOrder = (taskId, tasksCount) => ({
  type: createOrderTypes.DELETE_TASK_IN_ORDER_REQUEST,
  taskId,
  tasksCount,
});

const rejectTaskInOrder = (taskId, taskQuantity, orderId) => ({
  type: createOrderTypes.REJECT_TASK_IN_ORDER_REQUEST,
  taskId,
  taskQuantity,
  orderId,
});

const attachPromoCodeForOrder = ({ orderId, promoCode }) => ({
  type: createOrderTypes.ATTACH_PROMO_CODE_REQUEST,
  orderId,
  promoCode,
});

const detachPromoCodeForOrder = ({ orderId }) => ({
  type: createOrderTypes.DETACH_PROMO_CODE_REQUEST,
  orderId,
});

const startCreatingNewOrder = () => ({
  type: createOrderTypes.START_CREATING_NEW_ORDER,
});

const submitOrder = () => ({
  type: createOrderTypes.SUBMIT_ORDER_REQUEST,
});

const detachTaskerAndRedirect = (orderId, path) => ({
  type: createOrderTypes.DETACH_TASKER_REQUEST,
  orderId,
  path,
});

export const createOrderActions = {
  createEmptyOrder,
  createEmptyOrderSuccess,
  createEmptyOrderFailure,
  loadTasksForOrder,
  loadEditingOrder,
  selectCardRequest,
  selectCardSuccess,
  selectCardFailure,
  deleteEditingOrder,
  clearCreateOrderState,
  deleteTaskInOrder,
  rejectTaskInOrder,
  startCreatingNewOrder,
  submitOrder,
  detachTaskerAndRedirect,
  attachPromoCodeForOrder,
  detachPromoCodeForOrder,
};

/* SELECTORS */

export const createOrderSelectors = {
  getOrder: (state) => state.createOrder.order,

  getOrderLoading: (state) => state.createOrder.orderLoading,

  getOrderId: (state) =>
    state.createOrder.order ? state.createOrder.order.id : null,

  getOrderAddress: (state) =>
    state.createOrder.order ? state.createOrder.order.address : null,

  getOrderSelectedCard: (state) =>
    state.createOrder.order &&
    state.createOrder.order.card &&
    state.createOrder.order.card.id
      ? state.createOrder.order.card.id
      : null,

  getOrderSelectedCardLoading: (state) => state.createOrder.selectCardLoading,

  getOrderSelectedCardInProcess: (state) =>
    state.createOrder.selectedCardInProcess,

  getTasks: (state) => state.createOrder.tasks,

  areTasksLoading: (state) => state.createOrder.tasksLoading,

  areTasksLoaded: (state) => state.createOrder.tasksLoaded,

  getPromoCodeLoading: (state) => state.createOrder.promoCodeLoading,

  getPromoCodeSuccess: (state) => state.createOrder.promoCodeSuccess,

  getPromoCodeFailure: (state) => state.createOrder.promoCodeFailure,

  getSubmitOrderLoader: (state) => state.createOrder.submitOrderLoader,

  getDetachTaskerLoader: (state) => state.createOrder.detachTaskerLoader,
};
