import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { GreenButton } from '../../../components/buttons';

import styles from './ConfirmAccountDeactivation.module.scss';

class OtherDeviceSignedIn extends Component {
  handleOkClick = () => {
    // const {onClose} = this.props;

    alert('OK CLICK');
  };

  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('otherDeviceSignedIn')}</div>
        <div className={styles.text}>{t('youWillBeDisconnected')}</div>
        <div className={styles.btn}>
          <GreenButton onClick={this.handleOkClick}>{t('ok')}</GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(OtherDeviceSignedIn);
