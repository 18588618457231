import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import styles from './Pagination.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import NextButton from './components/NextButton';
import { useTranslation } from 'react-i18next';
import { submit } from 'redux-form';

import { RootReduxState } from 'store';
import PaginationItem from './components/PaginationItem';
import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';
import { ServiceActions } from 'containers/createTaskFullFlow/containers/selectService/state/actions';
import { taskersSelectors } from 'containers/createOrderTaskersNew/store';
import {
  CleaningSubCategoryTypesEnum,
  SelectedCategoryTypesEnum,
  ServiceTypesEnum,
} from 'utils/enum';
import { useLocation, useParams } from 'react-router-dom';
import { ADDRESS_FORM_NEW } from 'containers/createOrderMapNew/components/AddressesCreateEdit';
import { userSelectors } from 'reducers/user';
import PrevButton from './components/PrevButton';
import createTaskRoutes from '../../helper/createTaskRoutes';

const Pagination = () => {
  const bar = useRef<HTMLDivElement>();
  const [barWidth, setBarWidth] = useState('10%');
  const { t, i18n } = useTranslation();
  const { step, id: addressEditId } = useParams<{ step: string; id: string }>();
  const {
    activeStep,
    paginationData,
    progressLevel,
    selectedServiceIndex,
    selectedRepairServiceItemIndex,
    selectedServiceObject,
    selectedCategoryType,
    selectedAddressIndex,
    selectedTasker,
    currentCleaningServiceType,
    selectedServices,
    selectedWindowCleaningServiceRegular,
    currentOrder,
    shouldRenderAdditionalDetailsPage,
    userLoggedIn,
  } = useSelector((state: RootReduxState) => ({
    ...state.createTaskFullFlow,
    ...state.serviceStore,
    ...state.addressStore,
    selectedTasker: taskersSelectors.getSelectedTasker(state),
    userLoggedIn: userSelectors.isUserLoggedIn(state),
  }));

  const dispatch = useDispatch();  

  const location = useLocation();
  const updateBar = useCallback(() => {
    setBarWidth(
      bar.current
        ? bar.current?.children[activeStep]?.offsetLeft +
            bar.current?.children[activeStep]?.offsetWidth +
            8 +
            'px'
        : '10%',
    );
  }, [activeStep, bar]);

  useEffect(() => {
    updateBar();
  }, [updateBar]);

  const onNextStepClick = useCallback(() => {
    switch (activeStep) {
      case 0:
        break;
      case 1: {
        let newSelectedService: ServiceTreeType | null = null;
        const currentService =
          selectedServiceObject?.next_answers[selectedServiceIndex];
        //for handyman category
        if (selectedCategoryType === SelectedCategoryTypesEnum.HANDYMAN) {
          if (
            currentService?.next_question_type === 'ACTION' &&
            !shouldRenderAdditionalDetailsPage
          ) {
            newSelectedService =
              currentService.next_answers[selectedRepairServiceItemIndex];

            dispatch(ServiceActions.shouldRenderAdditionalDetailsPage(true));
          } else if (selectedServiceObject?.is_finish === false)
            newSelectedService =
              selectedServiceObject?.next_answers[selectedServiceIndex];
          else if (selectedServiceObject?.is_finish) {
            const id = selectedServiceObject?.services?.[0].id;
            return id && dispatch(createTaskActions.uploadTask());
          } else return;
          //for cleaning category
        } else if (
          selectedCategoryType === SelectedCategoryTypesEnum.CLEANING
        ) {
          if (
            selectedServiceObject?.answer_type ===
              CleaningSubCategoryTypesEnum.CLEANING_WINDOWS_CLEANING &&
            !selectedWindowCleaningServiceRegular
          ) {
            newSelectedService =
              selectedServiceObject?.next_answers[1].next_answers[1];
            dispatch(
              ServiceActions.setSelectedServiceObject(newSelectedService),
            );
            dispatch(ServiceActions.changeCurrentCleaningServiceType());
            return;
          } else {
            dispatch(ServiceActions.changeCurrentCleaningServiceType());
          }
        } else return;

        newSelectedService &&
          dispatch(ServiceActions.setSelectedServiceObject(newSelectedService));

        break;
      }
      case 2: {
        if (step === 'addressEdit') {
          dispatch(submit(ADDRESS_FORM_NEW));
        } else dispatch(createTaskActions.addAddressToOrder());
        break;
      }
      case 3: {
        dispatch(createTaskActions.addProfyToOrder());
        break;
      }
      case 4: {
        dispatch(createTaskActions.finishPayment());
        break;
      }

      default:
        break;
    }
  }, [
    activeStep,
    selectedServiceObject?.next_answers,
    selectedServiceObject?.is_finish,
    selectedServiceObject?.services,
    selectedServiceObject?.answer_type,
    selectedServiceIndex,
    selectedCategoryType,
    dispatch,
    shouldRenderAdditionalDetailsPage,
    selectedRepairServiceItemIndex,
    selectedWindowCleaningServiceRegular,
    step,
  ]);

  const renderButton = useMemo(() => {
    let shouldRender = false;
    let showErrow = true;
    let text = t('nextStep');

    switch (activeStep) {
      case 0:
        shouldRender = false;
        break;
      case 1: {
        if (selectedCategoryType === SelectedCategoryTypesEnum.HANDYMAN) {
          // for HANDYMAN category
          if (
            selectedServiceIndex !== -1 &&
            selectedRepairServiceItemIndex !== -1
          )
            shouldRender = true;
          else if (selectedServiceObject?.is_finish === true)
            shouldRender = true;
        } else if (
          selectedCategoryType === SelectedCategoryTypesEnum.CLEANING
        ) {
          // for CLEANING category
          if (
            currentCleaningServiceType === ServiceTypesEnum.UP_SALE ||
            currentCleaningServiceType === ServiceTypesEnum.ADDITIONAL_SERVICES
          )
            shouldRender = true;
          else if (selectedServices[currentCleaningServiceType])
            shouldRender = true;
        }

        break;
      }
      case 2: {
        if (!userLoggedIn && step === 'addressEdit') {
          text = t('continue');
          shouldRender = true;
        } else if (step === 'addressEdit') {
          text = t('save');
          shouldRender = true;
          showErrow = false;
        } else if (selectedAddressIndex > -1) shouldRender = true;

        break;
      }
      case 3: {
        if (selectedTasker.hourIndex !== null) shouldRender = true;
        break;
      }
      case 4: {
        shouldRender = true;
        text = t('checkOut');

        break;
      }

      default:
        break;
    }
    return (
      shouldRender && (
        <NextButton
          onClick={onNextStepClick}
          text={text}
          showErrow={showErrow}
        />
      )
    );
  }, [
    t,
    activeStep,
    onNextStepClick,
    selectedCategoryType,
    selectedServiceIndex,
    selectedRepairServiceItemIndex,
    selectedServiceObject?.is_finish,
    currentCleaningServiceType,
    selectedServices,
    userLoggedIn,
    step,
    selectedAddressIndex,
    selectedTasker.hourIndex,
  ]);

  const answers = useMemo(() => {
    if (!currentOrder?.model) {
      return [paginationData[0].answer];
    }
    const { services, address, tasker } = currentOrder?.model;
    const servecesStrings = services.map(({ name }) => name).join(', ') ?? '';

    return [
      currentOrder?.model?.flow?.[`display_name_${i18n.language}`] ??
        currentOrder?.model?.flow?.display_name_ge,
      servecesStrings,
      address.address,
      tasker ? tasker?.first_name + tasker?.last_name : '',
    ];
  }, [currentOrder?.model, i18n.language, paginationData]);

  const onStepChange = (i: number) => {
    if (
      i === 1 &&
      selectedCategoryType === SelectedCategoryTypesEnum.HANDYMAN
    ) {
      dispatch(ServiceActions.shouldRenderAdditionalDetailsPage(false));
    }
    if (
      i === 1 &&
      selectedCategoryType === SelectedCategoryTypesEnum.CLEANING
    ) {
      dispatch(ServiceActions.shouldRenderAdditionalDetailsPage(false));
    }
    dispatch(ServiceActions.clearCurrentCleaningServiceType());
    if(i == 1){
      i = 0
    }
    if (location.pathname !== createTaskRoutes.superCategory){
      dispatch(createTaskActions.changeActiveStep(i));
    }
  };

  const prevButtonHandle = () => {
    if (activeStep === 2) {
      if (selectedCategoryType === SelectedCategoryTypesEnum.HANDYMAN) {
        dispatch(ServiceActions.shouldRenderAdditionalDetailsPage(false));
      } else if (selectedCategoryType === SelectedCategoryTypesEnum.CLEANING) {
        dispatch(ServiceActions.shouldRenderAdditionalDetailsPage(false));
      }
    }
    dispatch(createTaskActions.changeActiveStep(activeStep - 1));
  };

  return useMemo(
    () => (
      <div className={styles.container}>
        <div className={styles.paginationContainer} ref={bar}>
          {/* {!!activeStep && <PrevButton onClick={() => prevButtonHandle()} />} */}
          {paginationData.map(({ id, question }, i: number) => (
            <PaginationItem
              key={i}
              onClick={() => i <= progressLevel && onStepChange(i)}
              step={id}
              active={i <= progressLevel}
              question={t(question)}
              answer={answers[i]}
              price={null}
              activeStep={activeStep}
              selectedServices={
                i === 1 && currentOrder?.model?.tasks.length
                  ? currentOrder?.model?.tasks
                  : null
              }
            />
          ))}
        </div>
        {renderButton}
        <div className={styles.progress} style={{ width: barWidth }} />
      </div>
    ),
    [
      activeStep,
      paginationData,
      renderButton,
      barWidth,
      dispatch,
      progressLevel,
      t,
      answers,
      currentOrder?.model?.tasks,
    ],
  );
};

export default Pagination;
