import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './EmptyMessageList.module.scss';

const EmptyMessageList = ({ isSupportChat, t }) => (
  <div className={styles.noMessageWrapper}>
    <div className={styles.noMessage}>
      {isSupportChat
        ? t('youCanWriteToYourSupportHere')
        : t('youCanWriteToYourTaskerHere')}
    </div>
  </div>
);

export default withTranslation()(EmptyMessageList);
