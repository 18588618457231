import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import SignInSocial from '../../signInSocial/SignInSocial';
import { LabeledTextInput } from '../../../components/inputs/text/index';
import { LabeledCheckbox } from '../../../components/inputs/checkbox/index';
import { FormRow, DefaultForm } from '../../../components/ui/index';
import { GreenButton } from '../../../components/buttons/index';
import { MaskInput } from '../../../components/inputs/maskInput';

import styles from '../SignInForm.module.scss';

const SignInForm = ({ handleSubmit, t, onSignUp, onForgot }) => (
  <DefaultForm onSubmit={handleSubmit}>
    <Helmet>
      <title>{t('signInTitle')}</title>
    </Helmet>
    <div className={styles.content}>
      <h2 className={styles.title}>{t('signIn')}</h2>

      <div className={styles.row}>
        <FormRow>
          <Field
            name="phone"
            component={MaskInput}
            mask={'+999 999 999 999'}
            maskChar={''}
            type="tel"
            label={t('phone')}
            labelTextPostion={'15'}
          />
        </FormRow>
      </div>

      {/* <div className={styles.row}>
        <FormRow>
          <Field
            name='password'
            component={LabeledTextInput}
            label={t('password')}
            labelTextPostion={'15'}
            type='password'
          />
        </FormRow>
      </div> */}

      {/* <div className={styles.linkWrapper}>
        <span className={styles.linkForgotPassword} onClick={onForgot}>
          {t('forgotPassword')}
        </span>
      </div> */}

      <div className={styles.buttonWrapper}>
        {/* <FormRow>
          <Field
            name='remember'
            component={LabeledCheckbox}
            label={t('rememberMe')}
            type='checkbox'
          />
        </FormRow> */}

        <GreenButton type="submit">{t('continue')}</GreenButton>
      </div>
      {/* <SignInSocial/> */}
    </div>

    {/* <div className={styles.footer}>
      <p className={styles.footerText}>
        {t('dontHaveAnAccount') + ' '}
        <span className={styles.footerLink} onClick={onSignUp}>{t('signUp')}</span>
      </p>
    </div> */}
  </DefaultForm>
);

export default compose(
  reduxForm({
    initialValues: {
      phone: '+995',
    },
  }),
  withTranslation(),
)(SignInForm);
