import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './CategoryCard.module.scss';

const CategoryCard = ({
  category,
  onSubcategorySelect,
  onCategorySelect,
  t,
  servicesCount,
  taskOrderId,
}) => (
  <div className={styles.container}>
    <div className={styles.body}>
      <div className={styles.cardHeader}>
        <div
          style={{
            width: '45px',
            height: '45px',
            background: `url(${category.model.image}) 0% 0% / cover no-repeat`,
          }}
        />

        <div className={styles.titleBlock}>
          <div className={styles.categoryName}>
            {category?.model?.flow?.type !== 'CLEANING' ? (
              category?.model?.name
            ) : (
              <span
                onClick={() => {
                  onCategorySelect({
                    categoryId: category.model.id,
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                {category.model.name}
              </span>
            )}
          </div>

          {servicesCount && (
            <div className={styles.subcategoriesCount}>
              {category.subcategories.length} {t('services')}
            </div>
          )}
        </div>
      </div>
      {category.model.flow.type !== 'CLEANING' ? (
        <div className={styles.cardBody}>
          {category.subcategories.map((subcategory) => (
            <div key={subcategory}>
              <span
                className={styles.subcategory}
                onClick={() =>
                  onSubcategorySelect({
                    categoryId: category.model.id,
                    question: category.second_question,
                    subcategory,
                    taskOrderId,
                  })
                }
              >
                {subcategory}
              </span>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  </div>
);

CategoryCard.propTypes = {
  category: PropTypes.shape({
    model: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image: PropTypes.string,
    }),
    second_question: PropTypes.string,
    subcategories: PropTypes.arrayOf(PropTypes.string),
  }),
  onSubcategorySelect: PropTypes.func,
  onCategoriesLoad: PropTypes.func,
  servicesCount: PropTypes.bool,
};

export default withTranslation()(CategoryCard);
