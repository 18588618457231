import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  fork,
  take,
  call,
  put,
  select,
  cancel,
  takeEvery,
  cancelled,
} from 'redux-saga/effects';
import { getToken } from '../utils/authUtil';
import { userActionTypes, userSelectors } from '../reducers/user';

/*#######   Socket events   #######*/

const websocketEvents = {
  CONNECT: 'connect',
  RECONNECT: 'reconnect',
  WEBSOCKET_AUTHORIZED: 'auth:ok',
  CONNECT_ERROR: 'connect_error',
  RECONNECT_ERROR: 'reconnect_error',
  CONNECT_TIMEOUT: 'connect_timeout',

  NEW_MESSAGE: 'msg:get',

  MESSAGE_SEND: 'msg:send',
  MESSAGE_SEND_SUCCESS: 'msg:send:ok',

  CHAT_ENTER: 'chat:enter',
  CHAT_ENTER_SUCCESS: 'chat:enter:ok',

  CHAT_LEAVE: 'chat:leave',
  CHAT_LEAVE_SUCCESS: 'chat:leave:ok',

  CHAT_CLOSED: 'chat:closed',
  CHAT_OPENED: 'chat:opened',

  PUSH: 'push',
};

/*#######   Action types   #######*/

export const wsActionTypes = {
  CONNECT_CHAT_SOCKET: 'CONNECT_CHAT_SOCKET',
  CLOSE_CHAT_SOCKET: 'CLOSE_CHAT_SOCKET',
  WEBSOCKET_AUTHORIZED: 'WEBSOCKET_AUTHORIZED',

  NEW_MESSAGE: 'NEW_MESSAGE',

  MESSAGE_SEND: 'MESSAGE_SEND',
  MESSAGE_SEND_SUCCESS: 'MESSAGE_SEND_SUCCESS',

  CHAT_ENTER: 'CHAT_ENTER',
  CHAT_ENTER_SUCCESS: 'CHAT_ENTER_SUCCESS',

  CHAT_LEAVE: 'CHAT_LEAVE',
  CHAT_LEAVE_SUCCESS: 'CHAT_LEAVE_SUCCESS',

  CHAT_CLOSED: 'CHAT_CLOSED',
  CHAT_OPENED: 'CHAT_OPENED',

  GET_PUSH: 'GET_PUSH',
};

/*#######   Action creators   #######*/

export const wsActions = {
  connectChatSocket: () => ({ type: wsActionTypes.CONNECT_CHAT_SOCKET }),
  closeChatSocket: () => ({ type: wsActionTypes.CLOSE_CHAT_SOCKET }),
  webSocketAuthorized: () => ({ type: wsActionTypes.WEBSOCKET_AUTHORIZED }),

  newMessage: (message) => ({
    type: wsActionTypes.NEW_MESSAGE,
    payload: { message },
  }),
  sendMessage: (message) => ({
    type: wsActionTypes.MESSAGE_SEND,
    payload: message,
  }),
  sendMessageSuccess: ({ guid }) => ({
    type: wsActionTypes.MESSAGE_SEND_SUCCESS,
    payload: { guid },
  }),
  enterChat: ({ room }) => ({
    type: wsActionTypes.CHAT_ENTER,
    payload: { room },
  }),
  enterChatSuccess: ({ room }) => ({
    type: wsActionTypes.CHAT_ENTER_SUCCESS,
    payload: { room },
  }),
  leaveChat: ({ room }) => ({
    type: wsActionTypes.CHAT_LEAVE,
    payload: { room },
  }),
  leaveChatSuccess: ({ room }) => ({
    type: wsActionTypes.CHAT_LEAVE_SUCCESS,
    payload: { room },
  }),
  closeChat: ({ room }) => ({
    type: wsActionTypes.CHAT_CLOSED,
    payload: { room },
  }),
  openChat: ({ room }) => ({
    type: wsActionTypes.CHAT_OPENED,
    payload: { room },
  }),

  getPush: ({ payload }) => ({
    type: wsActionTypes.GET_PUSH,
    payload,
  }),
};

/*#######   Create socket connection   #######*/

function subscribeSocketEvents(socket) {
  const onevent = socket.onevent;
  socket.onevent = function (packet) {
    const args = packet.data || [];
    onevent.call(this, packet); // original call
    packet.data = ['*'].concat(args);
    onevent.call(this, packet); // additional call to catch-all
  };

  const emit = socket.emit;
  socket.emit = function () {
    console.log(`%c Emit: ${arguments[0]}`, 'color: green', arguments[1]);
    emit.apply(this, arguments);
  };

  socket.on('*', function (event, data) {
    console.log(`%c Event: ${event}`, 'color: green', data);
  });
}

const connect = async () => {
  const token = getToken();
  return new Promise((resolve, reject) => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      path: '/socket.io',
      query: {
        token: token,
        language: 'ru',
      },
    });

    subscribeSocketEvents(socket);

    socket.on(websocketEvents.WEBSOCKET_AUTHORIZED, () => {
      console.log(
        '%c Websocket connection was successfully installed.',
        'color: green',
      );
      resolve(socket);
    });
    socket.on(websocketEvents.CONNECT_ERROR, () => {
      console.error('Websocket connect error');
    });
    socket.on(websocketEvents.RECONNECT_ERROR, () => {
      console.error('Websocket re-connect error');
    });
    socket.on(websocketEvents.CONNECT_TIMEOUT, () => {
      console.error('Websocket connect timeout');
    });
  });
};

/*#######   Subscribe to socket events   #######*/

const subscribe = (socket) => {
  return eventChannel((emit) => {
    socket.on(websocketEvents.NEW_MESSAGE, ({ message }) => {
      emit(wsActions.newMessage(message));
    });
    socket.on(websocketEvents.MESSAGE_SEND_SUCCESS, ({ guid, timestamp }) => {
      emit(wsActions.sendMessageSuccess({ guid, timestamp }));
    });
    socket.on(
      websocketEvents.CHAT_ENTER_SUCCESS,
      ({ user_id, room, timestamp }) => {
        emit(wsActions.enterChatSuccess({ user_id, room, timestamp }));
      },
    );
    socket.on(websocketEvents.CHAT_CLOSED, ({ room }) => {
      emit(wsActions.closeChat({ room }));
    });
    socket.on(websocketEvents.CHAT_OPENED, ({ room }) => {
      emit(wsActions.openChat({ room }));
    });
    socket.on(websocketEvents.PUSH, ({ payload }) => {
      emit(wsActions.getPush({ payload }));
    });
    return () => socket.close();
  });
};

function* read(socket) {
  const channel = yield call(subscribe, socket);

  try {
    while (true) {
      let action = yield take(channel);

      if (action.type === wsActionTypes.NEW_MESSAGE) {
        const user = yield select(userSelectors.getUserProfile);
        action.payload.userId = user.id;
        yield put(action);
      } else {
        yield put(action);
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function* write(socket) {
  while (true) {
    const OUTPUT_EVENTS = [
      wsActionTypes.MESSAGE_SEND,
      wsActionTypes.CHAT_ENTER,
      wsActionTypes.CHAT_LEAVE,
    ];
    const action = yield take(OUTPUT_EVENTS);
    socket.emit(websocketEvents[action.type], action.payload);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
  yield fork(write, socket);
}

function* chatWebsocketFlow() {
  const socket = yield call(connect);
  const task = yield fork(handleIO, socket);
  yield put(wsActions.webSocketAuthorized());
  yield take([wsActionTypes.CLOSE_CHAT_SOCKET, userActionTypes.LOGOUT]);

  yield cancel(task);
}

function* onPush({ payload }) {
  try {
    const actionName = 'push/' + payload.push_type;

    yield put({
      type: actionName,
      payload: { ...payload },
    });
  } catch (error) {
    console.error('Error onPush: ', error);
  }
}

export const websocketSagas = {
  rootSaga: function* () {
    yield takeEvery(wsActionTypes.CONNECT_CHAT_SOCKET, chatWebsocketFlow);
  },

  getPush: function* () {
    yield takeEvery(wsActionTypes.GET_PUSH, onPush);
  },
};
