const MODULE_PREFIX = 'order/';

export const orderTypes = {
  LOAD_ORDER_REQUEST: MODULE_PREFIX + 'LOAD_ORDER_REQUEST',
  LOAD_ORDER_SUCCESS: MODULE_PREFIX + 'LOAD_ORDER_SUCCESS',
  LOAD_ORDER_FAILURE: MODULE_PREFIX + 'LOAD_ORDER_FAILURE',

  UPDATE_ORDER_IN_BG_ORDER_REQUEST:
    MODULE_PREFIX + 'UPDATE_ORDER_IN_BG_ORDER_REQUEST',
  UPDATE_ORDER_IN_BG_ORDER_SUCCESS:
    MODULE_PREFIX + 'UPDATE_ORDER_IN_BG_ORDER_SUCCESS',
  UPDATE_ORDER_IN_BG_ORDER_FAILURE:
    MODULE_PREFIX + 'UPDATE_ORDER_IN_BG_ORDER_FAILURE',

  LOAD_COMPLAINT_REASONS_REQUEST:
    MODULE_PREFIX + 'LOAD_COMPLAINT_REASONS_REQUEST',
  LOAD_COMPLAINT_REASONS_SUCCESS:
    MODULE_PREFIX + 'LOAD_COMPLAINT_REASONS_SUCCESS',
  LOAD_COMPLAINT_REASONS_FAILURE:
    MODULE_PREFIX + 'LOAD_COMPLAINT_REASONS_FAILURE',

  SELECT_COMPLAINT_REASON: MODULE_PREFIX + 'SELECT_COMPLAINT_REASON',

  CREATE_COMPLAINT_REQUEST: MODULE_PREFIX + 'CREATE_COMPLAINT_REQUEST',
  CREATE_COMPLAINT_SUCCESS: MODULE_PREFIX + 'CREATE_COMPLAINT_SUCCESS',
  CREATE_COMPLAINT_FAILURE: MODULE_PREFIX + 'CREATE_COMPLAINT_FAILURE',

  CLEAR_COMPLAINT_STATE: MODULE_PREFIX + 'CLEAR_COMPLAINT_STATE',

  CREATE_REVIEW_REQUEST: MODULE_PREFIX + 'CREATE_REVIEW_REQUEST',
  CREATE_REVIEW_SUCCESS: MODULE_PREFIX + 'CREATE_REVIEW_SUCCESS',
  CREATE_REVIEW_FAILURE: MODULE_PREFIX + 'CREATE_REVIEW_FAILURE',

  GIVE_TIPS_REQUEST: MODULE_PREFIX + 'GIVE_TIPS_REQUEST',
  GIVE_TIPS_SUCCESS: MODULE_PREFIX + 'GIVE_TIPS_SUCCESS',
  GIVE_TIPS_FAILURE: MODULE_PREFIX + 'GIVE_TIPS_FAILURE',

  GET_OFFERED_TIPS_REQUEST: MODULE_PREFIX + 'GET_OFFERED_TIPS_REQUEST',
  GET_OFFERED_TIPS_SUCCESS: MODULE_PREFIX + 'GET_OFFERED_TIPS_SUCCESS',
  GET_OFFERED_TIPS_FAILURE: MODULE_PREFIX + 'GET_OFFERED_TIPS_FAILURE',

  GET_CANCEL_ORDER_REFOUND_REQUEST:
    MODULE_PREFIX + 'GET_CANCEL_ORDER_REFOUND_REQUEST',
  GET_CANCEL_ORDER_REFOUND_SUCCESS:
    MODULE_PREFIX + 'GET_CANCEL_ORDER_REFOUND_SUCCESS',
  GET_CANCEL_ORDER_REFOUND_FAILURE:
    MODULE_PREFIX + 'GET_CANCEL_ORDER_REFOUND_FAILURE',

  CANCEL_ORDER_REQUEST: MODULE_PREFIX + 'CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: MODULE_PREFIX + 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: MODULE_PREFIX + 'CANCEL_ORDER_FAILURE',

  DELETE_ORDER_REQUEST: MODULE_PREFIX + 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: MODULE_PREFIX + 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: MODULE_PREFIX + 'DELETE_ORDER_FAILURE',

  CLEAR_ORDER_STATE: MODULE_PREFIX + 'CLEAR_ORDER_STATE',

  LOAD_ORDER_TASKS_REQUEST: MODULE_PREFIX + 'LOAD_ORDER_TASKS_REQUEST',
  LOAD_ORDER_TASKS_SUCCESS: MODULE_PREFIX + 'LOAD_ORDER_TASKS_SUCCESS',
  LOAD_ORDER_TASKS_FAILURE: MODULE_PREFIX + 'LOAD_ORDER_TASKS_FAILURE',

  CONFIRM_ORDER_COMPLETE_REQUEST:
    MODULE_PREFIX + 'CONFIRM_ORDER_COMPLETE_REQUEST',
  CONFIRM_ORDER_COMPLETE_SUCCESS:
    MODULE_PREFIX + 'CONFIRM_ORDER_COMPLETE_SUCCESS',
  CONFIRM_ORDER_COMPLETE_FAILURE:
    MODULE_PREFIX + 'CONFIRM_ORDER_COMPLETE_FAILURE',

  LOAD_TASKER_LOCATION_REQUEST: MODULE_PREFIX + 'LOAD_TASKER_LOCATION_REQUEST',
  LOAD_TASKER_LOCATION_SUCCESS: MODULE_PREFIX + 'LOAD_TASKER_LOCATION_SUCCESS',
  LOAD_TASKER_LOCATION_FAILURE: MODULE_PREFIX + 'LOAD_TASKER_LOCATION_FAILURE',

  UPDATE_TASKER_LOCATION_IN_BG_REQUEST:
    MODULE_PREFIX + 'UPDATE_TASKER_LOCATION_IN_BG_REQUEST',
  UPDATE_TASKER_LOCATION_IN_BG_SUCCESS:
    MODULE_PREFIX + 'UPDATE_TASKER_LOCATION_IN_BG_SUCCESS',
  UPDATE_TASKER_LOCATION_IN_BG_FAILURE:
    MODULE_PREFIX + 'UPDATE_TASKER_LOCATION_IN_BG_FAILURE',

  REJECT_TASK_IN_ORDER_REQUEST: MODULE_PREFIX + 'REJECT_TASK_IN_ORDER_REQUEST',
  REJECT_TASK_IN_ORDER_SUCCESS: MODULE_PREFIX + 'REJECT_TASK_IN_ORDER_SUCCESS',
  REJECT_TASK_IN_ORDER_FAILURE: MODULE_PREFIX + 'REJECT_TASK_IN_ORDER_FAILURE',

  REJECT_TASK_START: MODULE_PREFIX + 'REJECT_TASK_START',
  REJECT_TASK_CHECK_IN_ORDER_REQUEST:
    MODULE_PREFIX + 'REJECT_TASK_CHECK_IN_ORDER_REQUEST',
  REJECT_TASK_CHECK_IN_ORDER_SUCCESS:
    MODULE_PREFIX + 'REJECT_TASK_CHECK_IN_ORDER_SUCCESS',
  REJECT_TASK_CHECK_IN_ORDER_FAILURE:
    MODULE_PREFIX + 'REJECT_TASK_CHECK_IN_ORDER_FAILURE',

  CONFIRM_TASK_IN_ORDER_REQUEST:
    MODULE_PREFIX + 'CONFIRM_TASK_IN_ORDER_REQUEST',
  CONFIRM_TASK_IN_ORDER_SUCCESS:
    MODULE_PREFIX + 'CONFIRM_TASK_IN_ORDER_SUCCESS',
  CONFIRM_TASK_IN_ORDER_FAILURE:
    MODULE_PREFIX + 'CONFIRM_TASK_IN_ORDER_FAILURE',

  CONFIRM_ADDED_ORDERS:
    MODULE_PREFIX + 'CONFIRM_ADDED_ORDERS',

  REJECT_ADDED_ORDERS:
    MODULE_PREFIX + 'REJECT_ADDED_ORDERS',
  CONFIRM_ADDED_ORDERS_FAILD:
    MODULE_PREFIX + 'CONFIRM_ADDED_ORDERS_FAILD'
};

const initialComplaint = {
  reasons: [],
  reasonsLoading: false,
  selectedReason: null,
};

const initialReview = {
  reviewLoading: false,
};

const initialTaskDelete = {
  taskDeleteCheckLoading: false,
  taskDeleteCheckAvailable: null,
  taskDeleteCheckPercent: 0,

  taskDeleteLoading: false,
};

const initialState: {
  order: OrderType | null;
  [k: string]: any;
} = {
  order: null,
  orderLoading: false,
  tasks: [],
  tasksLoading: false,
  taskerLocation: null,
  taskerLocationLoading: false,
  ...initialComplaint,
  ...initialReview,
  ...initialTaskDelete,
  orderConfirmLoading: false,
  offeredTips: null,

  refoundSumm: null,
  refoundSummLoader: false,
  confirmAddedOrderfaild: null
};

export default (state = initialState, action): typeof initialState => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case orderTypes.LOAD_ORDER_REQUEST:
      return {
        ...state,
        orderLoading: true,
      };

    case orderTypes.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        orderLoading: false,
        order: payload.order,
      };

    case orderTypes.LOAD_ORDER_FAILURE:
      return {
        ...state,
        orderLoading: false,
      };

    case orderTypes.CONFIRM_ORDER_COMPLETE_REQUEST:
      return {
        ...state,
        orderConfirmLoading: true,
      };

    case orderTypes.CONFIRM_ORDER_COMPLETE_SUCCESS:
  
      return {
        ...state,
        order: payload.order.model,
        orderConfirmLoading: false,
      };

    case orderTypes.CONFIRM_ORDER_COMPLETE_FAILURE:
      return {
        ...state,
        orderConfirmLoading: false,
      };

    case orderTypes.UPDATE_ORDER_IN_BG_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
      };

    case orderTypes.LOAD_COMPLAINT_REASONS_REQUEST:
      return {
        ...state,
        reasonsLoading: true,
      };

    case orderTypes.LOAD_COMPLAINT_REASONS_SUCCESS:
      return {
        ...state,
        reasons: payload.reasons,
        reasonsLoading: false,
      };

    case orderTypes.LOAD_COMPLAINT_REASONS_FAILURE:
      return {
        ...state,
        reasonsLoading: false,
      };

    case orderTypes.SELECT_COMPLAINT_REASON:
      return {
        ...state,
        selectedReason: payload.reason,
      };

    case orderTypes.CLEAR_COMPLAINT_STATE:
      return {
        ...state,
        ...initialComplaint,
      };

    case orderTypes.CLEAR_ORDER_STATE:
      return {
        ...initialState,
      };

    case orderTypes.LOAD_TASKER_LOCATION_REQUEST:
      return {
        ...state,
        taskerLocationLoading: true,
      };

    case orderTypes.LOAD_TASKER_LOCATION_SUCCESS:
      return {
        ...state,
        taskerLocation: payload.route,
        taskerLocationLoading: false,
      };

    case orderTypes.UPDATE_TASKER_LOCATION_IN_BG_SUCCESS:
      return {
        ...state,
        taskerLocation: payload.route,
        taskerLocationLoading: false,
      };

    case orderTypes.LOAD_TASKER_LOCATION_FAILURE:
      return {
        ...state,
        taskerLocationLoading: false,
      };

    case orderTypes.LOAD_ORDER_TASKS_REQUEST:
      return {
        ...state,
        tasksLoading: true,
      };

    case orderTypes.LOAD_ORDER_TASKS_SUCCESS:
      return {
        ...state,
        tasks: payload.tasks,
        tasksLoading: false,
      };

    case orderTypes.LOAD_ORDER_TASKS_FAILURE:
      return {
        ...state,
        tasksLoading: false,
      };

    case orderTypes.REJECT_TASK_START:
      return { ...state, ...initialTaskDelete };

    case orderTypes.REJECT_TASK_CHECK_IN_ORDER_REQUEST:
      return { ...state, taskDeleteCheckLoading: true };

    case orderTypes.REJECT_TASK_CHECK_IN_ORDER_FAILURE:
      return { ...state, taskDeleteCheckLoading: false };

    case orderTypes.REJECT_TASK_CHECK_IN_ORDER_SUCCESS:
      return {
        ...state,
        taskDeleteCheckLoading: false,
        taskDeleteCheckAvailable: payload.data.available,
        taskDeleteCheckPercent: payload.data.return_percent,
      };

    case orderTypes.REJECT_TASK_IN_ORDER_REQUEST:
      return { ...state, taskDeleteLoading: true };
    case orderTypes.REJECT_TASK_IN_ORDER_FAILURE:
      return { ...state, taskDeleteLoading: false };
    case orderTypes.REJECT_TASK_IN_ORDER_SUCCESS:
      return { ...state, taskDeleteLoading: false };

    /* *** get cancel order refound *** */
    case orderTypes.GET_CANCEL_ORDER_REFOUND_REQUEST:
      return {
        ...state,
        refoundSummLoader: true,
      };

    case orderTypes.GET_CANCEL_ORDER_REFOUND_SUCCESS:
      console.log('%c GET_CANCEL_ORDER_REFOUND_SUCCESS = ', payload);
      return {
        ...state,
        refoundSumm: payload.amount,
        refoundSummLoader: false,
      };

    case orderTypes.GET_CANCEL_ORDER_REFOUND_FAILURE:
      return {
        ...state,
        refoundSummLoader: false,
      };

    case orderTypes.GET_OFFERED_TIPS_SUCCESS:
      return {
        ...state,
        offeredTips: payload,
      };
    case orderTypes.CONFIRM_ADDED_ORDERS:
      return {
        ...state
      }
    case orderTypes.CONFIRM_ADDED_ORDERS_FAILD:
      return {
        ...state,
        confirmAddedOrderfaild: payload
      }

    default:
      return state;
  }
};

export const orderActions = {
  loadOrder: (id) => ({
    type: orderTypes.LOAD_ORDER_REQUEST,
    payload: {
      id,
    },
  }),

  updateOrderInBackground: (id) => ({
    type: orderTypes.UPDATE_ORDER_IN_BG_ORDER_REQUEST,
    id,
  }),

  loadReasons: () => ({
    type: orderTypes.LOAD_COMPLAINT_REASONS_REQUEST,
  }),

  selectReason: (reason) => ({
    type: orderTypes.SELECT_COMPLAINT_REASON,
    payload: {
      reason,
    },
  }),

  createComplaint: (data) => ({
    type: orderTypes.CREATE_COMPLAINT_REQUEST,
    payload: {
      data,
    },
  }),

  clearComplaintState: () => ({
    type: orderTypes.CLEAR_COMPLAINT_STATE,
  }),

  createReview: (data) => ({
    type: orderTypes.CREATE_REVIEW_REQUEST,
    payload: {
      data,
    },
  }),

  clearOrderState: () => ({
    type: orderTypes.CLEAR_ORDER_STATE,
  }),

  cancelOrder: (order) => ({
    type: orderTypes.CANCEL_ORDER_REQUEST,
    payload: { order },
  }),

  deleteOrder: (order) => ({
    type: orderTypes.DELETE_ORDER_REQUEST,
    payload: { order },
  }),

  loadOrderTasks: (id) => ({
    type: orderTypes.LOAD_ORDER_TASKS_REQUEST,
    payload: { id },
  }),

  confirmOrderComplete: () => ({
    type: orderTypes.CONFIRM_ORDER_COMPLETE_REQUEST,
  }),

  orderTaskerLoactionLoad: (id) => ({
    type: orderTypes.LOAD_TASKER_LOCATION_REQUEST,
    payload: { id },
  }),

  updateTaskerLoactionInBg: (id) => ({
    type: orderTypes.UPDATE_TASKER_LOCATION_IN_BG_REQUEST,
    payload: { id },
  }),

  rejectTaskCheckInOrder: (taskId, taskQuantity) => ({
    type: orderTypes.REJECT_TASK_CHECK_IN_ORDER_REQUEST,
    payload: {
      taskId: taskId,
      taskQuantity: taskQuantity,
    },
  }),

  rejectTaskStart: () => ({
    type: orderTypes.REJECT_TASK_START,
  }),

  rejectTaskInOrder: (taskId, taskQuantity, orderId) => ({
    type: orderTypes.REJECT_TASK_IN_ORDER_REQUEST,
    payload: {
      taskId: taskId,
      taskQuantity: taskQuantity,
      id: orderId,
    },
  }),

  confirmTaskInOrder: (task) => ({
    type: orderTypes.CONFIRM_TASK_IN_ORDER_REQUEST,
    payload: {
      task,
    },
  }),

  /* *** get cancel order refound *** */
  getCancelOrderRefoundRequest: (order) => ({
    type: orderTypes.GET_CANCEL_ORDER_REFOUND_REQUEST,
    payload: { order },
  }),

  getCancelOrderRefoundSuccess: (amount) => ({
    type: orderTypes.GET_CANCEL_ORDER_REFOUND_SUCCESS,
    payload: { amount },
  }),

  getCancelOrderRefoundFailure: () => ({
    type: orderTypes.GET_CANCEL_ORDER_REFOUND_FAILURE,
  }),
  loadOfferedTips: (orderId) => ({
    type: orderTypes.GET_OFFERED_TIPS_REQUEST,
    payload: { orderId },
  }),
  giveTips: (orderId, data) => ({
    type: orderTypes.GIVE_TIPS_REQUEST,
    payload: { orderId, data },
  }),
  confirmAddedOrder: (orders) => ({
    type: orderTypes.CONFIRM_ADDED_ORDERS,
    payload: orders,
  }),
  rejectAddedOrder: (order) => ({
    type: orderTypes.REJECT_ADDED_ORDERS,
    payload: order
  }),
  confirmAddedOrderFaild: (error) => ({
    type: orderTypes.CONFIRM_ADDED_ORDERS_FAILD,
    payload: error
  })
};

export const orderSelectors = {
  getOrder: (state) => state.order.order,

  getOrderLoading: (state) => state.order.orderLoading,

  getOrderConfirmLoading: (state) => state.order.orderConfirmLoading,

  getOrderTaskerLocation: (state) => state.order.taskerLocation,

  getOrderTaskerLocationLoading: (state) => state.order.taskerLocationLoading,

  getTasks: (state) => state.order.tasks,

  getTasksLoading: (state) => state.order.tasksLoading,

  getReasons: (state) => state.order.reasons,

  getSelectedReason: (state) => state.order.selectedReason,

  getRefoundSum: (state) => state.order.refoundSumm,

  getRefoundSumLoader: (state) => state.order.refoundSummLoader,

  getOfferedTips: (state) => state.order.offeredTips,

  getConfirmAddedOrdersFaild: (state) => state.order.confirmAddedOrderFaild
};
