import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Svg } from '../../components/ui';
import { userActions } from '../../reducers/user';
import styles from './SignInSocial.module.scss';

const SignInSocial = ({ signInFacebook, signInGoogle, t }) => (
  <div className={styles.container}>
    <div className={styles.buttonContainer}>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        fields={process.env.REACT_APP_FACEBOOK_APP_FIELDS}
        callback={(res) => signInFacebook(res)}
        render={(renderProps) => (
          <button
            className={styles.buttonLogin}
            onClick={renderProps.onClick}
            type="button"
          >
            <Svg className={styles.iconFb} src={'fb'} />
            {t('signInWithFacebook')}
          </button>
        )}
      />
    </div>
    <div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={(res) => signInGoogle(res)}
        onFailure={(err) => console.log(err)}
        className={styles.buttonGoogle}
        render={(renderProps) => (
          <button className={styles.buttonLogin} onClick={renderProps.onClick}>
            <Svg className={styles.iconG} src={'g'} />
            {t('signInWithGoogle')}
          </button>
        )}
      />
    </div>
  </div>
);

export default compose(
  connect(null, userActions),
  withTranslation(),
)(SignInSocial);
