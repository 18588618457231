import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { PinkButtonTransparent } from '../../../components/buttons';
import { getPaymentDateTime } from '../../../utils/time';
import { getPathForEditOrder } from '../../routing/constants/pathHelpers';

import styles from './PaymentInfo.module.scss';

class PaymentTasksList extends Component {
  handleChange = () => {
    const { detachTaskerAndRedirect, order } = this.props;

    detachTaskerAndRedirect(order.id, getPathForEditOrder());
  };

  render() {
    const { order, t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('informationAboutOrder')}</div>

        <div className={styles.fields}>
          <div className={styles.field}>
            <div className={styles.fieldName}>{t('timeOfOrder')}</div>
            <div className={styles.fieldValue}>
              {getPaymentDateTime(order.datetime)}
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.fieldName}>{t('address')}</div>
            <div className={styles.fieldValue}>{order.address.address}</div>
          </div>
        </div>

        <div className={styles.btn}>
          <PinkButtonTransparent onClick={this.handleChange}>
            {t('changeCapital')}
          </PinkButtonTransparent>
        </div>
      </div>
    );
  }
}

PaymentTasksList.propTypes = {
  order: PropTypes.object,
};

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      tasks: createOrderSelectors.getTasks(state),
    }),
    createOrderActions,
  ),
)(PaymentTasksList);
