import request from 'superagent';
import { getToken, getGuestToken, removeToken } from '../utils/authUtil';
import { getI18n } from 'react-i18next';
const handleUnAuthorized = (error) => {
  if (error.status === 401) {
    if (error.response && error.response.body && error.response.body.data) {
      if (error.response.body.data.reason === 'OTHER_DEVICE_SIGNED_IN') {
        console.log('%c handleUnAuthorized error = ', 'color: blue', error);

        if (getToken()) {
          removeToken();
          console.log('Error status reload from baseService');
          //eslint-disable-next-line
          alert(
            'Someone just signed in to your account on other device. You will be disconnected.',
          );
          document.location.reload();
        }
      }
    } else {
      console.log('%c handleUnAuthorized error = ', 'color: green', error);

      if (getToken()) {
        removeToken();
        console.log('Error status reload from baseService');
        document.location.reload();
      }
    }
  }
};

const makeApiCall = <T>({
  type,
  path,
  data,
  isJson,
  noJson,
  formData
}: any): Promise<T> => {
  const language = window.localStorage.getItem('lang');
  const i18language = getI18n?.()?.language;
  const requestHeaders = {
    'Accept-Language':
      i18language ??
      (language === 'en' ? 'EN' : language === 'ru' ? 'RU' : 'GE'),
  };

  if (noJson) {
    isJson = false;
  }

  if (isJson) {
    requestHeaders['Content-Type'] = 'application/json';
  } else if (noJson && !isJson) {
    requestHeaders['Content-Type'] = 'text/html';
  }else if(formData){
    requestHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  if (getToken()) {
    requestHeaders.Authorization = `JWT ${getToken()}`;
  } else if (getGuestToken()) {
    requestHeaders.Authorization = `GUEST ${getGuestToken()}`;
  }

 
    return new Promise((resolve, reject) => {
      //request(type, `${window.location.protocol}//${process.env.REACT_APP_API_URL}${path}`)
      return request(type, `https://${process.env.REACT_APP_API_URL}${path}`)
        .set(requestHeaders)
        .send(data)
        .then((res) => {
          if (noJson) {
            resolve(res.text);
          } else {
            resolve(res.body);
          }
        })
        .catch((error) => {
          handleUnAuthorized(error);
          if (error && error.response) {
            reject(error.response.body);
          }
        });
    });
  
  
};

export const get = <T>(path: string, noJson = false): Promise<T> =>
  makeApiCall<T>({ type: 'GET', path, isJson: true, noJson: noJson });

export const post = (path: string, data?: any) =>
  makeApiCall({ type: 'POST', path, data, isJson: true });

export const put = (path: string, data) =>
  makeApiCall({ type: 'PUT', path, data, isJson: true });

export const del = (path: string, data = undefined) =>
  makeApiCall({ type: 'DELETE', path, isJson: true, data });

export const postFormData = (path: string, data) =>
  makeApiCall({ type: 'POST', path, data, isJson: false, noJson: false });

export const postFormDataNew = (path: string, data) =>
  makeApiCall({ type: 'POST', path, data, isJson: false, noJson: false, formData: true });
