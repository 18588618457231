import React, { Fragment } from 'react';

import { withAuthData } from '../hoc';

import SignIn from '../signin';
import SignUp from '../signup';
import SignUpPhoneConfirm from '../signup/SignUpPhoneConfirm';

const GuestRoutes = ({ userLoggedIn }) =>
  userLoggedIn ? null : (
    <Fragment>
      <SignIn />
      <SignUp />
      <SignUpPhoneConfirm />
    </Fragment>
  );

export default withAuthData(GuestRoutes);
