import { takeLatest, call, put, select, take } from 'redux-saga/effects';
import { formatMomentDate } from '../../utils/time';
import { replace } from 'connected-react-router';
import get from 'lodash/get';

import * as service from '../../services/taskersService';
import { getPathForPaymentPage } from '../routing/constants/pathHelpers';
import { dialogActions } from '../dialog/store';
import { createOrderActions } from '../../reducers/createOrder';

/* ACTION TYPES */

const MODULE_PREFIX = 'taskers/';

export const types = {
  LOAD_TASKERS_REQUEST: MODULE_PREFIX + 'LOAD_TASKERS_REQUEST',
  LOAD_TASKERS_SUCCESS: MODULE_PREFIX + 'LOAD_TASKERS_SUCCESS',
  LOAD_TASKERS_FAILURE: MODULE_PREFIX + 'LOAD_TASKERS_FAILURE',

  CHANGE_FILTER_DATE: MODULE_PREFIX + 'CHANGE_FILTER_DATE',

  CHANGE_FILTER_TYPE: MODULE_PREFIX + 'CHANGE_FILTER_TYPE',

  CLEAR_TASKERS_STATE: MODULE_PREFIX + 'CLEAR_TASKERS_STATE',

  OPEN_POPUP_AND_LOAD_TIMETABLE:
    MODULE_PREFIX + 'OPEN_POPUP_AND_LOAD_TIMETABLE',

  SWITCH_DETAILED_POPUP_ON_TIMETABLE:
    MODULE_PREFIX + 'SWITCH_DETAILED_POPUP_ON_TIMETABLE',

  LOAD_TASKER_TIMETABLE_SUCCESS:
    MODULE_PREFIX + 'LOAD_TASKER_TIMETABLE_SUCCESS',
  LOAD_TASKER_TIMETABLE_FAILURE:
    MODULE_PREFIX + 'LOAD_TASKER_TIMETABLE_FAILURE',

  OPEN_DETAILED_INFO_POPUP: MODULE_PREFIX + 'OPEN_DETAILED_INFO_POPUP',

  LOAD_TASKER_DETAILED_REQUEST: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_REQUEST',
  LOAD_TASKER_DETAILED_SUCCESS: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_SUCCESS',
  LOAD_TASKER_DETAILED_FAILURE: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_FAILURE',

  LOAD_TASKER_REVIEWS_REQUEST: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_REQUEST',
  LOAD_TASKER_REVIEWS_SUCCESS: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_SUCCESS',
  LOAD_TASKER_REVIEWS_FAILURE: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_FAILURE',

  CLOSE_DETAILED_INFO_POPUP: MODULE_PREFIX + 'CLOSE_DETAILED_INFO_POPUP',

  SELECT_DATE_IN_TIMETABLE: MODULE_PREFIX + 'SELECT_DATE_IN_TIMETABLE',
  SELECT_TIME: MODULE_PREFIX + 'SELECT_TIME',

  CLOSE_TIMETABLE_POPUP: MODULE_PREFIX + 'CLOSE_TIMETABLE_POPUP',

  ATTACH_TASKER_REQUEST: MODULE_PREFIX + 'ATTACH_TASKER_REQUEST',
  ATTACH_TASKER_SUCCESS: MODULE_PREFIX + 'ATTACH_TASKER_SUCCESS',
  ATTACH_TASKER_FAILURE: MODULE_PREFIX + 'ATTACH_TASKER_FAILURE',

  GET_TASKERS_DAYS_REQUEST: MODULE_PREFIX + 'GET_TASKERS_DAYS_REQUEST',
  GET_TASKERS_DAYS_SUCCESS: MODULE_PREFIX + 'GET_TASKERS_DAYS_SUCCESS',
  GET_TASKERS_DAYS_FAILURE: MODULE_PREFIX + 'GET_TASKERS_DAYS_FAILURE',
  // GET_BANDELS: MODULE_PREFIX + 'GET_BANDELS',
  // GET_BANDELS_SUCCESS: MODULE_PREFIX + 'GET_BANDELS_SUCCESS'
};

/* INITIAL STATE */

const inititalTimetableState = {
  selectedTaskerId: null,
  timetableTasker: { user: {} },
  timetablePopupOpened: false,
  timetable: null,
  timetableLoading: false,
  // selectedDateInTimetable: moment(),
  selectedDateInTimetable: '',
  selectedTime: '',
  errors: null,
  taskersDays: null,
  taskersDaysLoading: false,
  // bandels: [],
};

const initialDetailedTaskerState = {
  taskerDetailedPopupOpened: false,
  taskerDetailed: {},
  taskerDetailedLoading: false,
  reviews: [],
  reviewsLoading: false,
};

const initialState = {
  taskers: [],
  taskersLoading: false,
  // date: moment(),
  date: '',
  filterType: '',
  

  ...inititalTimetableState,
  ...initialDetailedTaskerState,
};

/* REDUCER HELPERS */

const taskersHelpers = {
  openPopupAndLoadTimetable: (state, action) => ({
    ...state,
    ...initialDetailedTaskerState,
    timetablePopupOpened: true,
    timetableLoading: true,
    selectedTaskerId: action.tasker.user.id,
    selectedDateInTimetable: state.date,
    timetableTasker: action.tasker.price
      ? action.tasker
      : state.taskers.find(
          (tasker) => tasker.user.id === action.tasker.user.id,
        ),
  }),
};

/* REDUCER */

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_TASKERS_REQUEST:
      return { ...state, taskersLoading: true };

    case types.LOAD_TASKERS_SUCCESS:
      return { ...state, taskers: action.taskers, taskersLoading: false };

    case types.LOAD_TASKERS_FAILURE:
      return { ...state, taskersLoading: false };

    case types.CHANGE_FILTER_DATE:
      return { ...state, date: action.date };

    case types.CHANGE_FILTER_TYPE:
      return { ...state, filterType: action.filterType };

    case types.OPEN_POPUP_AND_LOAD_TIMETABLE:
      return taskersHelpers.openPopupAndLoadTimetable(state, action);

    case types.LOAD_TASKER_TIMETABLE_SUCCESS:
      return { ...state, timetable: action.timetable, timetableLoading: false };

    case types.LOAD_TASKER_TIMETABLE_FAILURE:
      return { ...state, timetableLoading: false };

    case types.CLOSE_TIMETABLE_POPUP:
      return { ...state, ...inititalTimetableState };

    case types.SELECT_DATE_IN_TIMETABLE:
      return {
        ...state,
        selectedDateInTimetable: action.date,
        selectedTime: null,
      };

    case types.SELECT_TIME:
      return { ...state, selectedTime: action.time };

    case types.OPEN_DETAILED_INFO_POPUP:
      return { ...state, taskerDetailedPopupOpened: true };

    case types.LOAD_TASKER_DETAILED_REQUEST:
      return { ...state, taskerDetailedLoading: true };

    case types.LOAD_TASKER_DETAILED_SUCCESS:
      return {
        ...state,
        taskerDetailed: action.taskerDetailed,
        taskerDetailedLoading: false,
      };

    case types.LOAD_TASKER_REVIEWS_REQUEST:
      return { ...state, reviewsLoading: true };

    case types.LOAD_TASKER_REVIEWS_SUCCESS:
      return { ...state, reviews: action.reviews, reviewsLoading: false };

    case types.CLOSE_DETAILED_INFO_POPUP:
      return { ...state, ...initialDetailedTaskerState };

    case types.ATTACH_TASKER_FAILURE:
      return { ...state, errors: get(action, 'error.errors.*') };

    case types.CLEAR_TASKERS_STATE:
      return initialState;

    case types.GET_TASKERS_DAYS_REQUEST:
      return { ...state, taskersDaysLoading: true };

    case types.GET_TASKERS_DAYS_SUCCESS:
      return {
        ...state,
        taskersDays: action.taskersDays,
        taskersDaysLoading: false,
      };
    // case types.GET_BANDELS_SUCCESS:
    //   return {...state, bandels: action.bandels};

    case types.GET_TASKERS_DAYS_FAILURE:
      return { ...state, taskersDaysLoading: false };

    default:
      return state;
  }
};

/* ACTION CREATORS */

const loadTaskersForOrder = (orderId) => ({
  type: types.LOAD_TASKERS_REQUEST,
  orderId,
});

const changeFilterDate = (date, orderId) => ({
  type: types.CHANGE_FILTER_DATE,
  date,
  orderId,
});

// const getBandelsData = () => ({
//   type: types.GET_BANDELS
// });

const changeFilterType = (filterType, orderId) => ({
  type: types.CHANGE_FILTER_TYPE,
  filterType,
  orderId,
});

const clearTaskersState = () => ({
  type: types.CLEAR_TASKERS_STATE,
});

const openTimetablePopup = (orderId, tasker) => ({
  type: types.OPEN_POPUP_AND_LOAD_TIMETABLE,
  orderId,
  tasker,
});

const switchDetailedPopupOnTimetable = (orderId, taskerId) => ({
  type: types.OPEN_POPUP_AND_LOAD_TIMETABLE,
  orderId,
  tasker: {
    user: {
      id: taskerId,
    },
  },
});

const closeTimetablePopup = () => ({
  type: types.CLOSE_TIMETABLE_POPUP,
});

const selectDateInTimetable = (date) => ({
  type: types.SELECT_DATE_IN_TIMETABLE,
  date,
});

const selectTime = (time) => ({
  type: types.SELECT_TIME,
  time,
});

const openDetailedPopup = (taskerId) => ({
  type: types.OPEN_DETAILED_INFO_POPUP,
  taskerId,
});

const loadTaskerDetailed = (taskerId) => ({
  type: types.LOAD_TASKER_DETAILED_REQUEST,
  taskerId,
});

const loadTaskerReviews = (taskerId) => ({
  type: types.LOAD_TASKER_REVIEWS_REQUEST,
  taskerId,
});

const closeDetailedInfoPopup = () => ({
  type: types.CLOSE_DETAILED_INFO_POPUP,
});

const attachTasker = (order_id) => ({
  type: types.ATTACH_TASKER_REQUEST,
  order_id,
});

const loadTaskersDays = () => ({
  type: types.GET_TASKERS_DAYS_REQUEST,
});



export const taskersActions = {
  loadTaskersForOrder,
  changeFilterDate,
  changeFilterType,
  clearTaskersState,
  openTimetablePopup,
  switchDetailedPopupOnTimetable,
  closeTimetablePopup,
  selectDateInTimetable,
  selectTime,
  openDetailedPopup,
  closeDetailedInfoPopup,
  attachTasker,
  loadTaskersDays,
  // getBandelsData
};

/* SELECTORS */

export const taskersSelectors = {
  getTaskers: (state) => state.createOrderTaskers.taskers,

  getTaskersLoading: (state) => state.createOrderTaskers.taskersLoading,

  getFilterDate: (state) => state.createOrderTaskers.date,

  getFilterType: (state) => state.createOrderTaskers.filterType,

  getSelectedTaskerId: (state) => state.createOrderTaskers.selectedTaskerId,

  getTimeTableTasker: (state) => state.createOrderTaskers.timetableTasker,

  getTimetable: (state) => state.createOrderTaskers.timetable,

  getTimetableLoading: (state) => state.createOrderTaskers.timetableLoading,

  getTimetablePopupOpened: (state) =>
    state.createOrderTaskers.timetablePopupOpened,

  getSelectedDateInTimetable: (state) =>
    state.createOrderTaskers.selectedDateInTimetable,

  getSelectedTime: (state) => state.createOrderTaskers.selectedTime,

  getDetailedtasker: (state) => state.createOrderTaskers.taskerDetailed,

  getDetailedtaskerLoading: (state) =>
    state.createOrderTaskers.taskerDetailedLoading,

  getTaskerReviews: (state) => state.createOrderTaskers.reviews,

  getTaskerReviewsLoading: (state) => state.createOrderTaskers.reviewsLoading,

  getDetailedTaskerPopupOpened: (state) =>
    state.createOrderTaskers.taskerDetailedPopupOpened,

  getErrors: (state) => state.createOrderTaskers.errors,

  getTaskersDays: (state) => state.createOrderTaskers.taskersDays,

  getTaskersDaysLoading: (state) => state.createOrderTaskers.taskersDaysLoading,

  // bandelsDatas: (state) => state.createOrderTaskers.bandels,
};

/* SAGAS */
function* onOrderErrors(error) {
  if (error.code && error.code === 'ORDER_IS_ALREADY_CREATED') {
    yield put(dialogActions.openSubmitOrderErrorPopup());
  }
}

function* onTaskersLoad({ orderId }) {
  try {
    const date = yield select(taskersSelectors.getFilterDate);

    let formattedDate = yield call(formatMomentDate, date);

    if (!formattedDate) formattedDate = '';

    const filterType = yield select(taskersSelectors.getFilterType);

    const { collection } = yield call(
      service.getTaskersForOrder,
      orderId,
      formattedDate,
      filterType,
    );
    yield put({ type: types.LOAD_TASKERS_SUCCESS, taskers: collection });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKERS_FAILURE, error });
  }
}

function* onTimetableLoad({ orderId, tasker }) {
  try {
    const timetable = yield call(
      service.getTaskerTimeTable,
      orderId,
      tasker.user.id,
    );
    yield put({ type: types.LOAD_TASKER_TIMETABLE_SUCCESS, timetable });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_TIMETABLE_FAILURE, error });
  }
}

function* onTaskerAttach({ order_id }) {
  try {
    const formattedDate = yield call(
      formatMomentDate,
      yield select(taskersSelectors.getSelectedDateInTimetable),
    );

    const { value } = yield select(taskersSelectors.getSelectedTime);

    const data = yield call(service.attachTasker, {
      order_id,
      tasker_id: yield select(taskersSelectors.getSelectedTaskerId),
      date: formattedDate,
      time: value,
    });

    yield put({ type: types.ATTACH_TASKER_SUCCESS, ...data });
    yield put(createOrderActions.loadEditingOrder());
    yield put(replace(getPathForPaymentPage(order_id)));
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.ATTACH_TASKER_FAILURE, error });
  }
}

function* onDetailedPopupOpen({ taskerId }) {
  yield put(loadTaskerDetailed(taskerId));
  yield put(loadTaskerReviews(taskerId));
}

function* onTaskerDetailedLoad({ taskerId }) {
  try {
    const { model } = yield call(service.getTaskerDetailed, taskerId);
    yield put({
      type: types.LOAD_TASKER_DETAILED_SUCCESS,
      taskerDetailed: model,
    });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_DETAILED_FAILURE });
  }
}

function* onTaskerReviewLoad({ taskerId }) {
  try {
    const { collection } = yield call(
      service.loadTaskerReviews,
      taskerId,
      0,
      50,
    );
    yield put({ type: types.LOAD_TASKER_REVIEWS_SUCCESS, reviews: collection });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_REVIEWS_FAILURE, error });
  }
}

function* onTaskersDaysLoad() {
  try {
    const { max_order_days } = yield call(service.getTaskersDays);
    yield put({
      type: types.GET_TASKERS_DAYS_SUCCESS,
      taskersDays: max_order_days,
    });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.GET_TASKERS_DAYS_FAILURE, error });
  }
}

export const taskersSagas = {
  watchTaskersLoad: function* () {
    yield takeLatest(types.LOAD_TASKERS_REQUEST, onTaskersLoad);
  },

  watchFilterDateChange: function* () {
    while (true) {
      const { orderId } = yield take(types.CHANGE_FILTER_DATE);
      yield put(loadTaskersForOrder(orderId));
    }
  },

  watchFilterTypeChange: function* () {
    while (true) {
      const { orderId } = yield take(types.CHANGE_FILTER_TYPE);
      yield put(loadTaskersForOrder(orderId));
    }
  },

  

  // watchGetBandels: function* (){
  //   try{
  //     yield take(types.GET_BANDELS)
  //     const bandels = yield call(service.bandels);
  //     yield put({
  //       type: types.GET_BANDELS_SUCCESS,
  //       bandels: bandels
  //     });
  //     }catch(error){
  //       console.log({'error': error})
  //     }
  // },

  watchTimetableLoad: function* () {
    yield takeLatest(types.OPEN_POPUP_AND_LOAD_TIMETABLE, onTimetableLoad);
  },

  watchTaskerAttach: function* () {
    yield takeLatest(types.ATTACH_TASKER_REQUEST, onTaskerAttach);
  },

  watchDetailedtaskerPopupOpened: function* () {
    yield takeLatest(types.OPEN_DETAILED_INFO_POPUP, onDetailedPopupOpen);
  },

  watchTaskerDetailedLoad: function* () {
    yield takeLatest(types.LOAD_TASKER_DETAILED_REQUEST, onTaskerDetailedLoad);
  },

  watchTaskerReviewsLoad: function* () {
    yield takeLatest(types.LOAD_TASKER_REVIEWS_REQUEST, onTaskerReviewLoad);
  },

  watchTaskersDaysLoad: function* () {
    yield takeLatest(types.GET_TASKERS_DAYS_REQUEST, onTaskersDaysLoad);
  },
};
