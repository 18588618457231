import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Loader } from '../../components/ui';
import { LabeledTextArea } from '../../components/inputs/text';
import { GrayButtonTransparent, GreenButton } from '../../components/buttons';
import { supportActions, supportSelectors } from '../../reducers/support';
import { screens } from './helpers/supportConstants';

import styles from './CreateComplaint.module.scss';
import { statuses } from '../order/helpers/orderConstants';
import { chatActions } from '../../reducers/chat';

class CreateComplaint extends Component {
  getScreen = () => {
    const { screen } = this.props;

    switch (screen) {
      case statuses.PAID:
        return screens.ORDER_PAID;

      case statuses.VIEWED:
        return screens.ORDER_VIEWED;

      case statuses.TRANSPORTATION:
        return screens.ORDER_TRANSPORTATION;

      case statuses.IN_PROGRESS:
        return screens.ORDER_IN_PROGRESS;

      case statuses.WAITING_CONFIRMATION:
        return screens.ORDER_CONFIRMATION;

      case 'ORDER_ON_WARRANTY':
        return screens.ORDER_ON_WARRANTY;

      case statuses.PENDING:
        return screens.ORDER_PENDING;

      case 'PROFILE':
        return screens.PROFILE;

      case 'DEACTIVATED':
        return screens.DEACTIVATED;

      case 'BLOCKED':
        return screens.BLOCKED;

      case 'ORDER_WAITING_PAYMENT':
        return screens.BLOCKED;

      default:
        return screens.PROFILE;
    }
  };

  componentDidMount() {
    this.props.loadComplaintsList(this.getScreen());
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedComplaint.id &&
      prevProps.selectedComplaint.id !== this.props.selectedComplaint.id
    ) {
      this.renderContent();
    }

    if (prevProps.selectedComplaint.id && !this.props.selectedComplaint.id) {
      this.props.loadComplaintsList(this.getScreen());
    }
  }

  renderComplaintsList = () => {
    const { complaintsList, t } = this.props;

    if (!complaintsList || (complaintsList && complaintsList.length === 0))
      return (
        <div className={styles.list}>
          <p>{t('noTopicsAvailable')}</p>
        </div>
      );

    return (
      <Fragment>
        <p>{t('selectComplaint')}:</p>

        <ul className={styles.list}>
          {complaintsList.map((complaint) => (
            <li
              onClick={() => this.onComplaintClick(complaint)}
              key={complaint.id}
              className={styles.item}
            >
              {complaint.name}
            </li>
          ))}
        </ul>
      </Fragment>
    );
  };

  onComplaintClick = (complaint) => {
    const { selectComplaint, orderId } = this.props;

    selectComplaint({
      ...complaint,
      screen: this.getScreen(),
      order_id: orderId,
    });
  };

  onChangeComplaintsComment = (event) => {
    this.props.complaintCommentChange(event.target.value);
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { complaintSend, selectedComplaint } = this.props;

    complaintSend(selectedComplaint);
  };

  onBackClickFromForm = () => {
    this.props.clearSelectedComplaintState();
  };

  renderContent = () => {
    const { selectedComplaint } = this.props;

    if (!selectedComplaint.id) return this.renderList();

    return this.renderForm();
  };

  renderForm = () => {
    const { t, selectedComplaint } = this.props;

    return (
      <div>
        <p className={styles.theme}>
          {t('theme')}: {selectedComplaint.name}
        </p>
        <p className={styles.description}>{selectedComplaint.description}</p>

        {selectedComplaint.is_reportable ? (
          <form onSubmit={this.onSubmit}>
            {selectedComplaint.is_reportable && (
              <LabeledTextArea
                value={selectedComplaint.comment}
                autoHeight={true}
                label={t('comment')}
                length={true}
                onChange={this.onChangeComplaintsComment}
                maxlength={1000}
              />
            )}

            <div className={styles.btnWrapper}>
              <GrayButtonTransparent
                iconName={'arrowLeft'}
                iconPos={'left'}
                type={'button'}
                onClick={this.onBackClickFromForm}
              >
                {t('back')}
              </GrayButtonTransparent>

              <GreenButton
                type={'submit'}
                disabled={selectedComplaint.comment.length === 0}
              >
                {t('send')}
              </GreenButton>
            </div>
          </form>
        ) : (
          <div className={styles.btnWrapper}>
            <GrayButtonTransparent
              iconName={'arrowLeft'}
              iconPos={'left'}
              type={'button'}
              onClick={this.onBackClickFromForm}
            >
              {t('back')}
            </GrayButtonTransparent>
          </div>
        )}
      </div>
    );
  };

  renderList = () => {
    const { t, complaintsListLoading, btnBackClick } = this.props;

    return (
      <div>
        <Loader loading={complaintsListLoading}>
          {this.renderComplaintsList()}
        </Loader>

        {btnBackClick && (
          <GrayButtonTransparent
            iconName={'arrowLeft'}
            iconPos={'left'}
            type={'button'}
            onClick={btnBackClick}
          >
            {t('back')}
          </GrayButtonTransparent>
        )}
      </div>
    );
  };

  render() {
    const { t, complaintSendLoading } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('support')}</div>

        <Loader loading={complaintSendLoading}>{this.renderContent()}</Loader>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearComplaintState();
  }
}

CreateComplaint.propTypes = {
  orderId: PropTypes.number,
  screen: PropTypes.string,
};

const mapStateToProps = (state) => ({
  complaintsListLoading: supportSelectors.getComplaintsListLoading(state),
  complaintsList: supportSelectors.getComplaintsList(state),
  selectedComplaint: supportSelectors.getSelectedComplaint(state),
  complaintSendLoading: supportSelectors.getComplaintSendLoading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    loadComplaintsList: supportActions.loadComplaintsList,
    selectComplaint: supportActions.selectComplaint,
    complaintCommentChange: supportActions.complaintCommentChange,
    clearSelectedComplaintState: supportActions.clearSelectedComplaintState,
    clearComplaintState: supportActions.clearComplaintState,
    complaintSend: supportActions.complaintSend,
    clearChatState: chatActions.clearChatState,
  }),
)(CreateComplaint);
