import React from 'react';
import { withReduxFormProps } from '../../../containers/hoc';

const InitialTextArea = (props) => {
  return (
    <textarea
      {...props}
      ref={props.inputref}
      defaultValue={props.defaultValue}
    ></textarea>
  );
};

export default withReduxFormProps(InitialTextArea);
