import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userSelectors } from '../../reducers/user';

const withAuthData = (WrappedComponent) => {
  class WithAuthDataHOC extends Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          userLoggedIn={this.props.userLoggedIn}
          deactivated={this.props.deactivated}
        />
      );
    }
  }

  return connect((state) => ({
    userLoggedIn: userSelectors.isUserLoggedIn(state),
    deactivated: userSelectors.isUserDeactivated(state),
  }))(WithAuthDataHOC);
};

export default withAuthData;
