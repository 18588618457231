import React from 'react';
import styles from './TabNavigationWithTitle.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Svg } from '.';

type TabNavigationWithTitlePropsType = {
  title?: string;
  onTabClick: (type: number | string) => void;
  activeTab: number;
  tabsArr: { title?: string; icon?: string; type?: string }[];
};

const TabNavigationWithTitle = ({
  title,
  onTabClick,
  activeTab,
  tabsArr,
}: TabNavigationWithTitlePropsType) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} flex flex-row flex-between`}>
      <Helmet>
        {!!tabsArr[activeTab]?.title && (
          <title>{t(tabsArr[activeTab].title ?? '')}</title>
        )}
      </Helmet>
      <h1 className={styles.rightSideTitle}>{t(title ?? '')}</h1>
      <div className={classNames(styles.windowCleaningType, styles.right)}>
        {tabsArr.map(({ title, icon, type }, index) => (
          <p
            key={index}
            onClick={onTabClick.bind(this, type ?? index)}
            className={classNames(
              styles.tab,
              {
                [styles.active]: activeTab === index,
              },
              'flex flex-center',
            )}
          >
            {title
              ? t(title)
              : !!icon && (
                  <Svg
                    src={icon}
                    className={classNames(styles.svg, {
                      [styles.active]: activeTab === index,
                    })}
                  />
                )}
          </p>
        ))}
      </div>
    </div>
  );
};

export default TabNavigationWithTitle;
