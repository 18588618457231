import React, { useEffect, useMemo, useState } from 'react';
import styles from './FormOrder.module.scss';
import { useTranslation, withTranslation } from 'react-i18next';
// import { createCleaningTaskActions, createTaskSelectors } from '../store';
import { compose } from 'recompose';
import WarningBox from '../components/WarningBox';
import { Field, reduxForm } from 'redux-form';
import WrappedCheckbox from './chosenCleaningService/components/WrappedCheckbox';
import InitialTextArea from 'components/inputs/text/InitialTextArea';
import { useDispatch, useSelector } from 'react-redux';
import { RootReduxState } from 'store';
import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';
import TaskImagesUploader from '../components/TaskImagesUploader';
import Svg from '../../../../../components/ui/Svg';
import { ServiceActions } from '../state/actions';

export const ORDER_FORM = 'ORDER_FORM_NEW';

const DescribeOrder = ({ initialize }) => {
  const { t } = useTranslation();
  const {
    additionalInfo,
    selectedServiceObject,
    currentCleaningServiceType,
    selectedServices,
  } = useSelector((state: RootReduxState) => ({
    ...state.createTaskFullFlow,
    ...state.serviceStore,
    additionalInfo: state.form?.[ORDER_FORM]?.values,
  }));

  const dispatch = useDispatch();

  const floorCounter = useMemo(
    () =>
      selectedServiceObject?.services?.filter((val) => val.type === 'REGULAR'),
    [selectedServiceObject, currentCleaningServiceType],
  );

  const [floorNumbers, setFloorNumbers] = useState(
    selectedServices['REGULAR'][Object.keys(selectedServices['REGULAR'])[0]]
      .count,
  );

  const [unit, setUnit] = useState(
    selectedServices['REGULAR'][Object.keys(selectedServices['REGULAR'])[0]]
      .unit,
  );
  const changeFloor = (change: number) => {
    if (
      floorNumbers + change >= floorCounter[0]?.min_quantity &&
      floorNumbers + change <= floorCounter[0]?.max_quantity
    ) {
      setFloorNumbers(floorNumbers + change);
      const myService =
        selectedServices['REGULAR'][
          Object.keys(selectedServices['REGULAR'])[0]
        ];
      dispatch(
        ServiceActions.enteranceCleaningFloors({
          service: myService,
          id: myService.id,
          count: floorNumbers + change,
        }),
      );
    }
  };
  // useEffect(() => {
  //   initialize?.({
  //     additionalInfos: additionalInfo?.user_description,
  //   });
  // }, [additionalInfo, initialize]);

  const floors = useMemo(() => {
    if (selectedServiceObject?.answer_type === 'CLEANING_HOUSE_MAID') {
      return (
        <>
          <div className={styles.mainTitleContainer}>
            {/* <h1>{t('taskDetails')}</h1> */}
            {/* <h5 className={styles.optional}>{t('optional')}</h5> */}
            <h5 className={styles.optional}>
              {unit == 'Hr' ? t('howManyHours') : t('howManyFloors')}
            </h5>
          </div>
          <div className={styles.counterContainer}>
            <div
              className={styles.counterDecBtn}
              onClick={() => changeFloor(-1)}
            >
              -
            </div>
            <div className={styles.counterValue}>
              {floorNumbers}{' '}
              {unit == 'Hr' || unit == 'საათი' || unit == 'Ч'
                ? t('hours')
                : t('floors')}
            </div>
            <div
              className={styles.counterIncBtn}
              onClick={() => changeFloor(1)}
            >
              +
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }, [floorNumbers]);

  const services = useMemo(
    () =>
      selectedServiceObject?.services?.filter(
        (val) => val.type === currentCleaningServiceType,
      ),
    [selectedServiceObject, currentCleaningServiceType],
  );

  useEffect(() => {
    dispatch(createTaskActions.onChangeQuestionStepStatus(true));
    return () => {
      dispatch(createTaskActions.onChangeQuestionStepStatus(false));
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form className={styles.content}>
      <div className={styles.scrollable}>
        <h1 className={styles.rightSideTitle}>{t('taskDetails')}</h1>
        <WarningBox text={t('step2Warning')} />

        <h4 className={styles.box__title}>
          {t('questionPage.questionsTitle')}
        </h4>
        <div className={styles.questionHolder}>
          {/* {floors} */}
          {services?.map(
            (val, index) =>
              val.type === currentCleaningServiceType && (
                <>
                  <Field
                    key={index}
                    name={'checkbox' + val.id}
                    label={val.name}
                    defaultChecked={
                      !!Object.keys(additionalInfo ?? {}).find((_val) => {
                        const str = (additionalInfo?.[_val] + '').includes(
                          'checkbox',
                        )
                          ? (additionalInfo?.[_val] + '').replace(
                              'checkbox',
                              '',
                            )
                          : '';

                        return +str === val.id;
                      })
                    }
                    component={WrappedCheckbox}
                    type={'checkbox'}
                    price={val.price_recommended + ' ' + t('gel')}
                  />
                </>
              ),
          )}
        </div>

        <h4 className={styles.box__title}>{t('questionPage.textareaTitle')}</h4>
        <div className={styles.formItemWrapper}>
          <Field
            name="additionalInfos"
            component={InitialTextArea}
            placeholder={t('leftCommentFor')}
            className={styles.textarea}
          />
          <p className={styles.notRequired}>{t('notRequired')}</p>
        </div>
        <TaskImagesUploader />
      </div>
    </form>
  );
};

export default compose(reduxForm({ form: ORDER_FORM }))(DescribeOrder);
