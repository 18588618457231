import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { orderActions, orderSelectors } from '../../../reducers/order';
import { ReviewsListItem } from '../../../components/reviews';
import ReviewForm from './ReviewForm';
import styles from './Review.module.scss';
import { Loader } from '../../../components/ui';
import { ordersActions, ordersSelectors } from '../../../reducers/ordersList';

class Review extends Component {
  getOrderIdFromProps = (props) => {
    if (props && props.match && props.match.params) {
      return +props.match.params.id;
    }
    return null;
  };

  componentDidMount() {
    this.props.loadCompletedOrders();
  }

  componentDidUpdate(prevProps) {
    if (this.props.order) {
      const updateCurrentOrderId = this.getOrderIdFromProps(this.props);

      if (this.props.order.id !== updateCurrentOrderId) {
        this.props.loadOrder(updateCurrentOrderId);
      }
    }
  }

  render() {
    const { order, orderLoading, completedOrdersLoading, t } = this.props;

    if (order && order.customer_feedback) {
      return (
        <div>
          <Helmet>
            <title>{t('orderReview')}</title>
          </Helmet>
          <div className={styles.title}>{t('yourReview')}</div>
          <Loader loading={orderLoading || completedOrdersLoading}>
            <ReviewsListItem review={order.customer_feedback} />
          </Loader>
        </div>
      );
    }

    return (
      <Loader loading={orderLoading}>
        <ReviewForm order={order} />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  order: orderSelectors.getOrder(state),
  orderLoading: orderSelectors.getOrderLoading(state),
  completedOrdersLoading: ordersSelectors.getCompletedOrdersLoading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    loadOrder: orderActions.loadOrder,
    loadCompletedOrders: ordersActions.loadCompletedOrders,
  }),
  withRouter,
)(Review);
