import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  createTaskActions,
  createTaskSelectors,
} from '../../../createTask/store';
import { SearchTextInput } from '../../../../components/inputs/text/index';
import { Loader } from '../../../../components/ui/index';
import Svg from '../../../../components/ui/Svg';
import styles from './SubcategorySearch.module.scss';
import { getPathForCreateNewTask } from '../../../routing/constants/pathHelpers';
import { createOrderSelectors } from '../../../../reducers/createOrder';

class SubcategorySearch extends Component {
  state = {
    focused: false,
    selected: 0,
  };

  formRef = React.createRef();
  resultsListRef = React.createRef();
  selectedResultRef = React.createRef();

  scrollToSelected = () => {
    const selectedElem = this.selectedResultRef.current;
    const resultsList = this.resultsListRef.current;

    if (!selectedElem || !resultsList) return;

    const selectedElemTop = selectedElem.offsetTop;
    const selectedElemHeight = selectedElem.offsetHeight;
    const resultsScrollY = resultsList.scrollTop;
    const resultsHeight = resultsList.offsetHeight;
    let scrollTop = 0;

    if (selectedElemTop === resultsScrollY) {
      scrollTop = resultsScrollY - selectedElemHeight;
    } else if (
      selectedElemTop > resultsScrollY &&
      selectedElemTop + selectedElemHeight < resultsScrollY + resultsHeight
    ) {
      scrollTop = resultsScrollY;
    } else if (selectedElemTop > resultsScrollY) {
      scrollTop = resultsScrollY + selectedElemHeight;
    } else {
      scrollTop = resultsScrollY - selectedElemHeight;
    }

    resultsList.scroll({
      left: 0,
      top: scrollTop,
      behavior: 'smooth',
    });
  };

  clearSelected = () => {
    if (this.state.selected === 0) return;

    this.setState({
      selected: 0,
    });
  };

  onArrowDown = () => {
    const { results } = this.props;
    if (results && results.length > 0) {
      if (this.state.selected === results.length - 1) return;
      this.setState({
        selected: this.state.selected + 1,
      });
    } else {
      this.clearSelected();
    }
  };

  onArrowUp = () => {
    const { results } = this.props;
    if (results && results.length > 0) {
      if (this.state.selected === 0) return;
      this.setState({
        selected: this.state.selected - 1,
      });
    } else {
      this.clearSelected();
    }
  };

  onEnter = () => {
    const { results } = this.props;
    if (isEmpty(results)) return;
    this.selectedResultRef.current.focus();
    this.onResultClick(results[this.state.selected]);
  };

  componentDidMount() {
    if (this.formRef.current) {
      this.formRef.current.addEventListener('keyup', (event) => {
        switch (event.code) {
          case 'ArrowDown':
            this.onArrowDown();
            break;
          case 'ArrowUp':
            this.onArrowUp();
            break;
          case 'Enter':
            this.onEnter();
            break;
          default:
            break;
        }
      });
    }
  }

  onResultClick = (result) => {
    const { order } = this.props;
    this.props.push(getPathForCreateNewTask());
    this.props.selectSubcategory({
      categoryId: result.category.id,
      question: result.question,
      subcategory: result.name,
      tasksCount: order && order.services ? order.services.length : null,
    });
    this.clearSelected();
  };

  renderResult = (result, index) => (
    <div
      className={classNames(styles.result, {
        [styles.selected]: index === this.state.selected,
      })}
      onClick={() => this.onResultClick(result)}
      key={result.category.id + result.category.name + result.name}
      ref={index === this.state.selected ? this.selectedResultRef : null}
      tabIndex="-1"
    >
      {result.image ? (
        <div className={styles.resultImage}>
          <img src={result.image} alt={'search result'} />
        </div>
      ) : (
        <div className={styles.resultImage} />
      )}
      <div className={styles.resultText}>
        <span className={styles.resultCategory}>{result.category.name}</span>
        <span className={styles.resultName}>{result.name}</span>
      </div>
    </div>
  );

  onBlur = () => {
    setTimeout(() => {
      this.setState({ focused: false });
      this.clearSelected();
    }, 250);
  };

  renderNoSubcategoriesFound = () => {
    const { value, t } = this.props;

    return (
      <div className={styles.notFound}>
        {value.length < 3 || value.length === ''
          ? t('enterMore3Symbols')
          : t('noServicesFound')}
      </div>
    );
  };

  renderResults = () => {
    const { results } = this.props;

    if (results.length === 0) return this.renderNoSubcategoriesFound();

    return (
      <div>
        {results.map(this.renderResult)}
        {this.scrollToSelected()}
      </div>
    );
  };

  render() {
    const { t, loadSubcategoriesSearchResult, value, loading } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <div className={styles.searchForm} ref={this.formRef}>
          <SearchTextInput
            placeholder={t('whatShouldBeDone')}
            name="search"
            value={value}
            onChange={loadSubcategoriesSearchResult}
            onFocus={() => this.setState({ focused: true })}
            onBlur={this.onBlur}
          />
          <button className={styles.searchFormSubmit} type="submit">
            <Svg className={styles.searchFormSubmitIcon} src="search" />
          </button>
        </div>
        {this.state.focused && value ? (
          <div className={styles.searchResults} ref={this.resultsListRef}>
            <Loader height={100} loading={loading}>
              {this.renderResults()}
            </Loader>
          </div>
        ) : null}
      </div>
    );
  }

  componentWillUnmount() {
    this.clearSelected();
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      value: createTaskSelectors.getSubcategoriesSearchValue(state),
      results: createTaskSelectors.getSubcategoriesSearchResults(state),
      loading: createTaskSelectors.isSubcategoriesSearchLoading(state),
      order: createOrderSelectors.getOrder(state),
      orderLoading: createOrderSelectors.getOrderLoading(state),
    }),
    { ...createTaskActions, push },
  ),
)(SubcategorySearch);
