import React from 'react';
import { reduxForm, Field, isValid } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import AsideMapSearchBoxNew from '../../../components/inputs/map/AsideMapSearchBoxNew';
import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';

import styles from './AddressForm.module.scss';
import { createOrderAddressActions } from '../store';
import LabeledTextInputNew from '../../../components/inputs/text/LabeledTextInputNew';

import { ReactComponent as Star } from 'assets/images/inline/star-black.svg';
import { RootReduxState } from 'store';
import { userSelectors } from 'reducers/user';
import NextStepButton from 'containers/createTaskFullFlow/components/NextStepButton';

type AddressFormPropType = {
  handleSubmit: (func: (data: any) => void) => void;
  selectAddress: (data: any) => void;
  changeAddressObj: (data: any) => void;
  setNewAddressLocation: (data: any) => void;
  onSubmit: (data: any) => void;
  isEditing: boolean;
  favorite: boolean;
  fromProfile: boolean;
  setFavorite: (favorite: boolean) => void;
  addresses: any;
};

const AddressForm = ({
  handleSubmit,
  changeAddressObj,
  setNewAddressLocation,
  onSubmit,
  favorite,
  setFavorite,
  isEditing,
  fromProfile,
}: AddressFormPropType) => {
  const { t } = useTranslation();

  const { userLoggedIn, addresses, selectedAddressIndex } = useSelector(
    (state: RootReduxState) => ({
      userLoggedIn: userSelectors.isUserLoggedIn(state),
      ...state.addressStore,
    }),
  );

  const favoriteAddress = addresses?.collection.find((address) => {
    return address.favorite;
  });

  return (
    <form className={styles.content} onSubmit={handleSubmit}>
      <div className={styles.background}>
        <div className={styles.addressTypeContainer}>
          {/* <AddressType
              active={active}
              type={addressType}
              ontoggle={ontoggle}
              onAddressTypeSelect={onAddressTypeSelect}
            /> */}
          {/* <Field
            component={LabeledTextInputNew}
            label={t('name')}
            maxlength={40}
            name="name"
          /> */}
        </div>
        <Field
          component={AsideMapSearchBoxNew}
          onChangeAddressObj={changeAddressObj}
          onSetNewAddressLocation={setNewAddressLocation}
          fromForm={true}
          label={t('address')}
          name="address"
        />
        <Field
          component={LabeledTextInputNew}
          label={t('addressPageComment')}
          name="comment"
        />
      </div>

      <div className={styles.buttonsContainer}>
        {userLoggedIn && (
          <div
            className={`flex flex-center  ${styles.addInFavorite} ${favorite ? styles.addInFavoriteActive : ''} ${favorite ? styles.active : ''}`}
            onClick={setFavorite.bind(this, !favorite)}
          >
            <Star fill="currentColor" className={styles.favoriteIcon} />
          </div>
        )}

        <div className="flex">
          <div className={styles.favoriteContainer}>
            {/* <h4 className={styles.favoriteTtile}>{favoriteAddress?.address}</h4>
            <p className={styles.favoriteComment}>{favoriteAddress?.comment}</p> */}
            <h4 className={styles.favoriteTtile}>{t('homeAddress')}</h4>
            <p className={styles.favoriteComment}>{t('markAsMainAddress')}</p>
          </div>
          {/* {!isEditing && !userLoggedIn && (
            <NextStepButton
              onClick={handleSubmit((val) =>
                onSubmit({ ...val, type: 'continue' }),
              )}
              title={t('continue')}
              withoutFill={true}
              marginRight={userLoggedIn ? true : false}
              width={'11rem'}
            />
          )} */}

          {fromProfile && (
            <NextStepButton
              onClick={handleSubmit((val) =>
                onSubmit({ ...val, type: 'save' }),
              )}
              title={t('save2')}
              withoutFill={true}
              marginRight={false}
              active={true}
              width={'11rem'}
            />
          )}
        </div>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors: { address?: string; comment?: string } = {};
  // if (!values.name) errors.name = t('enterAddressName');
  if (!values.address) errors.address = 'pleaseSelectAddress';

  return errors;
};

export default compose(
  reduxForm({ validate }),
  connect(
    (state, ownProps) => ({
      isValid: isValid(ownProps.form)(state),
      selectedAddress: addressesSelectors.getSelectedAddress(state),
    }),
    {
      ...createOrderAddressActions,
      ...addressesActions,
    },
  ),
)(AddressForm);
