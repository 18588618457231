import { wsActionTypes } from '../sagas/websocketSaga';

const MODULE_PREFIX = 'chat/';

export const chatTypes = {
  GET_CHAT_LIST_REQUEST: MODULE_PREFIX + 'GET_CHAT_LIST_REQUEST',
  GET_CHAT_LIST_SUCCESS: MODULE_PREFIX + 'GET_CHAT_LIST_SUCCESS',
  GET_CHAT_LIST_FAILURE: MODULE_PREFIX + 'GET_CHAT_LIST_FAILURE',

  GET_SUPPORT_CHAT_REQUEST: MODULE_PREFIX + 'GET_SUPPORT_CHAT_REQUEST',
  GET_SUPPORT_CHAT_SUCCESS: MODULE_PREFIX + 'GET_SUPPORT_CHAT_SUCCESS',
  GET_SUPPORT_CHAT_FAILURE: MODULE_PREFIX + 'GET_SUPPORT_CHAT_FAILURE',

  GET_MESSAGES_LIST_REQUEST: MODULE_PREFIX + 'GET_MESSAGES_LIST_REQUEST',
  GET_MESSAGES_LIST_SUCCESS: MODULE_PREFIX + 'GET_MESSAGES_LIST_SUCCESS',
  GET_MESSAGES_LIST_FAILURE: MODULE_PREFIX + 'GET_MESSAGES_LIST_FAILURE',

  SET_CURRENT_CHAT: MODULE_PREFIX + 'SET_CURRENT_CHAT',
  CLEAR_MESSAGES: MODULE_PREFIX + 'CLEAR_MESSAGES',

  ON_OPEN_CHAT_POPUP_BY_ID: MODULE_PREFIX + 'ON_OPEN_CHAT_POPUP_BY_ID',

  SET_SUPPORT_CHAT: MODULE_PREFIX + 'SET_SUPPORT_CHAT',

  CLEAR_CHAT_STATE: MODULE_PREFIX + 'CLEAR_CHAT_STATE',

  UPLOAD_CHAT_IMAGE_REQUEST: MODULE_PREFIX + 'UPLOAD_CHAT_IMAGE_REQUEST',
  UPLOAD_CHAT_IMAGE_SUCCESS: MODULE_PREFIX + 'UPLOAD_CHAT_IMAGE_SUCCESS',
  UPLOAD_CHAT_IMAGE_FAILURE: MODULE_PREFIX + 'UPLOAD_CHAT_IMAGE_FAILURE',
};

const initialState = {
  isConnected: false,

  chatList: [],
  chatListLoading: false,

  supportChat: null,
  supportChatLoading: false,

  messageList: [],
  messageListLoading: false,
  hasMore: false,

  currentChatId: '',

  uploadImage: {},
  uploadImageLoading: false,
  uploadedImage: {},
  uploadImages: [],
};

function setMessagesList(state, payload) {
  let messages = [];
  if (payload.earlier) {
    messages = [...payload.messageList, ...state.messageList];
  } else {
    messages = payload.messageList;
  }

  return {
    ...state,
    messageListLoading: false,
    messageList: messages,
    hasMore: payload.hasMore,
  };
}

function updateChatWithNewMessage(state, payload) {
  const isSameRoom = payload.message.room === state.currentChatId;
  const fromSupportChat = state.supportChat
    ? payload.message.room === state.supportChat.id
    : payload.message.user.first_name === 'Service';

  let updatedMessageList = [];

  if (isSameRoom) {
    if (
      payload.message.type === 'IMAGE' &&
      payload.userId === payload.message.user.id
    ) {
      updatedMessageList = state.messageList;
    } else {
      updatedMessageList = [...state.messageList, payload.message];
    }
  } else {
    updatedMessageList = state.messageList;
  }

  let updatedSupportChat = state.supportChat;
  let updatedChatList = state.chatList;

  if (fromSupportChat) {
    updatedSupportChat = {
      ...state.supportChat,
      unread: state.currentChatId ? 0 : state.supportChat.unread + 1,
      last_message: payload.message,
    };
  } else {
    updatedChatList = state.chatList.map((chat) => {
      if (chat.id === payload.message.room) {
        return {
          ...chat,
          last_message: payload.message,
          unread: chat.unread + 1,
        };
      }

      return chat;
    });
  }

  return {
    ...state,
    messageList: updatedMessageList,
    chatList: updatedChatList,
    supportChat: updatedSupportChat,
  };
}

function closeChat(state, payload) {
  const isSupportChat =
    state.supportChat && payload.room === state.supportChat.id;

  let updatedSupportChat = state.supportChat;
  let updatedChatList = state.chatList;

  if (isSupportChat) {
    updatedSupportChat = {
      ...state.supportChat,
      closed: true,
    };
  } else {
    updatedChatList = state.chatList.map((chat) => {
      if (chat.id === payload.room) {
        return {
          ...chat,
          closed: true,
        };
      }

      return chat;
    });
  }

  return {
    ...state,
    chatList: updatedChatList,
    supportChat: updatedSupportChat,
  };
}

function openChat(state, payload) {
  const updatedChatList = state.chatList.map((chat) => {
    if (chat.id === payload.room) {
      return {
        ...chat,
        closed: false,
      };
    }
    return chat;
  });

  return {
    ...state,
    chatList: updatedChatList,
  };
}

function clearUnreadMessagesCount(state, payload) {
  const isSupportChat =
    state.supportChat && payload.room === state.supportChat.id;
  let updatedSupportChat = state.supportChat;

  if (isSupportChat) {
    updatedSupportChat = {
      ...state.supportChat,
      unread: 0,
    };
  }

  return {
    ...state,
    supportChat: updatedSupportChat,
  };
}

function createMessageWithImage(payload) {
  return {
    content: payload.file.preview,
    // created_at: payload.createdAt,
    id: payload.clientGeneratedId,
    room: payload.chatId,
    type: 'IMAGE',
    user: {
      id: payload.userId,
    },
  };
}

function updateUploadImages(state, payload) {
  let uploadImagesUpdate = state.uploadImages;

  uploadImagesUpdate.splice(state.uploadImages.indexOf(payload.preview), 1);

  return uploadImagesUpdate;
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**  Chat list request  **/

    case chatTypes.GET_CHAT_LIST_REQUEST:
      return {
        ...state,
        chatListLoading: true,
      };

    case chatTypes.GET_CHAT_LIST_SUCCESS:
      return {
        ...state,
        chatListLoading: false,
        chatList: payload.chatList,
      };

    case chatTypes.GET_CHAT_LIST_FAILURE:
      return {
        ...state,
        chatListLoading: false,
        chatList: [],
      };

    /**  Support chat request  **/

    case chatTypes.GET_SUPPORT_CHAT_REQUEST:
      return {
        ...state,
        supportChatLoading: true,
      };

    case chatTypes.GET_SUPPORT_CHAT_SUCCESS:
      return {
        ...state,
        supportChat: payload.supportChat,
        supportChatLoading: false,
      };

    case chatTypes.GET_SUPPORT_CHAT_FAILURE:
      return {
        ...state,
        supportChat: null,
        supportChatLoading: false,
      };

    /**  Messages list request  **/

    case chatTypes.GET_MESSAGES_LIST_REQUEST:
      return {
        ...state,
        messageListLoading: true,
      };

    case chatTypes.GET_MESSAGES_LIST_SUCCESS:
      return setMessagesList(state, payload);

    case chatTypes.GET_MESSAGES_LIST_FAILURE:
      return {
        ...state,
        messageListLoading: false,
        messageList: [],
        hasMore: false,
      };

    /**  Update current chat id  **/

    case chatTypes.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChatId: payload.chatId,
      };

    /**  Get new message  **/

    case wsActionTypes.NEW_MESSAGE:
      return updateChatWithNewMessage(state, payload);

    /**  Websocket connection  **/

    case wsActionTypes.WEBSOCKET_AUTHORIZED:
      return {
        ...state,
        isConnected: true,
      };

    case wsActionTypes.CLOSE_CHAT_SOCKET:
      return {
        ...state,
        isConnected: false,
      };

    case wsActionTypes.CHAT_ENTER_SUCCESS:
      return clearUnreadMessagesCount(state, payload);

    case wsActionTypes.CHAT_CLOSED:
      return closeChat(state, payload);

    case wsActionTypes.CHAT_OPENED:
      return openChat(state, payload);

    /**  Clear Messages  **/

    case chatTypes.CLEAR_MESSAGES:
      return {
        ...state,
        messageList: [],
        hasMore: false,
        currentChatId: '',
      };

    case chatTypes.SET_SUPPORT_CHAT:
      return {
        ...state,
        supportChat: { ...payload.supportChat },
        supportChatLoading: false,
      };

    /* *** upload chat image *** */

    case chatTypes.UPLOAD_CHAT_IMAGE_REQUEST:
      return {
        ...state,
        uploadImages: [...state.uploadImages, payload.file.preview],
        uploadImage: payload.file,
        uploadImageLoading: true,
        messageList: [...state.messageList, createMessageWithImage(payload)],
      };

    case chatTypes.UPLOAD_CHAT_IMAGE_SUCCESS:
      return {
        ...state,
        uploadedImage: payload.uploadedImage,
        uploadImage: {},
        uploadImageLoading: false,
        uploadImages: updateUploadImages(state, payload),
      };

    case chatTypes.UPLOAD_CHAT_IMAGE_FAILURE:
      return {
        ...state,
        uploadImage: {},
        uploadImageLoading: false,
      };

    case chatTypes.CLEAR_CHAT_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export const chatActions = {
  getChatList: () => ({
    type: chatTypes.GET_CHAT_LIST_REQUEST,
  }),
  getChatListSuccess: (chatList) => ({
    type: chatTypes.GET_CHAT_LIST_SUCCESS,
    payload: { chatList },
  }),
  getChatListFailure: () => ({
    type: chatTypes.GET_CHAT_LIST_FAILURE,
  }),
  openChatPopupById: (id) => ({
    type: chatTypes.ON_OPEN_CHAT_POPUP_BY_ID,
    payload: id,
  }),
  getSupportChat: () => ({
    type: chatTypes.GET_SUPPORT_CHAT_REQUEST,
  }),
  getSupportChatSuccess: (supportChat) => ({
    type: chatTypes.GET_SUPPORT_CHAT_SUCCESS,
    payload: { supportChat },
  }),
  getSupportChatFailure: () => ({
    type: chatTypes.GET_SUPPORT_CHAT_FAILURE,
  }),

  getMessageList: ({ chatId, lastMessageId, count, earlier }) => ({
    type: chatTypes.GET_MESSAGES_LIST_REQUEST,
    payload: { chatId, lastMessageId, count, earlier },
  }),
  getMessageListSuccess: (messageList, hasMore, earlier) => ({
    type: chatTypes.GET_MESSAGES_LIST_SUCCESS,
    payload: { messageList, hasMore, earlier },
  }),
  getMessageListFailure: () => ({
    type: chatTypes.GET_MESSAGES_LIST_FAILURE,
  }),

  setCurrentChat: (chatId) => ({
    type: chatTypes.SET_CURRENT_CHAT,
    payload: { chatId },
  }),
  clearMessages: () => ({
    type: chatTypes.CLEAR_MESSAGES,
  }),

  clearChatState: () => ({
    type: chatTypes.CLEAR_CHAT_STATE,
  }),

  setSupportChat: (supportChat) => ({
    type: chatTypes.SET_SUPPORT_CHAT,
    payload: { supportChat },
  }),

  /* *** upload chat image *** */

  uploadChatImage: ({
    file,
    clientGeneratedId,
    chatId,
    createdAt,
    userId,
  }) => ({
    type: chatTypes.UPLOAD_CHAT_IMAGE_REQUEST,
    payload: { file, clientGeneratedId, chatId, createdAt, userId },
  }),

  uploadChatImageSuccess: (payload) => ({
    type: chatTypes.UPLOAD_CHAT_IMAGE_SUCCESS,
    payload: { ...payload },
  }),

  uploadChatImageFailure: (error) => ({
    type: chatTypes.UPLOAD_CHAT_IMAGE_FAILURE,
    payload: { error },
  }),
};

export const chatSelectors = {
  getMessageList: (state) => state.chat.messageList,
  isMessageListLoading: (state) => state.chat.messageListLoading,
  hasMoreMessages: (state) => state.chat.hasMore,
  getChatList: (state) => state.chat.chatList,
  isChatListLoading: (state) => state.chat.chatListLoading,
  getSupportChat: (state) => state.chat.supportChat,
  getSupportChatLoading: (state) => state.chat.supportChatLoading,
  isConnectionInstalled: (state) => state.chat.isConnected,
  getCurrentChatId: (state) => state.chat.currentChatId,
  getUploadImage: (state) => state.chat.uploadImage,
  getUploadImageLoading: (state) => state.chat.uploadImageLoading,
  getUploadedImage: (state) => state.chat.uploadedImage,
  getUploadImages: (state) => state.chat.uploadImages,
};
