const MODULE_PREFIX = 'SIGN_UP/';

export const types = {
  OPEN_SIGN_UP_POPUP: MODULE_PREFIX + 'OPEN_SIGN_UP_POPUP',
  CLOSE_SIGN_UP_POPUP: MODULE_PREFIX + 'CLOSE_SIGN_UP_POPUP',

  OPEN_CONFIRM_PHONE_POPUP: MODULE_PREFIX + 'OPEN_CONFIRM_PHONE_POPUP',
  CLOSE_CONFIRM_PHONE_POPUP: MODULE_PREFIX + 'CLOSE_CONFIRM_PHONE_POPUP',

  SIGN_UP_REQUEST: MODULE_PREFIX + 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: MODULE_PREFIX + 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: MODULE_PREFIX + 'SIGN_UP_FAILURE',

  CONFIRM_PHONE_REQUEST: MODULE_PREFIX + 'CONFIRM_PHONE_REQUEST',
  CONFIRM_PHONE_SUCCESS: MODULE_PREFIX + 'CONFIRM_PHONE_SUCCESS',
  CONFIRM_PHONE_FAILURE: MODULE_PREFIX + 'CONFIRM_PHONE_FAILURE',

  RESEND_CODE_REQUEST: MODULE_PREFIX + 'RESEND_CODE_REQUEST',
  RESEND_CODE_SUCCESS: MODULE_PREFIX + 'RESEND_CODE_SUCCESS',
  RESEND_CODE_FAILURE: MODULE_PREFIX + 'RESEND_CODE_FAILURE',

  SET_SOCIAL_SIGN_UP_DATA: MODULE_PREFIX + 'SET_SOCIAL_SIGN_UP_DATA',
  CLEAR_SOCIAL_SIGN_UP_DATA: MODULE_PREFIX + 'CLEAR_SOCIAL_SIGN_UP_DATA',

  OPEN_TERMS_CONDITIONS_POPUP: MODULE_PREFIX + 'OPEN_TERMS_CONDITIONS_POPUP',
  CLOSE_TERMS_CONDITIONS_POPUP: MODULE_PREFIX + 'CLOSE_TERMS_CONDITIONS_POPUP',

  GET_TERMS_CONDITIONS_REQUEST: MODULE_PREFIX + 'GET_TERMS_CONDITIONS_REQUEST',
  GET_TERMS_CONDITIONS_SUCCESS: MODULE_PREFIX + 'GET_TERMS_CONDITIONS_SUCCESS',
  GET_TERMS_CONDITIONS_FAILURE: MODULE_PREFIX + 'GET_TERMS_CONDITIONS_FAILURE',

  CONFIRM_TERMS_CONDITIONS_REQUEST:
    MODULE_PREFIX + 'CONFIRM_TERMS_CONDITIONS_REQUEST',
  CONFIRM_TERMS_CONDITIONS_SUCCESS:
    MODULE_PREFIX + 'CONFIRM_TERMS_CONDITIONS_SUCCESS',
  CONFIRM_TERMS_CONDITIONS_FAILURE:
    MODULE_PREFIX + 'CONFIRM_TERMS_CONDITIONS_FAILURE',

  CLEAR_SIGN_UP_DATA: MODULE_PREFIX + 'CLEAR_SIGN_UP_DATA',
};

const initialState = {
  popupOpened: false,
  confirmPhonePopupOpened: false,

  termsAndConditions: null,
  termsAndConditionsLoading: false,

  confirmTermsAndConditions: null,
  confirmTermsAndConditionsLoading: false,

  userAuthenticated: null,
  signUpLoading: null,
  session: null,
  smsCode: null,
  phone: null,
  password: null,

  social: null,
  socialAccessToken: null,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case types.OPEN_SIGN_UP_POPUP:
      return {
        ...state,
        popupOpened: true,
      };

    case types.CLOSE_SIGN_UP_POPUP:
      return {
        ...state,
        popupOpened: false,
      };

    case types.OPEN_CONFIRM_PHONE_POPUP:
      return {
        ...state,
        popupOpened: false,
        confirmPhonePopupOpened: true,
      };

    case types.CLOSE_CONFIRM_PHONE_POPUP:
      return {
        ...state,
        confirmPhonePopupOpened: false,
      };

    case types.OPEN_TERMS_CONDITIONS_POPUP:
      return {
        ...state,
        confirmTermsConditionsPopupOpened: true,
      };

    case types.CLOSE_TERMS_CONDITIONS_POPUP:
      return {
        ...state,
        confirmTermsConditionsPopupOpened: false,
      };

    case types.SIGN_UP_REQUEST:
      return { ...state, signUpLoading: true };

    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        session: payload.session,
        smsCode: payload.sms_code,
        phone: payload.phone,
        password: payload.password,
      };

    case types.SIGN_UP_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.RESEND_CODE_SUCCESS:
      return {
        ...state,
        smsCode: action.smsCode,
      };

    case types.LOGOUT:
      return {
        ...state,
        userAuthenticated: false,
        session: null,
      };

    case types.SET_SOCIAL_SIGN_UP_DATA:
      return {
        ...state,
        popupOpened: true,
        social: payload.social,
        socialAccessToken: payload.socialAccessToken,
      };

    case types.CLEAR_SOCIAL_SIGN_UP_DATA:
      return {
        ...state,
        social: null,
        socialAccessToken: null,
      };

    case types.CLEAR_SIGN_UP_DATA:
      return {
        ...initialState,
      };

    case types.GET_TERMS_CONDITIONS_REQUEST:
      return {
        ...state,
        termsAndConditionsLoading: true,
      };

    case types.GET_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsAndConditions: payload,
        termsAndConditionsLoading: false,
      };

    case types.GET_TERMS_CONDITIONS_FAILURE:
      return {
        ...state,
        error: payload.error,
        termsAndConditionsLoading: false,
      };

    case types.CONFIRM_TERMS_CONDITIONS_REQUEST:
      return {
        ...state,
        confirmTermsAndConditions: true,
        confirmTermsAndConditionsLoading: true,
      };

    case types.CONFIRM_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        confirmTermsAndConditionsLoading: false,
      };

    case types.CONFIRM_TERMS_CONDITIONS_FAILURE:
      return {
        ...state,
        error: payload.error,
        confirmTermsAndConditionsLoading: false,
      };

    default:
      return state;
  }
};

export const actions = {
  openSignUpPopup: () => ({
    type: types.OPEN_SIGN_UP_POPUP,
  }),

  closeSignUpPopup: () => ({
    type: types.CLOSE_SIGN_UP_POPUP,
  }),

  openConfirmPhonePopup: () => ({
    type: types.OPEN_CONFIRM_PHONE_POPUP,
  }),

  closeConfirmPhonePopup: () => ({
    type: types.CLOSE_CONFIRM_PHONE_POPUP,
  }),

  openConfirmTermsConditionsPopup: () => ({
    type: types.OPEN_TERMS_CONDITIONS_POPUP,
  }),

  closeConfirmTermsConditionsPopup: () => ({
    type: types.CLOSE_TERMS_CONDITIONS_POPUP,
  }),

  signUp: (values, resolve, reject) => ({
    type: types.SIGN_UP_REQUEST,
    payload: { values, resolve, reject },
  }),

  confirmPhoneCode: (values, resolve, reject) => ({
    type: types.CONFIRM_PHONE_REQUEST,
    payload: { values, resolve, reject },
  }),

  resendCode: () => ({
    type: types.RESEND_CODE_REQUEST,
  }),

  changeSignUpMode: (social, socialAccessToken) => ({
    type: types.SET_SOCIAL_SIGN_UP_DATA,
    payload: {
      social,
      socialAccessToken,
    },
  }),

  getTermsAndConditions: (urls) => ({
    type: types.GET_TERMS_CONDITIONS_REQUEST,
    payload: urls,
  }),

  confirmTermsAndConditions: () => ({
    type: types.CONFIRM_TERMS_CONDITIONS_REQUEST,
  }),

  clearSocialData: () => ({
    type: types.CLEAR_SOCIAL_SIGN_UP_DATA,
  }),

  clearSignUpData: () => ({
    type: types.CLEAR_SIGN_UP_DATA,
  }),
};

export const selectors = {
  isSignUpPopupOpened: (state) => state.signup.popupOpened,

  getSmsCode: (state) => state.signup.smsCode,

  getSession: (state) => state.signup.session,

  getPhone: (state) => state.signup.phone,

  getPassword: (state) => state.signup.password,

  getSocial: (state) => state.signup.social,

  getSocialAccessToken: (state) => state.signup.socialAccessToken,

  isConfirmPhonePopupOpened: (state) => state.signup.confirmPhonePopupOpened,

  getTermsAndConditions: (state) => state.signup.termsAndConditions,

  getTermsAndConditionsLoading: (state) =>
    state.signup.termsAndConditionsLoading,
};
