import React, { Component } from 'react';
import classNames from 'classnames';

import DefaultTextInput from './DefaultTextInput';
import styles from './SearchTextInput.module.scss';
import { withReduxFormProps } from '../../../containers/hoc';

class LabeledTextInput extends Component {
  clearField = () => {
    this.props.onChange('');
  };

  render() {
    const { onChange, value, error } = this.props;

    return (
      <label className={styles.label}>
        <DefaultTextInput
          {...this.props}
          onChange={(e) => onChange(e.target.value)}
          className={classNames(styles.input, { [styles.inputError]: error })}
        />
        <button
          className={classNames(styles.btnClear, {
            [styles.btnClearShow]: value !== '',
          })}
          type={'button'}
          onClick={this.clearField}
        />
      </label>
    );
  }
}

export default withReduxFormProps(LabeledTextInput);
