import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { createOrderActions } from '../../../reducers/createOrder';
import { PinkButton } from '../../../components/buttons';
import { Lari } from '../../../components/ui';

import styles from './ConfirmTaskDelete.module.scss';

class ConfirmTaskDeleteFromOrder extends Component {
  render() {
    const { task, deleteTask, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('deleteTheTask')}</div>
        <div className={styles.text}>
          <p>{t('areYouSureYouWantToDeleteTheTask')}</p>
          <p>
            {t('You will receive back') + ' ' + task.price}
            <Lari />
          </p>
        </div>
        <div className={styles.btn}>
          <PinkButton
            onClick={() => {
              deleteTask(task.id);
              onClose();
              ReactGA.event({
                category: 'event',
                action: 'click',
                label: 'gauqmeba',
              });
            }}
          >
            {t('delete')}
          </PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, { deleteTask: createOrderActions.deleteTaskInOrder }),
)(ConfirmTaskDeleteFromOrder);
