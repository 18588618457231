import React, { useCallback, useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { userActions, userSelectors } from 'reducers/user';
import { RootReduxState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dialogActions } from 'containers/dialog/store';

import MobileMenu from 'assets/images/icons/MobileMenu';
import LanguageDropdown from '../../components/language/LanguageDropDown';
import inbox from '../../assets/images/svg/inbox.svg';
import alarm from '../../assets/images/svg/alarm.svg';

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    right: '16px',
    top: '22px',
  },
  bmBurgerBars: {
    background: '',
  },
  bmBurgerBarsHover: {
    background: '',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
  },
  bmMenu: {
    background: '#FFFF',
    padding: '9px 15px',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;
const StyledHr = styled.hr`
  background-color: #f1f3f5;
  border-top: 1px #f1f3f5 solid;
  width: 100%;
  margin: 9px 0;
`;
const StyledItem = styled.div`
  margin: 20px 0;
  & > a {
    font-size: 14px;
    color: #787d88;
    text-decoration: none;
  }
`;
const StyledProfy = styled.p`
  color: #343d47;
  font-size: 13px;
  margin: 0;
`;
const StyledDes = styled.p`
  color: #adb1b5;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 0;
`;
const StyledIconBox = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 20px;
  background-color: #66c3ad;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  & img {
    margin: 0 11px;
  }
  &.bg {
    margin-bottom: 15px;
  }
  & > .img-box {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
`;

const StyledPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
  margin-right: 10px;
`;
const StyledUserText = styled.div`
  color: #343d47;
  font-size: 13px;
  padding: 15px 0 0 0;
  border-top: 1px #f1f3f5 solid;
  &.green {
    color: #66c3ad;
  }
  &.red {
    color: #db0a5b;
  }
  &:first-child {
    margin-top: 15px;
  }
`;
const StyledUserBox = styled.div`
  background-color: #fafafc;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 15px;
  & > a {
    margin: 0;
    padding: 0;
  }
`;
const Container = styled.div`
  z-index: 1010;
  @media (min-width: 911px) {
    display: none !important;
  },
`;
export const BurgerMenu = (prop) => {
  const { t } = useTranslation();

  const menu_nav = [
    {
      title: t('aboutUs'),
      url: '/about_us',
    },
    {
      title: t('blog'),
      url: '/blog',
    },
    {
      title: t('contact'),
      url: 'https://www.facebook.com/Profy505/?ref=br_rs',
    },
    {
      title: 'FAQ',
      url: 'https://intercom.help/profy505/en/',
    },
    {
      title: t('info'),
      url: 'https://intercom.help/profy505/en/articles/2633440-%E1%83%A0%E1%83%9D%E1%83%92%E1%83%9D%E1%83%A0-%E1%83%92%E1%83%90%E1%83%95%E1%83%90%E1%83%A4%E1%83%9D%E1%83%A0%E1%83%9B%E1%83%9D-%E1%83%A8%E1%83%94%E1%83%99%E1%83%95%E1%83%94%E1%83%97%E1%83%90',
    },
    {
      title: t('terms_cond'),
      url: '/documents',
    },
    {
      title: t('privacyPolicy'),
      url: '/privacy',
    },
  ];

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { userLoggedIn, profile, profileLoading, deactivated } = useSelector(
    (state: RootReduxState) => ({
      userLoggedIn: userSelectors.isUserLoggedIn(state),
      deactivated: userSelectors.isUserDeactivated(state),
      profile: userSelectors.selectUserProfile(state),
      profileLoading: userSelectors.selectUserProfileLoading(state),
    }),
  );

  const closeShowLinks = useCallback(() => {
    if (userLoggedIn) {
      setShow(false);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(userActions.getUserProfile());
    }
  }, [dispatch, userLoggedIn]);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Menu
        right
        width={'280px'}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
        customBurgerIcon={<MobileMenu />}
        styles={styles}
      >
        {process.env.REACT_APP_ENABLE_SELECT_LANG === 'true' && (
          <div className={styles.selectLang}>
            <LanguageDropdown instanceId={'header'} />
            {/* <SelectLang /> */}
          </div>
        )}
        <StyledHr />
        <StyledContainer>
          <StyledFlex>
            <a
              target={'_blank'}
              href="https://play.google.com/store/apps/details?id=com.profy505.customer&hl=ka&gl=US"
              rel="noreferrer"
            >
              <StyledIconBox>
                <svg
                  className="Headerstyled__ProfyIconGreen-sc-10sb4q0-4 gcYVhu"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 79.68 79.11"
                  style={{ height: '32px' }}
                >
                  <defs></defs>
                  <title>detail pink</title>
                  <path
                    // className="cls-1"
                    fill="white"
                    d="M30.18 24H48a.5.5 0 0 1 .43.25l8.91 15.5a.46.46 0 0 1 0 .48l-9 15.48A.49.49 0 0 1 48 56H30.12a.5.5 0 0 1-.43-.24l-8.91-15.5a.48.48 0 0 1 0-.49l9-15.47a.51.51 0 0 1 .34-.3m0-9.36a9.85 9.85 0 0 0-8.52 4.91L12.6 35a9.85 9.85 0 0 0 0 9.85l8.92 15.49a9.84 9.84 0 0 0 8.6 4.95H48a9.85 9.85 0 0 0 8.52-4.91l9-15.47a9.85 9.85 0 0 0 0-9.85L56.6 19.59a9.85 9.85 0 0 0-8.52-4.94h-18z"
                  ></path>
                  <path
                    // className="cls-1"
                    fill="white"
                    d="M41.64 33.12h-5.15a2.41 2.41 0 0 0-2.1 1.21l-2.58 4.47a2.43 2.43 0 0 0 0 2.42l2.58 4.47a2.41 2.41 0 0 0 2.1 1.21h5.15a2.44 2.44 0 0 0 2.11-1.21l2.57-4.47a2.38 2.38 0 0 0 0-2.42l-2.57-4.47a2.44 2.44 0 0 0-2.11-1.21"
                  ></path>
                </svg>
              </StyledIconBox>
            </a>
            <div>
              <StyledProfy>Profy</StyledProfy>
              <StyledDes>{t('downloadApp')}</StyledDes>
            </div>
          </StyledFlex>

          <StyledHr />
          {menu_nav.map((item) => {
            return (
              <StyledItem key={item.url}>
                <a href={item.url} onClick={closeSideBar}>
                  {item.title}
                </a>
              </StyledItem>
            );
          })}
        </StyledContainer>
        {!prop.userLoggedIn && (
          <StyledUserText
            className="green"
            onClick={() => {
              dispatch(userActions.openSignInPopup());
              closeSideBar();
            }}
          >
            {t('signIn')}
          </StyledUserText>
        )}
        {prop.userLoggedIn && (
          <StyledUserBox>
            <StyledFlex className="bg">
              <StyledPhoto
                src={
                  profile.avatar
                    ? `${profile.avatar.url}`
                    : 'https://pixsector.com/cache/50fcb576/av0cc3f7b41cb8510e35c.png'
                }
              />
              <StyledProfy>
                {(profile.first_name, profile.first_name)}
              </StyledProfy>
              <div className="img-box">
                <Link to={'/orders'}>
                  <img src={inbox} onClick={closeSideBar} />
                </Link>
                <img src={alarm} />
              </div>
            </StyledFlex>
            <Link to={'/settings/profile'}>
              <StyledUserText onClick={closeSideBar}>
                {t('myProfile')}
              </StyledUserText>
            </Link>
            <a onClick={() => dispatch(dialogActions.openInviteFriendDialog())}>
              <StyledUserText onClick={closeSideBar}>
                {' '}
                {t('inviteFriends')}
              </StyledUserText>
            </a>
            <Link to={'/settings/support'} onClick={closeShowLinks}>
              <StyledUserText onClick={closeSideBar}>
                {' '}
                {t('contactSupport')}
              </StyledUserText>
            </Link>
            <a>
              <StyledUserText
                className="red"
                onClick={() => {
                  dispatch(userActions.logout());
                  closeShowLinks();
                  closeSideBar();
                }}
              >
                {' '}
                {t('logout')}
              </StyledUserText>
            </a>
          </StyledUserBox>
        )}
      </Menu>
    </Container>
  );
};
