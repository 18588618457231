import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { Success, Failure } from '../../components/ui';

class PaymentResult extends Component {
  getOrderId() {
    const params = qs.parse(this.props.location.search.substring(1));

    if (params && params.id) {
      return params.id;
    }
    return null;
  }

  isSuccess() {
    return this.props.match.path.includes('success');
  }

  render() {
    return this.isSuccess() ? <Success /> : <Failure />;
  }
}

export default withRouter(PaymentResult);
