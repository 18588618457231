import { push, replace } from 'connected-react-router';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { orderActions, orderSelectors, orderTypes } from '../reducers/order';
import * as service from '../services/ordersListService';
import {
  cancelOrder,
  confirmOrderComplete,
  createComplaint,
  createReview,
  deleteOrder,
  loadOrder,
  loadOrderTaskerLocation,
  loadOrderTasks,
  loadReasons,
  getTipsOffer,
  giveTips,
} from '../services/ordersListService';
import { REVIEW, ORDERS } from '../containers/routing/constants/routes';
import { ordersActions, ordersSelectors, ordersTypes } from '../reducers/ordersList';
import { getCancelOrderRefound } from '../services/ordersListService';
import { dialogActions, dialogTypes } from '../containers/dialog/store';

export function* watchOrderLoad() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.LOAD_ORDER_REQUEST);

      const { model } = yield call(loadOrder, payload.id);
      yield put({
        type: orderTypes.LOAD_ORDER_SUCCESS,
        payload: {
          order: model,
        },
      });
    } catch (error) {
      yield put({ type: orderTypes.LOAD_ORDER_FAILURE });
    }
  }
}

export function* watchOrderTaskerLoactionLoad() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.LOAD_TASKER_LOCATION_REQUEST);
      const { route } = yield call(loadOrderTaskerLocation, payload.id);

      yield put({
        type: orderTypes.LOAD_TASKER_LOCATION_SUCCESS,
        payload: {
          route: route,
        },
      });
    } catch (error) {
      yield put({ type: orderTypes.LOAD_TASKER_LOCATION_FAILURE });
    }
  }
}

export function* watchReasonsLoad() {
  while (true) {
    try {
      yield take(orderTypes.LOAD_COMPLAINT_REASONS_REQUEST);

      const data = yield call(loadReasons);
      yield put({
        type: orderTypes.LOAD_COMPLAINT_REASONS_SUCCESS,
        payload: {
          reasons: data,
        },
      });
    } catch (error) {
      yield put({ type: orderTypes.LOAD_COMPLAINT_REASONS_FAILURE });
    }
  }
}

export function* watchCreateComplaint() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.CREATE_COMPLAINT_REQUEST);

      yield call(createComplaint, payload.data);
      yield put({
        type: orderTypes.CREATE_COMPLAINT_SUCCESS,
      });
      yield put(orderActions.loadOrder(payload.data.order_id));
    } catch (error) {
      yield put({ type: orderTypes.CREATE_COMPLAINT_FAILURE });
    }
  }
}

export function* watchReviewCreate() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.CREATE_REVIEW_REQUEST);

      yield call(createReview, payload.data);

      yield put({ type: orderTypes.CREATE_REVIEW_SUCCESS });
      // yield put(orderActions.loadOrder(payload.data.order_id));
      yield put(ordersActions.loadCompletedOrders());
    } catch (error) {
      yield put({ type: orderTypes.CREATE_REVIEW_FAILURE });
    }
  }
}

export function* watchLoadOfferedTips() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.GET_OFFERED_TIPS_REQUEST);

      const data = yield call(getTipsOffer, payload.orderId);

      yield put({
        type: orderTypes.GET_OFFERED_TIPS_SUCCESS,
        payload: data.tip_offers,
      });
    } catch (error) {
      yield put({ type: orderTypes.GET_OFFERED_TIPS_FAILURE });
    }
  }
}

export function* watchTipsGive() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.GIVE_TIPS_REQUEST);

      yield call(giveTips, payload.orderId, payload.data);

      yield put({ type: orderTypes.GIVE_TIPS_SUCCESS });
    } catch (error) {
      yield put({ type: orderTypes.GIVE_TIPS_FAILURE });
    }
  }
}

/* *** get cancel order refound *** */
export function* watchGetCancelOrderRefound() {
  while (true) {
    try {
      const { payload } = yield take(
        orderTypes.GET_CANCEL_ORDER_REFOUND_REQUEST,
      );

      yield put(dialogActions.openCancelOrderDialog(payload.order));

      const { amount } = yield call(getCancelOrderRefound, payload.order.id);

      yield put(orderActions.getCancelOrderRefoundSuccess(amount));
    } catch (error) {
      console.error('watchGetCancelOrderRefound error = ', error);
      yield put(orderActions.getCancelOrderRefoundFailure());
    }
  }
}
/* *** *** */

export function* watchCancelOrder() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.CANCEL_ORDER_REQUEST);
      const refoundSum = yield select(orderSelectors.getRefoundSum);

      yield call(cancelOrder, payload.order.id, { amount: refoundSum });

      yield put({ type: orderTypes.CANCEL_ORDER_SUCCESS });
      yield put(orderActions.loadOrder(payload.order.id));
    } catch (error) {
      console.error('watchCancelOrder error = ', error);
      if (error && error.errors && error.errors['*']) {
        if (
          error.errors['*'][0] &&
          error.errors['*'][0].code === 'ORDER_CANCELLATION_AMOUNT_CHANGED'
        ) {
          yield put(
            dialogActions.openCancleOrderError(error.errors['*'][0].message),
          );
        }
      }
      yield put({ type: orderTypes.CANCEL_ORDER_FAILURE, payload: { error } });
    }
  }
}

export function* watchDeleteOrder() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.DELETE_ORDER_REQUEST);

      yield call(deleteOrder, payload.order.id);
      yield put({ type: orderTypes.DELETE_ORDER_SUCCESS });
      yield put(push(ORDERS));
    } catch (error) {
      yield put({ type: orderTypes.DELETE_ORDER_FAILURE, payload: { error } });
    }
  }
}

export function* watchTasksLoading() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.LOAD_ORDER_TASKS_REQUEST);

      const tasks = yield call(loadOrderTasks, payload.id);

      yield put({
        type: orderTypes.LOAD_ORDER_TASKS_SUCCESS,
        payload: { tasks },
      });
    } catch (error) {
      yield put({ type: orderTypes.LOAD_ORDER_TASKS_FAILURE, error });
    }
  }
}

export function* watchCreateOrderLoading() {
  while (true) {
    try {
      yield take(orderTypes.CONFIRM_ORDER_COMPLETE_REQUEST);

      const order = yield select(orderSelectors.getOrder);
      const newOrder = yield call(confirmOrderComplete, order);

      yield put({
        type: orderTypes.CONFIRM_ORDER_COMPLETE_SUCCESS,
        payload: { order: newOrder },
      });
      yield put(replace(`${ORDERS}/${order.id}${REVIEW}`));
    } catch (error) {
      console.error('watchCreateOrderLoading error = ', error);
      yield put({ type: orderTypes.CONFIRM_ORDER_COMPLETE_FAILURE });
    }
  }
}

export function* watchUpdateOrderInBg() {
  while (true) {
    try {
      const { id } = yield take(orderTypes.UPDATE_ORDER_IN_BG_ORDER_REQUEST);
      const { model } = yield call(loadOrder, id);

      yield put({
        type: orderTypes.UPDATE_ORDER_IN_BG_ORDER_SUCCESS,
        order: model,
      });

      yield put({
        type: ordersTypes.UPDATE_ORDER_ON_LIST,
        payload: {
          order: model,
        },
      });
    } catch (error) {
      yield put({ type: orderTypes.UPDATE_ORDER_IN_BG_ORDER_FAILURE });
    }
  }
}

export function* watchUpdateTaskerLocationInBg() {
  while (true) {
    try {
      const { payload } = yield take(
        orderTypes.UPDATE_TASKER_LOCATION_IN_BG_REQUEST,
      );

      const { route } = yield call(loadOrderTaskerLocation, payload.id);

      yield put({
        type: orderTypes.UPDATE_TASKER_LOCATION_IN_BG_SUCCESS,
        payload: { route },
      });
    } catch (error) {
      yield put({ type: orderTypes.UPDATE_TASKER_LOCATION_IN_BG_FAILURE });
    }
  }
}

export function* watchTaskCheckReject() {
  while (true) {
    try {
      const { payload } = yield take(
        orderTypes.REJECT_TASK_CHECK_IN_ORDER_REQUEST,
      );

      const data = yield call(service.rejectTaskCheck, payload.taskId, {
        quantity: payload.taskQuantity,
      });

      yield put({
        type: orderTypes.REJECT_TASK_CHECK_IN_ORDER_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      yield put({ type: orderTypes.REJECT_TASK_CHECK_IN_ORDER_FAILURE, error });
    }
  }
}

export function* watchTaskReject() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.REJECT_TASK_IN_ORDER_REQUEST);

      yield call(service.rejectTask, payload.taskId, {
        quantity: payload.taskQuantity,
      });

      yield put({ type: orderTypes.REJECT_TASK_IN_ORDER_SUCCESS });

      yield put(orderActions.loadOrderTasks(payload.id));
    } catch (error) {
      yield put({ type: orderTypes.REJECT_TASK_IN_ORDER_FAILURE, error });
    }
  }
}

export function* watchTaskConfirm() {
  while (true) {
    try {
      const { payload } = yield take(orderTypes.CONFIRM_TASK_IN_ORDER_REQUEST);

      yield call(service.confirmTask, payload.task.id);

      yield put({ type: orderTypes.CONFIRM_TASK_IN_ORDER_SUCCESS });

      yield put(orderActions.loadOrderTasks(payload.task.order_id));
    } catch (error) {
      yield put({ type: orderTypes.CONFIRM_TASK_IN_ORDER_FAILURE, error });
    }
  }
}

export function* watchConfirmAddedOrder(){
  while(true){
    try{
      const {payload} = yield take(orderTypes.CONFIRM_ADDED_ORDERS);
      yield call(service.confirmTask, payload.id)
      
      let order = yield select(orderSelectors.getOrder)


      const tasks = yield call(loadOrderTasks, order.id);
    
      yield put({
        type: orderTypes.LOAD_ORDER_TASKS_SUCCESS,
        payload: { tasks },
      });
    
      const { model } = yield call(loadOrder, order.id);
          yield put({
            type: orderTypes.LOAD_ORDER_SUCCESS,
            payload: {
              order: model,
            },
          });
      yield put({ type: dialogTypes.CLOSE_DIALOG });
    }catch(error){
      yield put({ type: orderTypes.CONFIRM_ADDED_ORDERS_FAILD, payload: error });
    }
  }
}

function* rejectOrder(payload) {
  yield call(service.rejectAddedTask, payload.payload.id)

  let order = yield select(orderSelectors.getOrder)


  const tasks = yield call(loadOrderTasks, order.id);

  yield put({
    type: orderTypes.LOAD_ORDER_TASKS_SUCCESS,
    payload: { tasks },
  });

  const { model } = yield call(loadOrder, order.id);
      yield put({
        type: orderTypes.LOAD_ORDER_SUCCESS,
        payload: {
          order: model,
        },
      });


  yield put({ type: dialogTypes.CLOSE_DIALOG });
}

export function* watchRejectAddedOrder() {
  yield takeEvery(orderTypes.REJECT_ADDED_ORDERS, rejectOrder)
}
