import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PinkBorderButton, PinkButton } from '../../../components/buttons';

import styles from './ConfirmCardDelete.module.scss';
import { paymentsActions, paymentsSelectors } from '../../../reducers/payments';
import { Loader } from '../../../components/ui';

class ConfirmCardDelete extends Component {
  render() {
    const { onDeleteCard, id, onClose, deleteCardLoading, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('deleteCard')}</div>

        <Loader loading={deleteCardLoading}>
          <div className={styles.text}>{t('deleteCardText')}</div>

          <div className={styles.btnWrapper}>
            <PinkBorderButton
              onClick={() => {
                onDeleteCard(id);
                onClose();
              }}
            >
              {t('delete')}
            </PinkBorderButton>

            <PinkButton onClick={() => onClose()}>{t('cancel')}</PinkButton>
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      deleteCardLoading: paymentsSelectors.getDeleteCardLoading(state),
    }),
    {
      onDeleteCard: paymentsActions.deleteCardRequest,
    },
  ),
)(ConfirmCardDelete);
