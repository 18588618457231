import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { PinkButton } from '../../../components/buttons';
import styles from './ConfirmTaskDelete.module.scss';
import { orderActions } from '../../../reducers/order';
import { Lari } from '../../../components/ui';
import LabeledTextInput from '../../../components/inputs/text/LabeledTextInput';

class ConfirmTaskReject extends Component {
  state = {
    quantity: this.props.task.quantity,
  };

  changeQuantity = (e) => {
    const { task } = this.props;

    let value = e.target.value;

    if (value > task.quantity) value = task.quantity;

    if (value < 1) value = 1;

    this.setState({
      quantity: value,
    });
  };

  render() {
    const { task, rejectTask, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('rejectTheTask')}</div>
        <div className={styles.text}>
          <p>{t('areYouSureYouWantToRejectTheTask') + '?'}</p>

          <div className={styles.quantity}>
            <LabeledTextInput
              value={this.state.quantity}
              name={'quantity'}
              label={t('Quantity to reject, max') + '(' + task.quantity + ')'}
              length={true}
              max={task.quantity}
              min={1}
              type={'number'}
              onChange={this.changeQuantity}
              noMargin={true}
            />
          </div>

          <p>
            {t('You will receive back') +
              ' ' +
              (task.price / task.quantity) * this.state.quantity}
            <Lari />
          </p>
        </div>
        <div className={styles.btn}>
          <PinkButton
            onClick={() => {
              rejectTask(task.id, this.state.quantity, task.order_id);
              onClose();
              ReactGA.event({
                category: 'event',
                action: 'click',
                label: 'gauqmeba',
              });
            }}
          >
            {t('reject')}
          </PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    rejectTask: orderActions.rejectTaskInOrder,
  }),
)(ConfirmTaskReject);
