import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { createTaskActions, createTaskSelectors } from './store';
import { LabeledTextArea } from '../../components/inputs/text';

class TaskDescription extends Component {
  render() {
    const { comment, t } = this.props;
    return (
      <LabeledTextArea
        value={comment}
        autoHeight={true}
        label={t('provideASummary')}
        length={true}
        onChange={this.props.changeComment}
        maxlength={1000}
        noMargin={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  comment: createTaskSelectors.getComment(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, { changeComment: createTaskActions.changeComment }),
)(TaskDescription);
