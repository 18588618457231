import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { AddressesSidebarContainer } from '../../../components/common/addresses/index';
import AddressesCreateEdit from './AddressesCreateEdit';
import AddressesView from './AddressesView';

class CreateOrderMapSidebar extends Component {
  render() {
    const { t } = this.props;

    return (
      <AddressesSidebarContainer>
        <Helmet>
          <title>{t('addressForOrder')}</title>
        </Helmet>

        <Switch>
          <Route exact path="/map" component={AddressesView} />
          <Route exact path="/map/create" component={AddressesCreateEdit} />
        </Switch>
      </AddressesSidebarContainer>
    );
  }
}

export default compose(withTranslation(), withRouter)(CreateOrderMapSidebar);
