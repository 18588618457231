import React from 'react';
import styles from './AddItemRound.module.scss';
import Svg from './Svg';

type AddItemRoundPropsType = {
  onClick: () => void;
};
const AddItemRound = ({ onClick }: AddItemRoundPropsType) => {
  return (
    <div className={` ${styles.container} flex flex-center`} onClick={onClick}>
      <Svg src={'plus'} />
    </div>
  );
};

export default AddItemRound;
