import {
  formatMomentIntervalForCurrentTime,
  formatMomentIntervalForTime,
  formatMomentIntervalFromStartToEnd,
  getLocalDateTime,
} from './time';

export const getWorkingTime = (start, end?: number) => {
  let seconds, minutes, hours;

  if (end) {
    seconds = Math.round(formatMomentIntervalFromStartToEnd(start, end) / 1000);
  } else {
    seconds = Math.round(formatMomentIntervalForTime(start) / 1000);
  }

  minutes = Math.round(seconds / 60);

  if (minutes > 59) {
    hours = Math.trunc(minutes / 60);
    minutes = Math.trunc(minutes - hours * 60);
  } else {
    return `00:${minutes < 10 ? '0' + minutes : minutes}`;
  }

  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }`;
};

export const getWarrantyTime = (
  warrantyEnd,
  orderCompletedAt,
  t = undefined,
) => {
  let seconds, minutes, hours;

  seconds = Math.round(
    formatMomentIntervalForCurrentTime(warrantyEnd, orderCompletedAt) / 1000,
  );

  if (t) {
    if (seconds < 60)
      return `${t('warrantyIsLeftAt')} ${getLocalDateTime(warrantyEnd)}`;

    minutes = Math.round(seconds / 60);

    if (minutes > 59) {
      hours = Math.trunc(minutes / 60);
      minutes = Math.trunc(minutes - hours * 60);
    } else {
      return `${t('warrantyIsLeftAfter')}: 00:${
        minutes < 10 ? '0' + minutes : minutes
      }`;
    }

    return `${t('warrantyIsLeftAfter')}: ${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    }`;
  } else {
    seconds = Math.round(
      formatMomentIntervalForCurrentTime(warrantyEnd, orderCompletedAt) / 1000,
    );

    if (seconds) return `${getLocalDateTime(warrantyEnd)}`;

    return null;
  }
};
