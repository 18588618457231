import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  AddOrEditAddressLazy,
  AddressesLazy,
  CardListLazy,
  ProfileLazy,
} from './pages';
import styles from './ProfileContainerRoutes.module.scss';
import { chatActions, chatSelectors } from '../../reducers/chat';
import { useDispatch, useSelector } from 'react-redux';
import { RootReduxState } from 'store';
import TwoPanel from 'components/ui/TwoPanel';
import TabNavigationWithTitle from 'components/ui/TabNavigationWithTitle';
import profileLeftImage from 'assets/images/images/profileleftSideNew.png';
import profileAddressLeftImage from 'assets/images/images/address.png';
const data = [
  { icon: 'user-settings-new' },
  { icon: 'payment-settings-new' },
  { icon: 'location-settings-new' },
];

const ProfileContainerRoutes = () => {
  const dispatch = useDispatch();

  const { type } = useParams<{ type: string }>();

  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const { supportChat } = useSelector((state: RootReduxState) => ({
    supportChat: chatSelectors.getSupportChat(state),
  }));

  useEffect(() => {
    if (type === 'profile') {
      setActiveTab(0);
    } else if (type === 'payment') {
      push('./payment');
      setActiveTab(1);
    } else if (type === 'addresses') {
      setActiveTab(2);
    }
  }, [push, type]);

  useEffect(() => {
    if (!supportChat) {
      dispatch(chatActions.getSupportChat());
    }
  }, [dispatch, supportChat]);

  const onTabClick = useCallback(
    (index: number | string) => {
      if (index === 0) {
        push('./profile');
      } else if (index === 1) {
        push('./payment');
      } else if (index === 2) {
        push('./addresses');
      }
      setActiveTab(+index);
    },
    [push],
  );

  const renderMainContent = useCallback(() => {
    return (
      <div className={`contents ${styles.wrapper}`}>
        <TabNavigationWithTitle
          onTabClick={onTabClick}
          tabsArr={data}
          activeTab={activeTab}
        />
        <Switch>
          <Route exact path="/settings/profile" component={ProfileLazy} />
          <Route exact path="/settings/Payment" component={CardListLazy} />
          <Route exact path="/settings/addresses" component={AddressesLazy} />
          <Route
            exact
            path={'/settings/addresses/create/:id?'}
            component={AddOrEditAddressLazy}
          />
          <Redirect from="/settings" to="/settings/profile" />
        </Switch>
      </div>
    );
  }, [activeTab, onTabClick]);


  const profileLeftSide = useCallback(() => {
    if (activeTab == 2) {
      return (
        <>
          <div>
            <img style={{ width: '100%' }} src={profileAddressLeftImage} alt="left_image" />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div>
            <img style={{ width: '100%' }} src={profileLeftImage} alt="left_image" />
          </div>
        </>
      )
    }

  }, [activeTab])
  return useMemo(
    () => <TwoPanel leftSide={profileLeftSide()} rightSide={renderMainContent()} />,
    [renderMainContent],
  );
};

export default ProfileContainerRoutes;
