import React from 'react';

import styles from './ServiceView.module.scss';

const ServiceView = ({ service }) => (
  <div>
    <h2 className={styles.title}>{service.name}</h2>
    <p className={styles.description}>{service.description}</p>
  </div>
);

ServiceView.propTypes = {};

export default ServiceView;
