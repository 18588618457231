import { replace, push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from '../services/ordersListService';
import {
  getPathForDeactivated,
  getPathForOrderInfo,
  getPathForOrderTaskers,
} from '../containers/routing/constants/pathHelpers';
import {
  createOrderTypes,
  createOrderActions,
  createOrderSelectors,
} from '../reducers/createOrder';
import { dialogActions, dialogSelectors } from '../containers/dialog/store';
import { RootReduxState } from 'store';

function* onNewOrderCreatingStart() {
  const order = yield select(createOrderSelectors.getOrder);

  if (order.id) {
    yield put(createOrderActions.deleteEditingOrder());
  }
}

function* onEditingOrderLoad({ afterTaskDelete, paymentChange }) {
  try {
    const { model } = yield call(service.loadEditingAddress);

    console.log('%c onEditingOrderLoad model = ', 'color: blue', model);

    if (model) {
      const checkoutOrderId = +localStorage.getItem('onCheckoutOrder');
      if (checkoutOrderId === model.id && !model.seconds_to_paid) {
        localStorage.setItem('onCheckoutOrder', null);

        if (paymentChange) {
          const dialogState = yield select(dialogSelectors.getDialogState);

          if (!dialogState.dialogType) {
            yield put(dialogActions.openSubmitOrderErrorTimeout());
          }

          yield put(push(getPathForOrderTaskers()));
        }
      }

      yield put(createOrderActions.loadTasksForOrder(model.id));
      yield put({
        type: createOrderTypes.LOAD_EDITING_ORDER_SUCCESS,
        order: model,
      });
    } else {
      if (afterTaskDelete) {
        yield put(push('/'));
      } else {
        yield put(createOrderActions.createEmptyOrder());
      }
    }
  } catch (error) {
    const deactivated = error.data && error.data.reason === 'USER_DEACTIVATED';

    yield put({
      type: createOrderTypes.LOAD_EDITING_ORDER_FAILURE,
      error,
      deactivated,
    });

    if (deactivated) {
      yield put(replace(getPathForDeactivated()));
    }
  }
}

function* onTasksForOrderLoad({ orderId }) {
  try {
    const tasks = yield call(service.loadOrderTasks, orderId);

    yield put({ type: createOrderTypes.LOAD_TASKS_FOR_ORDER_SUCCESS, tasks });
  } catch (error) {
    yield put({ type: createOrderTypes.LOAD_TASKS_FOR_ORDER_FAILURE, error });
  }
}

function* onSelectCardForOrder({ cardId, newVersion }) {
  try {
    let orderId = null;
    if (newVersion) {
      orderId = yield select(
        (state: RootReduxState) =>
          state.createTaskFullFlow.currentOrder?.model?.id,
      );
    } else {
      orderId = (yield select(createOrderSelectors.getOrder)).id;
    }
    console.log('%c onSelectCardForOrder cardId = ', 'color: blue', cardId);

    const { model } = yield call(service.selectCard, orderId, {
      card_id: cardId,
    });

    console.log('%c onSelectCardForOrder model = ', 'color: blue', model);
    yield put({ type: createOrderTypes.SELECT_CARD_FOR_ORDER_SUCCESS, model });
  } catch (error) {
    console.error('%c onSelectCardForOrder error = ', 'color: blue', error);
    yield put({ type: createOrderTypes.SELECT_CARD_FOR_ORDER_FAILURE, error });
  }
}

function* onEmptyOrderCreate() {
  try {
    const { order } = yield call(service.createEmptyOrder);

    yield put(createOrderActions.createEmptyOrderSuccess(order));
    yield put(createOrderActions.loadTasksForOrder(order.id));
  } catch (error) {
    yield put(createOrderActions.createEmptyOrderFailure(error));
  }
}

function* onOrderDelete() {
  try {
    const orderId = yield select(createOrderSelectors.getOrderId);
    yield call(service.deleteOrder, orderId);

    yield put({ type: createOrderTypes.DELETE_EDITING_ORDER_SUCCESS });
    yield put(createOrderActions.clearCreateOrderState());
    yield put(createOrderActions.createEmptyOrder());
  } catch (error) {
    yield put({ type: createOrderTypes.DELETE_EDITING_ORDER_FAILURE, error });
  }
}

function* onTaskDelete({ taskId, tasksCount }) {
  try {
    yield call(service.deleteTask, taskId);

    if (tasksCount === 1) {
      window.location.href = '/';
    } else {
      yield put({
        type: createOrderTypes.DELETE_TASK_IN_ORDER_SUCCESS,
        taskId,
      });
      yield put(createOrderActions.loadEditingOrder(true));
    }
  } catch (error) {
    yield put({ type: createOrderTypes.DELETE_TASK_IN_ORDER_FAILURE, error });
  }
}

function* onOrderSubmit() {
  try {
    const { price_for_paid, id } = yield select(createOrderSelectors.getOrder);
    yield put(dialogActions.openSubmitOrderSuccess(id));
    yield call(service.submitOrder, { order_id: id, price: price_for_paid });
    yield put({ type: createOrderTypes.SUBMIT_ORDER_SUCCESS });
    yield put(push(getPathForOrderInfo(id)));
    yield put(createOrderActions.clearCreateOrderState());
  } catch (error) {
    if (error && error.errors && error.errors.card_id) {
      yield put(dialogActions.openSubmitOrderErrorPaymentMethodPopup());
    } else if (error.errors && error.errors['*'] && error.errors['*'][0]) {
      switch (error.errors['*'][0].code) {
        case 'ORDER_SUBMIT_PRICE_CHANGED':
          yield put(
            dialogActions.openSubmitOrderErrorPriceChanged(
              error.errors['*'][0].message,
            ),
          );
          yield put(createOrderActions.loadEditingOrder());
          yield put(push(getPathForOrderTaskers()));
          break;

        case 'ORDER_SUBMIT_TIMEOUT_EXPIRED':
          yield put(dialogActions.openSubmitOrderErrorTimeout());
          yield put(createOrderActions.loadEditingOrder());
          yield put(push(getPathForOrderTaskers()));
          break;

        case 'ORDER_SUBMIT_SYSTEM_TRANSACTION_FAILED':
          yield put(
            dialogActions.openSubmitOrderErrorSystemTransaction(
              error.errors['*'][0].message,
            ),
          );
          break;

        case 'ORDER_SUBMIT_CARD_IS_NOT_ACTIVE':
          yield put(
            dialogActions.openSubmitOrderErrorCardIsNotActive(
              error.errors['*'][0].message,
            ),
          );
          break;

        case 'ORDER_SUBMIT_TASKER_TRANSACTION_FAILED':
          yield put(
            dialogActions.openSubmitOrderErrorTaskerTransaction(
              error.errors['*'][0].message,
            ),
          );
          break;

        default:
          yield put(
            dialogActions.openSubmitOrderErrorPopup(
              error.errors['*'][0].message,
            ),
          );
          break;
      }
    } else {
      yield put(dialogActions.openSubmitOrderErrorPopup());
    }

    yield put({ type: createOrderTypes.SUBMIT_ORDER_FAILURE, error });
  }
}

function* onTaskerDetach({ orderId, path }) {
  try {
    yield call(service.detachTasker, { order_id: orderId });
    yield put({ type: createOrderTypes.DETACH_TASKER_SUCCESS });

    if (path) {
      yield put(replace(path));
    }
  } catch (error) {
    yield put({ type: createOrderTypes.DETACH_TASKER_FAILURE, error });
  }
}

function* onPromoCodeErrors(error) {
  if (error.code && error.message) {
    yield put({ type: createOrderTypes.ATTACH_PROMO_CODE_FAILURE, error });
  }
}

function* onAttachPromoCodeForOrder({ orderId, promoCode }) {
  try {
    const payload = yield call(service.attachPromoCode, {
      order_id: orderId,
      promocode: promoCode,
    });

    yield put(createOrderActions.loadEditingOrder());

    yield put({ type: createOrderTypes.ATTACH_PROMO_CODE_SUCCESS, payload });
  } catch (error) {
    if (error && error.errors && error.errors.promocode) {
      const errors = error.errors.promocode;
      yield errors.map(onPromoCodeErrors);
    } else {
      throw new Error(error);
    }
  }
}

function* onDetachPromoCodeForOrder({ orderId }) {
  try {
    const payload = yield call(service.detachPromoCode, orderId);

    yield put(createOrderActions.loadEditingOrder());

    yield put({ type: createOrderTypes.DETACH_PROMO_CODE_SUCCESS, payload });
  } catch (error) {
    // if (error && error.errors && error.errors.promocode) {
    // const errors = error.errors.promocode;
    // yield errors.map(onPromoCodeErrors);
    // }

    yield put({ type: createOrderTypes.DETACH_PROMO_CODE_FAILURE, error });
  }
}

export function* watchStartCreatingNewOrder() {
  yield takeLatest(
    createOrderTypes.START_CREATING_NEW_ORDER,
    onNewOrderCreatingStart,
  );
}

export function* watchEditingOrderLoad() {
  yield takeLatest(
    createOrderTypes.LOAD_EDITING_ORDER_REQUEST,
    onEditingOrderLoad,
  );
}

export function* watchTasksForOrderLoading() {
  yield takeLatest(
    createOrderTypes.LOAD_TASKS_FOR_ORDER_REQUEST,
    onTasksForOrderLoad,
  );
}

export function* watchSelectCardForOrder() {
  yield takeLatest(
    createOrderTypes.SELECT_CARD_FOR_ORDER_REQUEST,
    onSelectCardForOrder,
  );
}

export function* watchCreatingEmptyOrder() {
  yield takeLatest(
    createOrderTypes.CREATE_EMPTY_ORDER_REQUEST,
    onEmptyOrderCreate,
  );
}

export function* watchDeleteEditingOrder() {
  yield takeLatest(
    createOrderTypes.DELETE_EDITING_ORDER_REQUEST,
    onOrderDelete,
  );
}

export function* watchTaskDelete() {
  yield takeLatest(createOrderTypes.DELETE_TASK_IN_ORDER_REQUEST, onTaskDelete);
}

export function* watchOrderSubmit() {
  yield takeLatest(createOrderTypes.SUBMIT_ORDER_REQUEST, onOrderSubmit);
}

export function* watchTaskerDetach() {
  yield takeLatest(createOrderTypes.DETACH_TASKER_REQUEST, onTaskerDetach);
}

export function* watchAttachPromoCodeForOrder() {
  yield takeLatest(
    createOrderTypes.ATTACH_PROMO_CODE_REQUEST,
    onAttachPromoCodeForOrder,
  );
}

export function* watchDetachPromoCodeForOrder() {
  yield takeLatest(
    createOrderTypes.DETACH_PROMO_CODE_REQUEST,
    onDetachPromoCodeForOrder,
  );
}
