import { call, put, takeEvery, select, fork } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { replace } from 'connected-react-router';

import { types, selectors, actions } from '../reducers/signup';
import * as authService from '../services/authService';
import { parseServerErrorsToForm } from '../utils/parseServerErrorToForm';
import { saveToken, getGuestToken } from '../utils/authUtil';
import { userActions, userActionTypes, userSelectors } from '../reducers/user';
import { dialogActions } from '../containers/dialog/store';
import { getPathForDeactivated } from '../containers/routing/constants/pathHelpers';
import { putInitialAuthorizedActions } from './onAuthLoadSaga';

function GenderError() {
  this.name = 'GENDER_IS_EMPTY';
}

function* signupSagas({ payload: { values, resolve, reject } }) {
  try {
    const social = yield select(selectors.getSocial);
    const session = yield select(userSelectors.getSession);

    // const socialAccessToken = yield select(selectors.getSocialAccessToken);

    // const guestToken = yield call(getGuestToken);

    let data;

    // if (!values.hasOwnProperty('gender') || !Boolean(values.gender)) {
    //   throw new GenderError();
    // }

    if (social) {
      yield call(authService.addCusomterDetails, {
        ...values,
        session,
      });
    } else {
      // data = yield call(authService.signUp, {
      //   ...values,
      //   guest_session: guestToken,
      //   signup_mode: 'PASSWORD',
      // });
      yield call(authService.addCusomterDetails, {
        ...values,
        session,
      });
    }

    yield put({
      type: types.SIGN_UP_SUCCESS,
      payload: { ...data, phone: values.phone, password: values.password },
    });

    data = yield call(authService.agreementTermsAndConditions, { session });

    yield put(actions.closeSignUpPopup());

    yield call(saveToken, data.token);
    yield put(userActions.signInSuccess(data));

    if (data.status === 'DEACTIVATED') {
      yield put(replace(getPathForDeactivated()));
    } else {
      yield fork(putInitialAuthorizedActions);
    }
  } catch (error) {
    console.log('error = ', error);
    if (error.hasOwnProperty('name') && error.name === 'GENDER_IS_EMPTY') {
      const errors = {
        gender: [
          {
            code: 'UNKNOWN_ERROR',
            message: 'Gender is epmty',
          },
        ],
      };
      console.log('errors = ', errors);
      yield put({ type: types.SIGN_UP_FAILURE, errors });
      yield call(reject, new SubmissionError(parseServerErrorsToForm(errors)));
    } else {
      yield put({ type: types.SIGN_UP_FAILURE, error });
      yield call(
        reject,
        new SubmissionError(parseServerErrorsToForm(error.errors)),
      );
    }
  }
}

function* confirmPhoneCode({ payload: { values, resolve, reject } }) {
  try {
    const signinMode = yield select(userSelectors.getSignInMode);
    const session = yield select(userSelectors.getSession);
    if (signinMode === 'SIGN_IN') {
      const data = yield call(authService.signIn, {
        sms_code: values.code,
        session,
      });

      console.log('%c SIGN IN data = ', 'color: blue', data);

      yield call(saveToken, data.token);
      yield put(userActions.signInSuccess(data));

      if (data.status === 'DEACTIVATED') {
        yield put(replace(getPathForDeactivated()));
      } else {
        yield fork(putInitialAuthorizedActions);
      }

      if (data.errors && data.errors.length > 0) {
        console.log('%c SIGN IN data.errors = ', 'color: blue', data.errors);
        yield put(dialogActions.openSignInErrorPopup(data.errors));
      }
    } else {
      yield call(authService.confirmSignUpPhone, {
        code: values.code,
        session: session,
      });
      yield put(actions.closeConfirmPhonePopup());
      yield put(actions.openSignUpPopup());
    }

    // const session = yield select(selectors.getSession);
    // const data = yield call(authService.confirmSignUpPhone, {code: values.code, session});
    // yield call(saveToken, data.token);
    // yield put({type: types.CONFIRM_PHONE_SUCCESS, payload: {...data}});

    // yield put(actions.closeConfirmPhonePopup());

    // yield put(dialogActions.openTermsConditionsPopup());

    // // yield fork(putInitialAuthorizedActions);
    // //
    // // yield put(userActions.checkUserLoggedIn());
  } catch (error) {
    yield put({ type: types.CONFIRM_PHONE_FAILURE, error });
    yield call(
      reject,
      new SubmissionError(parseServerErrorsToForm(error.errors)),
    );
  }
}

function* resendCode() {
  try {
    const signin_mode = yield select(userSelectors.getSignInMode);
    const phone = yield select(userSelectors.getPhone);
    if (signin_mode === 'SIGN_IN') {
      const session = yield call(authService.sendConfirmationCode, {
        phone: phone,
      });
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        alert(session.sms_code);
      yield put(userActions.setSession(session.session));
    } else {
      const session = yield call(authService.signUp, {
        signup_mode: 'PASSWORD',
        phone: phone,
      });
      yield put(userActions.setSession(session.session));
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        alert(session.sms_code);
    }

    // const session = yield select(userSelectors.getSession);
    // const {sms_code} = yield call(authService.resendPhoneCode, {session});
    // yield put({type: types.RESEND_CODE_SUCCESS, smsCode: sms_code});
  } catch (error) {
    yield put({ type: types.RESEND_CODE_FAILURE, error });
  }
}

function* onGetTermsAndConditions() {
  try {
    const payload = yield call(
      authService.getTermsAndConditions,
      localStorage.getItem('lang') || 'ka',
    );
    yield put({ type: types.GET_TERMS_CONDITIONS_SUCCESS, payload });
    yield put(dialogActions.openTermsConditionsPopup());
  } catch (error) {
    yield put({ type: types.GET_TERMS_CONDITIONS_FAILURE, error });
  }
}

// Not used
function* onConfirmTermsAndConditions() {
  try {
    const session = yield select(selectors.getSession);

    yield call(authService.agreementTermsAndConditions, { session });

    // yield fork(putInitialAuthorizedActions);
    //
    // yield put(userActions.checkUserLoggedIn());

    yield put({ type: types.CONFIRM_TERMS_CONDITIONS_SUCCESS });

    const login = yield select(selectors.getPhone);
    const password = yield select(selectors.getPassword);

    yield put({
      type: userActionTypes.SIGN_IN_REQUEST,
      payload: {
        values: {
          login: login,
          password: password,
        },
      },
    });

    yield put(userActions.clearSignUpData());
  } catch (error) {
    yield put({ type: types.CONFIRM_TERMS_CONDITIONS_FAILURE, error });
  }
}

export function* watchSignUp() {
  yield takeEvery(types.SIGN_UP_REQUEST, signupSagas);
}

export function* watchResendCode() {
  yield takeEvery(types.RESEND_CODE_REQUEST, resendCode);
}

export function* watchConfirmCode() {
  yield takeEvery(types.CONFIRM_PHONE_REQUEST, confirmPhoneCode);
}

export function* watchGetTermsAndConditions() {
  yield takeEvery(types.GET_TERMS_CONDITIONS_REQUEST, onGetTermsAndConditions);
}

export function* watchConfirmTermsAndConditions() {
  yield takeEvery(
    types.CONFIRM_TERMS_CONDITIONS_REQUEST,
    onConfirmTermsAndConditions,
  );
}
