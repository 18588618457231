import marker from '../assets/images/icons/marker.png';

export const checkIfMapsApiLoaded = (successCallBack) => {
  if (window.google) {
    successCallBack();
  }
};

const getTextForErrorStatus = (status) => {
  switch (status) {
    case 'ZERO_RESULTS':
      return 'No addresses found';
    case 'OVER_QUERY_LIMIT':
      return 'Too many requests. Try again.';
    default:
      return 'Unknown error';
  }
};

export const getAddressByLocation = (location) =>
  new Promise((resolve, reject) => {
    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();

      const language = window.localStorage.lang;

      geocoder.geocode({ location, language }, (result, status) => {
        if (status === 'OK') {
          resolve(result[0].formatted_address);
        } else {
          reject(getTextForErrorStatus(status));
        }
      });
    } else {
      reject('');
    }
  });

export const DEFAULT_CENTER = { lat: 41.72083, lng: 44.798007 };

export const DEFAULT_ZOOM = 15;

export const getMapMarker = () => {
  if (window.google && window.google.maps) {
    return {
      url: marker,
      anchor: new window.google.maps.Point(30, 60),
      scaledSize: new window.google.maps.Size(60, 60),
      cursor: 'default',
    };
  }
  return null;
};
