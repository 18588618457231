import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { PageContent } from '../../components/ui/index';
import AddressesMap from './components/AddressesMap';
import CreateOrderMapSidebar from './components/CreateOrderMapSidebar';
import styles from './CreateOrderMap.module.scss';

class CreateOrderMap extends Component {
  render() {
    const { t } = this.props;

    return (
      <PageContent wrapper={false} title={t('selectAddress')}>
        <div className={styles.container}>
          <CreateOrderMapSidebar />

          <div className={styles.map}>
            <AddressesMap />
          </div>
        </div>

        <div className={styles.bgLine} />
      </PageContent>
    );
  }
}

export default withTranslation()(CreateOrderMap);
