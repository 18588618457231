import React, { Component } from 'react';
import {
  reduxForm,
  Field,
  formValueSelector,
  getFormSubmitErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { LabeledTextInput } from '../../components/inputs/text';
import { GreenButton } from '../../components/buttons';
import { DefaultForm, FormRow } from '../../components/ui';
import { Link } from 'react-router-dom';
import SignInSocial from '../signInSocial/SignInSocial';

import styles from './SignUpForm.module.scss';
import { MaskInput } from '../../components/inputs/maskInput';
import { GenderRadio } from '../../components/inputs/gender';

import { userSelectors } from '../../reducers/user';
import { selectors, actions } from '../../reducers/signup';
import store from '../dialog/store';

export const SIGN_UP_FORM = 'signInForm';

class SignUpForm extends Component {
  isDisabledRegistration = () => {
    const { first_name, last_name, email } = this.props;
    return !first_name || !last_name || !email;
  };

  getTermsAndConditions = (e) => {
    e.preventDefault();
    this.props.dispatch(
      actions.getTermsAndConditions(this.termsAndConditionsUrls),
    );
    //return actions.getTermsAndConditions;
  };

  render() {
    const { onSignIn, t, genderError, gender } = this.props;
    return (
      <DefaultForm onSubmit={this.props.handleSubmit}>
        <Helmet>
          <title>{t('signUp')}</title>
        </Helmet>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('registration')}</h2>

          {/* <FormRow>
            <GenderRadio genderError={genderError} gender={gender}/>
          </FormRow> */}

          <FormRow>
            <Field
              name="first_name"
              component={LabeledTextInput}
              label={t('firstName')}
            />
          </FormRow>

          <FormRow>
            <Field
              name="last_name"
              component={LabeledTextInput}
              label={t('lastName')}
            />
          </FormRow>

          <FormRow>
            <Field
              name="email"
              component={LabeledTextInput}
              label={t('email')}
              type="email"
            />
          </FormRow>

          <p className={styles.agreeText}>
            {/* <Field
              component={LabeledCheckbox}
              name='userAgrees'
            />  */}
            <span className={styles.agreeSpan}>{t('iAgreeWithService')}</span>
            <Link
              className={styles.agreeTextLink}
              to={'/'}
              onClick={this.getTermsAndConditions}
            >
              {t('termsAndConditions')}
            </Link>
          </p>

          <div className={styles.buttonWrapper}>
            <GreenButton type="submit" disabled={this.isDisabledRegistration()}>
              {t('register')}
            </GreenButton>
          </div>

          <SignInSocial />
        </div>
      </DefaultForm>
    );
  }
}

const selector = formValueSelector(SIGN_UP_FORM);
const selectorErrors = getFormSubmitErrors(SIGN_UP_FORM);

export default compose(
  withTranslation(),
  reduxForm({
    form: SIGN_UP_FORM,
    initialValues: {
      phone: '+995',
    },
  }),
  connect((state) => ({
    // gender: selector(state, 'gender'),
    first_name: selector(state, 'first_name'),
    last_name: selector(state, 'last_name'),
    email: selector(state, 'email'),
    termsAndConditions: selectors.termsAndConditions,

    // birth_date: selector(state, 'birth_date'),
    // phone: selector(state, 'phone'),
    // password: selector(state, 'password'),
    // genderError: selectorErrors(state).gender,
  })),
)(SignUpForm);
