import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './CreateTaskFullFlow.module.scss';
import {
  SuperCategoryLazy,
  SelectCategoryLazy,
  SelectServiceWrapperLazy,
  LeftSideLazy,
  ChooseAddressLazy,
  EditAddressLazy,
  SelectProfyLazy,
  CheckoutLazy,
} from './containers';

import SuperCategory from './containers/superCategory/SuperCategory';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RootReduxState } from 'store';
import { createTaskActions } from './state/actions';
import { Pagination } from './containers/pagination';
import createTaskRoutes from './helper/createTaskRoutes';
import { NumberParam, useQueryParam } from 'use-query-params';

export enum RouteTypeEnum {
  category = 'category',
  service = 'service',
  map = 'map',
  taskers = 'taskers',
  checkout = 'checkout',
}

const CreateTaskFullFlow = () => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    activeStep,
    selectedSuperCategoryIndex,
    currentOrder,
    emptyOrder,
    superCategories,
  } = useSelector((state: RootReduxState) => ({
    ...state.createTaskFullFlow,
    ...state.addressStore,
  }));

  const [superCategory, setShowSuper] = useQueryParam('super', NumberParam);
  const [category, setSuper2] = useQueryParam('category', NumberParam);

  useEffect(() => {
    // dispatch(createTaskActions.loadCurrentOrder());
    if (activeStep === 0 && superCategories === null) {
      dispatch(createTaskActions.onGetSuperCategories());
    } else {
      dispatch(createTaskActions.createOrderAndLoadCategories());
    }
  }, [dispatch]);

  useEffect(() => {
    // const path = location.pathname;
    // if (currentOrder) {
    //   if (
    //     path !== createTaskRoutes.category &&
    //     selectedSuperCategoryIndex < 0 &&
    //     !currentOrder?.model?.services.length
    //   ) {
    //     dispatch(createTaskActions.changeActiveStep(0));
    //   }
    // }
  }, [
    dispatch,
    location.pathname,
    selectedSuperCategoryIndex,
    currentOrder,
    activeStep,
  ]);

  useEffect(() => {
    if (superCategory === undefined && category === undefined)
      push(createTaskRoutes.superCategory);
  }, []);

  useEffect(() => {
    if (currentOrder !== null || emptyOrder !== null) {
      setLoading(false);
    }
  }, [currentOrder, emptyOrder, setLoading]);

  return (
    <div className={styles.container}>
      {!loading ? (
        <div className={styles.tabsContainer}>
          {location.pathname === createTaskRoutes.superCategory ? (
            // <SuperCategoryLazy />
            <SuperCategory />
          ) : (
            <>
              <div className={styles.leftSide}>
                <LeftSideLazy />
              </div>
              <div className={styles.rightSide}>
                <Switch>
                  <Route
                    exact
                    path={createTaskRoutes.category}
                    component={SelectCategoryLazy}
                  />
                  <Route
                    exact
                    path={createTaskRoutes.service}
                    component={SelectServiceWrapperLazy}
                  />
                  <Route
                    exact
                    path={createTaskRoutes.addressEdit}
                    component={EditAddressLazy}
                  />
                  <Route
                    exact
                    path={createTaskRoutes.address}
                    component={ChooseAddressLazy}
                  />
                  <Route
                    exact
                    path={createTaskRoutes.profies}
                    component={SelectProfyLazy}
                  />
                  <Route
                    exact
                    path={createTaskRoutes.checkout}
                    component={CheckoutLazy}
                  />
                  <Redirect
                    exact
                    from={'/'}
                    to={'/portal/product/superCategory'}
                  />
                </Switch>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.tabsContainer}></div>
      )}

      <Pagination />
    </div>
  );
};

export default React.memo(CreateTaskFullFlow);
