import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Avatar.module.scss';
import getImage from './helpers';

const Avatar = ({ auto, small, medium, big, image, gender }) => {
  return (
    <div
      className={classNames(styles.avatar, {
        [styles.auto]: auto,
        [styles.small]: small,
        [styles.medium]: medium,
        [styles.big]: big,
      })}
      style={{
        background: `url(${getImage(image, gender)}) center / cover no-repeat`,
      }}
    />
  );
};

Avatar.propTypes = {
  auto: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  big: PropTypes.bool,
  image: PropTypes.any,
};

export default Avatar;
