import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Svg } from '../../../components/ui';

import styles from './TaskImage.module.scss';

const TaskImage = ({ image, onDelete, openZoomImgPopup }) => {
  return (
    <div
      className={classNames(styles.container)}
      onClick={() => {
        openZoomImgPopup(image.base64Image || image.url);
      }}
    >
      <div
        className={styles.delete}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(image);
        }}
      >
        <Svg src="close" />
      </div>

      <div
        className={classNames(styles.image, {
          [styles.imageLoading]: image.loading,
        })}
        style={{
          background: `url(${
            image.base64Image || image.url
          }) center / cover no-repeat`,
        }}
      ></div>
    </div>
  );
};

TaskImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDelete: PropTypes.func.isRequired,
  openZoomImgPopup: PropTypes.func.isRequired,
};

export default TaskImage;
