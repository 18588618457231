import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import OrderCard from './components/OrderCard';
import { ordersActions, ordersSelectors } from '../../reducers/ordersList';
import { Loader } from '../../components/ui';
import {
  getUpcomingStatuses,
  getPastStatuses,
} from '../order/helpers/orderConstants';
import { PinkBorderButton } from '../../components/buttons';
import {
  getPathForEditOrder,
  getPathForOrderInfo,
  getPathForPaymentPage,
  getPathForTaskerMap,
  getPathForOrderStatus,
  getPathForChatWithTasker,
} from '../routing/constants/pathHelpers';

import { createOrderSelectors } from '../../reducers/createOrder';

import NoOrders from './components/NoOrders';
import styles from './Orders.module.scss';
import { userSelectors } from '../../reducers/user';
import TwoPanel from 'components/ui/TwoPanel';
import { RootReduxState } from 'store';
import TabNavigationWithTitle from 'components/ui/TabNavigationWithTitle';
import finishedOrders from 'assets/images/images/finished-orders.webp';
import currentOrder from 'assets/images/images/current-order.webp';

const data = [{ title: 'upcoming' }, { title: 'completedСapital' }];

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const {
    orders,
    ordersLoading,
    statuses,
    timeDifferenceWithServer,
    editingOrder,
  } = useSelector((state: RootReduxState) => ({
    orders: ordersSelectors.getOrders(state),
    ordersLoading: ordersSelectors.getOrdersLoading(state),
    statuses: ordersSelectors.getFilterStatuses(state),
    editingOrder: createOrderSelectors.getOrder(state),
    editingOrderLoading: createOrderSelectors.getOrderLoading(state),
    editingOrderTasks: createOrderSelectors.getTasks(state),
    timeDifferenceWithServer: userSelectors.getTimeDifferenceWithServer(state),
  }));

  useEffect(() => {
    dispatch(ordersActions.loadOrders());
  }, [dispatch]);

  useEffect(() => {
    if (statuses.PENDING) setActiveTab(1);
    else setActiveTab(0);
  }, [statuses.PENDING]);

  const onCardClick = (order: OrderType) => {
    if (order.status === statuses.CREATED && !order.tasker) {
      push(getPathForEditOrder());
    } else if (order.status === statuses.PAYMENT_IN_PROCESS) {
      //nothing to do
    } else if (
      (order.status === statuses.CREATED && order.tasker) ||
      order.status === statuses.WAITING_PAYMENT
    ) {
      push(getPathForPaymentPage());
    } else {
      push(getPathForOrderInfo(order.id));
    }
  };

  const renderButton = (order: OrderType) => {
    const connectWithTaskerButton = (
      <Link to={getPathForChatWithTasker(order.id)}>
        <PinkBorderButton stopPropagationRequired>
          {t('chatWithTasker')}
        </PinkBorderButton>
      </Link>
    );

    switch (order.status) {
      case statuses.CREATED:
        return (
          <PinkBorderButton
            stopPropagationRequired
            onClick={() => push(getPathForEditOrder())}
          >
            {t('continueEditing')}
          </PinkBorderButton>
        );

      case statuses.WAITING_PAYMENT:
        return (
          <PinkBorderButton
            stopPropagationRequired
            onClick={() => push(getPathForPaymentPage())}
          >
            {t('pay')}
          </PinkBorderButton>
        );

      case statuses.PAID:
        return connectWithTaskerButton;

      case statuses.VIEWED:
        return connectWithTaskerButton;

      case statuses.TRANSPORTATION:
        return (
          <PinkBorderButton
            stopPropagationRequired
            onClick={() => push(getPathForTaskerMap(order.id))}
          >
            {t('viewTaskerOnMap')}
          </PinkBorderButton>
        );

      case statuses.IN_PROGRESS:
        return (
          <PinkBorderButton
            stopPropagationRequired
            onClick={() => push(getPathForOrderStatus(order.id))}
          >
            {t('jobStatus')}
          </PinkBorderButton>
        );

      case statuses.WAITING_CONFIRMATION:
        return (
          <PinkBorderButton
            stopPropagationRequired
            onClick={() => push(getPathForOrderStatus(order.id))}
          >
            {t('reviewCompletedJob')}
          </PinkBorderButton>
        );

      case statuses.PENDING:
        return connectWithTaskerButton;

      default:
        return <></>;
    }
  };

  const onTabClick = useCallback(
    (index: number | string) => {
      if (!ordersLoading) {
        if (index === 0)
          dispatch(
            ordersActions.changeSelectedTab({
              ...getUpcomingStatuses(),
              COMPLETED: 'COMPLETED',
            }),
          );

        if (index === 1)
          dispatch(ordersActions.changeSelectedTab(getPastStatuses()));
      }

      setActiveTab(+index);
    },
    [dispatch, ordersLoading],
  );

  const renderEditingOrder = () => {
    if (
      editingOrder &&
      editingOrder.services &&
      editingOrder.services.length === 0
    ) {
      if (orders && orders.length === 1) {
        return <NoOrders key={editingOrder.id} active={true} />;
      }

      return null;
    }

    return (
      <OrderCard
        key={editingOrder.id}
        order={editingOrder}
        onCardClick={onCardClick}
        renderButton={renderButton}
        timeDifferenceWithServer={timeDifferenceWithServer}
      />
    );
  };

  const isNullOrder = (order) => {
    return !order;
  };

  const renderOrder = (order: OrderType) => {
    if (order.status === statuses.CREATED) return renderEditingOrder();

    if (
      statuses &&
      statuses.CREATED &&
      order.status === statuses.COMPLETED &&
      !order.on_warranty
    )
      return null;
    if (
      statuses &&
      statuses.CANCELLED &&
      order.status === statuses.COMPLETED &&
      order.on_warranty
    )
      return null;

    return (
      <OrderCard
        key={order.id}
        order={order}
        onCardClick={onCardClick}
        renderButton={renderButton}
        timeDifferenceWithServer={timeDifferenceWithServer}
      />
    );
  };

  const renderOrders = () => {
    if (!orders || orders?.length === 0) {
      return <NoOrders active={statuses.CREATED} />;
    }

    const renderedOrders = orders.map((order) => renderOrder(order));

    if (renderedOrders.every(isNullOrder)) {
      return <NoOrders active={statuses.CREATED} />;
    }

    return renderedOrders;
  };

  const mainContent = () => {
    return (
      <div className={'scrollable'}>
        <div className={`${styles.ordersContainer}`}>
          <TabNavigationWithTitle
            title={'orders'}
            onTabClick={onTabClick}
            tabsArr={data}
            activeTab={activeTab}
          />
          <Loader loading={ordersLoading} containerWithHeight={181}>
            {renderOrders()}
          </Loader>
        </div>
      </div>
    );
  };

  return (
    <TwoPanel
      imageLeftUrl={activeTab ? finishedOrders : currentOrder}
      rightSide={mainContent()}
    />
  );
};

export default Orders;
