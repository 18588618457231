import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { getPathForOrderTaskers } from '../../routing/constants/pathHelpers';
import { PinkButtonTransparent } from '../../../components/buttons';
import { Tasker } from '../../../components/userViews/tasker';

import styles from './PaymentTasker.module.scss';

class PaymentTasker extends Component {
  handleChange = () => {
    const { detachTaskerAndRedirect, loadEditingOrder, order } = this.props;

    detachTaskerAndRedirect(order.id, getPathForOrderTaskers());
    loadEditingOrder({ paymentChange: false });
  };

  render() {
    const { order, t } = this.props;

    return (
      <div className={styles.container}>
        {order.tasker && <Tasker user={order.tasker} small />}

        <div className={styles.btn}>
          <PinkButtonTransparent type={'button'} onClick={this.handleChange}>
            {t('changeCapital')}
          </PinkButtonTransparent>
        </div>
      </div>
    );
  }
}

PaymentTasker.propTypes = {
  order: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  connect(
    (state) => ({ order: createOrderSelectors.getOrder(state) }),
    createOrderActions,
  ),
)(PaymentTasker);
