const MODULE_PREFIX = 'addresses/';

export const paymentsTypes = {
  /* *** get cards list *** */
  GET_CARDS_LIST_REQUEST: MODULE_PREFIX + 'GET_CARDS_LIST_REQUEST',
  GET_CARDS_LIST_SUCCESS: MODULE_PREFIX + 'GET_CARDS_LIST_SUCCESS',
  GET_CARDS_LIST_FAILURE: MODULE_PREFIX + 'GET_CARDS_LIST_FAILURE',

  /* *** create card*** */
  CARD_CREATE_REQUEST: MODULE_PREFIX + 'CARD_CREATE_REQUEST',
  CARD_CREATE_SUCCESS: MODULE_PREFIX + 'CARD_CREATE_SUCCESS',
  CARD_CREATE_FAILURE: MODULE_PREFIX + 'CARD_CREATE_FAILURE',

  /* *** check card *** */
  CARD_CHECK_REQUEST: MODULE_PREFIX + 'CARD_CHECK_REQUEST',
  CARD_CHECK_SUCCESS: MODULE_PREFIX + 'CARD_CHECK_SUCCESS',
  CARD_CHECK_FAILURE: MODULE_PREFIX + 'CARD_CHECK_FAILURE',

  /* *** make card default *** */
  MAKE_CARD_DEFAULT_REQUEST: MODULE_PREFIX + 'MAKE_CARD_DEFAULT_REQUEST',
  MAKE_CARD_DEFAULT_SUCCESS: MODULE_PREFIX + 'MAKE_CARD_DEFAULT_SUCCESS',
  MAKE_CARD_DEFAULT_FAILURE: MODULE_PREFIX + 'MAKE_CARD_DEFAULT_FAILURE',

  /* *** delete card*** */
  CARD_DELETE_REQUEST: MODULE_PREFIX + 'CARD_DELETE_REQUEST',
  CARD_DELETE_SUCCESS: MODULE_PREFIX + 'CARD_DELETE_SUCCESS',
  CARD_DELETE_FAILURE: MODULE_PREFIX + 'CARD_DELETE_FAILURE',
};

const initialState = {
  cardsList: [],
  cardsListLoading: false,

  createCard: {},
  createCardLoading: false,

  checkCard: {},
  checkCardLoading: false,

  makeCardDefault: {},
  makeCardDefaultLoading: false,

  // deleteCard: {},
  deleteCardLoading: false,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};
  switch (action.type) {
    /* *** get cards list *** */
    case paymentsTypes.GET_CARDS_LIST_REQUEST:
      return {
        ...state,
        cardsList: [],
        cardsListLoading: true,
      };

    case paymentsTypes.GET_CARDS_LIST_SUCCESS:
      return {
        ...state,
        cardsList: payload.collection,
        cardsListLoading: false,
      };

    case paymentsTypes.GET_CARDS_LIST_FAILURE:
      return {
        ...state,
        cardsList: [],
        cardsListLoading: false,
      };

    /* *** create card *** */
    case paymentsTypes.CARD_CREATE_REQUEST:
      return {
        ...state,
        createCard: {},
        createCardLoading: true,
      };

    case paymentsTypes.CARD_CREATE_SUCCESS:
      return {
        ...state,
        createCard: payload,
        createCardLoading: false,
      };

    case paymentsTypes.CARD_CREATE_FAILURE:
      return {
        ...state,
        createCard: {},
        createCardLoading: false,
      };

    /* *** check card *** */
    case paymentsTypes.CARD_CHECK_REQUEST:
      return {
        ...state,
        checkCard: {},
        checkCardLoading: true,
      };

    case paymentsTypes.CARD_CHECK_SUCCESS:
      return {
        ...state,
        checkCard: payload,
        checkCardLoading: false,
      };

    case paymentsTypes.CARD_CHECK_FAILURE:
      return {
        ...state,
        checkCard: {},
        checkCardLoading: false,
      };

    /* *** make card default *** */
    case paymentsTypes.MAKE_CARD_DEFAULT_REQUEST:
      return {
        ...state,
        makeCardDefault: {},
        makeCardDefaultLoading: true,
      };

    case paymentsTypes.MAKE_CARD_DEFAULT_SUCCESS:
      return {
        ...state,
        makeCardDefault: payload,
        makeCardDefaultLoading: false,
      };

    case paymentsTypes.MAKE_CARD_DEFAULT_FAILURE:
      return {
        ...state,
        makeCardDefault: {},
        makeCardDefaultLoading: false,
      };

    /* *** delete card *** */
    case paymentsTypes.CARD_DELETE_REQUEST:
      return {
        ...state,
        deleteCardLoading: true,
      };

    case paymentsTypes.CARD_DELETE_SUCCESS:
      return {
        ...state,
        deleteCardLoading: false,
      };

    case paymentsTypes.CARD_DELETE_FAILURE:
      return {
        ...state,
        deleteCardLoading: false,
      };

    default:
      return state;
  }
};

export const paymentsActions = {
  /* *** get cards list *** */
  getCardsListRequest: () => ({
    type: paymentsTypes.GET_CARDS_LIST_REQUEST,
  }),
  getCardsListSuccess: (payload) => ({
    type: paymentsTypes.GET_CARDS_LIST_SUCCESS,
    payload,
  }),
  getCardsListFailure: () => ({
    type: paymentsTypes.GET_CARDS_LIST_FAILURE,
  }),

  /* *** create card *** */
  createCardRequest: () => ({
    type: paymentsTypes.CARD_CREATE_REQUEST,
  }),
  createCardSuccess: (payload) => ({
    type: paymentsTypes.CARD_CREATE_SUCCESS,
    payload,
  }),
  createCardFailure: () => ({
    type: paymentsTypes.CARD_CREATE_FAILURE,
  }),

  /* *** check card *** */
  checkCardRequest: (redirect) => ({
    type: paymentsTypes.CARD_CHECK_REQUEST,
    payload: { ...redirect },
  }),
  checkCardSuccess: (payload) => ({
    type: paymentsTypes.CARD_CHECK_SUCCESS,
    payload,
  }),
  checkCardFailure: () => ({
    type: paymentsTypes.CARD_CHECK_FAILURE,
  }),

  /* *** make card default *** */
  makeCardDefaultRequest: (id) => ({
    type: paymentsTypes.MAKE_CARD_DEFAULT_REQUEST,
    payload: {
      id,
    },
  }),
  makeCardDefaultSuccess: (payload) => ({
    type: paymentsTypes.MAKE_CARD_DEFAULT_SUCCESS,
    payload,
  }),
  makeCardDefaultFailure: () => ({
    type: paymentsTypes.MAKE_CARD_DEFAULT_FAILURE,
  }),

  /* *** delete card *** */
  deleteCardRequest: (id) => ({
    type: paymentsTypes.CARD_DELETE_REQUEST,
    payload: {
      id,
    },
  }),
  deleteCardSuccess: (payload) => ({
    type: paymentsTypes.CARD_DELETE_SUCCESS,
    payload,
  }),
  deleteCardFailure: (error) => ({
    type: paymentsTypes.CARD_DELETE_FAILURE,
    error,
  }),
};

export const paymentsSelectors = {
  /* *** get cards list *** */
  getCardsList: (state) => state.payments.cardsList,
  getCardsListLoading: (state) => state.payments.cardsListLoading,

  /* *** create card *** */
  getCreateCard: (state) => state.payments.createCard,
  getCreateCardLoading: (state) => state.payments.createCardLoading,

  /* *** create card *** */
  getCheckCard: (state) => state.payments.checkCard,
  getCheckCardLoading: (state) => state.payments.checkCardLoading,

  /* *** delete card *** */
  // getCreateCard: state => state.payments.createCard,
  getDeleteCardLoading: (state) => state.payments.deleteCardLoading,
};
