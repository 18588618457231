import React from 'react';
import { DefaultButton } from '../../../components/buttons';

const NotFoundConfirm = ({ onClose }) => (
  <div>
    <div>Something gone wrong</div>
    <div>
      <DefaultButton onClick={onClose}>Close</DefaultButton>
    </div>
  </div>
);

export default NotFoundConfirm;
