import React from 'react';
import styles from './FeedbackSuggestionItem.module.scss';

type FeedbackSuggestionItemPropsType = {
  onSelectItem: () => void;
  active: boolean;
  text: string;
};

const FeedbackSuggestionItem = ({
  active,
  onSelectItem,
  text,
}: FeedbackSuggestionItemPropsType) => {
  return (
    <div
      className={`flex flex-center ${styles.additionalServiceItem} ${
        active ? styles.active : ''
      }`}
      onClick={() => onSelectItem?.()}
    >
      <span>{text}</span>
    </div>
  );
};

export default FeedbackSuggestionItem;
