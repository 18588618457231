import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InitialCheckbox from './InitialCheckbox';

import styles from './LabeledCheckbox.module.scss';

const LabeledCheckbox = (props) => (
  <label className={styles.label}>
    <InitialCheckbox
      className={classNames(styles.check, {
        [styles.checkDisabled]: props.disabled,
      })}
      {...props}
    />
    <span
      className={classNames(styles.pseudoCheck, {
        [styles.noLabel]: !props.label,
      })}
    />

    <span className={styles.labelText}>{props.label}</span>
  </label>
);

LabeledCheckbox.propTypes = {
  label: PropTypes.string,
};

export default LabeledCheckbox;
