import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  .Select__control {
    border-radius: 2.75rem;
    min-width: 10rem;
    border: none;
    background-color: rgba(224, 226, 230, 0.4);
    border-width: 0;
    border-color: transparent;
    outline-color: transparent;
    box-shadow: none;
    min-height: 2.75rem;
    padding-right: 1rem;
  }
  .Select__control--menu-is-open,
  .Select__control:hover {
    background-color: rgb(102, 195, 173);
    cursor: pointer;
    z-index: 333;

    svg {
      fill: white;
      stroke: white;
      color: white;
    }
    .Select__value-container {
      p {
        color: white !important;
      }
    }
  }
  .Select__control--menu-is-open {
    svg {
      fill: white;
      stroke: white;
      color: white;
      transform: rotate(-180deg);
    }
  }

  svg {
    fill: gray;
    stroke: gray;
    color: gray;
    transition: transform 100ms ease-out;
    padding: 0;
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__value-container {
    padding: 0;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5rem;
  }
  .Select__single-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 333;
    height: 100%;
    img {
      width: 1.68rem;
      height: 1.1rem;
      object-fit: contain;
      margin-right: 0.625rem;
    }
    p {
      line-height: 0.9375rem;
      transform: translateY(1px);
    }
  }

  .Select__option,
  .Select__option:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.75rem;
    cursor: pointer;
    outline-color: transparent;
    background-color: transparent;
    img {
      width: 1.68rem;
      height: 1.1rem;
      object-fit: contain;
      margin-right: 0.625rem;
      margin-left: 0.3rem;
    }
  }
  /* .Select__option:hover {
  background-color: gray;
} */
  .Select__menu {
    background-color: rgba(224, 226, 230, 0.4);
    margin: 0;
    padding: 0;
    border-bottom-right-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
    min-width: 10rem;
    border: none;
    border-width: 0;
    border-color: transparent;
    outline-color: transparent;
    box-shadow: none;
    padding-top: 1.375rem;
    margin-top: -1.375rem;
    z-index: 332;
    opacity: 1 !important;
  }

  @media (max-width: 960px) {
    svg {
      display: none !important;
    }
    .Select__control {
      width: 112px;
      height: 36px;
      /* padding-right: 1.5rem; */
    }
    .Select__single-value {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      z-index: 333;
      height: 100%;
      img {
        width: 24px;
        height: 14px !important;
      }
      p {
        line-height: 0.9375rem;
        transform: translateY(1px);
        font-size: 14px;
      }
    }
    .Select__value-container {
      padding-right: 0.7rem;
    }
    svg {
      width: 10px;
    }
    .Select__menu-list {
      opacity: 1 !important;
      background-color: white;
    }
    .Select__option {
      opacity: 1 !important;
    }
  }
  .Select__option {
    p {
      margin-bottom: 0;
    }
  }
`;
