import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Loader } from '../../../components/ui';
import { GreenButton } from '../../../components/buttons';
import { LabeledCheckbox } from '../../../components/inputs/checkbox';
import { actions as signupActions, selectors } from '../../../reducers/signup';
import styles from './TermConditionsPopup.module.scss';

class TermsConditionsPopup extends Component {
  componentDidMount() {
    this.props.getTermsAndConditions();
  }

  state = {
    agree: false,
  };

  onChangeAgree = () => {
    this.setState({ agree: !this.state.agree });
  };

  onClickConfirm = () => {
    //this.props.confirmTermsAndConditions();
    this.props.onClose();
  };

  createMarkup = () => {
    return { __html: this.props.termsAndConditions };
  };

  render() {
    const { t, termsAndConditionsLoading } = this.props;

    return (
      <div className={styles.container}>
        <Loader loading={termsAndConditionsLoading} height={400}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={this.createMarkup()}
          />

          <div className={styles.controls}>
            <div className={styles.agreeText}>
              {/* <LabeledCheckbox
                value={this.state.agree}
                onChange={this.onChangeAgree}
              /> */}
              {/* <span className={styles.agreeSpan}>
                {t('iAgreeWithService') + ' '}
                {t('termsAndConditions')}
              </span> */}
            </div>
          </div>

          <div className={styles.btnContainer}>
            <GreenButton onClick={this.onClickConfirm}>{t('back')}</GreenButton>
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      termsAndConditions: selectors.getTermsAndConditions(state),
      termsAndConditionsLoading: selectors.getTermsAndConditionsLoading(state),
    }),
    {
      getTermsAndConditions: signupActions.getTermsAndConditions,
      confirmTermsAndConditions: signupActions.confirmTermsAndConditions,
    },
  ),
)(TermsConditionsPopup);
