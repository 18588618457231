import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GreenButton } from '../../../components/buttons';

import { compose } from 'recompose';

import styles from './MakeCardDefaultError.module.scss';

class MakeCardDefaultError extends Component {
  render() {
    const { error, t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('makeCardDefaultError')}</div>
        <div className={styles.text}>
          {error || t('makeCardDefaultErrorText')}
        </div>
        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              this.props.onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(MakeCardDefaultError);
