import { takeEvery, call, put } from 'redux-saga/effects';
import { supportActions, supportTypes } from '../reducers/support';
import { getComplaintsList, sendComplaint } from '../services/supportService';
import { dialogActions } from '../containers/dialog/store';
import { screens } from '../containers/support/helpers/supportConstants';
import { chatActions } from '../reducers/chat';

function* onLoadComplaintsList({ payload: { screen } }) {
  try {
    const { collection } = yield call(getComplaintsList, screen);

    yield put({
      type: supportTypes.LOAD_COMPLAINTS_LIST_SUCCESS,
      payload: {
        complaintsList: collection,
      },
    });
  } catch (error) {
    yield put({
      type: supportTypes.LOAD_COMPLAINTS_LIST_FAILURE,
      error,
    });
  }
}

function formatComplaintToBackend({ screen, id, comment, order_id }) {
  if (order_id) {
    return {
      content: comment,
      reason_id: id,
      screen,
      order_id,
    };
  }

  return {
    content: comment,
    reason_id: id,
    screen,
  };
}

function* onSendComplaint({ payload: { complaint } }) {
  try {
    const { room } = yield call(
      sendComplaint,
      formatComplaintToBackend(complaint),
    );

    yield put(dialogActions.openSuccessComplaintSend());

    if (complaint.screen === screens.ORDER_CONFIRMATION) {
      yield put(supportActions.toggleShowSupportOnStatusJob(false));
    }

    if (complaint.screen === screens.DEACTIVATED) {
      yield put(supportActions.toggleShowSupportOnDeactivated(false));
    }

    if (complaint.screen === screens.BLOCKED) {
      yield put(supportActions.toggleShowSupportOnBlocked(false));
    }

    yield call(supportActions.clearComplaintState);

    yield put({
      type: supportTypes.COMPLAINT_SEND_SUCCESS,
      payload: {
        chat: {
          ...room,
        },
      },
    });

    yield put(chatActions.setSupportChat(room));
  } catch (error) {
    yield put(dialogActions.openFailureComplaintSend());

    yield put({
      type: supportTypes.COMPLAINT_SEND_FAILURE,
      error,
    });
  }
}

export function* watchLoadComplaints() {
  yield takeEvery(
    supportTypes.LOAD_COMPLAINTS_LIST_REQUEST,
    onLoadComplaintsList,
  );
}

export function* watchSendComplaint() {
  yield takeEvery(supportTypes.COMPLAINT_SEND_REQUEST, onSendComplaint);
}
