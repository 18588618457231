import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from './DropDown';

type LanguageDropDownProps = {
  instanceId: string;
};

const options = [
  { value: 'ka', label: 'GEO', icon: 'georgian' },
  { value: 'en', label: 'ENG', icon: 'english' },
  { value: 'ru', label: 'RUS', icon: 'russian' },
];

const LanguageDropDown = ({
  instanceId,
}: LanguageDropDownProps): React.ReactElement => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<typeof options[0]>(options[0]);

  useEffect(() => {
    const index = options.findIndex((val) => val.value === i18n.language);
    setLanguage(options[index] || options[0]);
    window.HelpCrunch?.('setPhraseList', i18n.language);
  }, [i18n.language]);

  const handleChange = useCallback(
    ({ value }) => {
      i18n.changeLanguage(value);
      window.location.reload();

    },
    [i18n],
  );
  return (
    <DropDown
      handleChange={handleChange}
      options={options}
      value={language}
      instanceId={instanceId}
    />
  );
};

export default LanguageDropDown;
