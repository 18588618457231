import { ActionsEnum } from 'containers/createTaskFullFlow/state/actions';
import { AddressActionEnum } from './actions';

/* INITIAL STATE */
type InitialAddressStateType = {
  addresses: AddressesType | null;
  selectedAddressIndex: number;
};

const defaultInitialState: InitialAddressStateType = {
  addresses: null,
  selectedAddressIndex: -1,
};

const initialState: InitialAddressStateType = { ...defaultInitialState };
export type AddressPayload = {
  [AddressActionEnum.GET_ADDRESSES_SUCCESS]: AddressesType;
  [AddressActionEnum.SET_ACTIVE_ADDRESS_INDEX]: number;
  [ActionsEnum.FINISH_PAYMENT_SUCCESS]: undefined;
};

export type AppActions = ActionMap<AddressPayload>[keyof ActionMap<
  AddressPayload
>];

/* REDUCER */
export default (
  state = initialState,
  action: AppActions,
): InitialAddressStateType => {
  switch (action.type) {
    case AddressActionEnum.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload,
      };
    case AddressActionEnum.SET_ACTIVE_ADDRESS_INDEX:
      return {
        ...state,
        selectedAddressIndex: action.payload,
      };
    case ActionsEnum.FINISH_PAYMENT_SUCCESS:
      return {
        ...defaultInitialState,
      };

    default:
      return state;
  }
};
