import React, { Component } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import onClickOutside from 'react-onclickoutside';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

class EmojiPicker extends Component {
  render() {
    const { onEmojiSelect, t } = this.props;

    return (
      <Picker
        onSelect={onEmojiSelect}
        title={t('selectEmoji')}
        native={true}
        exclude={['flags']}
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          width: '355px',
          zIndex: '10',
        }}
      />
    );
  }
}

export default compose(withTranslation(), onClickOutside)(EmojiPicker);
