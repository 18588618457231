import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { InlineDatePicker } from '../../../components/inputs/date';
import styles from './TaskersSidebar.module.scss';
import { taskersActions, taskersSelectors } from '../store';
import { MAX_DAYS_TO_MAKE_ORDER } from '../../../utils/time';
import debounce from 'lodash/debounce';
import { ReactSelect } from '../../../components/inputs/select';
import { Loader } from '../../../components/ui';

class TaskersSidebar extends Component {
  componentDidMount() {
    this.props.loadTaskersDays();

    this.handleScroll();

    window.addEventListener('scroll', debounce(this.handleScroll, 16));
  }

  sidebarContainer = React.createRef();
  sidebar = React.createRef();

  state = {
    sidebarTop: 0,
    date: '',
    timerDebounce: null,
    filter: '',
    isAnyTime: true,
  };

  getTaskersSortOptions = () => {
    const { t } = this.props;

    return [
      { value: '', label: t('recommended') },
      { value: '&sort=price&order=asc', label: t('priceEsc') },
      { value: '&sort=price&order=desc', label: t('priceDesc') },
      { value: '&sort=rating&order=asc', label: t('ratingEsc') },
      { value: '&sort=rating&order=desc', label: t('ratingDesc') },
      { value: '&sort=available&order=asc', label: t('earliestAvailable') },
    ];
  };

  handleScroll = (event) => {
    if (
      this.sidebarContainer.current !== null &&
      this.sidebar.current !== null
    ) {
      const sidebarContainerTop = this.sidebarContainer.current.offsetTop;
      const sidebarContainerHeight = this.sidebarContainer.current.offsetHeight;
      const sidebarHeight = this.sidebar.current.offsetHeight;
      const scrolltop =
        event && event.srcElement && event.srcElement.scrollingElement
          ? event.srcElement.scrollingElement.scrollTop
          : 0;
      let resultTop = 0;

      if (sidebarContainerTop > scrolltop) {
        resultTop = 0;
      }

      if (scrolltop > sidebarContainerTop) {
        resultTop = scrolltop - sidebarContainerTop - 60;
      }

      if (
        scrolltop + sidebarHeight >
        sidebarContainerTop + sidebarContainerHeight
      ) {
        resultTop = sidebarContainerHeight - sidebarHeight;
      }

      if (resultTop < 0) resultTop = 0;

      this.setState({
        sidebarTop: resultTop,
      });
    }
  };

  isOutsideRange = (date) => {
    return (
      date < moment().startOf('day') ||
      date >
        moment().add(this.props.taskersDays || MAX_DAYS_TO_MAKE_ORDER, 'day')
    );
  };

  onDateChange = (date, anyTime = false) => {
    window.scroll(0, 0);
    const { orderId } = this.props;

    if (anyTime) {
      this.setState({
        date: '',
        isAnyTime: true,
      });
      this.props.changeFilterDate('', orderId);
    } else {
      this.setState({
        date: date,
        isAnyTime: false,
      });
      this.props.changeFilterDate(date, orderId);
    }
  };

  anyTime = () => {
    this.setState({
      isAnyTime: true,
    });

    this.onDateChange(null, true);
  };

  anyTimeDisable = () => {
    this.setState({
      isAnyTime: false,
    });
  };

  currentDay = () => {
    const date = moment(new Date());
    return this.onDateChange(date);
  };

  nextDay = () => {
    const date = moment(new Date()).add(1, 'days');
    return this.onDateChange(date);
  };

  isCurrentDay = () => {
    if (this.state.isAnyTime) return false;

    return moment(new Date()).day() === this.state.date.day();
  };

  isNextDay = () => {
    if (this.state.isAnyTime) return false;

    return moment(new Date()).day() + 1 === this.state.date.day();
  };

  onFilterChange = ({ value }) => {
    const { orderId } = this.props;
    this.setState({ filter: value });
    this.props.changeFilterType(value, orderId);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.taskersDays && !this.props.taskersDays) {
      this.props.loadTaskersDays();
    }
  }

  render() {
    const { taskersDaysLoading, t } = this.props;

    return (
      <div className={styles.sidebarColumn} ref={this.sidebarContainer}>
        <div
          className={styles.sidebar}
          ref={this.sidebar}
          style={{ top: `${this.state.sidebarTop}px` }}
        >
          <Loader loading={taskersDaysLoading} height={590}>
            <div className={styles.blockSelect}>
              <div className={styles.blockSelectTitle}>{t('sortedBy')}:</div>
              <ReactSelect
                placeholder={t('recommended')}
                menuPlacement="auto"
                selectProps={{
                  menuPlacement: 'auto',
                }}
                options={this.getTaskersSortOptions()}
                onChange={this.onFilterChange}
                value={this.getTaskersSortOptions().map((option) => {
                  if (option.value === this.state.filter) {
                    return option;
                  }

                  return null;
                })}
                maxMenuHeight={380}
                isSearchable={false}
              />
            </div>

            <div className={styles.btnWrapper}>
              <button
                className={classNames(styles.btnDay, {
                  [styles.btnDayActive]: this.state.isAnyTime,
                })}
                type={'button'}
                onClick={this.anyTime}
              >
                {t('anyTime')}
              </button>

              <button
                className={classNames(styles.btnDay, {
                  [styles.btnDayActive]: this.isCurrentDay(),
                })}
                type={'button'}
                onClick={this.currentDay}
              >
                {t('today')}
              </button>

              <button
                className={classNames(styles.btnDay, {
                  [styles.btnDayActive]: this.isNextDay(),
                })}
                type={'button'}
                onClick={this.nextDay}
              >
                {t('tomorrow')}
              </button>
            </div>

            <div className={styles.blockDataPicker}>
              <InlineDatePicker
                onChange={this.onDateChange}
                value={this.state.date}
                daySize={48}
                focused={true}
                isOutsideRange={this.isOutsideRange}
              />
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  date: taskersSelectors.getFilterDate(state),
  filterType: taskersSelectors.getFilterType(state),
  taskersDays: taskersSelectors.getTaskersDays(state),
  taskersDaysLoading: taskersSelectors.getTaskersDaysLoading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, taskersActions),
)(TaskersSidebar);
