import { Svg } from 'components/ui';
import React, { PureComponent } from 'react';
import styles from './WarningBox.module.scss';

type WarningBoxPropsType = {
  text: string;
};
class WarningBox extends PureComponent<WarningBoxPropsType> {
  render() {
    const { text } = this.props;

    return (
      <div className={styles.warningBoxContainer}>
        <Svg src={'info'} className={styles.WarningIcon} />
        <span className={styles.warningBoxText}>{text}</span>
      </div>
    );
  }
}

export default WarningBox;
