import i18next from "i18next";

export const dateFormater = (date: string, lang: string, convert: boolean = false): string => {

    if(convert){
        const dateArray = date.split('/')
        date = '20'+dateArray[2]+'-'+dateArray[1]+'-'+dateArray[0]
    }

    const convertedDate = new Date(date);
    const day: number = convertedDate.getDate();
    const month: number = convertedDate.getMonth();
    const year: number = convertedDate.getFullYear();
    let monthText: string = ''
    if (lang == 'en') {
        switch (month) {
            case 0:
                monthText = 'January';
                break;
            case 1:
                monthText = 'February';
                break;
            case 2:
                monthText = 'March';
                break;
            case 3:
                monthText = 'April';
                break;
            case 4:
                monthText = 'May';
                break;
            case 5:
                monthText = 'June';
                break;
            case 6:
                monthText = 'July';
                break;
            case 7:
                monthText = 'August';
                break;
            case 8:
                monthText = 'September';
                break;
            case 9:
                monthText = 'October';
                break;
            case 10:
                monthText = 'November';
                break;
            case 11:
                monthText = 'December';
                break;
            default:
                monthText = '';
        }


    } else if (lang == 'ka') {

        switch (month) {
            case 0:
                monthText = 'იანვარი';
                break;
            case 1:
                monthText = 'თებერვალი';
                break;
            case 2:
                monthText = 'მარტი';
                break;
            case 3:
                monthText = 'აპრილი';
                break;
            case 4:
                monthText = 'მაისი';
                break;
            case 5:
                monthText = 'ივნისი';
                break;
            case 6:
                monthText = 'ივლისი';
                break;
            case 7:
                monthText = 'აგვისტო';
                break;
            case 8:
                monthText = 'სექტემბერი';
                break;
            case 9:
                monthText = 'ოქტომბერი';
                break;
            case 10:
                monthText = 'ნოემბერი';
                break;
            case 11:
                monthText = 'დეკემბერი';
                break;
            default:
                monthText = '';
        }

    } else if (lang == 'ru') {

        switch (month) {
            case 0:
                monthText = 'Январь';
                break;
            case 1:
                monthText = 'Февраль';
                break;
            case 2:
                monthText = 'Маршировать';
                break;
            case 3:
                monthText = 'Апрель';
                break;
            case 4:
                monthText = 'Мая';
                break;
            case 5:
                monthText = 'Июнь';
                break;
            case 6:
                monthText = 'Июль';
                break;
            case 7:
                monthText = 'Август';
                break;
            case 8:
                monthText = 'Сентябрь';
                break;
            case 9:
                monthText = 'Октябрь';
                break;
            case 10:
                monthText = 'Ноябрь';
                break;
            case 11:
                monthText = 'Декабрь';
                break;
            default:
                monthText = '';
        }
    }

    const fullDate = `${day} ${monthText} ${year}`

    return fullDate
}


export const yearFormater = (date: string): string => {
    const convertedDate = new Date(date);
    const year: number = convertedDate.getFullYear();

    const fullDate = `${year}`

    return fullDate
}

export const dateAndTime = (fullDateAndTime) => {
    const dateTimeArray = fullDateAndTime.split(', ')
    if (dateTimeArray.length > 1) {
        const date = dateFormater(dateTimeArray[0], i18next.language)
        const hour = dateTimeArray[1]
        return `${date}, ${hour}`
    }
    return ''
}
