import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ORDERS } from '../../routing/constants/routes';

import {
  PinkBorderButton,
  GrayButtonTransparent,
  GrayBorderButton,
  GreenButton,
} from '../../../components/buttons';
import { orderActions, orderSelectors } from '../../../reducers/order';
import { getLabelForStatus } from '../helpers/orderConstants';
import { getLocalDateTime } from '../../../utils/time';
import TaskerCard from './TaskerCard';

import styles from './OrderInfo.module.scss';
import Loader from '../../../components/ui/Loader';
import { getWarrantyTime, getWorkingTime } from '../../../utils/orderTimes';
import { userSelectors } from '../../../reducers/user';
import { RootReduxState } from 'store';
import { isCurrentOrder } from '../helpers/helper';
import { OrderStatusesEnum, SelectedCategoryTypesEnum } from 'utils/enum';
import OrderList from 'containers/createTaskFullFlow/containers/checkout/components/OrderList';
import CheckList from 'containers/createTaskFullFlow/containers/checkout/components/CheckList';
import { dialogActions } from 'containers/dialog/store';

const OrderInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { order, orderLoading, timeDifferenceWithServer } = useSelector(
    (state: RootReduxState) => ({
      order: state.order.order,
      orderLoading: orderSelectors.getOrderLoading(state),
      timeDifferenceWithServer: userSelectors.getTimeDifferenceWithServer(
        state,
      ),
    }),
  );


  useEffect(() => {
    const addedTasks = [];
    order?.tasks.map((singleTask) => {
      if(singleTask.from_tasker && !singleTask.confirmed && !singleTask.rejected){
        addedTasks.push(singleTask) 
      }
    })

    
    if(addedTasks.length > 0){
      dispatch(dialogActions.openAddedOrdersCheckPopup(addedTasks))
    }
    
  }, [])

  const renderCancelButton = useCallback(() => {
    if (order && isCurrentOrder(order)) {
      return (
        <PinkBorderButton
          medium
          onClick={() =>
            dispatch(orderActions.getCancelOrderRefoundRequest(order))
          }
        >
          {t('cancelTheOrder')}
        </PinkBorderButton>
      );
    }
  }, [dispatch, order, t]);

  const renderContactTaskerButton = useCallback(() => {
    if (
      order &&
      ![OrderStatusesEnum.COMPLETED, OrderStatusesEnum.CANCELLED].includes(
        order.status as OrderStatusesEnum,
      )
    ) {
      return (
        <Link to={ORDERS + '/' + order.id + '/chat'}>
          <GrayBorderButton big iconNoFill>
            {t('contactTheTasker')}
          </GrayBorderButton>
        </Link>
      );
    }
  }, [order, t]);

  const renderReorderButton = useCallback(() => {
    if (
      order &&
      !isCurrentOrder(order) &&
      order?.flow?.type === SelectedCategoryTypesEnum.CLEANING
    ) {
      return (
        <Link to={ORDERS + '/' + order.id + '/chat'}>
          <GreenButton big iconNoFill>
            {t('createNew')}
          </GreenButton>
        </Link>
      );
    }
  }, [order, t]);

  const renderOrderTime = useCallback(() => {
    if (order?.status !== OrderStatusesEnum.IN_PROGRESS) return '';

    const time = getWorkingTime(
      order.tasker_begin_at + timeDifferenceWithServer,
    );

    if (time.length > 0) {
      if (time.indexOf('NaN') !== -1) return `(00:00)`;
      return `(${time})`;
    }

    return '(00:00)';
  }, [order?.status, order?.tasker_begin_at, timeDifferenceWithServer]);

  const renderWarrantyEnds = useCallback(() => {
    if (!order?.on_warranty) return;

    return (
      <div className={styles.field}>
        <div className={styles.fieldName}>{t('warrantyEnds')}</div>
        <div className={styles.fieldValue}>
          {getWarrantyTime(
            order.warranty_end_at + timeDifferenceWithServer,
            order.completed_at + timeDifferenceWithServer,
          )}
        </div>
      </div>
    );
  }, [
    order?.completed_at,
    order?.on_warranty,
    order?.warranty_end_at,
    t,
    timeDifferenceWithServer,
  ]);

  return useMemo(
    () =>
      order && (
        <div className={styles.mainContainer}>
          <Helmet>
            <title>{t('orderInformation')}</title>
          </Helmet>

          <Loader loading={orderLoading}>
            <div className={styles.tasker}>
              <TaskerCard order={order} />
            </div>
            <div className={styles.info}>
              <div className={styles.infoTitle}>
                {t('informationAboutOrder')}
              </div>

              <div className={styles.fields}>
                <div className={styles.field}>
                  <div className={styles.fieldName}>{t('status')}</div>
                  <div className={styles.fieldValue}>
                    {getLabelForStatus(order.status, t) +
                      `${renderOrderTime()}`}
                  </div>
                </div>

                {renderWarrantyEnds()}

                <div className={styles.field}>
                  <div className={styles.fieldName}>{t('timeOfOrder')}</div>
                  <div className={styles.fieldValue}>
                    {getLocalDateTime(order.datetime)}
                  </div>
                </div>

                {order.address && (
                  <div className={styles.field}>
                    <div className={styles.fieldName}>{t('address')}</div>
                    <div className={styles.fieldValue}>
                      {order.address.address}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <OrderList tasks={order.tasks} fromOrderDetails={true} />
            {order.tasks[0].comment || order.tasks[0].images.length > 0 ? (
              <div className={styles.info}>
                <div className={styles.infoTitle}>{t('comment')}</div>
                {order.tasks[0].comment && (
                  <p className={styles.comment}>{order.tasks[0].comment}</p>
                )}
                <div className={styles.imagesContainer}>
                { order.tasks[0].images.map((single_image) => {
                  return (
                    <div>
                      <img src={single_image.url} alt="" />
                    </div>
                  )
                })}
                </div>
              </div>
            ) : (
              <></>
            )}
            <CheckList
              tasks={order.tasks}
              fromOrderDetails={true}
              discountNumber={-order.price + order.price_for_paid}
              priceForPaid={order.price_for_paid}
              additional_services={order.additional_services}
            />

            {/* <div className={styles.blockMap}>
              <OrderInfoMap center={order.address.location} />
            </div> */}

            <div className={styles.btnWrapper}>
              <Link to={ORDERS}>
                <GrayButtonTransparent iconName={'arrowLeft'} iconPos={'left'}>
                  {t('goToPrevStep')}
                </GrayButtonTransparent>
              </Link>
              <div className={'flex flex-row'}>
                {renderCancelButton()}

                {renderContactTaskerButton()}
                {renderReorderButton()}
              </div>
            </div>
          </Loader>
        </div>
      ),
    [
      order,
      orderLoading,
      renderCancelButton,
      renderContactTaskerButton,
      renderOrderTime,
      renderReorderButton,
      renderWarrantyEnds,
      t,
    ],
  );
};

export default OrderInfo;
