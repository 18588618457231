import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
// import Raven from 'raven-js';
import RavenMiddleware from 'redux-raven-middleware';

import reducers from './reducers';
import { registerWithMiddleware } from './sagas';
import { onLoadActions } from './reducers/onLoadActions';
import history from './createHistory';

const middleware = [];
let sagaMiddleware;

if (process.env.NODE_ENV === 'production') {
  // sagaMiddleware = createSagaMiddleware({
  //   onError: error => Raven.captureException(error)
  // });

  sagaMiddleware = createSagaMiddleware({ onError: (error) => error });

  middleware.push(RavenMiddleware(process.env.REACT_APP_SENTRY_SDN));
} else {
  sagaMiddleware = createSagaMiddleware();
}

middleware.push(sagaMiddleware);
middleware.push(routerMiddleware(history));

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const createdReducers = reducers(history);

const store = createStore(createdReducers, composedEnhancers);
export type RootReduxState = ReturnType<typeof createdReducers>;

registerWithMiddleware(sagaMiddleware);

onLoadActions.forEach((action) => store.dispatch(action()));

export default store;
