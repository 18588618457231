import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dialogActions, dialogSelectors } from './store';
import ConfirmOrderCancel from './components/ConfirmOrderCancel';
import ConfirmOrderDelete from './components/ConfirmOrderDelete';
import ConfirmTaskDelete from './components/ConfirmTaskDelete';
import ConfirmAccountDeactivation from './components/ConfirmAccountDeactivation';
import ConfirmDetachTaskerFromOrder from './components/ConfirmDetachTaskerFromOrder';
import ConfirmTaskCreate from './components/ConfirmTaskCreate';
import OrderTaskDeletePopup from './components/OrderTaskDeletePopup';
import NotFoundConfirm from './components/NotFoundConfirm';
import ReceiptPopup from './components/ReceiptPopup';
import ZoomImg from './components/ZoomImg';
import { dialogNames } from './constants/dialogConstants';
import { Popup } from '../../components/ui';
import ConfirmTaskDeleteFromOrder from './components/ConfirmTaskDeleteFromOrder';
import ConfirmTaskReject from './components/ConfirmTaskReject';
import CropImage from './components/CropImage';
import TermsConditionsPopup from './components/TermsConditionsPopup';
import SubmitOrderError from './components/SubmitOrderError';
import SubmitOrderErrorPaymentMethod from './components/SubmitOrderErrorPaymentMethod';
import SubmitOrderErrorCardIsNotActive from './components/SubmitOrderErrorCardIsNotActive';
import SubmitOrderErrorTimeout from './components/SubmitOrderErrorTimeout';
import SubmitOrderErrorPriceChanged from './components/SubmitOrderErrorPriceChanged';
import SubmitOrderErrorSystemTransaction from './components/SubmitOrderErrorSystemTransaction';
import SubmitOrderErrorTaskerTransaction from './components/SubmitOrderErrorTaskerTransaction';
import SubmitOrderSuccess from './components/SubmitOrderSuccess';
import DeleteOrderError from './components/DeleteOrderError';
import UploadImageError from './components/UploadImageError';
import OtherDeviceSignedIn from './components/OtherDeviceSignedIn';
import SignInError from './components/SignInError';
import ComplaintSendSuccess from './components/ComplaintSendSuccess';
import ComplaintSendFailure from './components/ComplaintSendFailure';
import CloseSupportChat from './components/CloseSupportChat';
import ConfirmCardDelete from './components/ConfirmCardDelete';
import CheckCard from './components/CheckCard';
import DeleteCardFailure from './components/DeleteCardFailure';
import MakeCardDefaultError from './components/MakeCardDefaultError';
import CancelOrderError from './components/CancelOrderError';
import ChatIsNotAvailable from './components/ChatIsNotAvailable';
import InviteUser from './components/InviteUser';
import ProfyFeedback from './components/ProfyFeedback';
import ConfirmGoFirstStep from './components/ConfirmGoFirstStep';
import AddedOrdersCheck from './components/AddedOrdersCheck';

const getComponentForModalType = (dialogType) => {
  switch (dialogType) {
    case dialogNames.CONFIRM_ORDER_CANCEL:
      return ConfirmOrderCancel;

    case dialogNames.CONFIRM_ORDER_DELETE:
      return ConfirmOrderDelete;

    case dialogNames.CONFIRM_TASK_DELETE:
      return ConfirmTaskDelete;

    case dialogNames.CONFIRM_CREATE_TASK_FROM_ORDER:
      return ConfirmTaskCreate;

    case dialogNames.CONFIRM_TASK_DELETE_FROM_ORDER:
      return ConfirmTaskDeleteFromOrder;

    case dialogNames.CONFIRM_TASK_REJECT_FROM_ORDER:
      return ConfirmTaskReject;

    case dialogNames.CONFIRM_DETACH_TASKER_FROM_ORDER:
      return ConfirmDetachTaskerFromOrder;

    case dialogNames.CONFIRM_ACCOUNT_DEACTIVATION:
      return ConfirmAccountDeactivation;

    case dialogNames.OPEN_RECEIPT_POPUP:
      return ReceiptPopup;

    case dialogNames.OPEN_CANCEL_TASK_POPUP:
      return OrderTaskDeletePopup;

    case dialogNames.OPEN_ZOOM_IMG_POPUP:
      return ZoomImg;

    case dialogNames.OPEN_CROP_IMG_POPUP:
      return CropImage;

    case dialogNames.TERMS_CONDITIONS_POPUP:
      return TermsConditionsPopup;

    case dialogNames.SUBMIT_ORDER_ERROR:
      return SubmitOrderError;

    case dialogNames.SUBMIT_ORDER_ERROR_PAYMENT_METHOD:
      return SubmitOrderErrorPaymentMethod;

    case dialogNames.SUBMIT_ORDER_ERROR_CARD_IS_NOT_ACTIVE:
      return SubmitOrderErrorCardIsNotActive;

    case dialogNames.SUBMIT_ORDER_ERROR_TIMEOUT:
      return SubmitOrderErrorTimeout;

    case dialogNames.SUBMIT_ORDER_ERROR_PRICE_CHANGED:
      return SubmitOrderErrorPriceChanged;

    case dialogNames.SUBMIT_ORDER_ERROR_SYSTEM_TRANSACTION:
      return SubmitOrderErrorSystemTransaction;

    case dialogNames.SUBMIT_ORDER_ERROR_TASKER_TRANSACTION:
      return SubmitOrderErrorTaskerTransaction;

    case dialogNames.SUBMIT_ORDER_SUCCESS:
      return SubmitOrderSuccess;

    case dialogNames.DELETE_ORDER_ERROR:
      return DeleteOrderError;

    case dialogNames.UPLOAD_IMAGE_ERROR:
      return UploadImageError;

    case dialogNames.OTHER_DEVICE_SIGNED_IN:
      return OtherDeviceSignedIn;

    case dialogNames.SIGN_IN_ERROR:
      return SignInError;

    case dialogNames.COMPLAINT_SEND_SUCCESS:
      return ComplaintSendSuccess;

    case dialogNames.COMPLAINT_SEND_FAILURE:
      return ComplaintSendFailure;

    case dialogNames.CLOSE_SUPPORT_CHAT:
      return CloseSupportChat;

    case dialogNames.DELETE_CARD_CONFIRM:
      return ConfirmCardDelete;

    case dialogNames.CHECK_CARD:
      return CheckCard;

    case dialogNames.DELETE_CARD_FAILURE:
      return DeleteCardFailure;

    case dialogNames.MAKE_CARD_DEFAULT_ERROR:
      return MakeCardDefaultError;

    case dialogNames.CANCEL_ORDER_ERROR:
      return CancelOrderError;

    case dialogNames.CHAT_IS_NOT_AVAILABLE:
      return ChatIsNotAvailable;

    case dialogNames.INVITE_USER:
      return InviteUser;

    case dialogNames.PROFY_FEEDBACK:
      return ProfyFeedback;

    case dialogNames.CONFIRIM_GO_FIRST_STEP:
      return ConfirmGoFirstStep;

    case dialogNames.ADDED_ORDERS_CHECK:
      return AddedOrdersCheck

    default:
      return NotFoundConfirm;
  }
};

class Dialog extends Component {
  render() {
    const { dialogType, dialogProps, closeDialog } = this.props;

    if (dialogType) {
      const DialogComponent = getComponentForModalType(dialogType);

      return (
        <Popup
          onClose={() => {
            closeDialog();
            if (dialogProps && dialogProps.image && dialogProps.task) {
              this.props.openReceiptPopup(dialogProps.task);
            }
          }}
          noClose={dialogProps ? dialogProps.noClose : false}
          isOpen={true}
        >
          <DialogComponent {...dialogProps} onClose={closeDialog} />
        </Popup>
      );
    }

    return null;
  }
}

Dialog.propTypes = {
  dialogType: PropTypes.string,
  dialogProps: PropTypes.object,
};

export default connect(
  (state) => dialogSelectors.getDialogState(state),
  dialogActions,
)(Dialog);
