import React from 'react';

import styles from './TwoPanel.module.scss';

type TwoPanelPropsType = {
  leftSide?: React.ReactNode;
  rightSide: React.ReactNode;
  imageLeftUrl?: string;
};
const TwoPanel = ({ leftSide, rightSide, imageLeftUrl }: TwoPanelPropsType) => {
  return (
    <div className={styles.panelContainer}>
      <div className={styles.columnsAside}>
        {leftSide ||
          (imageLeftUrl && (
            <div className={`${styles.imageLeft} flex flex-center`}>
              <img src={imageLeftUrl} alt="left image" />
            </div>
          ))}
      </div>
      <div className={styles.columnsMainContent}>{rightSide}</div>
    </div>
  );
};

export default TwoPanel;
