import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Svg } from '../../../../components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Loader } from '../../../../components/ui';
import { GreenButton } from '../../../../components/buttons';
import {
  notificationsListActions,
  notificationsListSelectors,
} from '../../../../reducers/notificationsList';

import NotificationsItem from './NotificationsItem';

import styles from './NotificationsList.module.scss';
import { userSelectors } from '../../../../reducers/user';
import { RootReduxState } from 'store';
import { useClickAway } from 'ahooks';

type NotificationsListPropsType = {
  handleClickOutside: (event: any) => void;
};
const NotificationsList = ({
  handleClickOutside,
}: NotificationsListPropsType) => {
  const [loadMore, setLoadMore] = useState(false);
  const [lastSeen, setLastSeen] = useState(
    localStorage.getItem('lastNotificationSeen') || null,
  );
  const notificationsListElem = useRef<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    notificationsList,
    isOpen,
    notificationsListHasMore,
    notificationsListLoading,
    timeDifference,
  } = useSelector((state: RootReduxState) => ({
    isOpen: notificationsListSelectors.getNotificationsListIsOpen(state),
    notificationsList: state.notificationsList.notificationsList,
    notificationsListHasMore:
      notificationsListSelectors.getNotificationsListHasMore(state),
    notificationsListLoading:
      notificationsListSelectors.getNotificationsListLoading(state),
    timeDifference: userSelectors.getTimeDifferenceWithServer(state),
  }));

  useClickAway((event) => {
    handleClickOutside(event);
  }, ref);

  useEffect(() => {
    dispatch(notificationsListActions.getNotificationsList());
  }, [dispatch]);

  useEffect(() => {
    if (loadMore) {
      if (notificationsList && notificationsListElem.current) {
        notificationsListElem.current.scrollTop =
          notificationsListElem.current.scrollHeight;
      }
    }
  }, [loadMore, notificationsList]);

  useEffect(() => {
    const lastSeenNew = localStorage.getItem('lastNotificationSeen') || null;
    setLastSeen(lastSeenNew);
  }, []);

  const renderNoNotifications = useCallback(() => {
    return <div className={styles.noNotifications}>{t('noNotifications')}</div>;
  }, [t]);

  const renderNotifications = useCallback(() => {
    if (notificationsList && notificationsList.length > 0) {
      return notificationsList.map((notification) => (
        <NotificationsItem
          notification={notification}
          lastSeen={lastSeen}
          key={notification.id}
        />
      ));
    }

    return renderNoNotifications();
  }, [lastSeen, notificationsList, renderNoNotifications]);

  const handleLoadMoreButtonClick = useCallback(() => {
    console.log('ASSA');

    localStorage.setItem(
      'lastNotificationSeen',
      Date.now() + timeDifference * 1000 + '',
    );
    dispatch(notificationsListActions.getNotificationsList());
    setLoadMore(true);
  }, [dispatch, timeDifference]);

  const renderLoadMoreButton = useCallback(() => {
    if (
      notificationsListHasMore &&
      notificationsList &&
      notificationsList.length !== 0
    )
      return (
        <div className={styles.btnWrapper}>
          <p className="loadMore" onClick={handleLoadMoreButtonClick}>
            {t('loadMore')}
          </p>
        </div>
      );

    return null;
  }, [
    handleLoadMoreButtonClick,
    notificationsList,
    notificationsListHasMore,
    t,
  ]);

  const renderNotificationsSetting = useCallback(() => {
    return (
      <div className={styles.dropdown__header__setting}>
        <span className={styles.dropdown__header__title}>
          {t('notifications')}
        </span>
        <span className={styles.dropdown__header__icon}>
          <Svg className={styles.dropdownHeaderSVG} src={'setting-gray'} />
        </span>
      </div>
    );

    return renderNoNotifications();
  }, [lastSeen, notificationsList, renderNoNotifications]);

  return useMemo(
    () => (
      <div
        className={classNames(styles.notificationsContainer, {
          [styles.isOpen]: isOpen,
        })}
        ref={ref}
      >
        <div className={styles.notificationsList} ref={notificationsListElem}>
          <Loader loading={notificationsListLoading}>
            {renderNotificationsSetting()}
            {renderNotifications()}
            {renderLoadMoreButton()}
          </Loader>
        </div>
      </div>
    ),
    [
      isOpen,
      notificationsListLoading,
      renderLoadMoreButton,
      renderNotifications,
    ],
  );
};

export default NotificationsList;
