import React, { Component, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { ORDERS } from '../../routing/constants/routes';
import { Loader, Svg } from '../../../components/ui';
import { orderSelectors } from '../../../reducers/order';
import WarningBox from './WarningBox'
import classNames from 'classnames';
import {
  GrayButtonTransparent,
  PinkButtonTransparent,
  PinkButton,
} from '../../../components/buttons';
import { orderActions } from '../../../reducers/order';

import styles from './StatusJob.module.scss';
import CreateComplaint from '../../support/CreateComplaint';
import { supportActions, supportSelectors } from '../../../reducers/support';
import { getWorkingTime } from '../../../utils/orderTimes';

class StatusJob extends Component {
  state = {
    openSupport: false,
  };

  componentDidMount() {
    const orderId = this.getOrderId();

    this.props.loadOrderTasks(orderId);
  }

  getOrderId() {
    return this.props.match.params.id;
  }

  calculateTotal = (tasks) => {
    let totalPrice = 0

    tasks.map((item) => {
      totalPrice += item.price
    })

    return totalPrice
  }

  getQuantity = (task) => {
    const { quantity, quantity_finished, quantity_rejected } = task;

    if (quantity - quantity_rejected === quantity_finished) {
      return (
        <span className={styles.colorGreen}>
          x{quantity - quantity_rejected}
        </span>
      );
    } else {
      return (
        <span>
          <span className={styles.colorYellow}>{quantity_finished}</span>/
          {quantity}
        </span>
      );
    }
  };

  renderTask = (task) => {
    const {
      id,
      rejected,
      completed,
      quantity,
      quantity_finished,
      quantity_rejected,
      service,
    } = task;
    let srcIcon;

    if (rejected) {
      srcIcon = 'trash';
    } else {
      if (completed) {
        srcIcon =
          quantity === quantity_finished + quantity_rejected
            ? 'taskCompleted'
            : 'taskPartCompleted';
      } else {
        srcIcon = 'taskUncompleted';
      }
    }

    return (
      <div key={id} className={styles.task}>
        <img src={service.image} alt="" />
        {/* <Svg src={srcIcon} className={styles.taskIcon} /> */}
       <div className={styles.info__holder}>
          <span className={styles.single__task__name}>{service.name}</span>
          {/* <span className={styles.quantity}>({this.getQuantity(task)})</span> */}
          <span className={classNames(styles.quantity, quantity_finished == quantity ? styles.quantity__status_green : quantity_finished == 0 ? styles.quantity__status_red : styles.quantity__status_orang)}>{quantity_finished} / {quantity}</span>
       </div>
      </div>
    );
  };

  renderTaskPrice = (task) => {    
    const {
      id,
      rejected,
      completed,
      quantity,
      quantity_finished,
      quantity_rejected,
      service,
      price
    } = task;
    let srcIcon;

    if (rejected) {
      srcIcon = 'trash';
    } else {
      if (completed) {
        srcIcon =
          quantity === quantity_finished + quantity_rejected
            ? 'taskCompleted'
            : 'taskPartCompleted';
      } else {
        srcIcon = 'taskUncompleted';
      }
    }

    return (
      <div key={id+'a'} className={styles.taskPrice}>
        {/* <Svg src={srcIcon} className={styles.taskIcon} /> */}
       <div className={styles.price__info__holder}>
          <span className={styles.single__task__price__name}>{service.name}</span>
          {/* <span className={styles.quantity}>({this.getQuantity(task)})</span> */}
          <span className={styles.Price}>{price} ₾</span>
       </div>
      </div>
    );
  };

  // renderTask = (task) => {
  //   const {
  //     id,
  //     rejected,
  //     completed,
  //     quantity,
  //     quantity_finished,
  //     quantity_rejected,
  //     service,
  //   } = task;
  //   let srcIcon;

  //   if (rejected) {
  //     srcIcon = 'trash';
  //   } else {
  //     if (completed) {
  //       srcIcon =
  //         quantity === quantity_finished + quantity_rejected
  //           ? 'taskCompleted'
  //           : 'taskPartCompleted';
  //     } else {
  //       srcIcon = 'taskUncompleted';
  //     }
  //   }

  //   return (
  //     <div key={id} className={styles.task}>
  //       <Svg src={srcIcon} className={styles.taskIcon} />
  //       <span className={styles.quantity}>({this.getQuantity(task)})</span>
  //       <span>{service.name}</span>
  //     </div>
  //   );
  // };

  renderStatusJob = () => {
    const { tasksLoading, tasks, order, t } = this.props;

    return (
      <>
      <Fragment>
        {/* <div className={styles.timer}>
          {t('elapsedTime')}:{' '}
          {getWorkingTime(order.tasker_begin_at, order.tasker_competed_at)}
        </div> */}
        <div className={styles.tasks__wrapper}>

          <Loader loading={tasksLoading} height={85}>
          {tasks.map((task) => this.renderTask(task))}
        </Loader>
        {order.not_completed_reason ? (
          <div className={styles.reason}>
            {t('uncompletedReason')}: {order.not_completed_reason}
          </div>
        ) : null}

        </div>
        

        <div className={styles.tasks__wrapper}>

          <Loader loading={tasksLoading} height={85}>
            <h4 className={styles.task_price_title}>ღირებულება</h4>
          {tasks.map((task) => this.renderTaskPrice(task))}
          <div className={styles.total__holder}>
            <span className={styles.total__title}>ჯამი</span>
            <span className={styles.total__price}>{this.calculateTotal(tasks)} ₾</span>
          </div>
          </Loader>

        </div>
        {/* <div className={styles.tasksBtnWrapper}>
         
        </div> */}

        <div className={styles.btnWrapper}>
          {/* <Link to={ORDERS}>
            <GrayButtonTransparent iconPos={'left'} iconName={'arrowLeft'}>
              {t('back')}
            </GrayButtonTransparent>
          </Link> */}

          {/* <PinkButtonTransparent onClick={this.onReportProblemClick}>
            {t('reportAProblem')}
          </PinkButtonTransparent> */}
          <div className={styles.reportProblem}  onClick={this.onReportProblemClick}>
          {t('reportAProblem')}
          </div>

          <div className={styles.green__button} onClick={this.handleConfirmClick}>
          {t('confirm')}
          </div>

          {/* <PinkButton medium minWidth={120} onClick={this.handleConfirmClick}>
            {t('confirm')}
          </PinkButton> */}
        </div>
      </Fragment>
      
      </>
      
    );
  };

  btnBackClick = () => {
    this.props.toggleShowSupportOnStatusJob(false);
  };

  renderContent = () => {
    const { order, showSupportOnStatusJob } = this.props;
    

    if (showSupportOnStatusJob) {
      return (
        <>
        
        <CreateComplaint
          screen={order.status}
          orderId={order.id}
          btnBackClick={this.btnBackClick}
        />
        </>
        
      );
    }

    return this.renderStatusJob();
  };

  handleConfirmClick = () => {
    this.props.confirmOrderComplete();
  };

  onReportProblemClick = () => {
    this.props.toggleShowSupportOnStatusJob(true);
  };

  render() {
    const { orderConfirmLoading } = this.props;

    return (
      <>
      <div className={styles.warningContainer}>
        <h3 className={styles.title}>დაადასტურე შეკვეთა</h3>
        <WarningBox text='ხელოსანმა თქვენთან შეასრულა ეს შეკვეთები, ეთანხმებით თუ არა?' />
      </div>
      <div className={styles.container}>
        <Loader loading={orderConfirmLoading} height={250}>
          {this.renderContent()}
        </Loader>
      </div>
      </>
      
    );
  }
}

StatusJob.propTypes = {
  order: PropTypes.object,
  tasks: PropTypes.array,
  tasksLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  tasks: orderSelectors.getTasks(state),
  tasksLoading: orderSelectors.getTasksLoading(state),
  order: orderSelectors.getOrder(state),
  orderConfirmLoading: orderSelectors.getOrderConfirmLoading(state),
  showSupportOnStatusJob: supportSelectors.getShowSupportOnStatusJob(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    ...orderActions,
    toggleShowSupportOnStatusJob: supportActions.toggleShowSupportOnStatusJob,
  }),
  withRouter,
)(StatusJob);
