export const getToken = () => localStorage.getItem('token');

export const saveToken = (token) => {
  localStorage.setItem('token', token);
  removeGuestToken();
};

export const removeToken = () => localStorage.removeItem('token');

/* GUEST TOKEN */

export const getGuestToken = () => localStorage.getItem('guestToken');

export const saveGuestToken = (token) =>
  localStorage.setItem('guestToken', token);

export const removeGuestToken = () => localStorage.removeItem('guestToken');
