import React, { Component } from 'react';
import SocialItem from './SocialItem';
import styles from './Social.module.scss';

class Social extends Component {
  render() {
    const { links } = this.props;

    if (!links) return null;

    return (
      <ul className={styles.social}>
        {links.map((item) => {
          return (
            <SocialItem
              href={item.footer_icon_link.url}
              icon={item.footer_icon_url}
              target={item.footer_icon_link.target}
              key={item.footer_icon_link.url}
            />
          );
        })}
      </ul>
    );
  }
}

export default Social;
