import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { GreenButton } from '../../../components/buttons';

import styles from './ConfirmAccountDeactivation.module.scss';

class SignInError extends Component {
  render() {
    const { errors, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('error')}</div>
        <div className={styles.text}>
          {errors && errors.map((error) => <p>{error}</p>)}
        </div>
        <div className={styles.btn}>
          <GreenButton onClick={onClose}>{t('cancel')}</GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(SignInError);
