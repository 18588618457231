import { push } from 'connected-react-router';
import { taskersSelectors } from 'containers/createOrderTaskersNew/store';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  attachTasker,
  createEmptyOrder,
  createTasks,
  deleteOrder,
  getSuperCategories,
  getTaskTree,
  loadOrder,
  loadOrderTasks,
  saveOrderAddress,
  submitOrder,
  uploadUserDescription,
} from 'services/ordersListService';
import {
  addSubscriptions,
  deactivateSubscription,
} from 'services/taskersService';
import { RootReduxState } from 'store';
import createTaskRoutes from '../helper/createTaskRoutes';
import {
  ActionsEnum,
  createTaskActions,
  CreateTaskActionsType,
} from './actions';
import moment from 'moment';
import { dialogActions } from 'containers/dialog/store';
import {
  getPathForOrderInfo,
  getPathForOrderTaskers,
} from 'containers/routing/constants/pathHelpers';
import { createOrderActions } from 'reducers/createOrder';
import { SelectedCategoryTypesEnum } from 'utils/enum';
import {
  formatCleaning,
  formatHandyman,
} from '../helper/tasksFormatForSending';
import { loadEditingAddress } from 'services/ordersListService';

function* onGetSuperCategories() {
  try {
    const [superCategories]: [
      GetSuperCategoriesResponseType,
      CurrentOrderType,
    ] = yield all([call(getSuperCategories)]);
    const emptyOrder = yield call(createEmptyOrder);
    yield put({
      type: ActionsEnum.ORDER_CREATE_SUCCESS,
      payload: {
        emptyOrder,
        superCategories,
      },
    });
    yield put({ type: ActionsEnum.GO_FIRST_STEP });
  } catch (error) {
    console.log(error);
  }
}

function* onGoFirstStep() {
  const {
    currentOrder,
  }: {
    currentOrder: CurrentOrderType | null;
  } = yield select((state: RootReduxState) => ({
    currentOrder: state.createTaskFullFlow.currentOrder,
  }));
  const {
    superCategories,
  }: {
    superCategories: GetSuperCategoriesResponseType | null;
  } = yield select((state: RootReduxState) => ({
    superCategories: state.createTaskFullFlow.superCategories,
  }));
  const {
    taskFlow,
  }: {
    taskFlow: GetTaskTreeResponseType[] | [];
  } = yield select((state: RootReduxState) => ({
    taskFlow: state.createTaskFullFlow.taskFlow,
  }));
  try {
    if (currentOrder) yield call(deleteOrder, currentOrder?.model?.id);
    const emptyOrder = yield call(createEmptyOrder);
    yield put({
      type: ActionsEnum.ORDER_CREATE_SUCCESS,
      payload: {
        emptyOrder,
        superCategories,
        taskFlow,
      },
    });
  } catch (err) {
    console.log(err);
  }
}

function* createOrderOrLoadCurrent() {
  const {
    superCategories,
  }: {
    superCategories: GetSuperCategoriesResponseType | null;
  } = yield select((state: RootReduxState) => ({
    superCategories: state.createTaskFullFlow.superCategories,
  }));
  try {
    const [currentOrderResponse]: [CurrentOrderType] = yield all([
      call(loadEditingAddress),
    ]);

    let emptyOrder = null;
    let currentOrder: null | CurrentOrderType = currentOrderResponse;

    const hasCurrentOrder = !!currentOrder.model?.services.length;
    console.log('%c createOrderOrLoadCurrent', 'color: red');

    if (!hasCurrentOrder) {
      emptyOrder = yield call(createEmptyOrder);
      currentOrder = null;
      yield put(createTaskActions.changeActiveStep(0));
    }

    yield put({
      type: ActionsEnum.ORDER_CREATE_SUCCESS,
      payload: {
        emptyOrder,
        hasCurrentOrder,
        currentOrder,
        superCategories,
      },
    });

    if (hasCurrentOrder) {
      let step = 0;
      switch (currentOrder?.model?.status) {
        case 'CREATED':
          if (currentOrder?.model.address.address) {
            step = 3;
          } else {
            step = 2;
          }
          break;
        case 'WAITING_PAYMENT':
          step = 4;
          break;
        default:
          step = 0;
          break;
      }

      yield put(createTaskActions.changeActiveStep(step));
    }
  } catch (error) {
    console.log(error);
  }
}

function* changeActiveStep({
  payload,
}: ReturnType<CreateTaskActionsType['changeActiveStep']>) {
  let route = '';
  switch (payload) {
    case 0:
      try {
        yield put(dialogActions.openConfirmGoFirstStep());
      } catch (err) {
        console.log(err);
      }
      break;
    case 1:
      route = createTaskRoutes.service;
      break;
    case 2:
      route = createTaskRoutes.address;
      break;
    case 3:
      route = createTaskRoutes.profies;
      break;
    case 4:
      route = createTaskRoutes.checkout;
      break;
    case 9:
      try {
        yield call(onGoFirstStep);
        route = createTaskRoutes.superCategory;
      } catch (err) {
        console.log(err);
      }
      break;
    default:
      route = createTaskRoutes.superCategory;
      break;
  }

  if (route.length) yield put(push(route));
}

function* onCategoryFetch({
  payload,
}: ReturnType<CreateTaskActionsType['fetchCategory']>) {
  try {
    const taskFlow = yield call(getTaskTree, payload.id);

    yield put({
      type: ActionsEnum.FETCH_CATEGORY_SUCCESS,
      payload: { taskFlow, index: payload.index },
    });

    // yield put({ type: ActionsEnum.CHANGE_ACTIVE_STEP, payload: 1 });
  } catch (error) {
    console.log(error);
  }
}

function* onSuperCategorySelect({
  payload,
}: ReturnType<CreateTaskActionsType['selectSuperCategory']>) {
  yield put({
    type: ActionsEnum.SELECT_SUPER_CATEGORY_SUCCESS,
    payload: { index: payload.index },
  });
}

function* onCategoryIndexSelect({
  payload,
}: ReturnType<CreateTaskActionsType['selectCategoryIndex']>) {
  yield put({ type: ActionsEnum.CHANGE_ACTIVE_STEP, payload: 1 });
  yield put({
    type: ActionsEnum.SELECT_CATEGORY_SUCCESS,
    payload,
  });
}

function* onLoadCurrentOrder() {
  try {
    const currentOrder: CurrentOrderType = yield call(loadEditingAddress);
    yield put({
      type: ActionsEnum.LOAD_CURRENT_ORDER_SUCCESS,
      payload: {
        currentOrder,
        hasCurrentOrder: !!currentOrder.model?.services.length,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* onAddSubscription({
  payload,
}: ReturnType<CreateTaskActionsType['onAddSubscription']>) {
  try {
    const result = yield call(addSubscriptions, payload);
    yield put({
      type: ActionsEnum.ADD_SUBSCRIPTION_SUCCESS,
      payload: { ...result },
    });
  } catch (error) {
    console.log({ error: error });
  }
}

function* onRemoveSubscription({
  payload,
}: ReturnType<CreateTaskActionsType['onRemoveSubscription']>) {
  try {
    const result = yield call(deactivateSubscription, payload);
    yield put({
      type: ActionsEnum.REMOVE_SUBSCRIPTION_SUCCESS,
      payload: { ...result },
    });
  } catch (error) {
    console.log({ error: error });
  }
}

type SelectedTypeUploadType = {
  order: EmptyOrderType;
  formData: any;
  selectedCategoryType: SelectedCategoryTypesEnum;
};

function* onTaskUpload() {
  yield put(push(createTaskRoutes.service));

  const { order, selectedCategoryType }: SelectedTypeUploadType = yield select(
    (state: RootReduxState) => ({
      order: state.createTaskFullFlow.emptyOrder,
      selectedCategoryType: state.serviceStore.selectedCategoryType,
    }),
  );
  const {
    tasks,
    description,
  }: {
    tasks: TaskSkeletonType[];
    description: string | undefined;
  } =
    selectedCategoryType === SelectedCategoryTypesEnum.HANDYMAN
      ? yield call(formatHandyman)
      : yield call(formatCleaning);
  try {
    yield call(createTasks, order.order.id, { tasks });

    if (description) {
      yield call(uploadUserDescription, {
        order_id: order.order.id,
        user_description: description,
      });
    }
  } catch (error) {
    console.log(error, 'errors');
  }
  // yield put({ type: ActionsEnum.UPLOAD_TASK_SUCCESS });
  yield put({ type: ActionsEnum.CHANGE_ACTIVE_STEP, payload: 2 });
  yield call(onLoadCurrentOrder);
}

function* onAddAddressToOrder() {
  try {
    const {
      addresses,
      selectedAddressIndex,
      orderId,
    }: {
      addresses: AddressesType | null;
      selectedAddressIndex: number;
      orderId: number | undefined;
    } = yield select((state: RootReduxState) => ({
      ...state.addressStore,
      orderId: state.createTaskFullFlow.currentOrder?.model?.id,
    }));

    yield call(saveOrderAddress, orderId, {
      address_id: addresses?.collection?.[selectedAddressIndex].id,
    });
  } catch (error) {
    console.log(error, 'errors');
  }
  yield put({ type: ActionsEnum.CHANGE_ACTIVE_STEP, payload: 3 });
  // yield put(createTaskActions.loadCurrentOrder());
}

function* getCurrentOrderById(orderId: number) {
  const [currentOrder, tasks] = yield all([
    call(loadOrder, orderId),
    call(loadOrderTasks, orderId),
  ]);

  yield put({
    type: ActionsEnum.ADD_PROFY_TO_ORDER_SUCCESS,
    payload: { currentOrder, tasks },
  });
}

function* onAddTaskerToOrder() {
  const {
    taskers,
    selectedTasker,
    orderId,
  }: {
    taskers: TaskerType[];
    selectedTasker: any;
    orderId: number;
  } = yield select((state: RootReduxState) => ({
    orderId: state.createTaskFullFlow.currentOrder?.model?.id,
    taskers: taskersSelectors.getTaskers(state),
    selectedTasker: taskersSelectors.getSelectedTasker(state),
  }));

  const tasker = taskers[selectedTasker.index];
  const date = moment.unix(selectedTasker.unixDate).format('YYYY-MM-DD');
  const id = tasker.user.id;
  const time =
    tasker.timetable[selectedTasker.unixDate][selectedTasker.hourIndex];
  // let bundle_id = tasker.bundles[selectedTasker.cleanCountIndex].id
  try {
    yield call(attachTasker, {
      order_id: orderId,
      tasker_id: id,
      date,
      time,
    });
    yield call(getCurrentOrderById, orderId);

    yield put({ type: ActionsEnum.CHANGE_ACTIVE_STEP, payload: 4 });
    yield put(createTaskActions.loadCurrentOrder());
  } catch (error) {
    console.log(error, 'errors');
  }
}

function* onFinishOrder() {
  try {
    const { order }: { order: CurrentOrderType | undefined } = yield select(
      (state: RootReduxState) => ({
        order: state.createTaskFullFlow?.currentOrder,
      }),
    );
    yield put(dialogActions.openSubmitOrderSuccess(order?.model?.id));
    yield call(submitOrder, {
      order_id: order?.model?.id,
      price: order?.model?.price_for_paid,
    });

    yield put(createOrderActions.clearCreateOrderState());
    yield put(push(getPathForOrderInfo(order?.model?.id)));
    yield put({ type: ActionsEnum.FINISH_PAYMENT_SUCCESS });
  } catch (error) {
    if (error && error.errors && error.errors.card_id) {
      yield put(dialogActions.openSubmitOrderErrorPaymentMethodPopup());
    } else if (error.errors && error.errors['*'] && error.errors['*'][0]) {
      switch (error.errors['*'][0].code) {
        case 'ORDER_SUBMIT_PRICE_CHANGED':
          yield put(
            dialogActions.openSubmitOrderErrorPriceChanged(
              error.errors['*'][0].message,
            ),
          );
          yield put(createOrderActions.loadEditingOrder());
          yield put(push(getPathForOrderTaskers()));
          break;

        case 'ORDER_SUBMIT_TIMEOUT_EXPIRED':
          yield put(dialogActions.openSubmitOrderErrorTimeout());
          yield put(createOrderActions.loadEditingOrder());
          yield put(push(getPathForOrderTaskers()));
          break;

        case 'ORDER_SUBMIT_SYSTEM_TRANSACTION_FAILED':
          yield put(
            dialogActions.openSubmitOrderErrorSystemTransaction(
              error.errors['*'][0].message,
            ),
          );
          break;

        case 'ORDER_SUBMIT_CARD_IS_NOT_ACTIVE':
          yield put(
            dialogActions.openSubmitOrderErrorCardIsNotActive(
              error.errors['*'][0].message,
            ),
          );
          break;

        case 'ORDER_SUBMIT_TASKER_TRANSACTION_FAILED':
          yield put(
            dialogActions.openSubmitOrderErrorTaskerTransaction(
              error.errors['*'][0].message,
            ),
          );
          break;

        default:
          yield put(
            dialogActions.openSubmitOrderErrorPopup(
              error.errors['*'][0].message,
            ),
          );
          break;
      }
    } else {
      yield put(dialogActions.openSubmitOrderErrorPopup());
    }

    yield put({ type: ActionsEnum.FINISH_PAYMENT_FAILURE, error });
  }
}

// SAGAS
export const createFullTask = {
  creatingSagaListeners: function* () {
    yield takeEvery(ActionsEnum.ORDER_CREATE_START, createOrderOrLoadCurrent);
    yield takeEvery(ActionsEnum.CHANGE_ACTIVE_STEP, changeActiveStep);
    yield takeEvery(ActionsEnum.FETCH_CATEGORY, onCategoryFetch);
    yield takeEvery(ActionsEnum.SELECT_SUPER_CATEGORY, onSuperCategorySelect);
    yield takeEvery(ActionsEnum.UPLOAD_TASK, onTaskUpload);
    yield takeEvery(ActionsEnum.ADD_ADDRESS_TO_ORDER, onAddAddressToOrder);
    yield takeEvery(ActionsEnum.ADD_PROFY_TO_ORDER, onAddTaskerToOrder);
    yield takeEvery(ActionsEnum.FINISH_PAYMENT, onFinishOrder);
    yield takeEvery(ActionsEnum.LOAD_CURRENT_ORDER, onLoadCurrentOrder);
    yield takeEvery(ActionsEnum.SELECT_CATEGORY, onCategoryIndexSelect);
    yield takeEvery(ActionsEnum.GET_SUPER_CATEGORIES, onGetSuperCategories);
    yield takeEvery(ActionsEnum.GO_FIRST_STEP, onGoFirstStep);
    yield takeEvery(ActionsEnum.ADD_SUBSCRIPTION, onAddSubscription);
    yield takeEvery(ActionsEnum.REMOVE_SUBSCRIPTION, onRemoveSubscription);
  },
};
