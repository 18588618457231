import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { GreenButton } from '../../../components/buttons';

import styles from './CheckCard.module.scss';
import { paymentsActions, paymentsSelectors } from '../../../reducers/payments';
import { Loader } from '../../../components/ui';
import { createOrderActions } from '../../../reducers/createOrder';

class CheckCard extends Component {
  getStatusMessage = () => {
    const { checkCard, loadEditingOrder, t } = this.props;
    let message = '';

    if (checkCard && checkCard.status) {
      switch (checkCard.status) {
        case 'CREATED':
          message = t(
            "You haven't completed card registration. Please, try again",
          );
          break;

        case 'PENDING':
          message = t('cardCreateError');
          break;

        case 'FAILURE':
          message = t('cardCreateError');
          break;

        case 'FAILED':
          message = t('cardCreateError');
          break;

        case 'OK':
          loadEditingOrder();
          message = t('cardCreateSuccess');
          break;

        default:
          message = t('unknownStatus');
          break;
      }
    } else {
      message = t('cardCreateError');
    }

    return message;
  };

  render() {
    const { checkCardLoading, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('addCard')}</div>

        <Loader loading={checkCardLoading} height={160}>
          <div className={styles.text}>{this.getStatusMessage()}</div>

          <div className={styles.btnWrapper}>
            <GreenButton onClick={() => onClose()}>{t('OK')}</GreenButton>
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      checkCard: paymentsSelectors.getCheckCard(state),
      checkCardLoading: paymentsSelectors.getCheckCardLoading(state),
    }),
    {
      onDeleteCard: paymentsActions.deleteCardRequest,
      loadEditingOrder: createOrderActions.loadEditingOrder,
    },
  ),
)(CheckCard);
