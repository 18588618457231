import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getPathForOrders } from '../../routing/constants/pathHelpers';
import { GreenButton } from '../../../components/buttons';

import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { createOrderActions } from '../../../reducers/createOrder';

import styles from './ConfirmOrderDelete.module.scss';

class SubmitOrderError extends Component {
  render() {
    const { error, t } = this.props;
    return (
      <div className={styles.container} id="paymenterrorpopup">
        <div className={styles.title}>
          {!error ? t('orderIsAlreadyCreated') : ''}
        </div>
        <div className={styles.text}>
          {error ? error : t('itMightBeCreatedPreviouslyUsingOtherApplication')}
        </div>
        <div className={styles.btn}>
          <Link to={getPathForOrders()}>
            <GreenButton
              onClick={() => {
                // this.props.createEmptyOrder();
                this.props.onClose();
              }}
            >
              {t('returnToOrdersList')}
            </GreenButton>
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    createEmptyOrder: createOrderActions.createEmptyOrder,
  }),
)(SubmitOrderError);
