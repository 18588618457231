import {
  takeLatest,
  call,
  put,
  select,
  take,
  all,
  takeEvery,
} from 'redux-saga/effects';
import { formatMomentDate } from '../../utils/time';
import { replace } from 'connected-react-router';
import get from 'lodash/get';

import * as service from '../../services/taskersService';
import { getPathForPaymentPage } from '../routing/constants/pathHelpers';
import { dialogActions } from '../dialog/store';
import { createOrderActions } from '../../reducers/createOrder';
import { ActionsEnum } from 'containers/createTaskFullFlow/state/actions';

/* ACTION TYPES */

const MODULE_PREFIX = 'taskersNew/';

export const types = {
  LOAD_TASKERS_REQUEST: MODULE_PREFIX + 'LOAD_TASKERS_REQUEST',
  LOAD_TASKERS_SUCCESS: MODULE_PREFIX + 'LOAD_TASKERS_SUCCESS',
  LOAD_TASKERS_FAILURE: MODULE_PREFIX + 'LOAD_TASKERS_FAILURE',

  CHANGE_FILTER_DATE: MODULE_PREFIX + 'CHANGE_FILTER_DATE',

  CHANGE_FILTER_TYPE: MODULE_PREFIX + 'CHANGE_FILTER_TYPE',

  CLEAR_TASKERS_STATE: MODULE_PREFIX + 'CLEAR_TASKERS_STATE',

  OPEN_POPUP_AND_LOAD_TIMETABLE:
    MODULE_PREFIX + 'OPEN_POPUP_AND_LOAD_TIMETABLE',

  SWITCH_DETAILED_POPUP_ON_TIMETABLE:
    MODULE_PREFIX + 'SWITCH_DETAILED_POPUP_ON_TIMETABLE',

  LOAD_TASKER_TIMETABLE_SUCCESS:
    MODULE_PREFIX + 'LOAD_TASKER_TIMETABLE_SUCCESS',
  LOAD_TASKER_TIMETABLE_FAILURE:
    MODULE_PREFIX + 'LOAD_TASKER_TIMETABLE_FAILURE',

  OPEN_DETAILED_INFO_POPUP: MODULE_PREFIX + 'OPEN_DETAILED_INFO_POPUP',

  LOAD_TASKER_DETAILED_REQUEST: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_REQUEST',
  LOAD_TASKER_DETAILED_SUCCESS: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_SUCCESS',
  LOAD_TASKER_DETAILED_FAILURE: MODULE_PREFIX + 'LOAD_TASKER_DETAILED_FAILURE',

  LOAD_TASKER_REVIEWS_REQUEST: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_REQUEST',
  LOAD_TASKER_REVIEWS_SUCCESS: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_SUCCESS',
  LOAD_TASKER_REVIEWS_FAILURE: MODULE_PREFIX + 'LOAD_TASKER_REVIEWS_FAILURE',

  CLOSE_DETAILED_INFO_POPUP: MODULE_PREFIX + 'CLOSE_DETAILED_INFO_POPUP',

  SELECT_DATE_IN_TIMETABLE: MODULE_PREFIX + 'SELECT_DATE_IN_TIMETABLE',
  SELECT_TIME: MODULE_PREFIX + 'SELECT_TIME',

  CLOSE_TIMETABLE_POPUP: MODULE_PREFIX + 'CLOSE_TIMETABLE_POPUP',

  ATTACH_TASKER_REQUEST: MODULE_PREFIX + 'ATTACH_TASKER_REQUEST',
  ATTACH_TASKER_SUCCESS: MODULE_PREFIX + 'ATTACH_TASKER_SUCCESS',
  ATTACH_TASKER_FAILURE: MODULE_PREFIX + 'ATTACH_TASKER_FAILURE',

  GET_TASKERS_DAYS_REQUEST: MODULE_PREFIX + 'GET_TASKERS_DAYS_REQUEST',
  GET_TASKERS_DAYS_SUCCESS: MODULE_PREFIX + 'GET_TASKERS_DAYS_SUCCESS',
  GET_TASKERS_DAYS_FAILURE: MODULE_PREFIX + 'GET_TASKERS_DAYS_FAILURE',

  START_TASKER_CHANGE: MODULE_PREFIX + 'START_TASKER_CHANGE',
  FINISH_TASKER_CHANGE: MODULE_PREFIX + 'FINISH_TASKER_CHANGE',

  GLOBAL_CLEAR_COUNT_CHANGE: MODULE_PREFIX + 'GLOBAL_CLEAR_COUNT_CHANGE',

  TASKER_INFO_PAGE_CHANGE_REQUEST:
    MODULE_PREFIX + 'TASKER_INFO_PAGE_CHANGE_REQUEST',
  TASKER_INFO_PAGE_CHANGE_SUCCESS:
    MODULE_PREFIX + 'TASKER_INFO_PAGE_CHANGE_SUCCESS',
  TASKER_INFO_PAGE_CHANGE_FAILURE:
    MODULE_PREFIX + 'TASKER_INFO_PAGE_CHANGE_FAILURE',

  TASKER_DETAIL_REQUEST: MODULE_PREFIX + 'TASKER_DETAIL_REQUEST',
  TASKER_DETAIL_SUCCESS: MODULE_PREFIX + 'TASKER_DETAIL_SUCCESS',
  TASKER_DETAIL_FAILURE: MODULE_PREFIX + 'TASKER_DETAIL_FAILURE',
  GET_BANDELS: MODULE_PREFIX + 'GET_BANDELS',
  GET_BANDELS_SUCCESS: MODULE_PREFIX + 'GET_BANDELS_SUCCESS',
  UPDATE_SELECTED_TASKER: MODULE_PREFIX + 'UPDATE_SELECTED_TASKER'
};

/* INITIAL STATE */

const inititalTimetableState = {
  selectedTaskerId: null,
  timetableTasker: { user: {} },
  timetablePopupOpened: false,
  timetable: null,
  timetableLoading: false,
  selectedDateInTimetable: '',
  selectedTime: '',
  errors: null,
  taskersDays: null,
  taskersDaysLoading: false,
};

const taskerDetail = {
  taskerInfoPageIndex: -1,
  rating: null,
  comments: null,
};

const defaultInitialState = {
  taskers: null,
  taskersLoading: false,
  selectedTasker: {
    index: -1,
    id: null,
    unixDate: null,
    hourIndex: null,
    cleanCountIndex: null,

  },
  bandels: [],
  filterClearCountIndex: 0,
  // date: moment(),
  date: '',
  filterType: '&sort=recommended&order=desc',

  ...inititalTimetableState,
  ...taskerDetail,
};

const initialState = { ...defaultInitialState };

/* REDUCER */

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_TASKERS_REQUEST:
      return { ...state, taskersLoading: true };

    case types.LOAD_TASKERS_SUCCESS:
      return { ...state, taskers: action.taskers, taskersLoading: false };

    case types.LOAD_TASKERS_FAILURE:
      return { ...state, taskersLoading: false };

    case types.CHANGE_FILTER_DATE:
      return { ...state, date: action.date };

    case types.CHANGE_FILTER_TYPE:
      return { ...state, filterType: action.filterType };

    case types.OPEN_POPUP_AND_LOAD_TIMETABLE:
      return { ...state };

    case types.LOAD_TASKER_TIMETABLE_SUCCESS:
      return { ...state, timetable: action.timetable, timetableLoading: false };

    case types.LOAD_TASKER_TIMETABLE_FAILURE:
      return { ...state, timetableLoading: false };

    case types.CLOSE_TIMETABLE_POPUP:
      return { ...state, ...inititalTimetableState };

    case types.SELECT_DATE_IN_TIMETABLE:
      return {
        ...state,
        selectedDateInTimetable: action.date,
        selectedTime: null,
      };

    case types.SELECT_TIME:
      return { ...state, selectedTime: action.time };

    case types.OPEN_DETAILED_INFO_POPUP:
      return { ...state, taskerDetailedPopupOpened: true };

    case types.LOAD_TASKER_DETAILED_REQUEST:
      return { ...state, taskerDetailedLoading: true };

    case types.LOAD_TASKER_DETAILED_SUCCESS:
      return {
        ...state,
        taskerDetailed: action.taskerDetailed,
        taskerDetailedLoading: false,
      };

    case types.LOAD_TASKER_REVIEWS_REQUEST:
      return { ...state, reviewsLoading: true };

    case types.LOAD_TASKER_REVIEWS_SUCCESS:
      return { ...state, reviews: action.reviews, reviewsLoading: false };

    case types.ATTACH_TASKER_FAILURE:
      return { ...state, errors: get(action, 'error.errors.*') };

    case types.CLEAR_TASKERS_STATE:
      return initialState;

    case types.GET_TASKERS_DAYS_REQUEST:
      return { ...state, taskersDaysLoading: true };

    case types.GET_TASKERS_DAYS_SUCCESS:
      return {
        ...state,
        taskersDays: action.taskersDays,
        taskersDaysLoading: false,
      };

    case types.GET_TASKERS_DAYS_FAILURE:
      return { ...state, taskersDaysLoading: false };

    case types.FINISH_TASKER_CHANGE:
      return {
        ...state,
        selectedTasker: { ...state.selectedTasker, ...action.payload },
      };

    case types.GLOBAL_CLEAR_COUNT_CHANGE:
      return {
        ...state,
        filterClearCountIndex: action.payload,
        selectedTasker: { ...state.selectedTasker, index: -1 },
      };

    case types.TASKER_INFO_PAGE_CHANGE_SUCCESS:
      return { ...state, taskerInfoPageIndex: action.payload };

    case types.TASKER_DETAIL_SUCCESS:
      return { ...state, ...action.payload };
    case types.GET_BANDELS_SUCCESS:
      return {...state, bandels: action.bandels};
    case types.UPDATE_SELECTED_TASKER:
      return {...state, selectedTasker: { ...state.selectedTasker, cleanCountIndex: action.payload.index } }
    case ActionsEnum.FINISH_PAYMENT_SUCCESS:
      return {
        ...defaultInitialState,
      };

    default:
      return state;
  }
};

/* ACTION CREATORS */

const loadTaskersForOrder = (orderId) => {
  return {
    type: types.LOAD_TASKERS_REQUEST,
    orderId,
  };
};

const changeFilterDate = (date, orderId) => ({
  type: types.CHANGE_FILTER_DATE,
  date,
  orderId,
});

const changeFilterType = (filterType, orderId) => ({
  type: types.CHANGE_FILTER_TYPE,
  filterType,
  orderId,
});

const clearTaskersState = () => ({
  type: types.CLEAR_TASKERS_STATE,
});

const selectDateInTimetable = (date) => ({
  type: types.SELECT_DATE_IN_TIMETABLE,
  date,
});

const selectTime = (time) => ({
  type: types.SELECT_TIME,
  time,
});

const attachTasker = (order_id) => ({
  type: types.ATTACH_TASKER_REQUEST,
  order_id,
});

const onTaskerSelect = (payload) => ({
  type: types.START_TASKER_CHANGE,
  payload,
});
const onGlobalCleanCountChange = (payload) => ({
  type: types.GLOBAL_CLEAR_COUNT_CHANGE,
  payload,
});
const onTaskerInfoChange = (payload) => ({
  type: types.TASKER_INFO_PAGE_CHANGE_REQUEST,
  payload,
});
const getBandelsData = () => ({
  type: types.GET_BANDELS
});

const updateSelectedTasker = (payload) => ({
  type: types.UPDATE_SELECTED_TASKER,
  payload
});

export const taskersActions = {
  loadTaskersForOrder,
  changeFilterDate,
  changeFilterType,
  clearTaskersState,
  selectDateInTimetable,
  selectTime,
  attachTasker,
  onTaskerSelect,
  onGlobalCleanCountChange,
  onTaskerInfoChange,
  getBandelsData,
  updateSelectedTasker
};

/* SELECTORS */

export const taskersSelectors = {
  getTaskers: (state) => state.createOrderTaskersNew.taskers,

  getTaskersLoading: (state) => state.createOrderTaskersNew.taskersLoading,

  getFilterDate: (state) => state.createOrderTaskersNew.date,

  getFilterType: (state) => state.createOrderTaskersNew.filterType,

  getSelectedTaskerId: (state) => state.createOrderTaskersNew.selectedTaskerId,

  getTimeTableTasker: (state) => state.createOrderTaskersNew.timetableTasker,

  getTimetable: (state) => state.createOrderTaskersNew.timetable,

  getTimetableLoading: (state) => state.createOrderTaskersNew.timetableLoading,

  getTimetablePopupOpened: (state) =>
    state.createOrderTaskersNew.timetablePopupOpened,

  getSelectedDateInTimetable: (state) =>
    state.createOrderTaskersNew.selectedDateInTimetable,

  getSelectedTime: (state) => state.createOrderTaskersNew.selectedTime,

  getDetailedtasker: (state) => state.createOrderTaskersNew.taskerDetailed,

  getDetailedtaskerLoading: (state) =>
    state.createOrderTaskersNew.taskerDetailedLoading,

  getTaskerReviews: (state) => state.createOrderTaskersNew.reviews,

  getTaskerReviewsLoading: (state) =>
    state.createOrderTaskersNew.reviewsLoading,

  getDetailedTaskerPopupOpened: (state) =>
    state.createOrderTaskersNew.taskerDetailedPopupOpened,

  getErrors: (state) => state.createOrderTaskersNew.errors,

  getTaskersDays: (state) => state.createOrderTaskersNew.taskersDays,

  getTaskersDaysLoading: (state) =>
    state.createOrderTaskersNew.taskersDaysLoading,

  getSelectedTasker: (state) => state.createOrderTaskersNew.selectedTasker,

  filterClearCountIndex: (state) =>
    state.createOrderTaskersNew.filterClearCountIndex,

  taskerInfoPageIndex: (state) =>
    state.createOrderTaskersNew.taskerInfoPageIndex,

  rating: (state) => state.createOrderTaskersNew.rating,

  comments: (state) => state.createOrderTaskersNew.comments,

  bandelsDatas: (state) => state.createOrderTaskersNew.bandels,

};

/* SAGAS */
function* onOrderErrors(error) {
  if (error.code && error.code === 'ORDER_IS_ALREADY_CREATED') {
    yield put(dialogActions.openSubmitOrderErrorPopup());
  }
}

function* onTaskersLoad({ orderId }) {
  try {
    // const date = yield select(taskersSelectors.getFilterDate);
    const filterType = yield select(taskersSelectors.getFilterType);

    // let formattedDate = yield call(formatMomentDate, date);

    // if (!formattedDate) formattedDate = '';

    // const { collection } = yield call(
    //   service.getTaskersForOrder,
    //   orderId,
    //   formattedDate,
    //   filterType,
    // );
    const { collection } = yield call(
      service.getTaskersForOrderWithDate,
      orderId,
      filterType,
    );

    yield put({ type: types.LOAD_TASKERS_SUCCESS, taskers: collection });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKERS_FAILURE, error });
  }
}

function* onTimetableLoad({ orderId, tasker }) {
  try {
    const timetable = yield call(
      service.getTaskerTimeTable,
      orderId,
      tasker.user.id,
    );
    yield put({ type: types.LOAD_TASKER_TIMETABLE_SUCCESS, timetable });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_TIMETABLE_FAILURE, error });
  }
}

function* onTaskerAttach({ order_id }) {
  try {
    const formattedDate = yield call(
      formatMomentDate,
      yield select(taskersSelectors.getSelectedDateInTimetable),
    );

    const { value } = yield select(taskersSelectors.getSelectedTime);

    const data = yield call(service.attachTasker, {
      order_id,
      tasker_id: yield select(taskersSelectors.getSelectedTaskerId),
      date: formattedDate,
      time: value,
    });

    yield put({ type: types.ATTACH_TASKER_SUCCESS, ...data });
    yield put(createOrderActions.loadEditingOrder());
    yield put(replace(getPathForPaymentPage(order_id)));
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.ATTACH_TASKER_FAILURE, error });
  }
}

function* onTaskerDetailedLoad({ taskerId }) {
  try {
    const { model } = yield call(service.getTaskerDetailed, taskerId);
    yield put({
      type: types.LOAD_TASKER_DETAILED_SUCCESS,
      taskerDetailed: model,
    });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_DETAILED_FAILURE });
  }
}

function* onTaskerReviewLoad({ taskerId }) {
  try {
    const { collection } = yield call(
      service.loadTaskerReviews,
      taskerId,
      0,
      50,
    );
    yield put({ type: types.LOAD_TASKER_REVIEWS_SUCCESS, reviews: collection });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.LOAD_TASKER_REVIEWS_FAILURE, error });
  }
}

function* onTaskersDaysLoad() {
  try {
    const { max_order_days } = yield call(service.getTaskersDays);
    yield put({
      type: types.GET_TASKERS_DAYS_SUCCESS,
      taskersDays: max_order_days,
    });
  } catch (error) {
    if (error && error.errors && error.errors['*']) {
      const errors = error.errors['*'];
      yield errors.map(onOrderErrors);
    } else if (
      error &&
      error.error ===
        'Order not found. It might be deleted previously using other application.'
    ) {
      yield put(dialogActions.openDeleteOrderErrorPopup());
    }

    yield put({ type: types.GET_TASKERS_DAYS_FAILURE, error });
  }
}

function* onTaskerSelectSaga({ payload }) {
  yield put({ type: types.FINISH_TASKER_CHANGE, payload });
}

function* onTaskerInfoPage({ payload }) {
  yield put({ type: types.TASKER_INFO_PAGE_CHANGE_SUCCESS, payload });
  if (payload === -1) return;

  const taskers = yield select(taskersSelectors.getTaskers);

  const {
    user: { id },
  } = taskers[payload];

  try {
    const [rating, comments] = yield all([
      call(service.getTaskerDetailed, id),
      call(service.loadTaskerReviews, id),
    ]);

    yield put({
      type: types.TASKER_DETAIL_SUCCESS,
      payload: { rating, comments },
    });
  } catch (error) {
    yield put({ type: types.TASKER_DETAIL_FAILURE, payload });
  }
}

export const taskersSagasNew = {

  watchGetBandels: function* (){
    try{
      yield take(types.GET_BANDELS)
      const bandels = yield call(service.bandels);
      yield put({
        type: types.GET_BANDELS_SUCCESS,
        bandels: bandels
      });
      }catch(error){
        console.log({'error': error})
      }
  },

  watchUpdateSelectedTasker: function* (){
    try{
      const info = yield take(types.UPDATE_SELECTED_TASKER)

      // let currentTaskerInformation = yield select(taskersSelectors.getSelectedTasker)

      // console.log(currentTaskerInformation, 'this is info currentTasker info')
    }catch(error){
      console.log({'error': error})
    }
  },

  
  watchTaskersLoad: function* () {
    yield takeLatest(types.LOAD_TASKERS_REQUEST, onTaskersLoad);
  },

  watchFilterDateChange: function* () {
    while (true) {
      const { orderId } = yield take(types.CHANGE_FILTER_DATE);
      yield put(loadTaskersForOrder(orderId));
    }
  },

  watchFilterTypeChange: function* () {
    while (true) {
      const { orderId } = yield take(types.CHANGE_FILTER_TYPE);
      yield put(loadTaskersForOrder(orderId));
    }
  },

  watchTimetableLoad: function* () {
    yield takeLatest(types.OPEN_POPUP_AND_LOAD_TIMETABLE, onTimetableLoad);
  },

  watchTaskerAttach: function* () {
    yield takeLatest(types.ATTACH_TASKER_REQUEST, onTaskerAttach);
  },

  watchTaskerDetailedLoad: function* () {
    yield takeLatest(types.LOAD_TASKER_DETAILED_REQUEST, onTaskerDetailedLoad);
  },

  watchTaskerReviewsLoad: function* () {
    yield takeLatest(types.LOAD_TASKER_REVIEWS_REQUEST, onTaskerReviewLoad);
  },

  watchTasks: function* () {
    yield takeEvery(types.GET_TASKERS_DAYS_REQUEST, onTaskersDaysLoad);
    yield takeEvery(types.START_TASKER_CHANGE, onTaskerSelectSaga);
    yield takeEvery(types.TASKER_INFO_PAGE_CHANGE_REQUEST, onTaskerInfoPage);
  },
};
