import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { withReduxFormProps } from '../../../containers/hoc';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-webpack-loader-syntax
// import '!style-loader!css-loader!./customDataPickerNew.module.scss';
import './customDataPickerNew.scss';

class InlineDatePicker extends Component {
  state = {
    focused: this.props.focused || false,
  };

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          right: '24px',
          width: '200px',
          boxShadow: '0px 0px 20px #0000001A',
          height: '410px',
          backgroundColor: '#ffffff',
          borderRadius: 10,
        }}
      >
        <DayPickerSingleDateController
          date={this.props.value || null}
          onDateChange={this.props.onChange}
          focused={this.state.focused}
          onFocusChange={this.onFocusChange}
          isOutsideRange={this.props.isOutsideRange}
          daySize={this.props.daySize}
          noBorder={true}
          verticalBorderSpacing={0}
        />
      </div>
    );
  }
}

InlineDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
  numberOfMonths: PropTypes.number,
};

InlineDatePicker.defaultProps = {
  numberOfMonths: 1,
};

export default compose(withTranslation(), withReduxFormProps)(InlineDatePicker);
