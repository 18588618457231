import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { PinkButtonTransparent } from '../../../components/buttons';
import { getPathForEditOrder } from '../../routing/constants/pathHelpers';
import { Lari } from '../../../components/ui';

import styles from './PaymentTasksList.module.scss';

class PaymentTasksList extends Component {
  renderPhantomTask = (task) => {
    return (
      <div key={task.label} className={styles.item}>
        <div className={styles.itemTitle}>
          {task.quantity && <span>{`(x${task.quantity}) `}</span>}

          {task.label}
        </div>
        <div className={styles.itemCost}>
          {task.value}
          <Lari />
        </div>
      </div>
    );
  };

  renderTask = (task) => {
    return (
      <div key={task.id} className={styles.item}>
        <div className={styles.itemTitle}>
          <span>{`(x${task.quantity}) `}</span>
          {task.service.name}
        </div>
        <div className={styles.itemCost}>
          {task.price}
          <Lari />
        </div>
      </div>
    );
  };

  render() {
    const { tasks, order, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('orderList')}</div>

        <div className={styles.btn}>
          <PinkButtonTransparent
            onClick={() =>
              this.props.detachTaskerAndRedirect(
                order.id,
                getPathForEditOrder(order.id),
              )
            }
          >
            {t('changeCapital')}
          </PinkButtonTransparent>
        </div>

        <div className={styles.items}>
          {order.additional_services &&
            order.additional_services.map(this.renderPhantomTask)}

          {tasks.map(this.renderTask)}
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      tasks: createOrderSelectors.getTasks(state),
      order: createOrderSelectors.getOrder(state),
    }),
    createOrderActions,
  ),
)(PaymentTasksList);
