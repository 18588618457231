import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { createTaskActions, createTaskSelectors } from './store';
import CreateTaskBackButton from './components/CreateTaskBackButton';

import styles from './QuestionPage.module.scss';
import Loader from '../../components/ui/Loader';
import { createOrderSelectors } from '../../reducers/createOrder';

interface ChooseCategoryProps extends RouteComponentProps {
  order: any;
  answersLoading: boolean;
  selectedFromQueryString: boolean;
  orderId: number | string;
  answerQuestion: (answer: string, order: number | string) => void;
}

class QuestionPage extends PureComponent<ChooseCategoryProps> {
  render() {
    const {
      question,
      answerQuestion,
      answersLoading,
      answers,
      orderId,
      t,
      selectedFromQueryString,
    } = this.props;

    return (
      <div className={styles.flexRow}>
        <div className={styles.questionPage}>
          <Helmet>
            <title>{t('taskQuestions')}</title>
          </Helmet>

          <div className={styles.title}>{question}</div>

          <div className={styles.answers}>
            <Loader loading={answersLoading} height={114}>
              {answers.map((answer) => (
                <div
                  className={styles.answer}
                  key={answer.answer}
                  onClick={() => {
                    window.scroll(0, 0);
                    answerQuestion(answer.answer, orderId);
                  }}
                >
                  {answer.answer_image ? (
                    <img
                      className={styles.answerImage}
                      src={answer.answer_image}
                      alt=" "
                    />
                  ) : null}
                  {answer.answer}
                </div>
              ))}
            </Loader>
          </div>
          {!selectedFromQueryString && (
            <div className={styles.btnWrapper}>
              <CreateTaskBackButton />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  question: createTaskSelectors.getCurrentQuestion(state),
  answers: createTaskSelectors.getCurrentAnswers(state) || [],
  selectedFromQueryString: createTaskSelectors.getIfStepSelectedFromQueryString(
    state,
  ),
  answersLoading: createTaskSelectors.getAllAnswersLoading(state),
  orderId: createOrderSelectors.getOrderId(state),
});

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, createTaskActions),
)(QuestionPage);
