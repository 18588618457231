import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Popup } from '../../../components/ui';
import { PinkButton } from '../../../components/buttons';
import { Tasker } from '../../../components/userViews/tasker';
import { Reviews } from '../../../components/reviews';
import { taskersSelectors, taskersActions } from '../store';

import styles from './TaskerDetailedInfo.module.scss';
import Loader from '../../../components/ui/Loader';

class TaskerDetailedInfo extends Component {
  render() {
    const {
      taskerLoading,
      detailedPopupOpened,
      tasker,
      reviewsLoading,
      reviews,
      orderId,
      t,
    } = this.props;
    return (
      <Popup
        width={'900'}
        isOpen={detailedPopupOpened}
        onClose={() => this.props.closeDetailedInfoPopup()}
      >
        <div className={styles.container}>
          <Loader loading={taskerLoading} height={343}>
            <div
              className={classNames(styles.taskerInfo, {
                [styles.taskerInfoNoReviews]: !reviews || reviews.length === 0,
              })}
            >
              <Tasker
                user={tasker}
                descriptionTitle={t('aboutMeAllCapital')}
                since={tasker.registered_at}
                badge
                achievements
                detailed
              />

              <PinkButton
                medium
                minWidth={'125'}
                onClick={() =>
                  this.props.switchDetailedPopupOnTimetable(orderId, tasker.id)
                }
              >
                {t('seeAvailability')}
              </PinkButton>
            </div>

            <Loader loading={reviewsLoading}>
              <Reviews reviews={reviews} category />
            </Loader>
          </Loader>
        </div>
      </Popup>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      detailedPopupOpened: taskersSelectors.getDetailedTaskerPopupOpened(state),
      tasker: taskersSelectors.getDetailedtasker(state),
      taskerLoading: taskersSelectors.getDetailedtaskerLoading(state),
      reviews: taskersSelectors.getTaskerReviews(state),
      reviewsLoading: taskersSelectors.getTaskerReviewsLoading(state),
    }),
    taskersActions,
  ),
)(TaskerDetailedInfo);
