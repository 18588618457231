const MODULE_PREFIX = 'FOOTER/';

export const footerActionTypes = {
  FOOTER_SITE_LINKS_REQUEST: MODULE_PREFIX + 'FOOTER_SITE_LINKS_REQUEST',
  FOOTER_SITE_LINKS_SUCCESS: MODULE_PREFIX + 'FOOTER_SITE_LINKS_SUCCESS',
  FOOTER_SITE_LINKS_FAILURE: MODULE_PREFIX + 'FOOTER_SITE_LINKS_FAILURE',

  FOOTER_SOCIAL_LINKS_REQUEST: MODULE_PREFIX + 'FOOTER_SOCIAL_LINKS_REQUEST',
  FOOTER_SOCIAL_LINKS_SUCCESS: MODULE_PREFIX + 'FOOTER_SOCIAL_LINKS_SUCCESS',
  FOOTER_SOCIAL_LINKS_FAILURE: MODULE_PREFIX + 'FOOTER_SOCIAL_LINKS_FAILURE',
};

const initialState = {
  siteLinks: null,
  siteLinksLoader: false,

  socialLinks: null,
  socialLinksLoader: false,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case footerActionTypes.FOOTER_SITE_LINKS_REQUEST:
      return {
        ...state,
        siteLinksLoader: true,
      };

    case footerActionTypes.FOOTER_SITE_LINKS_SUCCESS:
      return {
        ...state,
        siteLinks: payload.siteLinks,
        siteLinksLoader: false,
      };

    case footerActionTypes.FOOTER_SITE_LINKS_FAILURE:
      return {
        ...state,
        siteLinksLoader: false,
        error: action.error,
      };

    case footerActionTypes.FOOTER_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        socialLinksLoader: true,
      };

    case footerActionTypes.FOOTER_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialLinks: payload.socialLinks,
        socialLinksLoader: false,
      };

    case footerActionTypes.FOOTER_SOCIAL_LINKS_FAILURE:
      return {
        ...state,
        socialLinksLoader: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const footerActions = {
  getFooterSiteLinks: () => ({
    type: footerActionTypes.FOOTER_SITE_LINKS_REQUEST,
  }),
  getFooterSocialLinks: () => ({
    type: footerActionTypes.FOOTER_SOCIAL_LINKS_REQUEST,
  }),
};

export const footerSelectors = {
  siteLinks: (state) => state.footer.siteLinks,
  siteLinksLoader: (state) => state.footer.siteLinksLoader,
  socialLinks: (state) => state.footer.socialLinks,
  socialLinksLoader: (state) => state.footer.socialLinksLoader,
};
