import PaymentCardsList from 'containers/settings/components/PaymentCardsList';
import { cloneDeep, lte } from 'lodash';
import { DEFAULT_PAGINATION_DATA } from '../helper/const';
import { ActionsEnum } from './actions';

/* INITIAL STATE */
type InitialStateType = {
  activeStep: number;
  progressLevel: number;
  taskFlow: GetTaskTreeResponseType[];
  taskFlow0: any[];
  taskFlow1: any[];
  taskFlow2: any[];
  emptyOrder: EmptyOrderType | null;
  hasCurrentOrder: boolean;
  currentOrder: CurrentOrderType | null;
  paginationData: typeof DEFAULT_PAGINATION_DATA;
  selectedSuperCategoryIndex: number;
  selectedServices: any[];
  leftSideInfo: {
    title: string;
    description: string;
    image: string;
  } | null;
  tasks: OrderTasksResponseType | null;
  superCategories: GetSuperCategoriesResponseType | null;
  selectedCategoryIndex: number;
  isQuestionStep: boolean;
};
const defaultInitialState: InitialStateType = {
  activeStep: 0,
  progressLevel: 0,
  taskFlow: [],
  taskFlow0: [],
  taskFlow1: [],
  taskFlow2: [],
  emptyOrder: null,
  paginationData: DEFAULT_PAGINATION_DATA,
  selectedSuperCategoryIndex: -1,
  selectedServices: [],
  leftSideInfo: null,
  hasCurrentOrder: false,
  currentOrder: null,
  tasks: null,
  superCategories: null,
  selectedCategoryIndex: -1,
  isQuestionStep: false,
};

const initialState: InitialStateType = cloneDeep(defaultInitialState);

export type AppPayload = {
  [ActionsEnum.CHANGE_ACTIVE_STEP]: number;
  [ActionsEnum.ORDER_CREATE_START]: undefined;
  [ActionsEnum.ORDER_CREATE_SUCCESS]: {
    emptyOrder: EmptyOrderType;
    hasCurrentOrder: boolean;
    currentOrder: CurrentOrderType;
    superCategories: GetSuperCategoriesResponseType;
    taskFlow: GetTaskTreeResponseType[];
  };
  [ActionsEnum.ORDER_CREATE_FAILURE]: number;
  [ActionsEnum.FETCH_CATEGORY_SUCCESS]: {
    taskFlow: GetTaskTreeResponseType;
    index: number;
  };
  [ActionsEnum.SELECT_SUPER_CATEGORY_SUCCESS]: {
    index: number;
  };
  [ActionsEnum.ADD_PROFY_TO_ORDER_SUCCESS]: {
    currentOrder: CurrentOrderType;
    tasks: OrderTasksResponseType;
  };
  [ActionsEnum.FINISH_PAYMENT_SUCCESS]: undefined;
  [ActionsEnum.SELECT_CATEGORY_SUCCESS]: {
    selectedSuperCategoryIndex: number;
    selectedCategoryIndex: number;
  };
  [ActionsEnum.LOAD_CURRENT_ORDER_SUCCESS]: {
    currentOrder: CurrentOrderType;
    hasCurrentOrder: boolean;
  };
  [ActionsEnum.GO_FIRST_STEP]: boolean;
  [ActionsEnum.CHANGE_QUESTION_STEP_STATUS]: boolean;
  [ActionsEnum.ADD_SUBSCRIPTION]: CurrentOrderType;
  [ActionsEnum.ADD_SUBSCRIPTION_SUCCESS]: CurrentOrderType;
  [ActionsEnum.REMOVE_SUBSCRIPTION]: CurrentOrderType;
  [ActionsEnum.REMOVE_SUBSCRIPTION_SUCCESS]: CurrentOrderType;
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

/* REDUCER */
export default (state = initialState, action: AppActions): InitialStateType => {
  switch (action.type) {
    case ActionsEnum.CHANGE_ACTIVE_STEP: {
      const activeStep = action.payload;

      if (action.payload === 0 || action.payload === 9) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        activeStep: activeStep,
        progressLevel:
          activeStep !== 0 ? Math.max(activeStep, state.progressLevel) : 0,
      };
    }
    case ActionsEnum.ORDER_CREATE_SUCCESS:
      return {
        ...cloneDeep(defaultInitialState),
        ...action.payload,
      };
    case ActionsEnum.ORDER_CREATE_FAILURE:
      return {
        ...state,
      };
    case ActionsEnum.FETCH_CATEGORY_SUCCESS: {
      switch (action.payload.index) {
        case 0:
          if (state.taskFlow1.length == 0 || state.taskFlow2.length == 0) {
            return {
              ...state,
              taskFlow0: action.payload.taskFlow,
            };
          } else {
            const finalTaskFlow: any[] = [];

            finalTaskFlow.splice(0, 1, action.payload.taskFlow);
            finalTaskFlow.splice(1, 1, state.taskFlow1);
            finalTaskFlow.splice(2, 1, state.taskFlow2);

            return {
              ...state,
              taskFlow: finalTaskFlow,
            };
          }
        case 1:
          if (state.taskFlow0.length == 0 || state.taskFlow2.length == 0) {
            return {
              ...state,
              taskFlow1: action.payload.taskFlow,
            };
          } else {
            const finalTaskFlow: any[] = [];

            finalTaskFlow.splice(0, 1, state.taskFlow0);
            finalTaskFlow.splice(1, 1, action.payload.taskFlow);
            finalTaskFlow.splice(2, 1, state.taskFlow2);

            return {
              ...state,
              taskFlow: finalTaskFlow,
            };
          }
        case 2:
          if (state.taskFlow0.length == 0 || state.taskFlow1.length == 0) {
            return {
              ...state,
              taskFlow2: action.payload.taskFlow,
            };
          } else {
            const finalTaskFlow: any[] = [];

            finalTaskFlow.splice(0, 1, state.taskFlow0);
            finalTaskFlow.splice(1, 1, state.taskFlow1);
            finalTaskFlow.splice(2, 1, action.payload.taskFlow);

            return {
              ...state,
              taskFlow: finalTaskFlow,
            };
          }
        default:
          return {
            ...state,
          };
      }
    }
    case ActionsEnum.SELECT_SUPER_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedSuperCategoryIndex: action.payload.index,
      };
    case ActionsEnum.ADD_PROFY_TO_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionsEnum.LOAD_CURRENT_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionsEnum.SELECT_CATEGORY_SUCCESS: {
      let updatedPaginationData = cloneDeep(state.paginationData);
      updatedPaginationData[0].answer =
        state.taskFlow?.[action.payload.selectedSuperCategoryIndex]?.[
          action.payload.selectedCategoryIndex
        ].model.name ?? '';

      return {
        ...state,
        selectedCategoryIndex: action.payload.selectedCategoryIndex,
        paginationData: updatedPaginationData,
      };
    }
    case ActionsEnum.FINISH_PAYMENT_SUCCESS:
      return {
        ...cloneDeep(defaultInitialState),
      };

    case ActionsEnum.CHANGE_QUESTION_STEP_STATUS:
      return {
        ...state,
        isQuestionStep: action.payload,
      };
    case ActionsEnum.ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentOrder: action.payload,
      };
    case ActionsEnum.REMOVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentOrder: action.payload,
      };
    default:
      return state;
  }
};
