import React, { Component } from 'react';
import CreateComplaint from '../../support/CreateComplaint';
import { orderActions, orderSelectors } from '../../../reducers/order';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Loader } from '../../../components/ui';

class Support extends Component {
  getOrderId = () => {
    return this.props.match.params.id;
  };

  getOrderStatus = () => {
    const { order } = this.props;

    if (order.on_warranty) {
      return 'ORDER_ON_WARRANTY';
    }

    return order.status;
  };

  render() {
    const { order, orderLoading } = this.props;

    return (
      <Loader loading={orderLoading}>
        <CreateComplaint screen={this.getOrderStatus()} orderId={order.id} />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  order: orderSelectors.getOrder(state),
  orderLoading: orderSelectors.getOrderLoading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    loadOrder: orderActions.loadOrder,
  }),
  withRouter,
)(Support);
