import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Loader, PageContent } from '../../components/ui';
import PaymentTasker from './components/PaymentTasker';
import PaymentTasksList from './components/PaymentTasksList';
import PaymentReceipt from './components/PaymentReceipt';
import PaymentInfo from './components/PaymentInfo';
import { PinkBorderButton } from '../../components/buttons';
import { GreenButton } from '../../components/buttons';
import { dialogActions, dialogSelectors } from '../dialog/store';
import { withAuthData, withCurrentOrder } from '../hoc';
import { actions } from '../../reducers/signup';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../reducers/createOrder';

import styles from './CreateOrderPayment.module.scss';
import PaymentPromoCode from './components/PaymentPromoCode';
import PaymentCardSelect from './components/PaymentCardSelect';
import { getPathForOrderTaskers } from '../routing/constants/pathHelpers';
import { userActions } from '../../reducers/user';

class CreateOrderPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsToPaid: null,
      cardModalOpen: false,
    };

    this.timer = null;
    this.cardModalOpen = false;
    this.waitToOpenTimeout = false;
  }

  componentDidMount() {
    this.props.loadEditingOrder();

    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderLoading && !this.props.orderLoading) {
      if (
        !prevProps.order &&
        this.props.order &&
        this.props.order.seconds_to_paid
      ) {
        if (!this.timer) this.startTimer();
      } else if (
        prevProps.order &&
        this.props.order &&
        prevProps.order.seconds_to_paid !== this.props.seconds_to_paid
      ) {
        if (!this.timer) this.startTimer();
      }
    }

    if (this.props.dialogState.dialogType === 'CHECK_CARD') {
      this.cardModalOpen = true;
    }

    if (
      prevProps.dialogState.dialogType === 'CHECK_CARD' &&
      !this.props.dialogState.dialogType
    ) {
      this.cardModalOpen = false;

      if (this.waitToOpenTimeout) {
        this.clearTimer();
      }
    }
  }

  startTimer = () => {
    localStorage.setItem('onCheckoutOrder', this.props.order.id);

    this.timer = setInterval(() => {
      if (
        this.state.secondsToPaid === null &&
        this.props.order &&
        this.props.order.seconds_to_paid > 15
      ) {
        this.setState({
          secondsToPaid: this.props.order.seconds_to_paid,
        });
      } else if (this.state.secondsToPaid > 15) {
        this.setState({
          secondsToPaid: this.state.secondsToPaid - 1,
        });
      } else {
        this.clearTimer();
      }
    }, 1000);
  };

  clearTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.setState({
      secondsToPaid: null,
    });

    if (!this.cardModalOpen) {
      localStorage.setItem('onCheckoutOrder', null);

      this.props.openSubmitOrderErrorTimeout();
      this.props.detachTaskerAndRedirect(
        this.props.order.id,
        getPathForOrderTaskers(),
      );
      this.props.loadEditingOrder();
    } else {
      this.waitToOpenTimeout = true;
    }
  };

  onCheckout = () => {
    const { userLoggedIn, order } = this.props;
    if (userLoggedIn) {
      if (order && order.card && order.card.id) {
        this.props.submitOrder();
      } else {
        this.props.openSubmitOrderErrorPaymentMethodPopup();
      }
    } else {
      this.props.openSignInPopup();
    }
  };

  render() {
    const { orderLoading, order, t } = this.props;

    return (
      <PageContent title={t('orders')}>
        <Helmet>
          <title>{t('orderPaymentInfo')}</title>
        </Helmet>
        <div className={styles.container}>
          <Loader loading={orderLoading} height={1000}>
            <PaymentTasker />

            <PaymentInfo order={order} />

            <PaymentTasksList />

            <PaymentPromoCode />

            <PaymentReceipt />

            <PaymentCardSelect />

            <div className={styles.btnWrapper}>
              <div className={styles.timer}>
                {this.state.secondsToPaid > 59
                  ? `${t('timerStart')} ${Math.floor(
                      this.state.secondsToPaid / 60,
                    )} ${t('timerEnd')}`
                  : t('timeLessMinute')}
              </div>

              <div className={styles.btn}>
                <PinkBorderButton
                  minWidth={'125'}
                  onClick={() => this.props.openDeleteOrderDialog(order)}
                >
                  {t('cancel')}
                </PinkBorderButton>
              </div>

              <div className={styles.btn}>
                <GreenButton minWidth={'125'} onClick={this.onCheckout}>
                  {t('checkOut')}
                </GreenButton>
              </div>
            </div>
          </Loader>
        </div>
      </PageContent>
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}

CreateOrderPayment.propTypes = {};

export default compose(
  withTranslation(),
  withCurrentOrder,
  connect(
    (state) => ({
      order: createOrderSelectors.getOrder(state),
      orderLoading: createOrderSelectors.getOrderLoading(state),
      dialogState: dialogSelectors.getDialogState(state),
    }),
    {
      ...createOrderActions,
      ...actions,
      openDeleteOrderDialog: dialogActions.openDeleteOrderDialog,
      openSubmitOrderErrorTimeout: dialogActions.openSubmitOrderErrorTimeout,
      openSubmitOrderErrorPaymentMethodPopup:
        dialogActions.openSubmitOrderErrorPaymentMethodPopup,
      openSignInPopup: userActions.openSignInPopup,
      replace,
    },
  ),
  withAuthData,
)(CreateOrderPayment);
