import React from 'react';
import classNames from 'classnames';
import styles from './DefaultTextInput.module.scss';
import { withReduxFormProps } from '../../../containers/hoc';

const DefaultTextInput = (props) => (
  <input
    ref={props.inputref}
    inputref={''}
    type={props.type || 'text'}
    value={props.value}
    placeholder={props.placeholder}
    onChange={props.onChange}
    maxLength={props.maxlength}
    max={props.max}
    min={props.min}
    onFocus={props.onFocus}
    onBlur={props.onBlur}
    readOnly={props.readOnly}
    autoFocus={props.autoFocus}
    className={classNames([props.className], {
      [styles.inputNumber]: props.type === 'number',
    })}
  />
);

export default withReduxFormProps(DefaultTextInput);
