import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ProfyFeedback.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootReduxState } from 'store';
import { userSelectors } from 'reducers/user';
import { Helmet } from 'react-helmet';
import { Loader } from 'components/ui';
import { orderActions, orderSelectors } from 'reducers/order';
import { ordersSelectors } from 'reducers/ordersList';
import ReviewForm from 'containers/order/components/ReviewForm';

type InviteUserPropsType = {
  onClose: () => void;
  orderId: number;
};

const InviteUser = ({ onClose, orderId }: InviteUserPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { orderLoading } = useSelector((state: RootReduxState) => ({
    profile: userSelectors.selectUserProfile(state),
    profileLoading: userSelectors.selectUserProfileLoading(state),
    orderLoading: orderSelectors.getOrderLoading(state),
    completedOrdersLoading: ordersSelectors.getCompletedOrdersLoading(state),
  }));

  useEffect(() => {
    dispatch(orderActions.loadOrder(orderId));
  }, [dispatch, orderId]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{t('inviteFriends')}</title>
      </Helmet>
      <Loader loading={orderLoading}>
        <ReviewForm onClose={onClose} />
      </Loader>
    </div>
  );
};
export default InviteUser;
