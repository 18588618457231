import React from 'react';
import { Svg } from 'components/ui';

import styles from './NextButton.module.scss';

type NextButtonPropsType = {
  text: string;
  onClick: () => void;
  showErrow: boolean;
};
const NextButton = ({ text, onClick, showErrow }: NextButtonPropsType) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <div className={styles.textContainer}>
        <p>{text}</p>
      </div>
      {showErrow && (
        <div className={styles.stepContainer}>
          <Svg src={'arrow-right-new'} className={styles.svg} />
        </div>
      )}
    </button>
  );
};

export default NextButton;
