import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import styles from './Svg.module.scss';

type SvgPropsType = {
  src: string;
} & React.HTMLAttributes<HTMLDivElement>;
const Svg = ({ className, src, ...props }: SvgPropsType) => {
  return (
    <div
      className={classNames(styles.inlineSvg, [className])}
      {...props}
      style={src === 'canceled' ? { backgroundColor: '#db0a77' } : {}}
    >
      <SVG src={require('../../assets/images/inline/' + src + '.svg')} />
    </div>
  );
};

Svg.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};

export default Svg;
