import { createOrderActions } from './createOrder';
import { userActions } from './user';
import { ordersActions } from './ordersList';
import { wsActions } from '../sagas/websocketSaga';
import { chatActions } from './chat';

export const onLoadActions = [
  userActions.checkUserLoggedIn,
  userActions.getServerTime,
];

export const onAuthorizedLoadActions = [
  createOrderActions.loadEditingOrder,
  userActions.getUserProfile,
  ordersActions.loadCompletedOrders,
  chatActions.getChatList,
  wsActions.connectChatSocket,
];
