const MODULE_PREFIX = 'notificationsList/';

export const notificationsListActionTypes = {
  NOTIFICATIONS_LIST_SHOW: MODULE_PREFIX + 'NOTIFICATIONS_LIST_SHOW',
  NOTIFICATIONS_LIST_SHOW_SUCCESS:
    MODULE_PREFIX + 'NOTIFICATIONS_LIST_SHOW_SUCCESS',

  NOTIFICATIONS_LIST_HIDE: MODULE_PREFIX + 'NOTIFICATIONS_LIST_HIDE',
  NOTIFICATIONS_LIST_HIDE_SUCCESS:
    MODULE_PREFIX + 'NOTIFICATIONS_LIST_HIDE_SUCCESS',

  GET_NOTIFICATIONS_LIST_REQUEST:
    MODULE_PREFIX + 'GET_NOTIFICATIONS_LIST_REQUEST',
  GET_NOTIFICATIONS_LIST_SUCCESS:
    MODULE_PREFIX + 'GET_NOTIFICATIONS_LIST_SUCCESS',
  GET_NOTIFICATIONS_LIST_FAILURE:
    MODULE_PREFIX + 'GET_NOTIFICATIONS_LIST_FAILURE',

  UPDATE_NOTIFICATIONS_LIST_REQUEST:
    MODULE_PREFIX + 'UPDATE_NOTIFICATIONS_LIST_REQUEST',
  UPDATE_NOTIFICATIONS_LIST_SUCCESS:
    MODULE_PREFIX + 'UPDATE_NOTIFICATIONS_LIST_SUCCESS',
  UPDATE_NOTIFICATIONS_LIST_FAILURE:
    MODULE_PREFIX + 'UPDATE_NOTIFICATIONS_LIST_FAILURE',
};

/** ***** Helpers ***** */

function handleNotificationList(state, payload) {
  const lastTimestamp =
    Number.parseInt(localStorage.getItem('lastNotificationSeen'), 10) || 0;
  let counter = 0;

  for (const notification of payload.collection) {
    if (notification.timestamp * 1000 > lastTimestamp) {
      counter++;
    } else {
      break;
    }
  }

  return {
    ...state,
    counter,
    notificationsList: payload.collection,
    notificationsListLoading: false,
    hasMore: payload.has_more,
  };
}

const initialState: {
  notificationsList: NotificationArrayType;
  isOpen: boolean;
  counter: number;
  notificationsListLoading: boolean;
  hasMore: boolean;
} = {
  isOpen: false,
  counter: 0,
  notificationsList: [],
  notificationsListLoading: false,
  hasMore: false,
};

export default (state = initialState, action): typeof initialState => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case notificationsListActionTypes.NOTIFICATIONS_LIST_SHOW_SUCCESS:
      return {
        ...state,
        isOpen: true,
        counter: 0,
      };

    case notificationsListActionTypes.NOTIFICATIONS_LIST_HIDE:
      return {
        ...state,
      };

    case notificationsListActionTypes.NOTIFICATIONS_LIST_HIDE_SUCCESS:
      return {
        ...state,
        isOpen: false,
      };

    case notificationsListActionTypes.GET_NOTIFICATIONS_LIST_REQUEST:
      return {
        ...state,
        notificationsListLoading: true,
      };

    case notificationsListActionTypes.GET_NOTIFICATIONS_LIST_SUCCESS:
      return handleNotificationList(state, payload);

    case notificationsListActionTypes.GET_NOTIFICATIONS_LIST_FAILURE:
      return {
        ...state,
        notificationsList: null,
        notificationsListLoading: false,
      };

    case notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_REQUEST:
      return {
        ...state,
        notificationsListLoading: true,
      };

    case notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_SUCCESS:
      return handleNotificationList(state, payload);

    case notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_FAILURE:
      return {
        ...state,
        notificationsList: null,
        notificationsListLoading: false,
      };

    default:
      return state;
  }
};

export const notificationsListActions = {
  notificationsListShow: () => ({
    type: notificationsListActionTypes.NOTIFICATIONS_LIST_SHOW,
  }),

  notificationsListShowSuccess: () => ({
    type: notificationsListActionTypes.NOTIFICATIONS_LIST_SHOW_SUCCESS,
  }),

  notificationsListHide: () => ({
    type: notificationsListActionTypes.NOTIFICATIONS_LIST_HIDE,
  }),

  notificationsListHideSuccess: () => ({
    type: notificationsListActionTypes.NOTIFICATIONS_LIST_HIDE_SUCCESS,
  }),

  getNotificationsList: (count?: number, lastId?: number) => ({
    type: notificationsListActionTypes.GET_NOTIFICATIONS_LIST_REQUEST,
    payload: {
      count,
      lastId,
    },
  }),

  getNotificationsListSuccess: ({ collection, has_more }) => ({
    type: notificationsListActionTypes.GET_NOTIFICATIONS_LIST_SUCCESS,
    payload: {
      collection,
      has_more,
    },
  }),

  getNotificationsListFailure: () => ({
    type: notificationsListActionTypes.GET_NOTIFICATIONS_LIST_FAILURE,
  }),

  updateNotificationsList: (count) => ({
    type: notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_REQUEST,
    payload: {
      count,
    },
  }),

  updateNotificationsListSuccess: ({ collection, has_more }) => ({
    type: notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_SUCCESS,
    payload: {
      collection,
      has_more,
    },
  }),

  updateNotificationsListFailure: () => ({
    type: notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_FAILURE,
  }),
};

export const notificationsListSelectors = {
  getNotificationsListIsOpen: (state) => state.notificationsList.isOpen,
  getNotificationsListHasMore: (state) => state.notificationsList.hasMore,
  getNotificationsList: (state) => state.notificationsList.notificationsList,
  getNotificationsListLoading: (state) =>
    state.notificationsList.notificationsListLoading,
  getNewNotificationsCounter: (state) => state.notificationsList.counter,
};
