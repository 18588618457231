import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Loader, Svg } from '../../../components/ui';
import classNames from 'classnames';
import styles from './PaymentCardSelectItem.module.scss';
import onClickOutside from 'react-onclickoutside';

class PaymentCardSelectItem extends Component {
  state = {
    isOpen: false,
    selectedCard: null,
  };

  toggleTooltip = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeTooltip = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleClickOutside = () => {
    this.closeTooltip();
  };

  renderCardNumber = (number) => {
    let numberArr = [];
    for (let i = 0; i < number.length / 4; i++) {
      const startPos = i * 4;
      const partString = number.slice(startPos, startPos + 4);

      if (startPos !== 12) {
        numberArr.push(partString + '-');
      } else {
        numberArr.push(partString);
      }
    }

    return numberArr.join('');
  };

  onSelectCard = () => {
    const {
      card: { id },
      selectCard,
    } = this.props;

    this.setState({
      selectedCard: id,
    });

    selectCard(id);
  };

  renderTooltip = (text) => (
    <div className={styles.tooltip} onClick={() => this.toggleTooltip()}>
      <div
        onClick={() => this.closeTooltip()}
        className={classNames(styles.tooltipMessage, {
          [styles.isOpen]: this.state.isOpen,
        })}
      >
        {text}
      </div>
    </div>
  );

  renderOverlay = () => {
    const {
      card: { id, active },
      selectedCardId,
      t,
    } = this.props;

    if (active) {
      if (selectedCardId && id === selectedCardId) {
        return this.renderTooltip(t('thisCardIsSelected'));
      } else {
        return (
          <div
            className={styles.cardOverlay}
            onClick={() => this.onSelectCard()}
          >
            <div className={styles.btnWrapper}>
              {active && <button className={styles.btn}>{t('select')}</button>}
            </div>
          </div>
        );
      }
    } else {
      return this.renderTooltip(t('thisCardIsUnavailable'));
    }
  };

  render() {
    const {
      card: { id, active, number, type },
      selectedCardInProcess,
      selectedCardId,
      selectedCardIdLoading,
    } = this.props;

    return (
      <div
        className={classNames(styles.card, {
          [styles.isSelected]: id === selectedCardId,
        })}
      >
        <Loader
          loading={selectedCardIdLoading && selectedCardInProcess === id}
          height={98}
        >
          <div className={styles.number}>{this.renderCardNumber(number)}</div>

          <div className={styles.status}>
            {!active && <Svg src={'taskUncompleted'} />}
          </div>

          <div className={styles.system}>
            {type === 'MASTERCARD' ? (
              <Svg src={'masterLogo'} />
            ) : (
              <Svg src={'visaLogo'} />
            )}
          </div>

          {this.renderOverlay()}
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  onClickOutside,
)(PaymentCardSelectItem);
