import { LabeledCheckboxNew } from 'components/inputs/checkbox';
import React, { PureComponent, useState } from 'react';
import { ThemeConsumer } from 'styled-components';
import styles from './WrappedCheckbox.module.scss';

class WrappedCheckbox extends PureComponent {
  render() {
    const { price } = this.props;
    // eslint-disable-next-line react/prop-types
    let priceNumb = price.match(/\d/g);
    priceNumb = priceNumb.join('');

    const imClicked = (e) => {
      const target = e.target;
      if (target.tagName != 'INPUT' && target.tagName != 'SPAN') {
        const input = document.getElementById(this.props.input.name);
        input?.click();
      }
    };
    return (
      <div
        className={styles.container}
        style={
          this.props.input.checked
            ? { background: '#CCEBE4', border: 'solid 1px #66C3AD' }
            : {}
        }
        onClick={(e) => imClicked(e)}
      >
        <LabeledCheckboxNew {...this.props} />
        <p>+ {priceNumb} ₾</p>
      </div>
    );
  }
}

export default WrappedCheckbox;
