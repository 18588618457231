import React, { Component } from 'react';

import styles from './ZoomImg.module.scss';

class ZoomImg extends Component {
  render() {
    const { image } = this.props;

    return (
      <div className={styles.container}>
        <img className={styles.image} src={image} alt="" />
      </div>
    );
  }
}

export default ZoomImg;
