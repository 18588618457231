import { take, call, put } from 'redux-saga/effects';
import { getTaskerLocation } from '../services/mapService';
import { mapsTypes } from '../reducers/map';

export function* watchTaskerLocation() {
    try {
      while(true){
      const { payload } = yield take(mapsTypes.GET_TASKER_LOCATION);
      const { route } = yield call(getTaskerLocation, payload.orderId);
      yield put({
        type: mapsTypes.GET_TASKER_LOCATION,
        payload: {
          location: route[route.length - 1].location
        },
      });
      }
      
    } catch (e) {
      console.log({error: e})
    }
}
