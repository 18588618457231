import { statuses } from 'containers/order/helpers/orderConstants';

export const getIconName = (order: OrderType) => {
  switch (order.status) {
    case statuses.CREATED:
      return 'filingIn';
    case statuses.WAITING_PAYMENT:
      return 'waitingPayment';
    case statuses.PAYMENT_IN_PROCESS:
      return 'pending';
    case statuses.PAID:
      return 'paidCheck';
    case statuses.VIEWED:
      return 'paidCheck';
    case statuses.TRANSPORTATION:
      return 'transportation';
    case statuses.IN_PROGRESS:
      return 'progress';
    case statuses.PENDING:
      return 'pending';
    case statuses.COMPLETED:
      return 'completed';
    case statuses.CANCELLED:
      return 'canceled';
    default:
      return 'pending';
  }
};
