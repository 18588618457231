import React from 'react';
import PropTypes from 'prop-types';

import ReceiptTasksListItem from './ReceiptTasksListItem';
import styles from './ReceiptTasksList.module.scss';
import PhantomTask from './PhantomTask';

const ReceiptTasksList = ({
  phantomTasks,
  completed,
  tasks,
  openReceiptPopup,
  orderStatus,
  openCancelPopup,
}) => {
  function renderPhantomTask(task) {
    return <PhantomTask key={task.label} task={task} />;
  }

  function renderTask(task) {
    return (
      <ReceiptTasksListItem
        key={task.id}
        completed={completed}
        task={task}
        openCancelPopup={openCancelPopup}
        openReceiptPopup={openReceiptPopup}
        orderStatus={orderStatus}
      />
    );
  }

  return (
    <div className={styles.tasks}>
      {phantomTasks.map(renderPhantomTask)}
      {tasks.map(renderTask)}
    </div>
  );
};

ReceiptTasksList.propTypes = {
  tasks: PropTypes.array,
};

export default ReceiptTasksList;
