import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { LabeledTextInput } from '../../inputs/text/index';
import { GreenButton } from '../../buttons/index';
import styles from './PhoneConfirmForm.module.scss';
import { GreenButtonTransparent } from '../../buttons/index';
import { findLastKey } from 'lodash';

class PhoneConfirmForm extends Component {
  render() {
    const {
      handleSubmit,
      onResend,
      phone,
      smsCode,
      codeConfirmLoading,
      t,
    } = this.props;
    // console.log(
    //   'process.env.REACT_APP_SHOW_CONFIRM_PHONE_CODE = ',
    //   process.env.REACT_APP_SHOW_CONFIRM_PHONE_CODE,
    // );

    return (
      <div className={styles.container}>
        <Helmet>
          <title>{t('confirmPhone')}</title>
        </Helmet>
        <div className={styles.title}>{t('smsVerification')}</div>
        <div className={styles.text}>
          {t('smsCodeHasSentToYourPhoneNumber')}{' '}
          <span className={styles.phone}>{phone}</span>
        </div>

        {process.env.REACT_APP_SHOW_CONFIRM_PHONE_CODE === 'true' || false && (
          <div className={styles.devText}>
            {t('code')}: {smsCode} ({t('willBeVisibleOnlyInDevelopmentVersion')}
            )
          </div>
        )}

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.field}>
            <Field
              name="code"
              component={LabeledTextInput}
              label={t('code')}
              maxlength={'4'}
              type={'number'}
            />
          </div>

          <div className={styles.btnWrapper}>
            <GreenButtonTransparent small onClick={onResend} type="button">
              {t('resend')}
            </GreenButtonTransparent>

            <GreenButton type="submit" disabled={codeConfirmLoading}>
              {t('checkCode')}
            </GreenButton>
          </div>
        </form>
      </div>
    );
  }
}

PhoneConfirmForm.propTypes = {
  onResend: PropTypes.func.isRequired,
  smsCode: PropTypes.string,
  phone: PropTypes.string,
  codeConfirmLoading: PropTypes.bool,
};

export default compose(withTranslation(), reduxForm())(PhoneConfirmForm);
