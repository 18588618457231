import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { GreenButton } from '../../../components/buttons';

import styles from './ConfirmOrderDelete.module.scss';

class ComplaintSendFailure extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('complaintSendError')}</div>
        <div className={styles.text}>{t('complaintSendErrorDescription')}</div>
        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              this.props.onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(ComplaintSendFailure);
