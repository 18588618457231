import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GreenButton } from '../../../components/buttons';

import styles from './SubmitOrderErrorPaymentMethod.module.scss';

class SubmitOrderErrorPaymentMethod extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.container} id="paymenterrorpopup">
        <div className={styles.title}>{t('selectPaymentMethod')}</div>

        <div className={styles.text}>{t('pleaseSelectPaymentMethod')}</div>

        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              this.props.onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SubmitOrderErrorPaymentMethod);
