import React, { useEffect, useRef, useState } from 'react';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { withProps, compose } from 'recompose';

import { checkIfMapsApiLoaded } from '../../../utils/mapUtils';
import DefaultTextInput from '../text/DefaultTextInput';
import { withReduxFormProps } from '../../../containers/hoc';

import styles from './AsideMapSearchBoxNew.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ClosePink } from 'assets/images/backgrounds/close-pink.svg';
const CHECK_FOR_GOOGLE_MAPS_API_LOADED_INTERVAL = 50;

type AsideMapSearchBoxPropType = {
  value?: string;
  onEdit: boolean;
  onChange: (text: string) => void;
  onLocationAnsAddressChange: (obj: any) => void;
  onLocationChange: (obj: any) => void;
  onSelectAddress: (obj: any) => void;
  onSetNewAddressLocation: (obj: any) => void;
  fromForm: boolean;
  touched: boolean;
  error: string;
};

const AsideMapSearchBox = ({
  value,
  onChange,
  onEdit,
  fromForm,
  error,
  touched,
  ...props
}: AsideMapSearchBoxPropType) => {
  const searchBox = useRef<StandaloneSearchBox>();
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const onMapsApiLoad = () => {
      clearInterval(timeoutId);
      setTimeoutId(null);
    };

    setTimeoutId(
      setInterval(
        () => checkIfMapsApiLoaded(onMapsApiLoad),
        CHECK_FOR_GOOGLE_MAPS_API_LOADED_INTERVAL,
      ),
    );

    return () => {
      clearInterval(timeoutId);
    };
  }, [setTimeoutId]);

  const handleAddressChange = () => {
    const places = searchBox.current?.getPlaces();

    if (places?.length !== 0 && places?.[0].formatted_address) {
      const place = places[0];

      const location = place.geometry.location.toJSON();
      const address = places[0].formatted_address;

      if (onEdit) {
        props.onLocationAnsAddressChange({ location, address });
      } else {
        onChange(address);
        // props.onLocationChange(location);
        // props.onSelectAddress(address);
        props.onSetNewAddressLocation(location);
      }
    }
  };
  const onClearClick = () => {
    onChange('');
  };

  const errorShouldBeRendered = () => {
    return !(touched === false || !error);
  };

  if (window.google) {
    return (
      <StandaloneSearchBox
        ref={searchBox}
        onPlacesChanged={handleAddressChange}
      >
        <div
          className={classNames(styles.inputContainer, {
            [styles.formInputContainer]: fromForm,
          })}
        >
          <DefaultTextInput
            {...props}
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value)}
            placeholder={t('enterAddress')}
            className={classNames(styles.input)}
          />
          {value && (
            <div
              className={`flex flex-center ${styles.close}`}
              onClick={onClearClick}
            >
              <ClosePink />
            </div>
          )}
          {errorShouldBeRendered() && (
            <div className={styles.errorLabel}>{t(error)}</div>
          )}
        </div>
      </StandaloneSearchBox>
    );
  }
  return null;
};

export default compose(
  React.memo,
  withReduxFormProps,
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
)(AsideMapSearchBox);
