import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { HexAvatar, Rating, Lari } from '../../ui';
import styles from './Tasker.module.scss';

function getSinceYear(since) {
  if (!since) return '';

  return since.slice(0, 4);
}

const Tasker = ({
  noPaid,
  user,
  price,
  small,
  descriptionTitle,
  since,
  taskerAvatarBig,
  bigPrice,
  badge,
  achievements,
  detailed,
  t,
}) => {
  if (Object.keys(user).length === 0) return null;

  const sinceYear = getSinceYear(since);

  const getBadgeTitle = (title) => {
    if (title.length > 14) {
      return title.slice(0, 14) + '...';
    }

    return title;
  };

  const renderBadge = () => {
    if (!badge || !user.avatar_badge) return null;

    const badgeBg =
      user.avatar_badge && user.avatar_badge.color
        ? { background: `${user.avatar_badge.color}` }
        : {};

    return (
      <div
        className={classNames(styles.elite, {
          [styles.eliteBig]: taskerAvatarBig,
        })}
        style={badgeBg}
      >
        {getBadgeTitle(user.avatar_badge.title)}
      </div>
    );
  };

  const renderAchievement = (achievement) => {
    return (
      <div className={styles.badge} key={achievement.id}>
        <div className={styles.badgeIcon}>
          <img src={achievement.icon} alt="" />
        </div>
        {achievement.title}
      </div>
    );
  };

  const renderAchievements = () => {
    if (
      !achievements ||
      !Array.isArray(user.achievements) ||
      user.achievements.length === 0
    )
      return null;

    const isAchievement = (elem) => {
      return !elem.show_as_icon;
    };

    if (user.achievements.every(isAchievement)) return null;

    return (
      <div
        className={classNames(styles.badgesContainer, {
          [styles.detailed]: detailed,
        })}
      >
        <div className={styles.badgesTitle}>{t('badges')}</div>
        <div className={styles.badges}>
          {Array.isArray(user.achievements) &&
            user.achievements.map(renderAchievement)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={classNames(styles.header, { [styles.headerSmall]: small })}
      >
        <div
          className={classNames(styles.avatar, {
            [styles.avatarBig]: taskerAvatarBig,
          })}
        >
          <HexAvatar image={user.avatar} />
          {renderBadge()}
        </div>

        <div className={styles.headerInfo}>
          <div className={styles.headerTop}>
            <div className={styles.name}>
              {user.first_name + ' '}

              {user.last_name
                ? noPaid
                  ? user.last_name[0] + '...'
                  : user.last_name
                : null}
            </div>
            {user.rating ? <Rating rating={user.rating} /> : null}
          </div>
          <div className={styles.headerBottom}>
            <div className={styles.countOrders}>
              {user.completed_orders} {t('completedOrders')}
            </div>
            <div className={styles.countReviews}>
              {user.reviews_count} {t('reviews')}
            </div>
          </div>

          {!small && price && price !== 0 ? (
            <div className={styles.cost}>
              {price}
              <Lari big={bigPrice} />
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.content}>
        {renderAchievements()}

        {!small && user.about && (
          <div className={styles.description}>
            {descriptionTitle && (
              <div className={styles.descriptionTitle}>{descriptionTitle}</div>
            )}
            <p className={styles.descriptionText}>{user.about}</p>
          </div>
        )}

        {since && (
          <p className={styles.since}>
            {localStorage.lang === 'ka'
              ? `Profy ${sinceYear + t('taskerSince')}`
              : `Profy ${t('taskerSince')} ${sinceYear}`}
          </p>
        )}
      </div>
    </div>
  );
};

Tasker.propTypes = {
  noPaid: PropTypes.bool,
  user: PropTypes.object,
  price: PropTypes.number,
  small: PropTypes.bool,
  descriptionTitle: PropTypes.string,
  since: PropTypes.string,
  taskerAvatarBig: PropTypes.bool,
  bigPrice: PropTypes.bool,
  badge: PropTypes.bool,
  achievements: PropTypes.bool,
  detailed: PropTypes.bool,
};

export default withTranslation()(Tasker);
