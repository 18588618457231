import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { LabeledTextInput } from '../../../components/inputs/text';
import { GreenButton, PinkButton } from '../../../components/buttons';
import { Lari, Loader } from '../../../components/ui';

import styles from './PaymentPromoCode.module.scss';

class PaymentPromoCode extends Component {
  state = {
    code: '',
    error: '',
    success: '',
  };

  componentDidMount() {
    if (this.props.order.promocode) {
      this.setState({
        code: this.props.order.promocode,
        error: '',
        success: this.getPromoCodeSuccessMessageFromOrder(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.promoCodeFailure && this.props.promoCodeFailure) {
      this.setState({
        error: this.getPromoCodeErrorMessage(),
        success: '',
      });
    }

    if (!prevProps.promoCodeSuccess && this.props.promoCodeSuccess) {
      this.setState({
        error: '',
        success: this.getPromoCodeSuccessMessage(),
      });
    }

    if (prevProps.order.promocode !== this.props.order.promocode) {
      this.setState({
        code: this.props.order.promocode,
        error: '',
        success: this.getPromoCodeSuccessMessageFromOrder(),
      });
    }
  }

  isButtonDisabled = () => {
    if (!this.state.code) return true;

    return this.state.code.length === 0 || this.state.error.length > 0;
  };

  renderButton = () => {
    const { t } = this.props;

    if (this.state.success)
      return (
        <PinkButton
          medium={true}
          minWidth={'130'}
          onClick={this.handleCancelPromoCode}
        >
          {t('cancel')}
        </PinkButton>
      );

    return (
      <GreenButton
        medium={true}
        minWidth={'130'}
        onClick={this.handleApplyPromoCode}
        disabled={this.isButtonDisabled()}
      >
        {t('apply')}
      </GreenButton>
    );
  };

  handleChangeCodeInput = (event) => {
    if (event.target.value.length < 11) {
      this.setState({
        code: event.target.value,
        error: '',
        success: '',
      });
    }
  };

  handleApplyPromoCode = () => {
    const { attachPromoCodeForOrder, order } = this.props;

    attachPromoCodeForOrder({
      orderId: order.id,
      promoCode: this.state.code,
    });
  };

  handleCancelPromoCode = () => {
    const { detachPromoCodeForOrder, order } = this.props;

    this.setState({
      code: '',
      error: '',
      success: '',
    });

    detachPromoCodeForOrder({ orderId: order.id });
  };

  getSuccessMessage = (value) => {
    const { t } = this.props;

    return localStorage.lang && localStorage.lang === 'en' ? (
      <div>
        {t('promoStart')} {value}
        <span className={styles.lari}>
          <Lari big={false} />
        </span>{' '}
        {t('promoEnd')}.
      </div>
    ) : (
      <div>
        {value}
        <span className={styles.lari}>
          <Lari big={false} />
        </span>{' '}
        {t('promoEnd')}.
      </div>
    );
  };

  getPromoCodeSuccessMessage = () => {
    const { promoCodeSuccess } = this.props;

    if (!promoCodeSuccess) return '';

    return this.getSuccessMessage(promoCodeSuccess.discount);
  };

  getPromoCodeErrorMessage = () => {
    const { promoCodeFailure, t } = this.props;

    if (!promoCodeFailure) return '';

    return promoCodeFailure.message || t('promoCodeNotFound');
  };

  getPromoCodeSuccessMessageFromOrder = () => {
    const { order } = this.props;

    if (!order.promocode_info) return '';

    return this.getSuccessMessage(order.promocode_discount);
  };

  render() {
    const { t, promoCodeLoading } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('promotionalCode')}</div>

        <Loader loading={promoCodeLoading} height={90}>
          <div className={styles.row}>
            <div className={styles.columnInput}>
              <LabeledTextInput
                type={'text'}
                label={t('code')}
                value={this.state.code || ''}
                onChange={this.handleChangeCodeInput}
                success={this.state.success}
                error={this.state.error}
                readOnly={this.state.success}
              />
            </div>

            <div className={styles.columnButton}>{this.renderButton()}</div>
          </div>
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: createOrderSelectors.getOrder(state),
  promoCodeLoading: createOrderSelectors.getPromoCodeLoading(state),
  promoCodeSuccess: createOrderSelectors.getPromoCodeSuccess(state),
  promoCodeFailure: createOrderSelectors.getPromoCodeFailure(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    attachPromoCodeForOrder: createOrderActions.attachPromoCodeForOrder,
    detachPromoCodeForOrder: createOrderActions.detachPromoCodeForOrder,
  }),
)(PaymentPromoCode);
