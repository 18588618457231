import React from 'react';
import classNames from 'classnames';
import styles from './Lari.module.scss';

type LariPropsType = {
  big?: boolean;
};
const Lari = ({ big }: LariPropsType) => {
  return (
    <span className={classNames(styles.lariContainer, { [styles.big]: big })}>
      ₾
    </span>
  );
};

export default Lari;
