import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { createTaskSelectors, createTaskActions } from './store';
import TaskDescription from './TaskDescription';
import ServiceView from './components/ServiceView';
import NoServicesFound from './components/NoServicesFound';
import { PinkButton } from '../../components/buttons';
import TaskImagesUpload from './TaskImagesUploader';
import { getTaskIdFromProps } from '../routing/constants/pathHelpers';
import CreateTaskBackButton from './components/CreateTaskBackButton';

import styles from './CreateTaskFinalPage.module.scss';
import TaskQuantity from './components/TaskQuantity';
import { createOrderSelectors } from '../../reducers/createOrder';

class CreateTaskFinalPage extends Component {
  onContinue = () => {
    const { orderId, saveTask } = this.props;
    const taskId = getTaskIdFromProps(this.props);

    window.scroll(0, 0);
    return saveTask(orderId, taskId);
  };

  render() {
    const { service, t, selectedFromQueryString } = this.props;

    return (
      <div className={styles.questionPage}>
        <Helmet>
          <title>{service.name}</title>
        </Helmet>
        <div className={styles.questionBlock}>
          {service ? (
            <ServiceView key={service} service={service} />
          ) : (
            <NoServicesFound />
          )}

          <TaskDescription />
        </div>

        <div className={styles.questionBlock}>
          <h2 className={styles.questionBlockTitle}>{t('selectQuantity')}</h2>
          <TaskQuantity unit={service.unit} />
        </div>

        <div className={styles.questionBlock}>
          <h2 className={styles.questionBlockTitle}>
            {t('addPhotosOptional')}
          </h2>
          <TaskImagesUpload />
        </div>

        <div className={styles.label}>
          {t('youCanAddAnotherServicesAfterSubmittingThisOne')}
        </div>

        <div className={styles.btnWrapper}>
          {selectedFromQueryString ? <div></div> : <CreateTaskBackButton />}

          <div className={styles.btnContinue}>
            <PinkButton
              iconName={'arrowRight'}
              iconPos={'right'}
              onClick={this.onContinue}
            >
              {t('continue')}
            </PinkButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  service: createTaskSelectors.getService(state),
  description: createTaskSelectors.getComment(state),
  quantity: createTaskSelectors.getQuantity(state),
  answers: createTaskSelectors.getAllAnswers(state),
  taskId: createTaskSelectors.selectTaskId(state),
  taskOrderId: createTaskSelectors.getTaskOrderId(state),
  orderId: createOrderSelectors.getOrderId(state),
  selectedFromQueryString: createTaskSelectors.getIfStepSelectedFromQueryString(
    state,
  ),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, createTaskActions),
  withRouter,
)(CreateTaskFinalPage);
