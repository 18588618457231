import React from 'react';
import classNames from 'classnames';

import styles from './DefaultButton.module.scss';
import buttonsPropTypes from './buttonsPropTypes';
import InitialButton from './InitialButton';

const DefaultButton = (props) => (
  <InitialButton
    className={classNames(styles.button, {
      [styles.buttonDisabled]: props.disabled,
    })}
    {...props}
  />
);

DefaultButton.propTypes = buttonsPropTypes;

export default DefaultButton;
