import React, { PureComponent } from 'react';
import styles from './NextStepButton.module.scss';
import classNames from 'classnames';

class NextStepButton extends PureComponent {
  render() {
    let {
      title,
      onClick,
      active,
      type,
      marginRight,
      withoutFill,
      width,
    } = this.props;

    return (
      <button
        type={'submit'}
        className={classNames(
          styles.nextStepButton,
          active ? styles.active : null,
          type === 'red' ? styles.red : null,
          withoutFill ? styles.active2 : null,
        )}
        style={Object.assign(
          marginRight ? { marginRight: '30px' } : {},
          width ? { width } : {},
        )}
        onClick={onClick}
      >
        <span className={styles.title}>{title}</span>
      </button>
    );
  }
}

export default NextStepButton;
