import React, { Component } from 'react';
import { compose } from 'recompose';
import { withReduxFormProps } from '../../../containers/hoc';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import DefaultTextInput from './DefaultTextInput';
import { Svg } from '../../ui';

import styles from './LabeledTextInput.module.scss';

class LabeledTextInput extends Component {
  state = {
    passwordVisible: false,
  };

  renderOptional = (optional) => {
    if (!optional) return;
    const { t } = this.props;

    return <span className={styles.labelOptional}>{t('optional')}</span>;
  };

  renderViewPassword = (type) => {
    if (type !== 'password') return;
    return (
      <button
        className={styles.btnViewPassword}
        type={'button'}
        onClick={this.togglePasswordVisibility}
      >
        <Svg
          className={classNames({
            [styles.btnViewPasswordActive]: this.state.passwordVisible === true,
          })}
          src={'view'}
        />
      </button>
    );
  };

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  getInputType() {
    const { type } = this.props;
    if (type !== 'password') {
      return type;
    } else if (this.state.passwordVisible) {
      return 'text';
    } else {
      return 'password';
    }
  }

  errorShouldBeRendered() {
    const { touched, error } = this.props;
    return !(touched === false || !error);
  }

  successShouldBeRendered() {
    const { touched, success } = this.props;
    return !(touched === false || !success);
  }

  render() {
    const {
      value,
      label,
      labelTextPostion,
      error,
      success,
      optional,
      type,
      noMargin,
      marginBottomHalf,
      readOnly,
      noReadOnlyStyles,
    } = this.props;

    return (
      <div>
        <label
          className={classNames(styles.label, {
            [styles.noMargin]: noMargin,
            [styles.marginBottomHalf]: marginBottomHalf,
          })}
        >
          <DefaultTextInput
            {...this.props}
            type={this.getInputType()}
            className={classNames(styles.input, {
              [styles.inputError]: this.errorShouldBeRendered(),
              [styles.inputSuccess]: this.successShouldBeRendered(),
              [styles.inputReadOnly]: !noReadOnlyStyles && readOnly,
            })}
          />
          <span
            className={classNames(styles.labelText, {
              [styles.labelTextFocus]: value !== '',
            })}
            style={
              value !== ''
                ? { top: `${value !== '' ? -labelTextPostion + 'px' : null}` }
                : null
            }
          >
            {label}
          </span>

          {this.renderOptional(optional)}
          {this.renderViewPassword(type)}

          {this.errorShouldBeRendered() && (
            <div className={styles.errorLabel}>{error}</div>
          )}

          {this.successShouldBeRendered() && (
            <div className={styles.successLabel}>{success}</div>
          )}
        </label>
      </div>
    );
  }
}

export default compose(withTranslation(), withReduxFormProps)(LabeledTextInput);
