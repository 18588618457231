import { takeEvery, call, put, take, fork, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { push, replace } from 'connected-react-router';

import { userActionTypes, userActions, userSelectors } from '../reducers/user';
import * as userService from '../services/userService';
import { uploadAvatar, uploadAvatarBase } from '../services/mediaService';
import { parseServerErrorsToForm } from '../utils/parseServerErrorToForm';
import { createFormData } from '../utils/formData';
import {
  getPathForProfile,
  getPathForDeactivated,
} from '../containers/routing/constants/pathHelpers';
import { putInitialAuthorizedActions } from './onAuthLoadSaga';
import qs from 'qs';
import { dialogActions } from '../containers/dialog/store';

export function* watchCloseSigninPopup() {
  while (true) {
    try {
      yield take(userActionTypes.CLOSE_SIGN_IN_POPUP);

      let params = qs.parse(window.location.search.substr(1));

      if (params.signin === '') {
        delete params.signin;

        let paramsString = qs.stringify(params);

        if (paramsString) {
          yield put(replace('?' + qs.stringify(params)));
        } else {
          yield put(replace(qs.stringify(params)));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* getUserProfile() {
  try {
    const data = yield call(userService.getUserProfile);
    yield put(userActions.getUserProfileSuccess(data));
  } catch (error) {
    yield put(userActions.getUserProfileFailure(error));
  }
}

export function* watchGetUserProfile() {
  yield takeEvery(userActionTypes.GET_USER_PROFILE_REQUEST, getUserProfile);
}

function* editUserProfile({ payload: { values, resolve, reject } }) {
  try {
    const data = yield call(userService.editUserProfile, values);
    yield put({
      type: userActionTypes.EDIT_USER_PROFILE_SUCCESS,
      payload: { ...data },
    });
    yield put(push(getPathForProfile()));
  } catch (error) {
    yield put({ type: userActionTypes.EDIT_USER_PROFILE_FAILURE, error });
    yield call(
      reject,
      new SubmissionError(parseServerErrorsToForm(error.errors)),
    );
  }
}

export function* watchEditUserProfile() {
  yield takeEvery(userActionTypes.EDIT_USER_PROFILE_REQUEST, editUserProfile);
}

export function* watchUpdateAvatar() {
  while (true) {
    try {
      const { payload } = yield take(userActionTypes.UPDATE_AVATAR_REQUEST);
      const formData = yield call(createFormData, payload.file);
      const { image } = yield call(uploadAvatar, formData);

      yield put({
        type: userActionTypes.UPDATE_AVATAR_SUCCESS,
        payload: { image },
      });

      yield call(userService.updateAvatar, { image_id: image.id });
    } catch (error) {
      yield put({ type: userActionTypes.UPDATE_AVATAR_FAILURE, error });
    }
  }
}

export function* watchUpdateAvatarBase() {
  while (true) {
    try {
      const { payload } = yield take(
        userActionTypes.UPDATE_AVATAR_BASE_REQUEST,
      );

      const { image } = yield call(uploadAvatarBase, payload.file);

      yield put({
        type: userActionTypes.UPDATE_AVATAR_SUCCESS,
        payload: { image },
      });

      const { token } = yield call(userService.updateAvatar, {
        image_id: image.id,
      });

      if (token) {
        localStorage.setItem('token', token);
      }
    } catch (error) {
      yield put({ type: userActionTypes.UPDATE_AVATAR_FAILURE, error });
    }
  }
}

export function* watchAccountDeactivate() {
  while (true) {
    try {
      yield take(userActionTypes.DEACTIVATE_ACCOUNT_REQUEST);
      yield call(userService.deactivateAccount);
      yield put({ type: userActionTypes.DEACTIVATE_ACCOUNT_SUCCESS });
      yield put(replace(getPathForDeactivated()));
    } catch (error) {
      yield put({ type: userActionTypes.DEACTIVATE_ACCOUNT_FAILURE });
    }
  }
}

export function* watchAccountActivate() {
  while (true) {
    try {
      yield take(userActionTypes.ACTIVATE_ACCOUNT_REQUEST);
      yield call(userService.activateAccount);
      yield put({ type: userActionTypes.ACTIVATE_ACCOUNT_SUCCESS });
      yield fork(putInitialAuthorizedActions);
      yield put(replace('/'));
    } catch (error) {
      yield put({ type: userActionTypes.ACTIVATE_ACCOUNT_FAILURE });
    }
  }
}

export function* watchGetUserNotifications() {
  while (true) {
    try {
      yield take(userActionTypes.GET_USER_NOTIFICATIONS_REQUEST);
      const notifications = yield call(userService.getUserNotifications);

      yield put({
        type: userActionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
        notifications,
      });
    } catch (error) {
      yield put({ type: userActionTypes.GET_USER_NOTIFICATIONS_FAILURE });
    }
  }
}

export function* watchChangeUserNotificationsField() {
  while (true) {
    try {
      const { field } = yield take(
        userActionTypes.CHANGE_NOTIFICATIONS_REQUEST,
      );
      const fieldRow = field.name.slice(0, field.name.indexOf('.'));
      const fieldType = field.name.slice(field.name.indexOf('.') + 1);
      const notifications = yield select(userSelectors.getUserNotifications);
      let rowIndex = null;

      notifications.forEach((row, i, arr) => {
        if (row.id === fieldRow) rowIndex = i;
      });

      if (field.checked) {
        if (notifications[rowIndex].enabled.indexOf(fieldType) === -1) {
          notifications[rowIndex].enabled.push(fieldType);
        }
      } else {
        if (notifications[rowIndex].enabled.indexOf(fieldType) > -1) {
          notifications[rowIndex].enabled.splice(
            notifications[rowIndex].enabled.indexOf(fieldType),
            1,
          );
        }
      }

      yield put({
        type: userActionTypes.CHANGE_NOTIFICATIONS_SUCCESS,
        notifications,
      });
    } catch (error) {
      console.error('watchChangeUserNotificationsField ERROR = ', error);
      yield put({ type: userActionTypes.CHANGE_NOTIFICATIONS_FAILURE });
    }
  }
}

function formatNotificationsToBackend(notifications) {
  let formatNotifications = {
    notifications: null,
  };

  notifications.forEach((row, i, arr) => {
    formatNotifications.notifications = {
      ...formatNotifications.notifications,
      [row.id]: row.enabled,
    };
  });

  return formatNotifications;
}

export function* watchSaveUserNotifications() {
  while (true) {
    try {
      yield take(userActionTypes.SET_USER_NOTIFICATIONS_REQUEST);
      const notifications = yield select(userSelectors.getUserNotifications);
      const formatNotifications = formatNotificationsToBackend(notifications);

      yield call(userService.setUserNotifications, formatNotifications);

      yield put({ type: userActionTypes.SET_USER_NOTIFICATIONS_SUCCESS });
    } catch (error) {
      yield put({
        type: userActionTypes.SET_USER_NOTIFICATIONS_FAILURE,
        error,
      });
    }
  }
}

export function* watchOtherSignedIn() {
  while (true) {
    try {
      yield take(userActionTypes.OTHER_SIGNED_ID_REQUEST);

      yield put(dialogActions.openOtherDeviceSignedIdPopup());

      yield put({ type: userActionTypes.OTHER_SIGNED_ID_SUCCESS });
    } catch (error) {
      yield put({ type: userActionTypes.OTHER_SIGNED_ID_FAILURE, error });
    }
  }
}

/* *** Get server time *** */

export function* watchGetServerTime() {
  while (true) {
    try {
      yield take(userActionTypes.GET_SERVER_TIME_REQUEST);

      let { timestamp } = yield call(userService.getServerTime);

      const currentTime = Date.now();
      const differenceTime = (currentTime - timestamp * 1000) / 1000;

      yield put(
        userActions.getServerTimeSuccess(
          timestamp,
          differenceTime > 1 ? differenceTime : 0,
        ),
      );
    } catch (error) {
      console.error(error);
      yield put(userActions.getServerTimeFailure(error));
    }
  }
}
