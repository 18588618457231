import React from 'react';
import styles from './OrderList.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type OrderListPropsType = {
  tasks: TaskType[] | undefined;
  fromOrderDetails?: boolean;
};

const OrderList = ({ tasks, fromOrderDetails }: OrderListPropsType) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.orderList} ${
        fromOrderDetails ? styles.fromOrderDetails : ''
      }`}
    >
      <h5>{t('orderList')}</h5>
      <ul>
        {tasks?.map((val, index) => (
          <li
            className={classNames(
              styles.orderListItem,
              index ? null : styles.withoutBorder,
            )}
            key={val.id}
          >
            <div className={styles.text}>
              <p className={styles.orderListTitle}>
                {val.service.category_name}
              </p>
              <p className={styles.selectedService}>{val.service.name}</p>
            </div>
            <div className={styles.count}>
              <p>x{val.quantity ?? '-'}</p>
            </div>
            <div className={styles.price}>
              <p className={styles.orderListPrice}>{val.price} ₾</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderList;
