import React from 'react';

import styles from './BlackMobileButton.module.scss';
import { Svg } from '../ui';

const BlackMobileButton = ({ iconName, smallText, bigText }) => (
  <button className={styles.buttonContainer}>
    <div className={styles.svgContainer}>
      <Svg src={iconName} />
    </div>
    <div className={styles.labelsContainer}>
      <div className={styles.smallText}>{smallText}</div>
      <div className={styles.bigText}>{bigText}</div>
    </div>
  </button>
);

export default BlackMobileButton;
