import React from 'react';
import styles from './ChooseCard.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Svg } from 'components/ui';

type ChooseCardPropsType = {
  cardsList: any[];
  selectedCardId: number;
  toggleCard: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: number,
  ) => void;
  onCardAdd: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fromFeedback?: boolean;
};

const ChooseCard = ({
  cardsList,
  selectedCardId,
  toggleCard,
  onCardAdd,
  fromFeedback,
}: ChooseCardPropsType) => {
  const { t } = useTranslation();

  return (
    <div className={styles.chooseCard}>
      {!fromFeedback && <h5>{t('paymentMethod')}</h5>}

      <ul className={styles.chooseCardList}>
        {cardsList?.map((val, index) => (
          <li
            key={val.id}
            onClick={(e) => toggleCard(e, val.id)}
            className={classNames(
              val.id === selectedCardId || (!selectedCardId && index === 0)
                ? styles.active
                : null,
            )}
          >
            <div className={styles.cardIconInfo}>
              <Svg
                src={
                  val.type === 'MASTERCARD'
                    ? 'mastercard-light-large'
                    : 'visa-light-large'
                }
                className={styles.chooseCardIcon}
              />
              <div>
                <p className={styles.cardNum}>{val.number}</p>
                <p className={styles.cardDate}>
                  {val.expiry_month}/
                  {val.expiry_year.toString().substring(2, 4)}
                </p>
              </div>
            </div>
            <div>
              <span className={styles.cardBtn}></span>
            </div>
          </li>
        ))}
      </ul>
      {!fromFeedback &&
        (cardsList?.length ? (
          <div className={styles.addNewCardRound} onClick={onCardAdd}>
            <Svg src={'plus-card'} className={styles.svg} />
          </div>
        ) : (
          <div className={styles.addNewCard} onClick={onCardAdd}>
            <span>{t('addNewCard')}</span>
            <div className={styles.svgContainer}>
              <Svg src={'plus-card'} className={styles.addNewCardIcon} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChooseCard;
