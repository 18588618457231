import { call, takeEvery, put, select } from 'redux-saga/effects';
import { chatActions, chatSelectors, chatTypes } from '../reducers/chat';
import { getChatList, getMessages } from '../services/chatService';
import { getSupportChat } from '../services/supportService';
import { uploadImage } from '../services/mediaService';
import { createFormData } from '../utils/formData';
import { wsActions } from './websocketSaga';
import { v4 } from 'uuid';
import { dialogActions } from '../containers/dialog/store';

/** Fetch message list **/
function* fetchMessageList({ payload }) {
  try {
    const requestData = {
      id: payload.chatId,
      last: payload.lastMessageId || '',
      count: payload.count || 20,
    };
    const response = yield call(getMessages, requestData);

    if (response && Array.isArray(response.collection)) {
      yield put(
        chatActions.getMessageListSuccess(
          response.collection.reverse(),
          response.has_more,
          payload.earlier,
        ),
      );
    } else {
      throw new Error('Messages list request error ');
    }
  } catch (error) {
    console.error(error);
    yield put(chatActions.getMessageListFailure());
  }
}

export function* watchMessagesLoading() {
  yield takeEvery(chatTypes.GET_MESSAGES_LIST_REQUEST, fetchMessageList);
}

/** Fetch chat list **/

function* fetchChatList() {
  try {
    const response = yield call(getChatList);

    if (response && Array.isArray(response.collection)) {
      yield put(chatActions.getChatListSuccess(response.collection));
    } else {
      throw new Error('Chat list request error ');
    }
  } catch (error) {
    console.error(error);
    yield put(chatActions.getChatListFailure());
  }
}

/** Fetch support chat **/

function* fetchSupportChat() {
  try {
    const response = yield call(getSupportChat);

    if (response && response.hasOwnProperty('chat')) {
      yield put(chatActions.getSupportChatSuccess(response.chat));
    } else {
      throw new Error('Support chat request error ');
    }
  } catch (error) {
    console.error(error);
    yield put(chatActions.getSupportChatFailure());
  }
}

/* *** upload chat image *** */

function* onUploadImage({ payload }) {
  try {
    const formData = createFormData(payload.file);
    const { image } = yield call(uploadImage, formData);

    yield put(
      wsActions.sendMessage({
        type: 'IMAGE',
        guid: v4(),
        room: payload.chatId,
        content: image.id,
        timezone: '+03:00',
        // created_at: payload.createdAt
      }),
    );

    yield put(
      chatActions.uploadChatImageSuccess({
        ...image,
        preview: payload.file.preview,
        clientGeneratedId: payload.clientGeneratedId,
      }),
    );
  } catch (error) {
    console.error('UPLOAD IMAGE ERROR: ', error);
    yield put(chatActions.uploadChatImageFailure(error));
  }
}

/* *** on get chat by id *** */

function* onOpenChatPopup({ payload: id }) {
  function findChatById(chat, index) {
    if (chat.id !== id) return false;
    return index;
  }

  const chatList = yield select(chatSelectors.getChatList);
  const indexClosedChat = chatList.findIndex(findChatById);
  if (chatList[indexClosedChat] && chatList[indexClosedChat].closed) {
    yield put(dialogActions.openChatIsNotAvailable());
  }
}

export function* watchChatListLoading() {
  yield takeEvery(chatTypes.GET_CHAT_LIST_REQUEST, fetchChatList);
}

export function* watchGetSupportChat() {
  yield takeEvery(chatTypes.GET_SUPPORT_CHAT_REQUEST, fetchSupportChat);
}

export function* watchUploadImage() {
  yield takeEvery(chatTypes.UPLOAD_CHAT_IMAGE_REQUEST, onUploadImage);
}

export function* watchOpenChatPopupById() {
  yield takeEvery(chatTypes.ON_OPEN_CHAT_POPUP_BY_ID, onOpenChatPopup);
}
