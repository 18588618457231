// auth
export const ORDERS = '/orders';
export const INFO = '/info';
export const COMPLAINT = '/complaint';
export const SUPPORT = '/support';
export const REVIEW = '/review';
export const CHAT = '/chat';
export const TASKER_MAP = '/tasker-location';
export const RECEIPT = '/receipt';
export const STATUS = '/status';
