import React, { Component } from 'react';

import Select from 'react-select';
import SelectWithIconItem from './SelectWithIconItem';

class ReactSelect extends Component {
  render() {
    const { options, onChange, value } = this.props;

    return (
      <Select
        value={value}
        styles={stylesSelect}
        options={options}
        onChange={onChange}
        components={SelectWithIconItem}
      />
    );
  }
}

export default ReactSelect;

const stylesSelect = {
  input: (styles) => ({
    ...styles,
    marginLeft: -8,
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: -8,
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: -8,
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    opacity: 1,
  }),
  menu: (styles) => ({
    ...styles,
    padding: 0,
    overflow: 'hidden',
    opacity: 1,
  }),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  control: (styles, state) => ({
    ...styles,
    paddingLeft: 0,
    outline: 0,
    borderRadius: 0,
    border: 0,
    borderBottom: state.isFocused
      ? '1px solid rgba(0, 155, 119, .5)'
      : '1px solid #f2f2f2',
    backgroundColor: 'white',
    boxShadow: 'none!important',
    '&:hover': {
      outline: 0,
      boxShadow: 'none',
      borderColor: '#009b77',
    },
    '&:focus': {
      outline: 0,
      boxShadow: 'none',
      borderColor: '009b77',
    },
    '&:active': {
      outline: 0,
      boxShadow: 'none',
      borderColor: '009b77',
    },
  }),
  dropdownIndicator: (styles, state) => ({
    'svg path': {
      ...styles,
      fill: state.isFocused ? 'rgba(0, 155, 119, 1)' : '#f2f2f2',
    },
  }),
  option: (styles, state) => ({
    ...styles,
    boxSizing: 'border-box',
    borderBottom: '1px solid hsl(0,0%,80%)',
    color: state.isSelected ? '#fff' : '#343D47',
    padding: 20,
    paddingTop: 18,
    paddingBottom: 18,
    backgroundColor: state.isSelected ? '#009b77' : 'white',
    transition: '.25s',
    cursor: 'pointer',
    opacity: 1,
    '&:last-child': {
      paddingBottom: 19,
      borderBottom: 0,
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#009b77',
    },
    '&:hover': {
      color: state.isSelected ? '#fff' : '#009b77',
      backgroundColor: state.isSelected ? '#009b77' : '#fff',
    },
  }),
};
