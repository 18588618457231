import { takeLatest, call, put, select } from 'redux-saga/effects';
import * as services from '../../services/authService';
import { parseServerErrorsToForm } from '../../utils/parseServerErrorToForm';
import { SubmissionError } from 'redux-form';
import { userActions } from '../../reducers/user';

/* ACTION TYPES */

const MODULE_PREFIX = 'restorePassword/';

const types = {
  OPEN_CHANGE_PASSWORD: MODULE_PREFIX + 'OPEN_CHANGE_PASSWORD',
  OPEN_RESTORE_PASSWORD: MODULE_PREFIX + 'OPEN_RESTORE_PASSWORD',

  CLOSE_CHANGE_RESTORE_PASSWORD:
    MODULE_PREFIX + 'CLOSE_CHANGE_RESTORE_PASSWORD',

  SEND_PHONE_REQUEST: MODULE_PREFIX + 'SEND_PHONE_REQUEST',
  SEND_PHONE_SUCCESS: MODULE_PREFIX + 'SEND_PHONE_SUCCESS',
  SEND_PHONE_FAILURE: MODULE_PREFIX + 'SEND_PHONE_FAILURE',

  CONFIRM_CODE_REQUEST: MODULE_PREFIX + 'CONFIRM_CODE_REQUEST',
  CONFIRM_CODE_SUCCESS: MODULE_PREFIX + 'CONFIRM_CODE_SUCCESS',
  CONFIRM_CODE_FAILURE: MODULE_PREFIX + 'CONFIRM_CODE_FAILURE',

  RESEND_CODE_REQUEST: MODULE_PREFIX + 'RESEND_CODE_REQUEST',
  RESEND_CODE_SUCCESS: MODULE_PREFIX + 'RESEND_CODE_SUCCESS',
  RESEND_CODE_FAILURE: MODULE_PREFIX + 'RESEND_CODE_FAILURE',

  SAVE_NEW_PASSWORD_REQUEST: MODULE_PREFIX + 'SAVE_NEW_PASSWORD_REQUEST',
  SAVE_NEW_PASSWORD_SUCCESS: MODULE_PREFIX + 'SAVE_NEW_PASSWORD_SUCCESS',
  SAVE_NEW_PASSWORD_FAILURE: MODULE_PREFIX + 'SAVE_NEW_PASSWORD_FAILURE',

  CLEAR_RESTORE_PASSWORD_STATE: MODULE_PREFIX + 'CLEAR_RESTORE_PASSWORD_STATE',
};

/* INITIAL STATE */

export const RESTORE_PHONES_STEPS = {
  SEND_PHONE: 'SEND_PHONE',
  CONFIRM_SMS: 'CONFIRM_SMS',
  ENTER_NEW_PASSWORD: 'ENTER_NEW_PASSWORD',
};

const initialState = {
  currentStep: RESTORE_PHONES_STEPS.SEND_PHONE,

  changeRestorePasswordOpened: false,

  singInNeeded: false,

  phone: null,
  phoneLoading: false,
  session: null,
  smsCode: null,

  codeConfirmLoading: false,

  newPasswordLoading: false,
};

/* REDUCER */

export default (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_CHANGE_PASSWORD:
      return {
        ...state,
        changeRestorePasswordOpened: true,
        currentStep: RESTORE_PHONES_STEPS.CONFIRM_SMS,
      };

    case types.OPEN_RESTORE_PASSWORD:
      return {
        ...state,
        changeRestorePasswordOpened: true,
        singInNeeded: true,
      };

    case types.CLOSE_CHANGE_RESTORE_PASSWORD:
      return initialState;

    case types.SEND_PHONE_REQUEST:
      return {
        ...state,
        phoneLoading: true,
        phone: action.phone,
      };

    case types.SEND_PHONE_SUCCESS:
      return {
        ...state,
        phoneLoading: false,
        currentStep: RESTORE_PHONES_STEPS.CONFIRM_SMS,
        session: action.session,
        smsCode: action.sms_code, // DELETE BEFORE PROD
      };

    case types.SEND_PHONE_FAILURE:
      return {
        ...state,
        phoneLoading: false,
      };

    case types.CONFIRM_CODE_REQUEST:
      return {
        ...state,
        codeConfirmLoading: true,
      };

    case types.CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        codeConfirmLoading: false,
        currentStep: RESTORE_PHONES_STEPS.ENTER_NEW_PASSWORD,
      };

    case types.CONFIRM_CODE_FAILURE:
      return {
        ...state,
        codeConfirmLoading: false,
      };

    case types.RESEND_CODE_SUCCESS:
      return {
        ...state,
        smsCode: action.sms_code,
      };

    case types.SAVE_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        newPasswordLoading: true,
      };

    case types.SAVE_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPasswordLoading: false,
      };

    case types.CLEAR_RESTORE_PASSWORD_STATE:
      return initialState;

    default:
      return state;
  }
};

/* ACTION CREATORS */

const openChangePassword = (phone) => ({
  type: types.OPEN_CHANGE_PASSWORD,
  phone,
});

const openRestorePassword = () => ({
  type: types.OPEN_RESTORE_PASSWORD,
});

const closeChangeRestorePassword = () => ({
  type: types.CLOSE_CHANGE_RESTORE_PASSWORD,
});

const sendPhone = (phone, reject) => ({
  type: types.SEND_PHONE_REQUEST,
  phone,
  reject,
});

const clearRestorePasswordState = () => ({
  type: types.CLEAR_RESTORE_PASSWORD_STATE,
});

const confirmCode = (code, reject) => ({
  type: types.CONFIRM_CODE_REQUEST,
  code,
  reject,
});

const resendCodeRestore = () => ({
  type: types.RESEND_CODE_REQUEST,
});

const saveNewPassword = (values, reject) => ({
  type: types.SAVE_NEW_PASSWORD_REQUEST,
  values,
  reject,
});

export const restorePasswordActions = {
  openChangePassword,
  openRestorePassword,
  closeChangeRestorePassword,
  sendPhone,
  clearRestorePasswordState,
  confirmCode,
  resendCodeRestore,
  saveNewPassword,
};

/* SELECTORS */

export const restorePasswordSelectors = {
  isChangeRestorePhoneOpened: (state) =>
    state.restorePassword.changeRestorePasswordOpened,

  getPhoneLoading: (state) => state.restorePassword.phoneLoading,

  getCurrentStep: (state) => state.restorePassword.currentStep,

  getSmsCode: (state) => state.restorePassword.smsCode,

  getSession: (state) => state.restorePassword.session,

  getPhone: (state) => state.restorePassword.phone,

  getCodeConfirmLoading: (state) => state.restorePassword.codeConfirmLoading,

  getNewPasswordLoading: (state) => state.restorePassword.newPasswordLoading,

  isSignInNeeded: (state) => state.restorePassword.singInNeeded,
};

/* SAGAS */

function* onChangePasswordOpen({ phone }) {
  yield put(sendPhone(phone, () => {}));
}

function* onPhoneSend({ phone, resolve, reject }) {
  try {
    const data = yield call(services.restorePassword, phone);
    yield put({ type: types.SEND_PHONE_SUCCESS, ...data });
  } catch (error) {
    yield put({ type: types.SEND_PHONE_FAILURE, error });
    const parsedError = yield call(parseServerErrorsToForm, error.errors);

    if (parsedError && parsedError.token) {
      // wrong field name comes from backend
      parsedError.phone = parsedError.token;
    }

    yield call(reject, new SubmissionError(parsedError));
  }
}

function* onPhoneConfirm({ code, reject }) {
  try {
    const session = yield select(restorePasswordSelectors.getSession);

    yield call(services.confirmCode, { session, code });
    yield put({ type: types.CONFIRM_CODE_SUCCESS });
  } catch (error) {
    yield put({ type: types.CONFIRM_CODE_FAILURE, error });

    const parsedError = yield call(parseServerErrorsToForm, error.errors);
    reject(new SubmissionError(parsedError));
    yield call(reject, new SubmissionError(parsedError));
  }
}

function* onPhoneCodeResend() {
  try {
    const session = yield select(restorePasswordSelectors.getSession);
    const { sms_code } = yield call(services.resendCode, { session });
    yield put({ type: types.RESEND_CODE_SUCCESS, sms_code });
  } catch (error) {
    yield put({ type: types.RESEND_CODE_FAILURE, error });
  }
}

function* onNewPasswordRequest({ values, signInRequired, reject }) {
  try {
    const session = yield select(restorePasswordSelectors.getSession);

    yield call(services.restoreFinish, { session, password: values.password });

    if (yield select(restorePasswordSelectors.isSignInNeeded)) {
      const phone = yield select(restorePasswordSelectors.getPhone);

      yield put(
        userActions.signIn({
          login: phone,
          password: values.password,
        }),
      );
    }
    yield put(closeChangeRestorePassword());
  } catch (error) {
    const parsedError = yield call(parseServerErrorsToForm, error.errors);

    yield call(reject, new SubmissionError(parsedError));

    yield put({ type: types.RESEND_CODE_FAILURE, error });
  }
}

export const restorePasswordSagas = {
  watchOpenChangePassword: function* () {
    yield takeLatest(types.OPEN_CHANGE_PASSWORD, onChangePasswordOpen);
  },

  onPhoneSend: function* () {
    yield takeLatest(types.SEND_PHONE_REQUEST, onPhoneSend);
  },

  onCodeConfirm: function* () {
    yield takeLatest(types.CONFIRM_CODE_REQUEST, onPhoneConfirm);
  },

  onCodeResend: function* () {
    yield takeLatest(types.RESEND_CODE_REQUEST, onPhoneCodeResend);
  },

  onNewPasswordSubmit: function* () {
    yield takeLatest(types.SAVE_NEW_PASSWORD_REQUEST, onNewPasswordRequest);
  },
};
