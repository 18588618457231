import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import SignInForm from './components/SignInForm';
import { userActions, userSelectors } from '../../reducers/user';
import { actions } from '../../reducers/signup';
import Popup from '../../components/ui/Popup';
import { restorePasswordActions } from '../restorePassword/store';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

const SIGN_IN_FORM = 'SIGN_IN_FORM';

class SignIn extends Component {
  componentDidMount() {
    this.openSignInPopupFromLink();
  }

  componentDidUpdate(prevProps) {
    this.openSignInPopupFromLink();
  }

  // onForgot = () => {
  //   this.closeSignInPopup();
  //   this.props.openRestorePassword();
  // };

  openSignInPopupFromLink = (remove, open) => {
    let params = qs.parse(window.location.search.substr(1));

    if (params.signin === '') {
      this.props.openSignInPopup();
    }
  };

  closeSignInPopup = () => {
    this.props.closeSignInPopup();
  };

  render() {
    const { popupOpened } = this.props;

    return (
      <Popup isOpen={popupOpened} onClose={this.closeSignInPopup}>
        <SignInForm
          form={SIGN_IN_FORM}
          onSubmit={this.onSubmit}
          onSignUp={this.onSignUp}
          onForgot={this.onForgot}
        />
      </Popup>
    );
  }

  onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      this.props.signIn(values, resolve, reject);
    });
  };
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      popupOpened: userSelectors.isSignInPopupOpened(state),
    }),
    { ...userActions, ...actions, ...restorePasswordActions },
  ),
)(SignIn);
