import React, { Fragment } from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';
import Svg from './Svg';

type LoaderPropsType = {
  children?: React.ReactNode;
  loading: boolean;
  className?: string;
  containerWithHeight?: number;
  height?: number;
  scale?: boolean;
};
const Loader = ({
  children,
  loading,
  className,
  containerWithHeight,
  height,
  scale,
}: LoaderPropsType) => {
  const containerStyles = { height: containerWithHeight || height };

  return (
    <Fragment>
      {loading ? (
        <div
          className={classNames(styles.loader, className, {
            [styles.container]: containerWithHeight,
            [styles.scale]: scale,
          })}
          style={containerStyles}
        >
          <div className={styles.loaderContainer}>
            <Svg className={styles.loaderSvg} src={'loaderSvg'} />
            <div className={styles.loaderCircle} />
            <div className={styles.loaderCircle1} />
            <div className={styles.loaderCircle3} />
          </div>
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default Loader;
