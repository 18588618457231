import { wsActionTypes } from '../sagas/websocketSaga';

const MODULE_PREFIX = 'support/';

export const supportTypes = {
  LOAD_COMPLAINTS_LIST_REQUEST: MODULE_PREFIX + 'LOAD_COMPLAINTS_LIST_REQUEST',
  LOAD_COMPLAINTS_LIST_SUCCESS: MODULE_PREFIX + 'LOAD_COMPLAINTS_LIST_SUCCESS',
  LOAD_COMPLAINTS_LIST_FAILURE: MODULE_PREFIX + 'LOAD_COMPLAINTS_LIST_FAILURE',

  SELECT_COMPLAINT: MODULE_PREFIX + 'SELECT_COMPLAINT',

  COMPLAINT_COMMENT_CHANGE: MODULE_PREFIX + 'COMPLAINT_COMMENT_CHANGE',

  COMPLAINT_SEND_REQUEST: MODULE_PREFIX + 'COMPLAINT_SEND_REQUEST',
  COMPLAINT_SEND_SUCCESS: MODULE_PREFIX + 'COMPLAINT_SEND_SUCCESS',
  COMPLAINT_SEND_FAILURE: MODULE_PREFIX + 'COMPLAINT_SEND_FAILURE',

  CLEAR_SELECTED_COMPLAINT_STATE: MODULE_PREFIX + 'CLEAR_COMPLAINT_STATE',

  CLEAR_COMPLAINT_STATE: MODULE_PREFIX + 'CLEAR_COMPLAINT_STATE',

  SHOW_SUPPORT_ON_STATUS_JOB: MODULE_PREFIX + 'SHOW_SUPPORT_ON_STATUS_JOB',
  SHOW_SUPPORT_ON_DEACTIVATED: MODULE_PREFIX + 'SHOW_SUPPORT_ON_DEACTIVATED',
  SHOW_SUPPORT_ON_BLOCKED: MODULE_PREFIX + 'SHOW_SUPPORT_ON_BLOCKED',
};

const initialSelectedComplaint = {
  screen: '',
  id: null,
  name: '',
  isReportable: false,
  description: '',
  comment: '',
};

const initialState = {
  complaintsList: [],
  complaintsListLoading: false,

  selectedComplaint: {
    ...initialSelectedComplaint,
  },

  complaintSendLoading: false,

  showSupportOnStatusJob: false,
  showSupportOnDeactivated: false,
  showSupportOnBlocked: false,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case supportTypes.LOAD_COMPLAINTS_LIST_REQUEST:
      return {
        ...state,
        complaintsListLoading: true,
      };

    case supportTypes.LOAD_COMPLAINTS_LIST_SUCCESS:
      return {
        ...state,
        complaintsList: payload.complaintsList,
        complaintsListLoading: false,
      };

    case supportTypes.LOAD_COMPLAINTS_LIST_FAILURE:
      return {
        ...state,
        complaintsListLoading: false,
      };

    case supportTypes.SELECT_COMPLAINT:
      return {
        ...state,
        selectedComplaint: {
          ...payload.complaint,
          comment: '',
        },
      };

    case supportTypes.COMPLAINT_COMMENT_CHANGE:
      return {
        ...state,
        selectedComplaint: {
          ...state.selectedComplaint,
          comment: payload.comment,
        },
      };

    case supportTypes.COMPLAINT_SEND_REQUEST:
      return {
        ...state,
        complaintSendLoading: true,
      };

    case supportTypes.COMPLAINT_SEND_SUCCESS:
      return {
        ...state,
        ...initialState,
        supportChat: {
          ...payload.chat,
        },
      };

    case supportTypes.COMPLAINT_SEND_FAILURE:
      return {
        ...state,
        ...initialState,
      };

    case supportTypes.CLEAR_SELECTED_COMPLAINT_STATE:
      return {
        ...state,
        selectedComplaint: {
          ...initialSelectedComplaint,
        },
      };
    case supportTypes.CLEAR_COMPLAINT_STATE:
      return {
        ...state,
        ...initialState,
      };

    case supportTypes.SHOW_SUPPORT_ON_STATUS_JOB:
      return {
        ...state,
        showSupportOnStatusJob: payload.show,
      };

    case supportTypes.SHOW_SUPPORT_ON_DEACTIVATED:
      return {
        ...state,
        showSupportOnDeactivated: payload.show,
      };

    case supportTypes.SHOW_SUPPORT_ON_BLOCKED:
      return {
        ...state,
        showSupportOnBlocked: payload.show,
      };

    case wsActionTypes.CHAT_CLOSED:
      return {
        ...state,
        supportChat: {
          ...state.supportChat,
          closed: true,
        },
      };
    default:
      return state;
  }
};

export const supportActions = {
  loadComplaintsList: (screen) => ({
    type: supportTypes.LOAD_COMPLAINTS_LIST_REQUEST,
    payload: {
      screen,
    },
  }),

  selectComplaint: (complaint) => ({
    type: supportTypes.SELECT_COMPLAINT,
    payload: {
      complaint,
    },
  }),

  complaintCommentChange: (value) => ({
    type: supportTypes.COMPLAINT_COMMENT_CHANGE,
    payload: {
      comment: value,
    },
  }),

  complaintSend: (complaint) => ({
    type: supportTypes.COMPLAINT_SEND_REQUEST,
    payload: {
      complaint,
    },
  }),

  clearSelectedComplaintState: () => ({
    type: supportTypes.CLEAR_SELECTED_COMPLAINT_STATE,
  }),

  clearComplaintState: () => ({
    type: supportTypes.CLEAR_COMPLAINT_STATE,
  }),

  toggleShowSupportOnStatusJob: (show) => ({
    type: supportTypes.SHOW_SUPPORT_ON_STATUS_JOB,
    payload: {
      show: show,
    },
  }),

  toggleShowSupportOnDeactivated: (show) => ({
    type: supportTypes.SHOW_SUPPORT_ON_DEACTIVATED,
    payload: {
      show: show,
    },
  }),

  toggleShowSupportOnBlocked: (show) => ({
    type: supportTypes.SHOW_SUPPORT_ON_BLOCKED,
    payload: {
      show: show,
    },
  }),
};

export const supportSelectors = {
  getComplaintsListLoading: (state) => state.support.complaintsListLoading,
  getComplaintsList: (state) => state.support.complaintsList,
  getSelectedComplaint: (state) => state.support.selectedComplaint,
  getComplaintSendLoading: (state) => state.support.complaintSendLoading,
  getShowSupportOnStatusJob: (state) => state.support.showSupportOnStatusJob,
  getShowSupportOnDeactivated: (state) =>
    state.support.showSupportOnDeactivated,
  getShowSupportOnBlocked: (state) => state.support.showSupportOnBlocked,
};
