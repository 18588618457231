import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.scss';
import onClickOutside from 'react-onclickoutside';

class Tooltip extends Component {
  state = {
    isOpen: false,
  };

  toggleTooltip = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeTooltip = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleClickOutside = () => {
    this.closeTooltip();
  };

  render() {
    const { text } = this.props;
    return (
      <div className={styles.tooltip}>
        <button onClick={this.toggleTooltip} className={styles.btn}>
          ?
        </button>
        {this.state.isOpen ? (
          <div onClick={this.closeTooltip} className={styles.text}>
            {text}
          </div>
        ) : null}
      </div>
    );
  }
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default onClickOutside(Tooltip);
