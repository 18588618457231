import moment from 'moment';

export const getLocalDateTime = (time) => {
  return moment(time, 'X').format('DD.MM.YY, HH:mm');
};

export const getLocalDateYearFull = (time) => {
  return moment(time, 'X').format('YYYY-MM-DD, HH:mm');
};

export const getLocalDateTimeFull = (time) => {
  return moment(time, 'X').format('DD.MM.YY, HH:mm:ss');
};

export const getPaymentDateTime = (time) => {
  return moment(time, 'X').format('DD/MM/YY, HH:mm');
};

export const getDateTextMonth = (time) => {
  return moment(time, 'X').format('MMMM DD, YYYY');
};

export const formatMomentDate = (date) => {
  if (date instanceof moment) {
    return moment(date).format('YYYY-MM-DD');
  }
};

export const formatMomentIntervalForCurrentTime = (time) => {
  return moment(time, 'X').valueOf() - moment().valueOf();
};

export const formatMomentIntervalForTime = (start) => {
  return moment().valueOf() - moment(start, 'X').valueOf();
};

export const formatMomentIntervalFromStartToEnd = (start, end) => {
  return moment(end, 'X').valueOf() - moment(start, 'X').valueOf();
};

export const MAX_DAYS_TO_MAKE_ORDER = 14;
