import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { PageContent, Svg } from '../../components/ui/index';
import { GreenButton } from '../../components/buttons/index';

import styles from './BlockedAccount.module.scss';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { userSelectors } from '../../reducers/user';
import { getPathForCreateNewTask } from '../../containers/routing/constants/pathHelpers';
import { supportActions, supportSelectors } from '../../reducers/support';
import CreateComplaint from '../../containers/support/CreateComplaint';
import Chat from '../chat/Chat';
import { Loader } from '../../components/ui';
import { chatActions, chatSelectors } from '../../reducers/chat';
import { dialogActions } from '../dialog/store';

class BlockedAccount extends Component {
  state = {
    isChat: false,
  };

  componentDidMount() {
    this.props.getSupportChat();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile && !this.props.profile.blocked) {
      this.props.history.replace(getPathForCreateNewTask());
    }

    if (
      prevProps.supportChat !== this.props.supportChat &&
      this.props.supportChat.id
    ) {
      this.props.setCurrentChat(this.props.supportChat.id);
    }

    if (
      prevProps.currentChatId !== this.props.currentChatId &&
      this.props.supportChat &&
      this.props.currentChatId === this.props.supportChat.id
    ) {
      this.props.getMessages({
        chatId: this.props.supportChat.id,
      });
    }

    if (
      prevProps.supportChat &&
      !prevProps.supportChat.closed &&
      this.props.supportChat &&
      this.props.supportChat.closed
    ) {
      if (this.state.isChat) this.props.openCloseSupportChatPopup();
    }
  }

  onBtnSupportClick = () => {
    this.props.toggleShowSupportOnBlocked(true);
  };

  renderBlocked = () => {
    const { t, profile } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.blockImg}>
          <Svg src={'blockedAccount'} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{t('accountIsBlocked')}</div>
          <div className={styles.text}>
            <p>{t('yourAccountIsBlocked')}</p>

            {profile && profile.unblock_date && (
              <p>{`${t('youWillBeUnblockAt')} ${profile.unblock_date}`}</p>
            )}
          </div>
          <div className={styles.btnWrapper}>
            <GreenButton onClick={this.onBtnSupportClick}>
              {t('contactSupport')}
            </GreenButton>
          </div>
        </div>
      </div>
    );
  };

  btnBackClick = () => {
    this.props.toggleShowSupportOnBlocked(false);
  };

  isUserMessage = (message) => {
    return message && message.user.id !== -1;
  };

  renderSupport = () => {
    const { supportChat, messages } = this.props;

    if (supportChat && (supportChat.unread !== 0 || !supportChat.closed)) {
      if (
        messages &&
        messages.length > 1 &&
        !messages.every(this.isUserMessage)
      ) {
        if (!this.state.isChat)
          this.setState({
            isChat: true,
          });
        return (
          <div className={styles.containerChat}>
            <Chat chatId={supportChat.id} />
          </div>
        );
      }
    }

    if (this.state.isChat)
      this.setState({
        isChat: false,
      });

    return (
      <div className={styles.container}>
        <CreateComplaint screen={'BLOCKED'} btnBackClick={this.btnBackClick} />
      </div>
    );
  };

  renderContent = () => {
    if (this.props.showSupportOnDeactivated) return this.renderSupport();

    return this.renderBlocked();
  };

  render() {
    const {
      t,
      profileLoading,
      supportChatLoading,
      messagesLoading,
    } = this.props;

    return (
      <PageContent>
        <Helmet>
          <title>{t('accountIsBlocked')}</title>
        </Helmet>

        <Loader
          loading={profileLoading || supportChatLoading || messagesLoading}
        >
          {this.renderContent()}
        </Loader>
      </PageContent>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      profile: userSelectors.getUserProfile(state),
      profileLoading: userSelectors.selectUserProfileLoading(state),
      showSupportOnDeactivated: supportSelectors.getShowSupportOnBlocked(state),
      supportChat: chatSelectors.getSupportChat(state),
      supportChatLoading: chatSelectors.getSupportChatLoading(state),
      messages: chatSelectors.getMessageList(state),
      messagesLoading: chatSelectors.isMessageListLoading(state),
      currentChatId: chatSelectors.getCurrentChatId(state),
    }),
    {
      toggleShowSupportOnBlocked: supportActions.toggleShowSupportOnBlocked,
      getSupportChat: chatActions.getSupportChat,
      getMessages: chatActions.getMessageList,
      setCurrentChat: chatActions.setCurrentChat,
      openCloseSupportChatPopup: dialogActions.openCloseSupportChatPopup,
    },
    null,
    { pure: false },
  ),
  withRouter,
)(BlockedAccount);
