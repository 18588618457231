import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { GreenButton } from '../../../components/buttons';

import styles from './CancelOrderError.module.scss';

class CancelOrderError extends Component {
  render() {
    const { error, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('cancelTheOrderError')}</div>
        <div className={styles.text}>
          {error ? error : t('cancelTheOrderError')}
        </div>
        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(CancelOrderError);
