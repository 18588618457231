import React from 'react';
import PropTypes from 'prop-types';
import getImage from './helpers';
import styles from './HexAvatar.module.scss';

const HexAvatar = ({ image, gender }) => {
  return (
    <div
      className={styles.avatarImage}
      style={{
        background: `url(${getImage(
          image,
          gender,
        )}) center center / cover no-repeat`,
      }}
    />
  );
};

HexAvatar.propTypes = {
  image: PropTypes.object,
  gender: PropTypes.string,
  green: PropTypes.bool,
};

export default HexAvatar;
