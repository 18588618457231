import { SelectedCategoryTypesEnum, ServiceTypesEnum } from 'utils/enum';

export enum ServiceActionEnum {
  RESET_STORE = 'serviceAction/RESET_STORE',

  SELECT_REPAIR_TASK = 'serviceAction/SELECT_REPAIR_TASK',
  SELECT_REPAIR_TASK_SUCCESS = 'serviceAction/SELECT_REPAIR_TASK_SUCCESS',
  SELECT_REPAIR_TASK_FAILURE = 'serviceAction/SELECT_REPAIR_TASK_FAILURE',

  SELECT_CLEANING_TASK = 'serviceAction/SELECT_CLEANING_TASK',
  SELECT_CLEANING_TASK_SUCCESS = 'serviceAction/SELECT_CLEANING_TASK_SUCCESS',
  SELECT_CLEANING_TASK_FAILURE = 'serviceAction/SELECT_CLEANING_TASK_FAILURE',

  SELECT_REPAIR_TASK_ITEM = 'serviceAction/SELECT_REPAIR_TASK_ITEM',
  SELECT_REPAIR_TASK_ITEM_SUCCESS = 'serviceAction/SELECT_REPAIR_TASK_ITEM_SUCCESS',
  SELECT_REPAIR_TASK_ITEM_FAILURE = 'serviceAction/SELECT_REPAIR_TASK_ITEM_FAILURE',

  SELECTED_SERVICE_OBJECT = 'serviceAction/SELECTED_SERVICE_OBJECT',
  SELECTED_SERVICE_OBJECT_SUCCESS = 'serviceAction/SELECTED_SERVICE_OBJECT_SUCCESS',
  SELECTED_SERVICE_OBJECT_FAILURE = 'serviceAction/SELECTED_SERVICE_OBJECT_FAILURE',

  UPLOAD_IMAGE_START = 'serviceAction/UPLOAD_IMAGE_START',
  UPLOAD_IMAGE_START_SUCCESS = 'serviceAction/UPLOAD_IMAGE_START_SUCCESS',
  UPLOAD_IMAGE_START_FAILURE = 'serviceAction/UPLOAD_IMAGE_START_FAILURE',

  CHANGE_ACTIVE_SERVICE_ID = 'serviceAction/CHANGE_ACTIVE_SERVICE_ID',
  CHANGE_ACTIVE_SERVICE_ID_SUCCESS = 'serviceAction/CHANGE_ACTIVE_SERVICE_ID_SUCCESS',
  CHANGE_ACTIVE_SERVICE_ID_FAILURE = 'serviceAction/CHANGE_ACTIVE_SERVICE_ID_FAILURE',

  DELETE_IMAGE = 'serviceAction/DELETE_IMAGE',

  CHANGE_SERVICE_COUNT = 'serviceAction/CHANGE_SERVICE_COUNT',

  SELECTED_SERVICE_TYPE = 'serviceAction/SELECTED_SERVICE_TYPE',

  SELECT_WINDOW_CLEANING_SERVICE_REGULAR = 'serviceAction/SELECT_WINDOW_CLEANING_SERVICE_REGULAR',

  SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE = 'serviceAction/SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE',

  CURRENT_CLEANING_SERVICE_TYPE = 'serviceAction/CURRENT_CLEANING_SERVICE_TYPE',
  CURRENT_CLEANING_SERVICE_TYPE_SUCCESS = 'serviceAction/CURRENT_CLEANING_SERVICE_TYPE_SUCCESS',

  CLEAR_CLEANING_SERVICE_TYPE = 'serviceAction/CLEAR_CLEANING_SERVICE_TYPE',
  ENTRANCE_CLEANING_FLOORS = 'serviceAction/ENTRANCE_CLEANING_FLOORS'
}

// TYPE DESCRIPTION
export type ServiceActionsType = {
  setActiveRepairService: (
    payload: number,
  ) => {
    type: ServiceActionEnum.SELECT_REPAIR_TASK;
    payload;
  };
  setActiveRepairServiceItem: (
    payload: number,
  ) => {
    type: ServiceActionEnum.SELECT_REPAIR_TASK_ITEM;
    payload;
  };
  setSelectedServiceObject: (
    payload: ServiceTreeType,
  ) => {
    type: ServiceActionEnum.SELECTED_SERVICE_OBJECT;
    payload;
  };
  setSelectedServiceType: (
    payload: SelectedCategoryTypesEnum,
  ) => {
    type: ServiceActionEnum.SELECTED_SERVICE_TYPE;
    payload: SelectedCategoryTypesEnum;
  };

  uploadImage: (payload: {
    base64: string;
    id: string;
    file: File;
  }) => {
    type: ServiceActionEnum.UPLOAD_IMAGE_START;
    payload: {
      base64: string;
      id: string;
      file: File;
    };
  };
  deleteImage: (
    payload: number,
  ) => {
    type: ServiceActionEnum.DELETE_IMAGE;
    payload: number;
  };
  changeServiceCount: (
    payload: number,
  ) => {
    type: ServiceActionEnum.CHANGE_SERVICE_COUNT;
    payload: number;
  };
  selectCleaningService: (
    payload: {
      serviceType: ServiceTypesEnum;
      increase: boolean;
    } & ServiceType,
  ) => {
    type: ServiceActionEnum.SELECT_CLEANING_TASK;
    payload: {
      serviceType: ServiceTypesEnum;
      increase: boolean;
    } & ServiceType;
  };
  selectWindowCleaningServiceRegular: (
    payload: boolean,
  ) => {
    type: ServiceActionEnum.SELECT_WINDOW_CLEANING_SERVICE_REGULAR;
    payload: boolean;
  };
  changeActiveServiceId: (
    payload: number,
  ) => {
    type: ServiceActionEnum.CHANGE_ACTIVE_SERVICE_ID;
    payload: number;
  };
  changeCurrentCleaningServiceType: () => {
    type: ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE;
  };
  clearCurrentCleaningServiceType: () => {
    type: ServiceActionEnum.CLEAR_CLEANING_SERVICE_TYPE;
  };
  shouldRenderAdditionalDetailsPage: (
    payload: boolean,
  ) => {
    type: ServiceActionEnum.SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE;
    payload: boolean;
  };
  resetStore: () => {
    type: ServiceActionEnum.RESET_STORE;
  };
  enteranceCleaningFloors: (
    payload: {
      service: ServiceTreeType,
      id: number,
      count: number
    }
  ) => {
    type: ServiceActionEnum.ENTRANCE_CLEANING_FLOORS;
    payload: {
      service: ServiceTreeType,
      id: number,
      count: number
    }
  }
};

// ACTIONS
export const ServiceActions: ServiceActionsType = {
  setActiveRepairService: (payload) => ({
    type: ServiceActionEnum.SELECT_REPAIR_TASK,
    payload,
  }),
  setActiveRepairServiceItem: (payload) => ({
    type: ServiceActionEnum.SELECT_REPAIR_TASK_ITEM,
    payload,
  }),
  setSelectedServiceObject: (payload) => ({
    type: ServiceActionEnum.SELECTED_SERVICE_OBJECT,
    payload,
  }),
  setSelectedServiceType: (payload) => ({
    type: ServiceActionEnum.SELECTED_SERVICE_TYPE,
    payload,
  }),
  uploadImage: (payload) => ({
    type: ServiceActionEnum.UPLOAD_IMAGE_START,
    payload,
  }),
  deleteImage: (payload) => ({
    type: ServiceActionEnum.DELETE_IMAGE,
    payload,
  }),
  changeServiceCount: (payload) => ({
    type: ServiceActionEnum.CHANGE_SERVICE_COUNT,
    payload,
  }),
  selectCleaningService: (payload) => ({
    type: ServiceActionEnum.SELECT_CLEANING_TASK,
    payload,
  }),
  selectWindowCleaningServiceRegular: (payload) => ({
    type: ServiceActionEnum.SELECT_WINDOW_CLEANING_SERVICE_REGULAR,
    payload,
  }),
  changeActiveServiceId: (payload) => ({
    type: ServiceActionEnum.CHANGE_ACTIVE_SERVICE_ID,
    payload,
  }),
  changeCurrentCleaningServiceType: () => ({
    type: ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE,
  }),
  clearCurrentCleaningServiceType: () => ({
    type: ServiceActionEnum.CLEAR_CLEANING_SERVICE_TYPE,
  }),
  shouldRenderAdditionalDetailsPage: (payload) => ({
    type: ServiceActionEnum.SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE,
    payload,
  }),
  resetStore: () => ({
    type: ServiceActionEnum.RESET_STORE,
  }),
  enteranceCleaningFloors: (payload) => ({
    type: ServiceActionEnum.ENTRANCE_CLEANING_FLOORS,
    payload,
  })
};
