import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InitialCheckbox from './InitialCheckbox';

import styles from './LabeledCheckboxNew.module.scss';

const LabeledCheckboxNew = (props) => (
  <div>
    <label className={styles.label}>
      <InitialCheckbox
        className={classNames(styles.check, {
          [styles.checkDisabled]: props.disabled,
        })}
        {...props}
      />
      {/* <span
        className={classNames(styles.pseudoCheck, {
          [styles.noLabel]: !props.label,
        })}
      /> */}

      <span className={styles.labelText} style={props.input.checked ? { color: '#343D47' } : {}}>{props.label}</span>
    </label>
  </div>
);

LabeledCheckboxNew.propTypes = {
  label: PropTypes.string,
};

export default LabeledCheckboxNew;
