import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Popup } from '../../components/ui';
import RestorePasswordForm from './components/RestorePasswordForm';
import RestoreConfirmPhone from './components/RestoreConfirmPhone';
import NewPasswordForm from './components/NewPasswordForm';
import { restorePasswordActions, restorePasswordSelectors } from './store';
import { RESTORE_PHONES_STEPS } from './store';

// import styles from './RestorePasswordView.module.scss';

const RESTORE_PHONE_FORM = 'restorePhoneForm';
const NEW_PASSWORD_FORM = 'newPasswordForm';

class RestorePasswordView extends Component {
  onPhoneSubmit = (values) => {
    return new Promise((resolve, reject) => {
      this.props.sendPhone(values.phone, reject);
    });
  };

  onNewPasswordSubmit = (values) => {
    return new Promise((resolve, reject) => {
      this.props.saveNewPassword(values, reject);
    });
  };

  renderSubmitPhoneForm = () => (
    <RestorePasswordForm
      form={RESTORE_PHONE_FORM}
      onSubmit={this.onPhoneSubmit}
    />
  );

  renderNewPasswordForm = () => (
    <NewPasswordForm
      form={NEW_PASSWORD_FORM}
      onSubmit={this.onNewPasswordSubmit}
      disabled={this.props.newPasswordLoading}
    />
  );

  renderCurrentStep() {
    switch (this.props.currentStep) {
      case RESTORE_PHONES_STEPS.SEND_PHONE:
        return this.renderSubmitPhoneForm();

      case RESTORE_PHONES_STEPS.CONFIRM_SMS:
        return <RestoreConfirmPhone />;

      case RESTORE_PHONES_STEPS.ENTER_NEW_PASSWORD:
        return this.renderNewPasswordForm();

      default:
        throw new Error('invalid RESTORE_PHONES_STEPS constant');
    }
  }

  render() {
    const { opened, title } = this.props;

    return (
      <Popup isOpen={opened} onClose={this.props.closeChangeRestorePassword}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {this.renderCurrentStep()}
      </Popup>
    );
  }
}

RestorePasswordView.propTypes = {
  opened: PropTypes.bool,
};

RestorePasswordView.defaultProps = {};

export default connect(
  (state) => ({
    opened: restorePasswordSelectors.isChangeRestorePhoneOpened(state),
    currentStep: restorePasswordSelectors.getCurrentStep(state),
    newPasswordLoading: restorePasswordSelectors.getNewPasswordLoading(state),
  }),
  restorePasswordActions,
)(RestorePasswordView);
