import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import styles from './Header.module.scss';
import { Svg } from '../../components/ui';
import { Search } from './components/search/index';
import UserBlock from './components/userBlock/UserBlock';
import SelectLang from './components/SelectLang';
import { createOrderSelectors } from '../../reducers/createOrder';
import NotificationsList from './components/notificationsList/NotificationsList';
import {
  notificationsListActions,
  notificationsListSelectors,
} from '../../reducers/notificationsList';
import { userSelectors } from '../../reducers/user';
import { RootReduxState } from 'store';
import LanguageDropdown from '../../components/language/LanguageDropDown';
import MobileLogo from 'assets/images/icons/MobileLogo';
import MobileMenu from 'assets/images/icons/MobileMenu';
import { BurgerMenu } from './BurgerMenu';

const Header = () => {
  const mailButton = useRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const {
    isOpen,
    newNotificationsCounter,
    userLoggedIn,
    tasks,
    deactivated,
    blocked,
  } = useSelector((state: RootReduxState) => ({
    tasks: createOrderSelectors.getTasks(state),
    notificationsList: notificationsListSelectors.getNotificationsList(state),
    newNotificationsCounter:
      notificationsListSelectors.getNewNotificationsCounter(state),
    isOpen: notificationsListSelectors.getNotificationsListIsOpen(state),
    deactivated: userSelectors.isUserDeactivated(state),
    blocked: userSelectors.isUserBlocked(state),
    userLoggedIn: userSelectors.isUserLoggedIn(state),
  }));

  const handleClickOutside = useCallback(
    (event) => {
      if (isOpen && !mailButton.current?.contains(event.target)) {
        if (!event.target.classList.contains('loadMore')) {
          dispatch(notificationsListActions.notificationsListHide());
        }
      }
    },
    [dispatch, isOpen],
  );

  const onOpen = useCallback(() => {
    if (!isOpen) {
      dispatch(notificationsListActions.notificationsListShow());
    } else {
      dispatch(notificationsListActions.notificationsListHide());
    }
  }, [dispatch, isOpen]);

  const renderNotificationsCounter = useCallback(() => {
    if (newNotificationsCounter > 0) {
      return (
        <span className={styles.counter}>
          {newNotificationsCounter > 99 ? '99+' : newNotificationsCounter}
        </span>
      );
    }
    return null;
  }, [newNotificationsCounter]);

  const homeLink = localStorage.getItem('lang');
  const [burgerMenu, setBurgerMenu] = useState(false);

  const handleClick = () => {
    setBurgerMenu(!burgerMenu);
  };
  return useMemo(
    () => (
      <header className={styles.header}>
        <div className={classNames(styles.mobile_profy_logo)}>
          <Link to="/">
            <MobileLogo />
          </Link>
        </div>
        <div className={classNames(styles.leftSide)}>
          <div className={styles.space} />
          <div className={classNames(styles.logoSearch, styles.dFlexWidth)}>
            <a
              href={`https://profy.ge/${homeLink ?? ''}`}
              className={classNames(styles.headerLogo, {
                [styles.headerLogoActive]: deactivated,
              })}
            >
              <Svg src={'logoWhite'} />
            </a>

            <div className={styles.headerSearch}>
              {!deactivated ? <Search /> : null}
            </div>
          </div>
        </div>
        <div className={classNames(styles.rightSide)}>
          <div className={classNames(styles.userInfoLang, styles.dFlexWidth)}>
            <div className={styles.userBlock}>
              <div className={styles.headerUserBlock}>
                <UserBlock />
              </div>

              {userLoggedIn && !deactivated ? (
                <Link className={styles.linkIcon} to={'/orders'}>
                  <Svg src={'document'} />
                  {tasks && tasks.length !== 0 ? (
                    <span className={styles.counter}>!</span>
                  ) : null}
                </Link>
              ) : null}

              {userLoggedIn && !deactivated && !blocked && (
                <div className={styles.notificationsContainer}>
                  <button
                    className={styles.linkIcon}
                    type={'button'}
                    onClick={onOpen}
                    ref={mailButton}
                  >
                    <Svg src={'envelope'} />
                    {renderNotificationsCounter()}
                  </button>

                  {/* <div className={styles.dropdownContainer}>
                    <div className={styles.dropdownHeader}>
                      <span className={styles.dropdownHeaderTitle}>
                        შეტყობინებები
                      </span>
                      <span className={styles.dropdownHeaderIcon}>
                        <Svg
                          className={styles.dropdownHeaderSVG}
                          src={'setting-gray'}
                        />
                      </span>
                    </div>
                  </div> */}
                  <NotificationsList handleClickOutside={handleClickOutside} />
                </div>
              )}
            </div>

            {process.env.REACT_APP_ENABLE_SELECT_LANG === 'true' && (
              <div className={styles.selectLang}>
                <LanguageDropdown instanceId={'header'} />
                {/* <SelectLang /> */}
              </div>
            )}

            {/* {userLoggedIn && !deactivated && !blocked && (
              <NotificationsList handleClickOutside={handleClickOutside} />
            )} */}
          </div>
          <div className={styles.space}></div>
        </div>
      </header>
    ),
    [
      blocked,
      deactivated,
      handleClickOutside,
      homeLink,
      onOpen,
      renderNotificationsCounter,
      tasks,
      userLoggedIn,
    ],
  );
};

export default Header;
