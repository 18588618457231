import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { taskersActions, taskersSelectors } from '../store';
import { Popup, HexAvatar, Rating } from '../../../components/ui';
import { InlineDatePicker } from '../../../components/inputs/date';
import { formatMomentDate } from '../../../utils/time';
import { ReactSelect } from '../../../components/inputs/select';
import { GreenButton } from '../../../components/buttons';
import { Loader } from '../../../components/ui';

import styles from './TaskerTimeTable.module.scss';

class TaskerTimeTable extends Component {
  state = {
    timeError: true,
    serverErrors: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.errors && this.props.errors) {
      this.setState({ serverErrors: true });
    }

    if (prevProps.errors && !this.props.errors) {
      this.setState({ serverErrors: false });
    }

    if (
      prevProps.selectedTime !== this.props.selectedTime ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.setState({ serverErrors: false });
    }

    if (prevProps.selectedTime && !this.props.selectedTime) {
      this.setState({ timeError: true });
    }
  }

  getTimetableForDate(date) {
    const { timetable } = this.props;
    const formattedDate = formatMomentDate(date);

    if (timetable && Array.isArray(timetable[formattedDate])) {
      if (timetable[formattedDate].length > 0) {
        return timetable[formattedDate];
      }
    }
    return null;
  }

  isOutsideRange = (date) => {
    return !this.getTimetableForDate(date);
  };

  formatTimetableToOptions = (timetable) => {
    return timetable.map((time) => ({ label: time, value: time }));
  };

  hideTimeError = () => {
    this.setState({
      timeError: false,
    });
  };

  renderSelectedDateError = (selectedDate) => {
    if (selectedDate) return;

    const { t } = this.props;

    return <div className={styles.errorDate}>{t('pleaseSelectDate')}</div>;
  };

  renderServerError = () => {
    if (!this.state.serverErrors) return;

    const { errors } = this.props;

    if (!errors) return;

    return errors.map((error) => (
      <div className={styles.error} key={error.message}>
        {error.message + ';'}
      </div>
    ));
  };

  renderError = () => {
    if (!this.state.timeError) return;

    const { t } = this.props;

    return <div className={styles.error}>{t('pleaseSelectTime')}</div>;
  };

  render() {
    const {
      taskerInfo,
      popupOpened,
      selectedDate,
      timetableLoading,
      selectedTime,
      orderId,
      tasker,
      t,
    } = this.props;
    const timeTableForDate = this.getTimetableForDate(selectedDate) || [];

    return (
      <Popup
        width={'391'}
        isOpen={popupOpened}
        onClose={this.props.closeTimetablePopup}
      >
        <div className={styles.container}>
          {taskerInfo && (
            <div className={styles.tasker}>
              <div className={styles.avatar}>
                <HexAvatar image={tasker.user.avatar} />
              </div>

              <div className={styles.headerInfo}>
                <div className={styles.headerTop}>
                  <div className={styles.name}>
                    {tasker.user.first_name} {tasker.user.last_name}
                  </div>
                </div>
                <div className={styles.headerBottom}>
                  {tasker.user.rating ? (
                    <Rating rating={tasker.user.rating} />
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <Loader loading={timetableLoading} height={535}>
            <div className={styles.inputsWrapper}>
              <div className={styles.inputTime}>
                <InlineDatePicker
                  focused={true}
                  value={selectedDate}
                  onChange={this.props.selectDateInTimetable}
                  isOutsideRange={this.isOutsideRange}
                  daySize={48}
                />
              </div>

              {this.renderSelectedDateError(selectedDate)}

              <ReactSelect
                placeholder={t('selectTime')}
                options={this.formatTimetableToOptions(timeTableForDate)}
                value={selectedTime}
                isDisabled={!selectedDate}
                maxMenuHeight={276}
                menuPlacement="top"
                selectProps={{
                  menuPlacement: 'top',
                }}
                onChange={(time) => {
                  this.hideTimeError();
                  this.props.selectTime(time);
                }}
                isSearchable={false}
              />

              {this.renderError()}

              {this.renderServerError()}
            </div>
          </Loader>

          <div className={styles.btnWrapper}>
            <div className={styles.btnSelect} onClick={() => {}}>
              <GreenButton
                disabled={!(selectedDate && selectedTime)}
                onClick={() => this.props.attachTasker(orderId)}
              >
                {t('selectAndContinue')}
              </GreenButton>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

TaskerTimeTable.propTypes = {
  taskerInfo: PropTypes.bool,
};

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      timetable: taskersSelectors.getTimetable(state),
      timetableLoading: taskersSelectors.getTimetableLoading(state),
      popupOpened: taskersSelectors.getTimetablePopupOpened(state),
      selectedDate: taskersSelectors.getSelectedDateInTimetable(state),
      selectedTime: taskersSelectors.getSelectedTime(state),
      tasker: taskersSelectors.getTimeTableTasker(state),
      errors: taskersSelectors.getErrors(state),
    }),
    taskersActions,
  ),
)(TaskerTimeTable);
