import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

import {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  getMapMarker,
} from '../../../utils/mapUtils';
import { createOrderAddressActions } from '../store';
import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';
import { withAuthData } from '../../hoc';
import Loader from '../../../components/ui/Loader';

import styles from './AddressesMap.module.scss';
import { Svg } from '../../../components/ui';
import { createOrderSelectors } from '../../../reducers/createOrder';

const defaultOptions = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: true,
  rotateControl: false,
  fullscreenControl: false,
};

class AddressesMap extends Component {
  state = {
    center: this.props.selectedAddress.location || DEFAULT_CENTER,
    marker: null,
  };

  map = React.createRef();

  componentDidMount() {
    if (this.props.orderAddress.location) {
      this.props.initializeOrderAddressState(this.props.orderAddress);
      this.setState({
        center: this.props.orderAddress.location,
        marker: this.props.orderAddress.location,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedAddress &&
      prevProps.selectedAddress.location !== this.props.selectedAddress.location
    ) {
      if (this.props.selectedAddress.mapClick) {
        this.setState({
          marker: this.props.selectedAddress.location,
        });
      } else {
        this.setState({
          center: this.props.selectedAddress.location,
          marker: this.props.selectedAddress.location,
        });
      }

      this.props.changeAddressObj(this.props.selectedAddress);
    }
  }

  getUserLocation = () => {
    const geoSuccess = (position) => {
      this.props.setNewAddressLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      this.props.changeAddressObj(this.props.selectedAddress);

      this.props.changeSelectedAddressLocation(
        this.props.selectedAddress.location,
      );
    };

    const geoError = (error) => {
      console.error('Error get current position: ', error);
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  };

  onMapClick = (point) => {
    this.props.setNewAddressLocation(point.latLng.toJSON(), true);
  };

  render() {
    return (
      <div>
        <GoogleMap
          ref={this.map}
          defaultZoom={DEFAULT_ZOOM}
          center={this.state.center}
          onClick={this.onMapClick}
          defaultOptions={defaultOptions}
        >
          <Marker icon={getMapMarker()} position={this.state.marker} />
        </GoogleMap>

        <button className={styles.btnMyLocation} onClick={this.getUserLocation}>
          <Svg src={'target'} className={styles.btnMyLocationIcon} />
        </button>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearNewAddress();
    this.props.deleteAddressObj();
  }
}

const mapStateToProps = (state) => ({
  orderAddress: createOrderSelectors.getOrderAddress(state),
  selectedAddress: addressesSelectors.getSelectedAddress(state),
});

export default compose(
  withAuthData,
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,

    loadingElement: <Loader loading={true} />,
    containerElement: <div style={{ height: `calc(100vh - 291.4px)` }} />,
    mapElement: <div style={{ height: `calc(100vh - 291.4px)` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  connect(mapStateToProps, {
    ...createOrderAddressActions,
    ...addressesActions,
  }),
)(AddressesMap);
