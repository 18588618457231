import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createTaskSelectors, createTaskActions } from './store';
import QuestionPage from './QuestionPage';
import CreateTaskFinalPage from './CreateTaskFinalPage';
import { getTaskIdFromProps } from '../routing/constants/pathHelpers';
import TaskSidebar from './components/TaskSidebar';
import ChooseCategory from './ChooseCategory';
import { Columns, Loader, PageContent } from '../../components/ui';
import { userSelectors } from '../../reducers/user';
import { dialogActions } from '../dialog/store';
import { createOrderSelectors } from '../../reducers/createOrder';

class CreateTask extends PureComponent {
  componentDidMount() {
    const {
      questionIsFinish,
      categories,
      userLoggedIn,
      guestTokenLoaded,
    } = this.props;
    const taskId = getTaskIdFromProps(this.props);
    if (taskId && !questionIsFinish) {
      this.props.loadTask(taskId);
    }

    if (categories.length === 0) {
      if (userLoggedIn || guestTokenLoaded) {
        this.props.loadCategories();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { categories, userLoggedIn, guestTokenLoaded } = this.props;
    // debugger;

    if (categories.length === 0) {
      if (
        (!prevProps.userLoggedIn && userLoggedIn) ||
        (!prevProps.guestTokenLoaded && guestTokenLoaded)
      ) {
        this.props.loadCategories();
      }
    }

    if (
      this.props.orderId &&
      this.props.taskOrderId &&
      this.props.orderId !== this.props.taskOrderId
    ) {
      this.props.openSubmitOrderErrorPopup();
    }
  }

  isCategoryNeedToBeSelected() {
    const { questionIsFinish, categoryId } = this.props;
    // debugger;

    return !questionIsFinish && !categoryId;
  }

  renderChooseCategory() {
    return (
      <Loader
        loading={this.props.categories.length === 0}
        containerWithHeight={181}
      >
        <ChooseCategory />
      </Loader>
    );
  }

  renderContent() {
    const { questionIsFinish } = this.props;
    const taskId = this.props.match.params.taskId;

    if (taskId) {
      return (
        <div>
          <Columns
            aside={<TaskSidebar taskId={taskId} />}
            mainContent={<CreateTaskFinalPage />}
          />
          <Columns
            aside={<TaskSidebar taskId={taskId} />}
            mainContent={<CreateTaskFinalPage />}
          />
          <Columns
            aside={<TaskSidebar taskId={taskId} />}
            mainContent={<CreateTaskFinalPage />}
          />
        </div>
      );
    }

    if (this.isCategoryNeedToBeSelected()) {
      return this.renderChooseCategory();
    }

    return (
      <Columns
        aside={<TaskSidebar />}
        mainContent={
          questionIsFinish ? <CreateTaskFinalPage /> : <QuestionPage />
        }
      />
    );
  }

  render() {
    const { t } = this.props;

    return (
      <PageContent
        title={
          this.isCategoryNeedToBeSelected()
            ? t('selectCategory')
            : t('describeYourTask')
        }
      >
        {this.renderContent()}
      </PageContent>
    );
  }

  componentWillUnmount() {
    this.props.clearState();
  }
}

const mapStateToProps = (state) => ({
  categoryId: createTaskSelectors.getSelectedCategoryId(state),
  categories: createTaskSelectors.getCategories(state),
  questionIsFinish: createTaskSelectors.getQuestionIsFinish(state),
  userLoggedIn: userSelectors.isUserLoggedIn(state),
  guestTokenLoaded: userSelectors.isGuestTokenLoaded(state),
  taskOrderId: createTaskSelectors.getTaskOrderId(state),
  orderId: createOrderSelectors.getOrderId(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    ...createTaskActions,
    openSubmitOrderErrorPopup: dialogActions.openSubmitOrderErrorPopup,
  }),
)(CreateTask);
