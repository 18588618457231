import React, { Component, Fragment } from 'react';

import styles from './PhoneView.module.scss';
import { Svg } from '../index';
import { Social } from '../../social';
import { BlackMobileButton } from '../../buttons';
import { removeInitialLoader } from '../../../utils/removeInitialLoader';
import Loader from '../Loader';
import { withTranslation } from 'react-i18next';

class PhoneView extends Component {
  componentDidMount() {
    removeInitialLoader();
    document.body.style.minWidth = 'auto';
  }

  renderLinks = () => {
    return (
      <Fragment>
        <div className={styles.text}>
          In order to get the best possible experience we recommend to use
          mobile apps.
        </div>
        <div>
          <div className={styles.button}>
            <BlackMobileButton
              iconName="apple"
              smallText="Available on the"
              bigText="App Store"
            />
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.profy505.customer">
            <BlackMobileButton
              iconName="googlePlay"
              smallText="Android app on"
              bigText="Google Play"
            />
          </a>
        </div>
      </Fragment>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Svg src="logoWhite" />
        </div>
        <div className={styles.content}>
          <Loader loading={true} />
          <p>{t('paymentLoadingText')}</p>
        </div>
        <div className={styles.footer}>
          <Social />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PhoneView);
