import { takeEvery, call, put, take, fork, select } from 'redux-saga/effects';
import { SubmissionError, initialize } from 'redux-form';
import { replace } from 'connected-react-router';

import { SIGN_UP_FORM } from '../containers/signup/SignUpForm';
import { userActionTypes, userActions } from '../reducers/user';
import { actions } from '../reducers/signup';
import { parseServerErrorsToForm } from '../utils/parseServerErrorToForm';
import {
  removeToken,
  saveToken,
  getGuestToken,
  saveGuestToken,
  getToken,
} from '../utils/authUtil';
import * as authService from '../services/authService';
import { logout } from '../services/userService';
import { putInitialAuthorizedActions } from './onAuthLoadSaga';
import { getPathForDeactivated } from '../containers/routing/constants/pathHelpers';
import { dialogActions } from '../containers/dialog/store';
import { chatSelectors } from '../reducers/chat';
import { wsActions } from './websocketSaga';

function* signIn({ payload: { values, resolve, reject } }) {
  const phone = values.phone.replace(/ /g, '');
  yield put(userActions.setPhone(phone));

  try {
    const phone_exists_response = yield call(authService.checkPhoneExists, {
      phone: phone,
    });

    if (phone_exists_response.phone_exists) {
      yield put(userActions.setSignInModeSignIn());
      const session = yield call(authService.sendConfirmationCode, {
        phone: phone,
      });
      yield put(userActions.setSession(session.session));
      yield put(userActions.closeSignInPopup());
      yield put(actions.openConfirmPhonePopup());
    } else {
      const session = yield call(authService.signUp, {
        signup_mode: 'PASSWORD',
        phone: phone,
      });
      yield put(userActions.setSession(session.session));
      yield put(userActions.closeSignInPopup());
      yield put(actions.openConfirmPhonePopup());
      yield put(userActions.setSignInModeSignUp());
    }
  } catch (error) {
    yield put({ type: userActionTypes.SIGN_IN_FAILURE, error });

    yield call(
      reject,
      new SubmissionError(parseServerErrorsToForm(error.errors)),
    );
  }
}

export function* watchSignIn() {
  yield takeEvery(userActionTypes.SIGN_IN_REQUEST, signIn);
}

function* onLogout() {
  const currentChatId = yield select(chatSelectors.getCurrentChatId);
  console.log('%c currentChatId = ', 'color: blue', currentChatId);

  if (currentChatId) {
    console.log(
      '%c LOGOUT LEAVE CHAT  currentChatId = ',
      'color: blue',
      currentChatId,
    );
    yield put(wsActions.leaveChat(currentChatId));
  }

  yield call(logout, {
    platform: 'WEB',
    token: getToken(),
  });

  yield call(removeToken);

  if (window.OneSignal) {
    window.OneSignal.push(function () {
      window.OneSignal.setSubscription(false);
      // window.OneSignal.database.rebuild()
    });
  }

  window.location.href = '/';
}

export function* watchLogout() {
  yield takeEvery(userActionTypes.LOGOUT, onLogout);
}

export function* watchSignInSocial() {
  while (true) {
    try {
      const { payload } = yield take(userActionTypes.SIGN_IN_SOCIAL_REQUEST);

      const authData = {
        token: payload.accessToken,
        social: payload.social,
        guest_session: getGuestToken(),
      };

      const data = yield call(authService.signInSocial, authData);

      if (data.token) {
        yield call(saveToken, data.token);
        yield put(userActions.signInSocialSuccess(data));

        yield put(userActions.closeSignInPopup());
        yield put(actions.closeSignUpPopup());

        if (data.status === 'DEACTIVATED') {
          yield put(replace(getPathForDeactivated()));
        } else {
          yield fork(putInitialAuthorizedActions);
        }
      } else {
        const { social_user } = yield call(authService.signUpSocial, authData);
        yield put(userActions.closeSignInPopup());
        yield put(
          actions.changeSignUpMode(payload.social, payload.accessToken),
        );
        yield put(
          initialize(SIGN_UP_FORM, {
            first_name: social_user.name,
            last_name: social_user.surname,
            gender: social_user.gender,
            email: social_user.email,
            phone: social_user.phone || '+995',
            avatar_id: social_user.photo ? social_user.photo.id : null,
          }),
        );
      }
    } catch (e) {
      yield put({ type: userActionTypes.SIGN_IN_SOCIAL_FAILURE });
    }
  }
}

export function* onUserLoggedInCheck() {
  const { userLoggedIn } = yield take(userActionTypes.CHECK_USER_LOGGED_IN);

  const guestToken = yield call(getGuestToken);

  if (!userLoggedIn && !guestToken) {
    yield put(userActions.loadGuestToken());
  }
}

export function* onGuestTokenLoad() {
  try {
    yield take(userActionTypes.LOAD_GUEST_TOKEN_REQUEST);

    const { token } = yield call(authService.loadGuestToken);

    yield call(saveGuestToken, token);
    yield put({ type: userActionTypes.LOAD_GUEST_TOKEN_SUCCESS });
  } catch (error) {
    yield put({ type: userActionTypes.LOAD_GUEST_TOKEN_FAILURE });
  }
}
