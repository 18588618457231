import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  notificationsListActions,
  notificationsListActionTypes,
  notificationsListSelectors,
} from '../reducers/notificationsList';
import {
  getNotificationsList,
  updateNotificationsList,
} from '../services/notificationsListService';
import { userSelectors } from '../reducers/user';

function* onNotificationsListShow() {
  try {
    document.body.style.overflow = 'hidden';

    yield put(notificationsListActions.notificationsListShowSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* onNotificationsListHide() {
  try {
    document.body.style.overflow = 'auto';

    const timeDifference = yield select(
      userSelectors.getTimeDifferenceWithServer,
    );

    localStorage.setItem(
      'lastNotificationSeen',
      Date.now() + timeDifference * 1000,
    );

    yield put(notificationsListActions.notificationsListHideSuccess());
  } catch (error) {
    console.error(error);
  }
}

function* onGetNotificationsList({ payload: { count = 20 } }) {
  try {
    const notificationsList = yield select(
      notificationsListSelectors.getNotificationsList,
    );
    const lastId =
      notificationsList && notificationsList.length > 0
        ? notificationsList[notificationsList.length - 1].id
        : 0;

    let { collection, has_more } = yield call(getNotificationsList, {
      count,
      lastId,
    });

    if (notificationsList) {
      collection = [...notificationsList, ...collection];
    }

    yield put(
      notificationsListActions.getNotificationsListSuccess({
        collection,
        has_more,
      }),
    );
  } catch (error) {
    console.error(error);
    yield put(notificationsListActions.getNotificationsListFailure());
  }
}

function* onUpdateNotificationsList({ payload: { count = 20 } }) {
  try {
    const notificationsList = yield select(
      notificationsListSelectors.getNotificationsList,
    );
    const has_more = yield select(
      notificationsListSelectors.getNotificationsListHasMore,
    );

    let { collection } = yield call(updateNotificationsList, { count });

    if (notificationsList) {
      collection = [...collection, ...notificationsList];
    }

    yield put(
      notificationsListActions.updateNotificationsListSuccess({
        collection,
        has_more,
      }),
    );
  } catch (error) {
    console.error(error);
    yield put(notificationsListActions.updateNotificationsListFailure());
  }
}

export function* watchNotificationsListShow() {
  yield takeEvery(
    notificationsListActionTypes.NOTIFICATIONS_LIST_SHOW,
    onNotificationsListShow,
  );
}

export function* watchNotificationsListHide() {
  yield takeEvery(
    notificationsListActionTypes.NOTIFICATIONS_LIST_HIDE,
    onNotificationsListHide,
  );
}

export function* watchGetNotificationsList() {
  yield takeEvery(
    notificationsListActionTypes.GET_NOTIFICATIONS_LIST_REQUEST,
    onGetNotificationsList,
  );
}

export function* watchUpdateNotificationsList() {
  yield takeEvery(
    notificationsListActionTypes.UPDATE_NOTIFICATIONS_LIST_REQUEST,
    onUpdateNotificationsList,
  );
}
