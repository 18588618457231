import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import AvatarEditor from 'react-avatar-editor';

import PinkButton from '../../../components/buttons/PinkButton';
import { GreenButton } from '../../../components/buttons';
import connect from 'react-redux/es/connect/connect';
import { userActions } from '../../../reducers/user';

import styles from './CropImage.module.scss';

class CropImage extends Component {
  state = {
    scale: 1,
  };

  onScaleChange = (e) => {
    this.setState({
      scale: e.target.value,
    });
  };

  onClickSave = () => {
    if (this.editor) {
      this.props.updateProfileBase({
        filename: this.props.image.name,
        content: this.editor.getImage().toDataURL(),
      });

      this.props.onClose();
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    const { image, t } = this.props;

    return (
      <div className={styles.container}>
        <div>
          <div className={styles.title}>{t('cropImage')}</div>

          <AvatarEditor
            ref={this.setEditorRef}
            image={image}
            width={400}
            height={400}
            border={100}
            scale={+this.state.scale}
            className={styles.avatar}
          />

          <div className={styles.controls}>
            <label>
              {t('scaleImage')}:
              <input
                type="range"
                min={1}
                max={5}
                step={0.1}
                value={this.state.scale}
                onChange={this.onScaleChange}
              />
            </label>
          </div>

          <div className={styles.btnContainer}>
            <PinkButton onClick={this.props.onClose}>{t('cancel')}</PinkButton>
            <GreenButton onClick={this.onClickSave}>{t('save')}</GreenButton>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    updateProfileBase: userActions.updateProfileBase,
  }),
)(CropImage);
