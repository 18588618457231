import isPlainObject from 'lodash/isPlainObject';

export const parseServerErrorsToForm = (errors) => {
  if (isPlainObject(errors)) {
    const formattedErrors = {};
    for (let field in errors) {
      if (
        errors.hasOwnProperty(field) &&
        Array.isArray(errors[field]) &&
        errors[field].length !== 0 &&
        errors[field][0].message
      ) {
        formattedErrors[field] = errors[field][0].message;
      }
    }
    return formattedErrors;
  }
  return {};
};
