import * as React from "react";

const MobileMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={12}
    viewBox="0 0 24 12"
    {...props}
  >
    <defs>
      <style>{".a{fill:#343d47;}"}</style>
    </defs>
    <g transform="translate(0 -182.856)">
      <circle
        className="a"
        cx={1.2}
        cy={1.2}
        r={1.2}
        transform="translate(21.6 192.456)"
      />
      <rect
        className="a"
        width={19.2}
        height={2.4}
        rx={1.2}
        transform="translate(0 192.456)"
      />
      <rect
        className="a"
        width={24}
        height={2.4}
        rx={1.2}
        transform="translate(0 182.856)"
      />
    </g>
  </svg>
);

export default MobileMenu;