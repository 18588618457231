export const createFormData = (data) => {
  let formData = new FormData();

  if (data instanceof File) {
    formData.append('image', data);
  }

  return formData;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
