import React from 'react';

const NoServicesFound = () => (
  <h3>
    No services found. This issue is likely due to the answers config. Go back
    and try another answers.
  </h3>
);

export default NoServicesFound;
