import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { HexAvatar, Rating, Svg } from '../../../components/ui';
import { GreenButtonTransparent } from '../../../components/buttons';
import { isAddingReviewAvailable } from '../helpers/checkers';
import { getPathForOrderReview } from '../../routing/constants/pathHelpers';
import styles from './TaskerCard.module.scss';
import { isCurrentOrder } from '../helpers/helper';

type TaskerCardPropsType = {
  order: OrderType;
  small?: boolean;
  noRating?: boolean;
  nameLight?: boolean;
  avatarGreen?: boolean;
};
const TaskerCard = ({
  order,
  small,
  noRating,
  nameLight,
  avatarGreen,
}: TaskerCardPropsType) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { tasker } = order;

  function renderRating() {
    if (tasker?.rating === 0 || noRating) return;

    return (
      <div className={styles.rating}>
        <Rating rating={tasker?.rating} />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.container, { [styles.cardSmall]: small })}
    >
      <div className={styles.avatar}>
        <HexAvatar
          image={tasker?.avatar}
          gender={tasker?.gender}
          green={avatarGreen}
        />
      </div>
      <div className={styles.body}>
        <div
          className={classNames(styles.name, { [styles.nameLight]: nameLight })}
        >
          {tasker?.first_name} {tasker?.last_name}
        </div>

        {isAddingReviewAvailable(order) && (
          <Link
            to={getPathForOrderReview(order.id)}
            className={styles.taskerAddReview}
          >
            <GreenButtonTransparent small>
              {t('addReview')}
            </GreenButtonTransparent>
          </Link>
        )}
      </div>

      {!small && order && isCurrentOrder(order) ? (
        <div
          onClick={() => push('./chat')}
          className={` ${styles.chatIconContainer} ${styles.rating}`}
        >
          <Svg src="comment-icon__order-details" />
        </div>
      ) : (
        !small && renderRating()
      )}
    </div>
  );
};

TaskerCard.propTypes = {
  tasker: PropTypes.object,
  small: PropTypes.bool,
  noRating: PropTypes.bool,
  nameLight: PropTypes.bool,
  avatarGreen: PropTypes.bool,
};

export default TaskerCard;
