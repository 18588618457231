import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import { GreenButton } from '../../../components/buttons';

import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';

import styles from './SubmitOrderSuccess.module.scss';
import { Loader } from '../../../components/ui';

class SubmitOrderSuccess extends Component {
  componentDidMount() {
    ReactGA.event({
      category: 'event',
      action: 'click',
      label: 'gadaxda',
    });
  }
  render() {
    const { orderId, submitOrderLoader, t } = this.props;
    return (
      <div className={styles.container} id="paymentsuccesspopup">
        <div className={styles.title}>
          {submitOrderLoader
            ? t('paymentInProcess') + '...'
            : t('orderSubmitSuccess')}
        </div>

        <Loader loading={submitOrderLoader} height={204}>
          <div className={styles.text}>
            {`${t('orderSubmitSuccessTextStart')} #${orderId} ${t(
              'orderSubmitSuccessTextEnd',
            )}`}
          </div>
          <div className={styles.btn}>
            <GreenButton
              onClick={() => {
                this.props.onClose();
              }}
            >
              {t('OK')}
            </GreenButton>
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      submitOrderLoader: createOrderSelectors.getSubmitOrderLoader(state),
    }),
    {
      createEmptyOrder: createOrderActions.createEmptyOrder,
    },
  ),
)(SubmitOrderSuccess);
