import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../../../utils/mapUtils';
import { getMapMarker } from '../../../utils/mapUtils';
import Loader from '../../../components/ui/Loader';

const defaultOptions = {
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: true,
  rotateControl: false,
  fullscreenControl: false,
  draggable: true,
  draggableCursor: 'pointer',
};

class TaskerLocationMap extends Component {
  render() {
    const { center } = this.props;

    return (
      <GoogleMap
        defaultZoom={DEFAULT_ZOOM}
        center={center || DEFAULT_CENTER}
        defaultOptions={defaultOptions}
      >
        {center ? <Marker position={center} icon={getMapMarker()} /> : null}
      </GoogleMap>
    );
  }
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,

    loadingElement: <Loader loading={true} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `400px` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(TaskerLocationMap);
