import React, { Component } from 'react';

import { Redirect } from 'react-router';
import { withRouter, Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import Raven from 'raven-js';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';

import { ORDERS } from './containers/routing/constants/routes';

import Orders from './containers/ordersList';
import Order from './containers/order';
import Dialog from './containers/dialog';
import { PaymentResult } from './containers/createOrder';
import CreateOrderPayment from './containers/createOrderPayment';
import CreateOrderTaskers from './containers/createOrderTaskers';
import CreateOrderMap from './containers/createOrderMap';
import CreateTask from './containers/createTask';

import {
  NotFound,
  ErrorScreen,
  Loader,
  AfterPaymentView,
  Success,
} from './components/ui';
import Header from './containers/header/Header';
import RestorePassword from './containers/restorePassword';
import GuestPart from './containers/routing/GuestPart';
import { userSelectors } from './reducers/user';
import { onAuthorizedLoadActions } from './reducers/onLoadActions';
import { removeInitialLoader } from './utils/removeInitialLoader';
import styles from './containers/routing/Routes.module.scss';
import { ordersSelectors } from './reducers/ordersList';
import {
  getOrderIdFromProps,
  getPathForBlocked,
} from './containers/routing/constants/pathHelpers';

import initPush from './push/oneSignalSubscribe';
import { createOrderActions } from './reducers/createOrder';
import { BlockedAccount } from './containers/blockedAccount';
import { DeactivatedAccount } from './containers/deactivatedAccount';
import { paymentsActions } from './reducers/payments';
import { statuses } from './containers/order/helpers/orderConstants';
import { setPushToken } from './services/userService';
import { CreateTaskFullFlow } from 'containers/createTaskFullFlow';
import { lazyLoadable } from 'utils';
import { withAuthData } from 'containers/hoc';
import ProfileContainerRoutes from 'containers/profileContainer';
import { dialogActions } from 'containers/dialog/store';
import { ProfileEditLazy } from 'containers/profileEdit';
import AddBankResult from './containers/bankCard/AddBankResult';
import { BurgerMenu } from 'containers/header/BurgerMenu';
import DonationFeedback from 'containers/profile/components/DonationFeedback';

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-5C435W3',
  });

  ReactGA.initialize('UA-126648679-1', {
    debug: true,
  });
}

class App extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      Raven.captureException(error, { extra: errorInfo });
    }
  }

  updateLangPushes = (lang) => {
    try {
      const token = window.localStorage.getItem('oneSignalUserId');

      if (token) {
        setPushToken({
          platform: 'WEB',
          token,
          language: lang,
        });
      }
    } catch (error) {
      console.log('%c Error updateLangPushes: ', error);
    }
  };

  componentDidMount() {
    const { history, t, openProfyFeedbackDialog } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);

    removeInitialLoader();

    // openProfyFeedbackDialog(87707);

    const { dispatch, userLoggedIn } = this.props;

    if (userLoggedIn) {
      onAuthorizedLoadActions.forEach((action) => dispatch(action()));
      this.redirectAfterBankOperation(dispatch);

      initPush(t);
      const language = window.localStorage.getItem('lang');
      this.updateLangPushes(
        language === 'en' ? 'EN' : language === 'ru' ? 'RU' : 'GE',
      );
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { dispatch, history, t } = this.props;

    if (!prevProps.guestTokenLoaded && this.props.guestTokenLoaded) {
      dispatch(createOrderActions.loadEditingOrder());
    }

    if (!prevProps.userLoggedIn && this.props.userLoggedIn) {
      initPush(t);
    }

    if (this.props.profile && this.props.profile.blocked) {
      if (history.location.pathname !== getPathForBlocked()) {
        history.replace(getPathForBlocked());
      }
    }

    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);

    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }

    let user_data;
    if (Object.keys(this.props.profile).length > 0) {
      user_data = {
        user_id: this.props.profile.id,
        email: this.props.profile.email,
        name:
          this.props.profile.first_name + ' ' + this.props.profile.last_name,
        company: ' ',
        phone: this.props.profile.phone,
        logged_id: true,
      };
    } else {
      user_data = {
        logged_id: false,
      };
    }

    document.cookie = `user_info=${JSON.stringify(
      user_data,
    )};max-age=604800;path=/`;
  }

  redirectAfterBankOperation = (dispatch) => {
    const redirect = JSON.parse(
      window.localStorage.getItem('redirectToCardCreate'),
    );
    if (redirect) {
      dispatch(paymentsActions.checkCardRequest(redirect));
    }
  };

  handleLocationChange = (location) => {
    const { completedOrders, openProfyFeedbackDialog, dialogType } = this.props;
    const currentOrderId = getOrderIdFromProps(this.props);
    let orderWithoutFeedback = null;

    if (completedOrders && completedOrders.length > 0) {
      orderWithoutFeedback = completedOrders.filter(
        (order) =>
          order.status === statuses.COMPLETED && !order.customer_feedback,
      );
    }

    if (
      orderWithoutFeedback &&
      orderWithoutFeedback.length > 0 &&
      location.pathname.lastIndexOf('/review') === -1
    ) {
      if (
        currentOrderId === null ||
        currentOrderId !== orderWithoutFeedback[0].id
      ) {
        dialogType !== 'PROFY_FEEDBACK' &&
          openProfyFeedbackDialog(orderWithoutFeedback[0].id);
        // history.push(getPathForOrderReview(orderWithoutFeedback[0].id));
      }
    }
  };

  renderError = () => {
    return <ErrorScreen />;
  };

  renderRoutes() {
    const { userLoggedIn, guestTokenLoaded, userDeactivated } = this.props;
    if (userLoggedIn) {
      return (
        <Switch>
          <Route
            path={'/taskers'}
            component={lazyLoadable(
              () => import('./containers/createOrderTaskers'),
            )}
          />
          {/*change on custom routes*/}
          <Route path={'/product/:step?/:id?'} component={CreateTaskFullFlow} />
          <Route path={'/map'} component={CreateOrderMap} />
          <Route exact path={'/map/create'} component={CreateOrderMap} />
          <Route exact path={'/task/:taskId'} component={CreateTask} />
          <Route exact path={'/task'} component={CreateTask} />
          <Route exact path={'/payment'} component={CreateOrderPayment} />
          <Redirect exact from="/" to="/product" />
          <Route exact path={'/order/success'} component={PaymentResult} />
          <Route exact path={'/order/failure'} component={PaymentResult} />
          <Route exact path={'/card/success'} component={AddBankResult} />
          <Route exact path={'/card/failure'} component={AddBankResult} />
          <Route
            path={'/donation/ok'}
            render={(props) => <DonationFeedback {...props} susses={true} />}
          />
          <Route
            path={'/donation/fail'}
            render={(props) => <DonationFeedback {...props} susses={false} />}
          />
          <Route
            exact
            path={'/settings/profile/edit'}
            component={ProfileEditLazy}
          />
          <Route path={'/settings/:type?'} component={ProfileContainerRoutes} />
          <Route exact path={ORDERS} component={Orders} />
          <Route path={ORDERS + '/:id'} component={Order} />
          <Route path={'/deactivated'} component={DeactivatedAccount} />
          <Route path={'/blocked'} component={BlockedAccount} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    if (guestTokenLoaded || userDeactivated) {
      return (
        <Switch>
          <Route
            exact
            path={'/payment-in-process'}
            component={AfterPaymentView}
          />
          <Route exact path={'/taskers'} component={CreateOrderTaskers} />
          <Route exact path={'/map'} component={CreateOrderMap} />
          <Route exact path={'/map/create'} component={CreateOrderMap} />
          <Route path={'/product/:step?/:id?'} component={CreateTaskFullFlow} />
          <Route exact path={'/task/:taskId'} component={CreateTask} />
          <Route exact path={'/payment'} component={CreateOrderPayment} />
          <Redirect exact from="/" to="/product" />
          <Redirect from="/order/success" to="/?signin" />
          <Redirect from="/order/failure" to="/?signin" />
          <Redirect from="/settings/addresses" to="/?signin" />
          <Redirect from="/settings/addresses/:id" to="/?signin" />
          <Redirect from="/settings" to="/?signin" />
          <Redirect from="/orders" to="/?signin" />
          <Redirect from="/orders/:id" to="/?signin" />
          <Route path={'/deactivated'} component={DeactivatedAccount} />
          <Route exact path={'/card/success'} component={AddBankResult} />
          <Route exact path={'/card/failure'} component={AddBankResult} />
          <Route
            path={'/donation/ok'}
            render={(props) => <DonationFeedback {...props} susses={true} />}
          />
          <Route
            path={'/donation/fail'}
            render={(props) => <DonationFeedback {...props} susses={false} />}
          />
          <Redirect to="/product/superCategory" />
          {/* <Route component={NotFound} /> */}
        </Switch>
      );
    }

    return <Loader height={500} loading />;
  }

  render() {
    const { logoutLoader, userLoggedIn } = this.props;

    const pathnameEnd = window.location.pathname.slice(
      window.location.pathname.lastIndexOf('/'),
    );
    const afterPayment = pathnameEnd === '/payment-in-process';

    if (afterPayment) return <AfterPaymentView />;

    return (
      <Loader loading={logoutLoader} height={'100vh'}>
        <div className={styles.flexRow}>
          <div className={styles.page}>
            <Header />
            <BurgerMenu userLoggedIn={userLoggedIn} />
            <Dialog />
            {this.state.hasError ? this.renderError() : this.renderRoutes()}
            <GuestPart />
            <RestorePassword />
            {/* <Footer/> */}
          </div>
        </div>
      </Loader>
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }
}

export default compose(
  withTranslation(),
  withAuthData,
  connect(
    (state) => ({
      userLoggedIn: userSelectors.isUserLoggedIn(state),
      profile: userSelectors.getUserProfile(state),
      userDeactivated: userSelectors.isUserDeactivated(state),
      userBlocked: userSelectors.isUserBlocked(state),
      guestTokenLoaded: userSelectors.isGuestTokenLoaded(state),
      logoutLoader: userSelectors.getLogoutLoader(state),
      completedOrders: ordersSelectors.getCompletedOrders(state),
      serverTime: userSelectors.getServerTime(state),
      serverTimeLoading: userSelectors.getServerTimeLoading(state),
      dialogType: state.dialog?.dialogType,
      state: state,
    }),
    {
      openProfyFeedbackDialog: dialogActions.openProfyFeedbackDialog,
    },
    null,
    { pure: false },
  ),
  withRouter,
)(App);
