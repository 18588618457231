import React, { Component } from 'react';
import { connect } from 'react-redux';

import SignUpForm from './SignUpForm';
import { actions as signupActions, selectors } from '../../reducers/signup';
import Popup from '../../components/ui/Popup';
import { userActions, userSelectors } from '../../reducers/user';
import { formatBirthDate } from '../../utils/formtBirthDate';
import { formatPhone } from '../../utils/formatPhone';

class SignUp extends Component {
  onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      this.props.signUp(
        {
          ...values,
          //birth_date: formatBirthDate(values.birth_date),
          //phone: formatPhone(values.phone)
        },
        resolve,
        reject,
      );
    });
  };

  render() {
    return (
      <Popup isOpen={this.props.opened} onClose={this.props.closeSignUpPopup}>
        <SignUpForm
          onSubmit={this.onSubmit}
          onGetTermsAndConditions={this.props.onGetTermsAndConditions}
          // passwordRequired={!this.props.social}
          onSignIn={this.onSignIn}
        />
      </Popup>
    );
  }

  componentWillUnmount() {
    this.props.clearSocialData();
  }
}

export default connect(
  (state) => ({
    mode: 'singin',
    social: selectors.getSocial(state),
    opened: selectors.isSignUpPopupOpened(state),
  }),
  { ...signupActions, ...userActions },
)(SignUp);
