import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import styles from './PaymentReceipt.module.scss';
import { Lari } from '../../../components/ui';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';

class PaymentReceipt extends Component {
  renderContent = () => {
    const { order } = this.props;

    if (order.promocode) return this.renderPriceWithPromoCode();

    return this.renderPrice();
  };

  renderPrice = () => {
    const { t, order } = this.props;

    return (
      <div className={styles.fields}>
        <div className={styles.field}>
          <div className={styles.fieldName}>{t('total')}</div>
          <div className={styles.fieldValue}>
            {order.price}
            <Lari />
          </div>
        </div>
      </div>
    );
  };

  renderPriceWithPromoCode = () => {
    const { t, order } = this.props;

    return (
      <div className={styles.fields}>
        <div className={styles.field}>
          <div className={styles.fieldName}>{t('amount')}</div>
          <div className={styles.fieldValue}>
            {order.price}
            <Lari />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.fieldName}>{t('promotionalCodeDiscount')}</div>
          <div className={styles.fieldValue}>
            - {order.promocode_discount}
            <Lari />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.fieldName}>{t('total')}</div>
          <div className={styles.fieldValue}>
            {order.price_for_paid}
            <Lari />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('receipt')}</div>

        {this.renderContent()}
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({ order: createOrderSelectors.getOrder(state) }),
    createOrderActions,
  ),
)(PaymentReceipt);
