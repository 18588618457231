import React, { Component } from 'react';
import { reduxForm, Field, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { getPathForOrderAddress } from '../../routing/constants/pathHelpers';
import { LabeledTextInput } from '../../../components/inputs/text';
import { LabeledCheckbox } from '../../../components/inputs/checkbox';
import { AsideMapSearchBox } from '../../../components/inputs/map';
import {
  GreenButton,
  GrayButtonTransparent,
} from '../../../components/buttons';
import {
  addressesActions,
  addressesSelectors,
} from '../../../reducers/addresses';
import { LabeledTextArea } from '../../../components/inputs/text';

import styles from './AddressForm.module.scss';
import { createOrderAddressActions } from '../store';

class AddressForm extends Component {
  isSaveEnabled() {
    const { isValid, selectedAddress } = this.props;
    return isValid && selectedAddress.location;
  }

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.verticalScrollContainer}>
          <div className={styles.title}>{t('createAddress')}</div>

          <div className={styles.fields}>
            <Field
              component={LabeledTextInput}
              label={t('name')}
              maxlength={40}
              name="name"
            />
            <Field
              component={AsideMapSearchBox}
              onSelectAddress={this.props.selectAddress}
              onLocationChange={this.props.onLocationChange}
              onChangeAddressObj={this.props.changeAddressObj}
              onSetNewAddressLocation={this.props.setNewAddressLocation}
              label={t('address')}
              name="address"
            />
            <Field
              component={LabeledTextArea}
              big={true}
              autoHeight={true}
              label={t('addressComment')}
              twoLinePlaceholder={true}
              name="comment"
              validateOn="change"
            />

            <Field
              component={LabeledCheckbox}
              label={t('setAPrimary')}
              name="favorite"
            />
          </div>
        </div>

        <div className={styles.btnWrapper}>
          <div className={styles.btn}>
            <Link to={getPathForOrderAddress()}>
              <GrayButtonTransparent medium>
                {t('cancel')}
              </GrayButtonTransparent>
            </Link>
          </div>

          <div className={styles.btn}>
            <GreenButton medium minWidth={'112'} type="submit">
              {t('save')}
            </GreenButton>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!values.name) errors.name = t('enterAddressName');
  if (!values.address) errors.address = t('pleaseSelectAddress');
  if (!values.comment) errors.comment = t('pleaseProvideCommentToAddress');

  return errors;
};

export default compose(
  withTranslation(),
  reduxForm({ validate }),
  connect(
    (state, ownProps) => ({
      isValid: isValid(ownProps.form)(state),
      selectedAddress: addressesSelectors.getSelectedAddress(state),
    }),
    {
      ...createOrderAddressActions,
      ...addressesActions,
    },
  ),
)(AddressForm);
