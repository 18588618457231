import { dialogNames } from './constants/dialogConstants';

const MODULE_PREFIX = 'dialog/';

export const dialogTypes = {
  SHOW_DIALOG: MODULE_PREFIX + 'SHOW_DIALOG',
  CLOSE_DIALOG: MODULE_PREFIX + 'CLOSE_DIALOG',
};

const initialState = {
  dialogType: null,
  dialogProps: {},
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case dialogTypes.SHOW_DIALOG:
      return {
        ...state,
        dialogType: payload.dialogType,
        dialogProps: payload.dialogProps,
      };

    case dialogTypes.CLOSE_DIALOG:
      return initialState;

    default:
      return state;
  }
};

const showDialog = (dialogType, dialogProps: any = undefined) => ({
  type: dialogTypes.SHOW_DIALOG,
  payload: {
    dialogType,
    dialogProps,
  },
});

export const dialogActions = {
  closeDialog: () => ({
    type: dialogTypes.CLOSE_DIALOG,
  }),

  openCancelOrderDialog: (order) =>
    showDialog(dialogNames.CONFIRM_ORDER_CANCEL, { order }),

  openDeleteOrderDialog: (order, deletingFromOrderInfo = false) =>
    showDialog(dialogNames.CONFIRM_ORDER_DELETE, {
      order,
      deletingFromOrderInfo,
    }),

  openDeleteTaskDialog: (taskId, tasksCount) =>
    showDialog(dialogNames.CONFIRM_TASK_DELETE, { taskId, tasksCount }),

  openCreateTaskFromOrderDialog: (path) =>
    showDialog(dialogNames.CONFIRM_CREATE_TASK_FROM_ORDER, {
      path,
      noClose: true,
    }),

  openDeleteTaskFromOrderDialog: (task) =>
    showDialog(dialogNames.CONFIRM_TASK_DELETE_FROM_ORDER, { task }),

  openRejectTaskFromOrderDialog: (task) =>
    showDialog(dialogNames.CONFIRM_TASK_REJECT_FROM_ORDER, { task }),

  openDetachTaskerFromOrderDialog: (orderId) =>
    showDialog(dialogNames.CONFIRM_DETACH_TASKER_FROM_ORDER, { orderId }),

  openTermsConditionsPopup: () =>
    showDialog(dialogNames.TERMS_CONDITIONS_POPUP, { noClose: true }),

  openAccountDeactivationDialog: () =>
    showDialog(dialogNames.CONFIRM_ACCOUNT_DEACTIVATION),

  openReceiptPopup: (task, orderStatus) =>
    showDialog(dialogNames.OPEN_RECEIPT_POPUP, { task, orderStatus }),

  openCancelPopup: (task) =>
    showDialog(dialogNames.OPEN_CANCEL_TASK_POPUP, { task }),

  openZoomImgPopup: (image) =>
    showDialog(dialogNames.OPEN_ZOOM_IMG_POPUP, { image }),

  openCropImgPopup: (image) =>
    showDialog(dialogNames.OPEN_CROP_IMG_POPUP, { image }),

  openSubmitOrderErrorPopup: (error?: any) =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR, { error, noClose: true }),

  openSubmitOrderErrorPaymentMethodPopup: () =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_PAYMENT_METHOD),

  openSubmitOrderErrorCardIsNotActive: (error) =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_CARD_IS_NOT_ACTIVE, { error }),

  openSubmitOrderErrorTimeout: () =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_TIMEOUT, { noClose: true }),

  openSubmitOrderErrorPriceChanged: (error) =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_PRICE_CHANGED, {
      error,
      noClose: true,
    }),

  openSubmitOrderErrorSystemTransaction: (error) =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_SYSTEM_TRANSACTION, {
      error,
      noClose: true,
    }),

  openSubmitOrderErrorTaskerTransaction: (error) =>
    showDialog(dialogNames.SUBMIT_ORDER_ERROR_TASKER_TRANSACTION, {
      error,
      noClose: true,
    }),

  openSubmitOrderSuccess: (orderId) =>
    showDialog(dialogNames.SUBMIT_ORDER_SUCCESS, { noClose: true, orderId }),

  openDeleteOrderErrorPopup: () =>
    showDialog(dialogNames.DELETE_ORDER_ERROR, { noClose: true }),

  openUploadImageErrorPopup: (maxSize = {}) =>
    showDialog(dialogNames.UPLOAD_IMAGE_ERROR, maxSize),

  openOtherDeviceSignedIdPopup: () =>
    showDialog(dialogNames.OTHER_DEVICE_SIGNED_IN, { noClose: true }),

  openSignInErrorPopup: (errors) =>
    showDialog(dialogNames.SIGN_IN_ERROR, { errors }),

  openSuccessComplaintSend: (backPath) =>
    showDialog(dialogNames.COMPLAINT_SEND_SUCCESS, { backPath }),

  openFailureComplaintSend: (errors) =>
    showDialog(dialogNames.COMPLAINT_SEND_FAILURE, { errors }),

  openCloseSupportChatPopup: (errors) =>
    showDialog(dialogNames.CLOSE_SUPPORT_CHAT, { errors }),

  openDeleteCardConfirm: (id) =>
    showDialog(dialogNames.DELETE_CARD_CONFIRM, id),

  openCheckCardPopup: () =>
    showDialog(dialogNames.CHECK_CARD, { noClose: true }),

  openDeleteCardFailure: (error) =>
    showDialog(dialogNames.DELETE_CARD_FAILURE, { error }),

  openMakeCardDefaultError: (error) =>
    showDialog(dialogNames.MAKE_CARD_DEFAULT_ERROR, { error }),

  openCancleOrderError: (error) =>
    showDialog(dialogNames.CANCEL_ORDER_ERROR, { error }),

  openChatIsNotAvailable: (support) =>
    showDialog(dialogNames.CHAT_IS_NOT_AVAILABLE, { support }),
  openInviteFriendDialog: () =>
    showDialog(dialogNames.INVITE_USER, { noClose: true }),

  openProfyFeedbackDialog: (orderId) =>
    showDialog(dialogNames.PROFY_FEEDBACK, { noClose: true, orderId }),

  openConfirmGoFirstStep: () =>
    showDialog(dialogNames.CONFIRIM_GO_FIRST_STEP, { noClose: true }),

  openAddedOrdersCheckPopup: (orders) => showDialog(dialogNames.ADDED_ORDERS_CHECK, {noClose: true, orders})
};



export const dialogSelectors = {
  getDialogState: (state) => state.dialog,
};
