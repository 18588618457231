import { SelectedCategoryTypesEnum, ServiceTypesEnum } from 'utils/enum';
import { ServiceActionEnum } from './actions';

/* INITIAL STATE */
type InitialServiceStateType = {
  selectedServiceIndex: number;
  selectedServiceCount: number;
  selectedRepairServiceItemIndex: number;
  selectedServiceObject: ServiceTreeType | null;
  selectedCategoryType: SelectedCategoryTypesEnum | null;
  additionalDetailsImages: { id: number; url: string }[];
  selectedServices: SelectedCleaningServices;
  currentCleaningServiceType: ServiceTypesEnum;
  currentActiveId: null | number;
  selectedWindowCleaningServiceRegular: boolean;
  shouldRenderAdditionalDetailsPage: boolean;
  enteranceCleaningFloors: number;
};

const defaultInitialState: InitialServiceStateType = {
  selectedServiceIndex: -1,
  selectedServiceCount: 1,
  selectedRepairServiceItemIndex: -1,
  selectedServiceObject: null,
  selectedCategoryType: null,
  additionalDetailsImages: [],
  selectedServices: {},
  currentCleaningServiceType: ServiceTypesEnum.REGULAR,
  currentActiveId: null,
  selectedWindowCleaningServiceRegular: true,
  shouldRenderAdditionalDetailsPage: false,
  enteranceCleaningFloors: 1
};

const initialState: InitialServiceStateType = { ...defaultInitialState };

export type ServicePayload = {
  [ServiceActionEnum.SELECT_REPAIR_TASK]: number;
  [ServiceActionEnum.SELECT_REPAIR_TASK_ITEM]: number;
  [ServiceActionEnum.SELECTED_SERVICE_OBJECT]: ServiceTreeType;
  [ServiceActionEnum.SELECTED_SERVICE_TYPE]: SelectedCategoryTypesEnum;
  [ServiceActionEnum.UPLOAD_IMAGE_START_SUCCESS]: {
    id: number;
    url: string;
  };
  [ServiceActionEnum.DELETE_IMAGE]: number;
  [ServiceActionEnum.CHANGE_SERVICE_COUNT]: number;
  [ServiceActionEnum.SELECT_CLEANING_TASK_SUCCESS]: {
    selectedServices: SelectedCleaningServices;
    currentActiveId: null | number;
  };
  [ServiceActionEnum.CHANGE_ACTIVE_SERVICE_ID]: number;
  [ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE_SUCCESS]: ServiceTypesEnum;
  [ServiceActionEnum.SELECT_WINDOW_CLEANING_SERVICE_REGULAR]: boolean;
  [ServiceActionEnum.SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE]: boolean;
  [ServiceActionEnum.RESET_STORE]: InitialServiceStateType;
  [ServiceActionEnum.ENTRANCE_CLEANING_FLOORS]: number;
};

export type AppActions = ActionMap<ServicePayload>[keyof ActionMap<ServicePayload>];

/* REDUCER */
export default (
  state = initialState,
  action: AppActions,
): InitialServiceStateType => {
  switch (action.type) {
    case ServiceActionEnum.SELECT_REPAIR_TASK:
      return {
        ...state,
        selectedServiceIndex: action.payload,
        selectedRepairServiceItemIndex: -1,
      };
    case ServiceActionEnum.SELECT_REPAIR_TASK_ITEM:
      return {
        ...state,
        selectedRepairServiceItemIndex: action.payload,
      };
    case ServiceActionEnum.SELECTED_SERVICE_OBJECT:
      return {
        ...state,
        selectedServiceObject: action.payload,
        selectedServiceIndex: -1,
        selectedRepairServiceItemIndex: -1,
      };
    case ServiceActionEnum.SELECTED_SERVICE_TYPE:
      return {
        ...state,
        selectedCategoryType: action.payload,
      };
    case ServiceActionEnum.UPLOAD_IMAGE_START_SUCCESS:
      return {
        ...state,
        additionalDetailsImages: state.additionalDetailsImages.concat(
          action.payload,
        ),
      };
    case ServiceActionEnum.DELETE_IMAGE: {
      const filteredImages = [
        ...state.additionalDetailsImages.slice(0, action.payload),
        ...state.additionalDetailsImages.slice(
          action.payload + 1,
          state.additionalDetailsImages.length,
        ),
      ];
      return {
        ...state,
        additionalDetailsImages: filteredImages,
      };
    }
    case ServiceActionEnum.CHANGE_SERVICE_COUNT: {
      return {
        ...state,
        selectedServiceCount: action.payload,
      };
    }
    case ServiceActionEnum.SELECT_CLEANING_TASK_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };

    }
    case ServiceActionEnum.CHANGE_ACTIVE_SERVICE_ID: {
      return {
        ...state,
        currentActiveId: action.payload,
      };
    }
    case ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE_SUCCESS: {
      return {
        ...state,
        currentCleaningServiceType: action.payload,
      };
    }
    case ServiceActionEnum.SELECT_WINDOW_CLEANING_SERVICE_REGULAR: {
      return {
        ...state,
        selectedWindowCleaningServiceRegular: action.payload,
        selectedServices: {},
        currentActiveId: null,
      };
    }
    case ServiceActionEnum.SHOULD_RENDER_ADDITIONAL_DETAILS_PAGE: {
      return {
        ...state,
        shouldRenderAdditionalDetailsPage: action.payload,
      };
    }
    case ServiceActionEnum.RESET_STORE: {
      return {
        ...defaultInitialState,
        additionalDetailsImages: [],
        selectedServices: {},
      };
    }
    case ServiceActionEnum.ENTRANCE_CLEANING_FLOORS: {
      return {
        ...state,
        enteranceCleaningFloors: action.payload,
      };
    }
    default:
      return state;
  }
};
