import React from 'react';
import PropTypes from 'prop-types';

import styles from './Columns.module.scss';

const Columns = ({ aside, mainContent }) => {
  return (
    <div className={styles.columns}>
      <div className={styles.columnsAside}>{aside}</div>

      <div className={styles.columnsMainContent}>{mainContent}</div>
    </div>
  );
};

Columns.propTypes = {
  aside: PropTypes.node,
  mainContent: PropTypes.node,
};

export default Columns;
