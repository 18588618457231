import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import TaskView from './components/TaskView';
import { PinkBorderButton, PinkButton } from '../../components/buttons';
import { addressesActions } from '../../reducers/addresses';
import { PageContent } from '../../components/ui';
import { createTaskActions } from '../createTask/store';
import {
  getPathForCreateNewTask,
  getPathForOrderTaskers,
  getPathForOrderAddress,
  getPathForDeactivated,
} from '../routing/constants/pathHelpers';
import SelectedAddressView from './components/SelectedAddressView';
import CreateOrderDisabledMap from './components/CreateOrderDisabledMap';
import { dialogActions } from '../dialog/store';
import { withCurrentOrder } from '../hoc';
import { userSelectors } from '../../reducers/user';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../reducers/createOrder';

import styles from './CreateOrder.module.scss';

class CreateOrder extends Component {
  addressBtn = React.createRef();

  componentDidMount() {
    // debugger;
    this.props.loadEditingOrder();
    // debugger;
  }

  onTaskCardClick = (task) => {
    this.props.startEditingTask(task);
  };

  renderTask = (task) => {
    const { tasks } = this.props;

    return (
      <div key={task.id} className={styles.task}>
        <TaskView
          tasksCount={tasks ? tasks.length : null}
          task={task}
          onClick={this.onTaskCardClick}
          onDelete={this.props.openDeleteTaskDialog}
        />
      </div>
    );
  };

  isOrderEditingAvailable() {
    return !this.props.order.tasker;
  }

  scaleAddressButton = () => {
    const { tasks, order } = this.props;

    if (!order.address.address || tasks.length === 0) {
      this.addressBtn.current.style.transform = 'scale(1.15)';
      setTimeout(() => {
        this.addressBtn.current.style.transform = 'scale(1)';
      }, 500);
    }
  };

  renderDeleteButton() {
    const { order, t } = this.props;

    return (
      <div className={styles.btnDelete}>
        <PinkBorderButton
          onClick={() => this.props.openDeleteOrderDialog(order)}
        >
          {t('deleteOrder')}
        </PinkBorderButton>
      </div>
    );
  }

  addNewTaskHandle = () => {
    const { openDetachTaskerFromOrderDialog, history, order } = this.props;

    window.scroll(0, 0);

    if (!this.isOrderEditingAvailable()) {
      openDetachTaskerFromOrderDialog(order.id);
    } else {
      history.push(getPathForCreateNewTask());
    }
  };

  onSeeTaskersClick = () => {
    window.scroll(0, 0);
    ReactGA.event({
      category: 'event',
      action: 'click',
      label: 'fasebi',
    });
    return this.props.push(getPathForOrderTaskers());
  };

  getMinutes = () => {
    if (!this.props.order) return 0;
    const { working_time_sec } = this.props.order;
    if (!working_time_sec) return 0;
    return Math.floor(working_time_sec / 60);
  };

  render() {
    const { tasks, order, t, deactivated } = this.props;

    if (deactivated) {
      this.props.replace(getPathForDeactivated());
    }
    return (
      <PageContent title={t('describeYourTask')}>
        <Helmet>
          <title>{t('orderCreation')}</title>
        </Helmet>
        <div className={styles.flexRow}>
          <div className={styles.container}>
            <div className={styles.blockAddress}>
              <div
                className={styles.blockMap}
                onClick={() => this.props.push(getPathForOrderAddress())}
              >
                <div className={styles.blockMapOverlay}>
                  <span className={styles.blockMapOverlayText}>
                    {order.address.address
                      ? t('changeAddress')
                      : t('selectAddress')}
                  </span>
                </div>

                <CreateOrderDisabledMap
                  center={
                    order && order.address ? order.address.location : null
                  }
                />
              </div>

              <div>
                <div className={styles.blockAddressScale} ref={this.addressBtn}>
                  <SelectedAddressView address={order.address} />
                </div>
              </div>
            </div>

            <div className={styles.tasks}>
              {tasks && tasks.map(this.renderTask)}

              <div className={styles.task}>
                <button
                  className={styles.taskNew}
                  onClick={this.addNewTaskHandle}
                >
                  {t('addNewTask')}
                </button>
              </div>
            </div>

            <div className={styles.footer}>
              <p className={styles.avergeText}>
                {t('averageTimeToCompleteYourOrderIs')}{' '}
                <span className={styles.avergeTextTime}>
                  {this.getMinutes()} {t('min')}
                </span>
              </p>

              <div className={styles.btnWrapper}>
                {this.renderDeleteButton()}

                <div onClick={this.scaleAddressButton}>
                  <PinkButton
                    iconName={'arrowRight'}
                    iconPos={'right'}
                    iconSmall={true}
                    disabled={!order.address.address || tasks.length === 0}
                    onClick={this.onSeeTaskersClick}
                  >
                    {t('seeTaskersAndPrices')}
                  </PinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: createOrderSelectors.getTasks(state),
  order: createOrderSelectors.getOrder(state),
  deactivated: userSelectors.isUserDeactivated(state),
});

export default compose(
  withTranslation(),
  withCurrentOrder,
  withRouter,
  connect(mapStateToProps, {
    ...addressesActions,
    ...createOrderActions,
    ...dialogActions,
    ...createTaskActions,
    push,
    replace,
  }),
)(CreateOrder);
