import * as addressesSagas from './addressesSagas';
import { createOrderMapSagas } from '../containers/createOrderMap/store';
import { createOrderMapNewSagas } from '../containers/createOrderMapNew/store';
import { taskersSagas } from '../containers/createOrderTaskers/store';
import { taskersSagasNew } from '../containers/createOrderTaskersNew/store';
import { createFullTask } from '../containers/createTaskFullFlow';
import { createAddressTasks } from '../containers/createTaskFullFlow/containers/chooseAddress/state/saga';
import { createServiceTasks } from '../containers/createTaskFullFlow/containers/selectService/state/saga';

import { createTaskSagas } from '../containers/createTask/store';
import { restorePasswordSagas } from '../containers/restorePassword/store';
import * as orderSagas from './orderSagas';
import * as createOrderSagas from './createOrderSagas';
import * as ordersListSagas from './ordersListSagas';
import * as signupSagas from './signupSagas';
import * as signinSagas from './signinSagas';
import * as userSagas from './userSagas';
import * as footerSagas from './footerSagas';
import * as pushSagas from './pushSagas';
import * as chatSagas from './chatSagas';
import { websocketSagas } from './websocketSaga';
import * as supportSagas from './supportSagas';
import * as notificationsListSagas from './notificationsListSagas';
import * as paymentsSagas from './paymentsSagas';
import * as mapsSagas from './mapsSagas';

const sagas = [
  addressesSagas,
  createOrderSagas,
  createOrderMapSagas,
  createTaskSagas,
  restorePasswordSagas,
  taskersSagas,
  orderSagas,
  ordersListSagas,
  signupSagas,
  signinSagas,
  userSagas,
  footerSagas,
  pushSagas,
  chatSagas,
  // websocketSagas, // temporary, throwing errors on console log
  supportSagas,
  notificationsListSagas,
  paymentsSagas,
  createFullTask,
  createOrderMapNewSagas,
  taskersSagasNew,
  createAddressTasks,
  createServiceTasks,
  mapsSagas
];

export const registerWithMiddleware = (sagaMiddleware) => {
  sagas.forEach((sagas) => {
    for (const name in sagas) {
      if (sagas.hasOwnProperty(name)) {
        sagaMiddleware.run(sagas[name]);
      }
    }
  });
};
