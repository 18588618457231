import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InitialTextArea from '../../components/inputs/text/InitialTextArea';
import Svg from '../../components/ui/Svg';
import styles from './InputBlock.module.scss';

import EmojiPicker from './EmojiPicker';
import DropZone from 'react-dropzone';

class InputBlock extends Component {
  textarea = React.createRef();

  state = {
    messageText: '',
    showEmojiPicker: false,
  };

  onTextChange = (event) => this.setState({ messageText: event.target.value });

  onKeyPress = (event) => {
    if (event.shiftKey) return;

    if (event.key === 'Enter') {
      event.preventDefault();
      return this.sendMessage();
    }
  };

  sendMessage = () => {
    const { messageText } = this.state;
    if (messageText.trim()) {
      this.props.onSubmit(messageText);
      this.setState({ messageText: '' });
    }
  };

  onSelectEmoji = (data) => {
    this.setState({
      showEmojiPicker: false,
      messageText: this.state.messageText + data.native,
    });

    if (this.textarea) this.textarea.focus();
  };

  toggleEmojiPicker = () => {
    this.setState({
      showEmojiPicker: !this.state.showEmojiPicker,
    });
  };

  hideEmojiPicker = () => {
    this.setState({
      showEmojiPicker: false,
    });
  };

  renderEmojiPicker = () => {
    if (!this.state.showEmojiPicker) return;

    return (
      <EmojiPicker
        handleClickOutside={this.hideEmojiPicker}
        onEmojiSelect={this.onSelectEmoji}
      />
    );
  };

  render() {
    const { onDrop, t } = this.props;
    const { messageText } = this.state;

    return (
      <div className={styles.controls}>
        <div className={styles.control}>
          <DropZone
            className={styles.dropZone}
            onDrop={onDrop}
            accept={'image/png, image/jpg, image/jpeg'}
          />

          <Svg src={'photo'} className={styles.controlIcon} />
        </div>

        <div className={styles.inputContainer}>
          <InitialTextArea
            inputref={(ref) => (this.textarea = ref)}
            placeholder={t('message')}
            className={styles.input}
            onChange={this.onTextChange}
            onKeyPress={this.onKeyPress}
            value={messageText}
            maxLength={1000}
            autoFocus={true}
          />

          <button className={styles.btnSend} onClick={this.sendMessage}>
            <Svg src={'sendArrow'} className={styles.btnSendIcon} />
          </button>
        </div>

        <button
          className={styles.control}
          type={'button'}
          onClick={this.toggleEmojiPicker}
        >
          <Svg src={'emoji'} className={styles.controlIcon} />
        </button>

        {this.renderEmojiPicker()}
      </div>
    );
  }
}

export default withTranslation()(InputBlock);
