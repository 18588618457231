import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  GreenBorderButton,
  GreenButton,
  PinkButton,
} from '../../../components/buttons';

import styles from './ReceiptPopup.module.scss';
import { dialogActions } from '../store';
import { orderActions } from '../../../reducers/order';

class ReceiptPopup extends Component {
  renderAnswer = (answer) => {
    return (
      <li className={styles.answer} key={answer.answer}>
        <div className={styles.answerTitle}>{answer.question}</div>
        <div className={styles.answerContent}>{answer.answer}</div>
      </li>
    );
  };

  renderImage = (image) => {
    return (
      <div
        className={styles.image}
        key={image.id}
        onClick={(event) => this.props.openZoomImgPopup(image.url)}
      >
        <img src={image.url} alt="" />
      </div>
    );
  };

  renderConfirmButton = () => {
    const { task, onClose, t } = this.props;

    if (task.rejected || task.confirmed) {
      return null;
    }

    return (
      <Fragment>
        &nbsp;&nbsp;
        <GreenBorderButton
          onClick={() => {
            this.props.confirmTaskInOrder(task);
            onClose();
          }}
        >
          {t('confirmTheTask')}
        </GreenBorderButton>
        &nbsp;&nbsp;
      </Fragment>
    );
  };

  renderRightButton = () => {
    const { task, onClose, openCancelPopup, t } = this.props;

    if (task.from_tasker && !task.confirmed) {
      return (
        <PinkButton onClick={() => openCancelPopup(task)}>
          {t('dismiss')}
        </PinkButton>
      );
    }

    return <GreenButton onClick={onClose}>{t('cancel')}</GreenButton>;
  };

  render() {
    const { task, orderStatus, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{task.service.name}</div>

        {task.comment && (
          <div className={styles.block}>
            <div className={styles.blockTitle}>{t('description')}</div>
            <div className={styles.blockText}>{task.comment}</div>
          </div>
        )}

        {task.answers.length > 0 && (
          <div className={styles.block}>
            <div className={styles.blockTitle}>{t('answers')}</div>
            <ol className={styles.answers}>
              {task.answers.map((answer) => this.renderAnswer(answer))}
            </ol>
          </div>
        )}

        {task.answers.length > 0 && (
          <div className={styles.block}>
            <div className={styles.blockTitle}>
              {t('quantity') + ': ' + task.quantity}
            </div>
          </div>
        )}

        {task.images.length > 0 && (
          <div className={styles.block}>
            <div className={styles.blockTitle}>{t('images')}</div>
            <div className={styles.images}>
              {task.images.map((image) => this.renderImage(image))}
            </div>
          </div>
        )}

        <div className={styles.btnWrapper}>
          {this.renderConfirmButton(orderStatus)}

          <div className={styles.btnRight}>{this.renderRightButton()}</div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    openCancelPopup: dialogActions.openCancelPopup,
    openZoomImgPopup: dialogActions.openZoomImgPopup,
    openRejectTaskFromOrderDialog: dialogActions.openRejectTaskFromOrderDialog,
    confirmTaskInOrder: orderActions.confirmTaskInOrder,
  }),
)(ReceiptPopup);
