import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { createTaskSelectors } from '../store';
import styles from './TaskSidebar.module.scss';

class TaskSidebar extends Component {
  renderAnswer = (answer, index, questions) => {
    return (
      <div
        className={classNames(styles.questionCard, {
          [styles.first]: index === 0,
          [styles.current]: index === questions.length - 1,
        })}
        key={answer.question + answer.answer}
      >
        <div className={styles.question}>
          {index + 1}. {answer.question}
        </div>

        <div className={styles.answer}>{answer.answer}</div>
      </div>
    );
  };

  render() {
    const { taskId, questions, questionIsFinish, t } = this.props;

    return (
      <div className={styles.sidebar}>
        {questions.map(this.renderAnswer)}

        {questionIsFinish || taskId ? (
          <div className={styles.questionCard}>
            <div className={styles.question}>
              {questions.length + 1}. {t('taskDetails')}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questions: createTaskSelectors.getAllAnswers(state),
  questionIsFinish: createTaskSelectors.getQuestionIsFinish(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(TaskSidebar);
