import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 50px;
  justify-content: center;
  @media only screen and (max-width: 910px) {
    padding-top: 144px;
  }
`;
const StyledTextContainer = styled.div`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #66c3ad;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  height: 206px;
  width: 500px;
  &.err {
    border: 3px solid red;
  }
  @media only screen and (max-width: 910px) {
    height: 175px;
    width: 94%;
  }
`;
const StyledText = styled.h3`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  font-family: NotoSansGeorgian-Bold;
  padding: 16px;
  @media only screen and (max-width: 910px) {
    font-size: 20px;
  }
`;
const DonationFeedback = ({ susses }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledTextContainer>
        <StyledText className={susses ? 'suc' : 'err'}>
          {susses ? t('successDonation') : t('unsuccessDonation')}
        </StyledText>
      </StyledTextContainer>
    </StyledContainer>
  );
};

export default DonationFeedback;
