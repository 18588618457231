import React from 'react';
import { withReduxFormProps } from '../../../containers/hoc';

const InitialCheckbox = (props) => (
  <input
    type="checkbox"
    id={props.name}
    defaultChecked={props.defaultChecked}
    name={props.name}
    onBlur={props.onBlur}
    onChange={props.onChange}
    onFocus={props.onFocus}
    value={props.value}
    className={props.className}
  />
);

export default withReduxFormProps(InitialCheckbox);
