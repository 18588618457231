import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './OrdersTabBar.module.scss';
import { CHAT, ORDERS, SUPPORT } from '../routing/constants/routes';
import { Tab } from '../../components/ui';
import { statuses } from './helpers/orderConstants';

class OrderTabsBar extends Component {
  render() {
    const { order, tabsConfig, path, chatClosed } = this.props;
    const { id, status, on_warranty } = order;

    return (
      <div className={styles.container}>
        {tabsConfig.map((tab) => {
          if (tab.link) {
            return (
              <a href={tab.link} key={tab.name}>
                <Tab arrow={true} border={true}>
                  {tab.name}
                </Tab>
              </a>
            );
          }

          if (tab.path === SUPPORT) {
            if (status === statuses.COMPLETED) {
              if (on_warranty) {
                return (
                  <Link
                    to={`${ORDERS}/${id}${tab.path}`}
                    replace
                    key={tab.name}
                  >
                    <Tab
                      arrow={true}
                      border={true}
                      isSelected={path.includes(tab.path)}
                    >
                      {tab.name}
                    </Tab>
                  </Link>
                );
              } else {
                return null;
              }
            }
          }

          if (tab.path === CHAT) {
            if (chatClosed) return null;
            if (status === statuses.COMPLETED && !on_warranty) return null;
            return (
              <Link to={`${ORDERS}/${id}${tab.path}`} replace key={tab.name}>
                <Tab
                  arrow={true}
                  border={true}
                  isSelected={path.includes(tab.path)}
                >
                  {tab.name}
                </Tab>
              </Link>
            );
          }

          return (
            <Link to={`${ORDERS}/${id}${tab.path}`} replace key={tab.name}>
              <Tab
                arrow={true}
                border={true}
                isSelected={path.includes(tab.path)}
              >
                {tab.name}
              </Tab>
            </Link>
          );
        })}
      </div>
    );
  }
}

OrderTabsBar.propTypes = {
  tabsConfig: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default connect(null, {
  push,
})(OrderTabsBar);
