import React from 'react';
import { useTranslation } from 'react-i18next';

import { Svg } from '../../../components/ui';
import styles from './NoOrders.module.scss';

type NoOrdersPropsType = {
  active: boolean;
};

const NoOrders = ({ active }: NoOrdersPropsType) => {
  const { t } = useTranslation();
  return (
    <div className={styles.flexRow}>
      <div className={styles.container}>
        <div className={styles.blockNo}>
          <div className={styles.blockImage}>
            <Svg className={styles.image} src={'noActiveOrders'} />
          </div>
          <div className={styles.blockContent}>
            <div className={styles.blockTitle}>
              {active ? t('noActiveOrders') : t('noCompletedOrders')}
            </div>
            <p className={styles.blockText}>{t('noOrdersText')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOrders;
