import React, { Component } from 'react';
import isPlainObject from 'lodash/isPlainObject';

const withReduxFormProps = (WrappedComponent) => {
  class WithReduxFormPropsHOC extends Component {
    render() {
      let mergedProps = {
        ...this.props,
      };
      if (isPlainObject(this.props.input) && isPlainObject(this.props.meta)) {
        mergedProps = {
          ...this.props.input,
          ...this.props.meta,
          ...mergedProps,
        };
      }
      return <WrappedComponent {...mergedProps} />;
    }
  }

  return WithReduxFormPropsHOC;
};

export default withReduxFormProps;
