import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Lari, Loader, Svg } from '../../../components/ui';
import { PinkBorderButton } from '../../../components/buttons';
import { dialogActions } from '../../dialog/store';
import { orderActions, orderSelectors } from '../../../reducers/order';
import { statuses } from '../helpers/orderConstants';

import ReceiptTasksList from './ReceiptTasksList';
import styles from './Receipt.module.scss';
import { getWarrantyTime, getWorkingTime } from '../../../utils/orderTimes';
import { userSelectors } from '../../../reducers/user';

class Receipt extends Component {
  componentDidMount() {
    if (!this.props.order) return;

    this.props.loadOrderTasks(this.props.order.id);
  }

  renderWarrantyTimer = () => {
    const { order, timeDifferenceWithServer, t } = this.props;

    if (order.on_warranty)
      return (
        <div className={styles.headerTime}>
          <div className={styles.headerTimeIcon}>
            <Svg src={'pending'} />
          </div>
          {getWarrantyTime(
            order.warranty_end_at + timeDifferenceWithServer,
            order.completed_at + timeDifferenceWithServer,
            t,
          )}
        </div>
      );
  };

  getTime = () => {
    const { order, timeDifferenceWithServer } = this.props;

    const time = getWorkingTime(
      order.tasker_begin_at + timeDifferenceWithServer,
    );

    if (time.length > 0) {
      if (time.indexOf('NaN') !== -1) return `(00:00)`;
      return `(${time})`;
    }

    return '(00:00)';
  };

  renderWorkingTimer = () => {
    const { order, t } = this.props;

    if (order.status === statuses.IN_PROGRESS) {
      return (
        <div className={styles.headerTime}>
          <div className={styles.headerTimeIcon}>
            <Svg src={'pending'} />
          </div>
          {`${t('workingTime')}: ${this.getTime()}`}
        </div>
      );
    }
  };

  renderDeleteCancelButton = () => {
    const { order, t } = this.props;
    if ([statuses.WAITING_PAYMENT, statuses.CREATED].includes(order.status)) {
      return (
        <PinkBorderButton
          onClick={() => this.props.openDeleteOrderDialog(order, true)}
        >
          {t('deleteOrder')}
        </PinkBorderButton>
      );
    }
  };

  getOrderPrice = () => {
    const { order, tasks } = this.props;
    let price = order.price;

    tasks
      .filter((task) => task.confirmed)
      .map((task) => (task.rejected ? (price = price - task.price) : 0));

    return price;
  };

  renderPrice = () => {
    const { order } = this.props;

    if (!order.promocode) return this.renderOrderPrice();

    return this.renderOrderPriceWithPromoCode();
  };

  renderOrderPrice = () => {
    const { order, t } = this.props;

    return (
      <div className={styles.field}>
        <div className={styles.fieldName}>{t('total')}:</div>
        <div className={styles.fieldValue}>
          {order.price_for_paid}
          <Lari big={true} />
        </div>
      </div>
    );
  };

  renderOrderPriceWithPromoCode = () => {
    const { t, order } = this.props;

    return (
      <div>
        <div className={styles.field}>
          <div className={styles.fieldName}>{t('amount')}:</div>
          <div className={styles.fieldValue}>
            {this.getOrderPrice()}
            <Lari big={true} />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.fieldName}>
            {t('promotionalCodeDiscount')}:
          </div>
          <div className={styles.fieldValue}>
            - {order.promocode_discount}
            <Lari big={true} />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.fieldName}>{t('total')}:</div>
          <div className={styles.fieldValue}>
            {order.price_for_paid}
            <Lari big={true} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { order, orderLoading, tasks, tasksLoading, t } = this.props;

    return (
      <Loader loading={orderLoading}>
        <Helmet>
          <title>{t('orderReceipt')}</title>
        </Helmet>

        <div className={styles.header}>
          <div className={styles.headerTitle}>{t('orderList')}</div>
          {this.renderWorkingTimer()}
          {this.renderWarrantyTimer()}
        </div>

        <Loader loading={tasksLoading} height={128}>
          <ReceiptTasksList
            completed={order.status === statuses.COMPLETED}
            phantomTasks={order.additional_services}
            tasks={tasks}
            orderStatus={order.status}
            openReceiptPopup={this.props.openReceiptPopup}
            openCancelPopup={this.props.openCancelPopup}
          />
        </Loader>

        <div className={styles.fields}>
          <div className={styles.fieldsTitle}>{t('receipt')}</div>

          {this.renderPrice()}
        </div>
      </Loader>
    );
  }
}

Receipt.propTypes = {
  order: PropTypes.object,
  tasks: PropTypes.array,
};

const mapStateToProps = (state) => ({
  order: orderSelectors.getOrder(state),
  orderLoading: orderSelectors.getOrderLoading(state),
  tasks: orderSelectors.getTasks(state),
  tasksLoading: orderSelectors.getTasksLoading(state),
  timeDifferenceWithServer: userSelectors.getTimeDifferenceWithServer(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    ...orderActions,
    openReceiptPopup: dialogActions.openReceiptPopup,
    openCancelPopup: dialogActions.openCancelPopup,
  }),
)(Receipt);
