import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { createTaskActions, createTaskSelectors } from './../store';
import LabeledNumberInput from '../../../components/inputs/number/LabeledNumberInput';

class TaskQuantity extends Component {
  render() {
    const { unit, quantity, quantityError } = this.props;

    return (
      <LabeledNumberInput
        value={quantity}
        name={'quantity'}
        unit={unit}
        type={'text'}
        onChange={this.props.changeQuantity}
        noMargin={true}
        error={quantityError}
        quantity={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  quantity: createTaskSelectors.getQuantity(state),
  quantityError: createTaskSelectors.getQuantityError(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    changeQuantity: createTaskActions.changeQuantity,
  }),
)(TaskQuantity);
