import React, { Component } from 'react';
import { withReduxFormProps } from '../../../containers/hoc';
import classNames from 'classnames';

import styles from './InitialTextAreaAutoHeight.module.scss';

export class InitialTextAreaAutoHeight extends Component {
  pseudoTextarea = React.createRef();

  state = {
    height: null,
  };

  componentDidMount(nextProps) {
    this.getPseudoTextareaHeight();
  }

  componentDidUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.getPseudoTextareaHeight();
    }
  }

  getPseudoTextareaHeight = () => {
    this.setState({
      height: this.pseudoTextarea.current
        ? this.pseudoTextarea.current.clientHeight + 1
        : 'auto',
    });
  };

  renderPseudoTextarea = () => (
    <div
      className={classNames(styles.pseudoTextarea, {
        [styles.big]: this.props.big,
        [styles.required]: this.props.required,
      })}
      ref={this.pseudoTextarea}
    >
      <pre
        className={classNames(styles.pseudoTextareaPre, {
          [styles.big]: this.props.big,
        })}
      >
        {this.props.value}
      </pre>
    </div>
  );

  renderTextarea = () => (
    <div
      className={classNames(styles.textarea, {
        [styles.big]: this.props.big,
        [styles.required]: this.props.required,
      })}
    >
      <textarea
        rows={this.props.row ? this.props.row : '1'}
        style={{
          minHeight: this.state.height,
        }}
        required={this.props.required}
        className={this.props.className}
        value={this.props.value}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        maxLength={this.props.maxlength}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    </div>
  );

  render() {
    return (
      <div>
        {this.renderPseudoTextarea()}
        {this.renderTextarea()}
      </div>
    );
  }
}

export default withReduxFormProps(InitialTextAreaAutoHeight);
