import React from 'react';
import classNames from 'classnames';
import { Svg } from 'components/ui';
import styles from './PaginationItem.module.scss';
import { useTranslation } from 'react-i18next';

import navServices from './../../../../../assets/images/inline/navServices.svg';
import navLocation from './../../../../../assets/images/inline/navLocation.svg';
import navPayment from './../../../../../assets/images/inline/navPayment.svg';
import navOuterLines from './../../../../../assets/images/inline/navOuterLines.svg';

type PaginationItemPropsType = {
  step: string | number;
  onClick: () => void;
  active: boolean;
  question: string;
  answer: string | null;
  price: string | number | null;
  selectedServices: TaskType[] | null;
  activeStep: number;
};
const PaginationItem = ({
  step,
  onClick,
  active,
  question,
  answer,
  price,
  selectedServices,
  activeStep,
}: PaginationItemPropsType) => {
  const { t } = useTranslation();

  return (
    <button
      className={classNames(
        styles.button,
        active ? styles.active : null,
        selectedServices ? styles.selectedServicesButton : null,
      )}
      onClick={onClick}
    >
      <div style={{ display: 'contents' }}>
        <div className={styles.stepContainer}>
          <span>{step}</span>
          <div
            className={
              activeStep === +step - 1
                ? styles.mobileActiveStep
                : styles.displayNone
            }
          >
            <h5>{t('step')}</h5>
            <h5>{step}</h5>
          </div>
          {+step === 1 && !(activeStep === +step - 1) && (
            <Svg className={styles.mobIcon} src={'navServices'} />
          )}
          {+step === 2 && !(activeStep === +step - 1) && (
            <Svg className={styles.mobIcon} src={'navSurface'} />
          )}
          {+step === 3 && !(activeStep === +step - 1) && (
            <Svg className={styles.mobIcon} src={'navLocation'} />
          )}
          {+step === 4 && !(activeStep === +step - 1) && (
            <Svg className={styles.mobIcon} src={'navOuterLines'} />
          )}
          {+step === 5 && !(activeStep === +step - 1) && (
            <Svg className={styles.mobIcon} src={'navPayment'} />
          )}
        </div>
        <div className={styles.textContainer}>
          <p>{question}</p>
          {answer && <p title={answer}>{answer}</p>}
        </div>
      </div>
      {(price || selectedServices) && (
        <div className={styles.taskerItemPriceContainer}>
          {/*V. on second step disabled arrow up*/}
          {selectedServices ? null : ( // <Svg src={'arrow-right-new'} className={styles.svg} />
            <p className={styles.taskerItemPrice}>{price} ₾</p>
          )}
        </div>
      )}
      {/*V. On second step disabled popup*/}
      {/*{selectedServices && (*/}
      {/*  <div className={styles.hoverableContainer}>*/}
      {/*    <div className={styles.selectedServices}>*/}
      {/*      <ul>*/}
      {/*        {selectedServices?.map(({ service }, index) => (*/}
      {/*          <li key={index} className={classNames(styles.orderListItem)}>*/}
      {/*            <div className={styles.text}>*/}
      {/*              <p className={styles.orderListTitle}>*/}
      {/*                {t(service.category_name)}*/}
      {/*              </p>*/}
      {/*              <p className={styles.selectedService}>{t(service.name)}</p>*/}
      {/*            </div>*/}
      {/*            <div className={styles.price}>*/}
      {/*              /!* <p className={styles.orderListPrice}>{t(price)} ₾</p> *!/*/}
      {/*              <p className={styles.orderListPrice}></p>*/}
      {/*            </div>*/}
      {/*          </li>*/}
      {/*        ))}*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </button>
  );
};

export default PaginationItem;
