import {
  getUpcomingOrdersStatuses,
  statuses,
} from '../containers/order/helpers/orderConstants';

const MODULE_PREFIX = 'ordersList/';

export const ordersTypes = {
  LOAD_ORDERS_REQUEST: MODULE_PREFIX + 'LOAD_ORDERS_REQUEST',
  LOAD_ORDERS_SUCCESS: MODULE_PREFIX + 'LOAD_ORDERS_SUCCESS',
  LOAD_ORDERS_FAILURE: MODULE_PREFIX + 'LOAD_ORDERS_FAILURE',

  LOAD_COMPLETED_ORDERS_REQUEST:
    MODULE_PREFIX + 'LOAD_COMPLETED_ORDERS_REQUEST',
  LOAD_COMPLETED_ORDERS_SUCCESS:
    MODULE_PREFIX + 'LOAD_COMPLETED_ORDERS_SUCCESS',
  LOAD_COMPLETED_ORDERS_FAILURE:
    MODULE_PREFIX + 'LOAD_COMPLETED_ORDERS_FAILURE',

  CHANGE_SELECTED_TAB: MODULE_PREFIX + 'CHANGE_SELECTED_TAB',

  UPDATE_ORDER_ON_LIST: MODULE_PREFIX + 'UPDATE_ORDER_ON_LIST',
};

const initialState = {
  orders: [],
  ordersLoading: false,
  completedOrders: [],
  completedOrdersLoading: false,
  filterStatuses: getUpcomingOrdersStatuses(),
};

const transformOrdersList = (orders) => {
  let waitingPos = null;
  let processPos = null;
  let order = null;

  for (let i = 0; i < orders.length; i++) {
    if (orders[i].status === statuses.CREATED) {
      waitingPos = i;
    }

    if (orders[i].status === statuses.WAITING_PAYMENT) {
      waitingPos = i;
    }

    if (orders[i].status === statuses.PAYMENT_IN_PROCESS) {
      processPos = i;
    }
  }

  if (waitingPos) {
    order = orders.splice(waitingPos, 1)[0];

    if (order) {
      orders.unshift(order);
    }
  }

  if (processPos) {
    order = orders.splice(processPos, 1)[0];

    if (order) {
      orders.unshift(order);
    }
  }

  return orders;
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case ordersTypes.LOAD_ORDERS_REQUEST:
      return {
        ...state,
        ordersLoading: true,
      };

    case ordersTypes.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        ordersLoading: false,
        orders: transformOrdersList(payload.orders),
      };

    case ordersTypes.LOAD_ORDERS_FAILURE:
      return {
        ...state,
        ordersLoading: false,
      };

    case ordersTypes.LOAD_COMPLETED_ORDERS_REQUEST:
      return {
        ...state,
        completedOrdersLoading: true,
      };

    case ordersTypes.LOAD_COMPLETED_ORDERS_SUCCESS:
      return {
        ...state,
        completedOrdersLoading: false,
        completedOrders: payload.orders,
      };

    case ordersTypes.LOAD_COMPLETED_ORDERS_FAILURE:
      return {
        ...state,
        completedOrdersLoading: false,
      };

    case ordersTypes.CHANGE_SELECTED_TAB:
      return {
        ...state,
        filterStatuses: payload.statuses,
      };

    case ordersTypes.UPDATE_ORDER_ON_LIST:
      const updatedOrders = state.orders.map((order) => {
        if (order.id !== payload.order.id) return order;

        return {
          ...order,
          ...payload.order,
        };
      });

      return {
        ...state,
        orders: transformOrdersList(updatedOrders),
      };

    default:
      return state;
  }
};

export const ordersActions = {
  loadOrders: (statuses = undefined) => ({
    type: ordersTypes.LOAD_ORDERS_REQUEST,
    payload: { statuses },
  }),

  loadCompletedOrders: () => ({
    type: ordersTypes.LOAD_COMPLETED_ORDERS_REQUEST,
  }),

  changeSelectedTab: (statuses) => ({
    type: ordersTypes.CHANGE_SELECTED_TAB,
    payload: { statuses },
  }),

  updateOrderOnList: (order) => ({
    type: ordersTypes.UPDATE_ORDER_ON_LIST,
    payload: order,
  }),
};

export const ordersSelectors = {
  getOrders: (state) => state.ordersList.orders,

  getOrdersLoading: (state) => state.ordersList.ordersLoading,

  getCompletedOrders: (state) => state.ordersList.completedOrders,

  getCompletedOrdersLoading: (state) => state.ordersList.completedOrdersLoading,

  getFilterStatuses: (state) => state.ordersList.filterStatuses,
};
