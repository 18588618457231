import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import styles from './ConfirmTaskDelete.module.scss';

import { PinkButton } from '../../../components/buttons';

import { orderActions } from '../../../reducers/order';

import LabeledNumberInput from '../../../components/inputs/number/LabeledNumberInput';

import { Loader } from '../../../components/ui';

class OrderTaskDeletePopup extends Component {
  componentDidMount() {
    this.props.rejectTaskStart();
  }

  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
    };
  }

  renderFirstStep() {
    const { t, task, checkLoading } = this.props;

    return (
      <Fragment>
        <div className={styles.text}>
          {t('areYouSureYouWantToRejectTheTask')}
        </div>
        <Loader loading={checkLoading}>
          <div>
            <LabeledNumberInput
              max={task.quantity - task.quantity_rejected}
              unit={task.service.unit}
              onChange={(value) => {
                this.setState({ quantity: value });
              }}
            />
          </div>

          <div className={styles.btn}>
            <PinkButton
              onClick={() => {
                this.props.checkDelete(task.id, this.state.quantity);
                ReactGA.event({
                  category: 'event',
                  action: 'click',
                  label: 'gauqmeba',
                });
              }}
            >
              {t('reject')}
            </PinkButton>
          </div>
        </Loader>
      </Fragment>
    );
  }

  renderReturnPercent = () => {
    const { t, task, checkPercent } = this.props;

    if (task.from_tasker && !task.confirmed) return null;

    return (
      <div className={styles.text}>
        <span>
          {t('returnMoney')}: <b>{checkPercent}%</b>
        </span>
      </div>
    );
  };

  renderSecondStep() {
    const { t, task } = this.props;

    return (
      <Fragment>
        <div className={styles.text}>
          {t('areYouSureYouWantToRejectTheTask')}
        </div>

        <Loader loading={false}>
          {this.renderReturnPercent()}

          <div className={styles.btn}>
            <PinkButton
              onClick={() => {
                this.props.rejectTask(
                  task.id,
                  this.state.quantity,
                  task.order_id,
                );
                this.props.onClose();
              }}
            >
              {t('confirmReject')}
            </PinkButton>
          </div>
        </Loader>
      </Fragment>
    );
  }

  renderContent() {
    const { checkAvailable } = this.props;

    if (checkAvailable === null) {
      return this.renderFirstStep();
    }

    if (checkAvailable) {
      return this.renderSecondStep();
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('rejectTheTask')}</div>

        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    checkLoading: state.order.taskDeleteCheckLoading,
    checkAvailable: state.order.taskDeleteCheckAvailable,
    checkPercent: state.order.taskDeleteCheckPercent,
  };
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    checkDelete: orderActions.rejectTaskCheckInOrder,
    rejectTaskStart: orderActions.rejectTaskStart,
    rejectTask: orderActions.rejectTaskInOrder,
  }),
)(OrderTaskDeletePopup);
