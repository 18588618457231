import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { PageContent, Svg } from '../index';
import { GreenButton } from '../../buttons/index';

import styles from './Failure.module.scss';

const Failure = () => {
  return (
    <PageContent noBg>
      <Helmet>
        <title>Payment failure</title>
      </Helmet>
      <div className={styles.img}>
        <Svg src={'downvote'} />
      </div>
      <div className={styles.title}>Oops Failed</div>
      <p className={styles.text}>
        we are very sorry for inconvenience. It looks like you're trying to
        access a page that eather has been deleted or never even existed.
      </p>
      <div className={styles.btnWrapper}>
        <Link to="/" className={styles.notFound_link}>
          <GreenButton>Go Home</GreenButton>
        </Link>
      </div>
    </PageContent>
  );
};

export default Failure;
