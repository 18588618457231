import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Svg } from './index';

import styles from './Tab.module.scss';

const Tab = ({
  isSelected,
  children,
  onClick,
  icon,
  border,
  arrow,
  newMessages,
}) => (
  <div
    className={classNames(styles.tab, {
      [styles.tabSelected]: isSelected,
      [styles.tabIcon]: icon,
      [styles.border]: border,
      [styles.arrow]: arrow,
    })}
    onClick={onClick}
  >
    {icon && (
      <div className={styles.icon}>
        <Svg src={icon} />
      </div>
    )}
    {children}

    {newMessages && newMessages > 0 ? (
      <div className={styles.newMessagesWrapper}>
        <div className={styles.newMessages}>{newMessages}</div>
      </div>
    ) : null}
  </div>
);

Tab.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Tab;
