import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { getPathForCreateNewTask } from '../../routing/constants/pathHelpers';
import { createOrderActions } from '../../../reducers/createOrder';
import { PinkButton } from '../../../components/buttons';

import styles from './ConfirmDetachTaskerFromOrder.module.scss';

class ConfirmDetachTaskerFromOrder extends Component {
  render() {
    const { orderId, detachTaskerAndRedirect, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('detachTaskerFromOrder')}</div>
        <div className={styles.text}>
          <p>{t('currentSelectedTaskerWillBeAddNewTaskToJobOrder') + '.'}</p>
          <p>{t('doYouWontToContinue')}</p>
        </div>
        <div className={styles.btn}>
          <PinkButton
            onClick={() => {
              detachTaskerAndRedirect(orderId, getPathForCreateNewTask());
              onClose();
            }}
          >
            {t('continue')}
          </PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    detachTaskerAndRedirect: createOrderActions.detachTaskerAndRedirect,
  }),
)(ConfirmDetachTaskerFromOrder);
