import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  deleteAddress,
  getAddresses,
  makeFavorite,
} from 'services/addressService';
import { deleteOrder, deleteTask } from 'services/ordersListService';
import { RootReduxState } from 'store';
import { AddressActionEnum, AddressActionsType } from './actions';

function* onGetAddresses() {
  try {
    const data: AddressesType = yield call(getAddresses);

    yield put({
      type: AddressActionEnum.GET_ADDRESSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
}

function* onDeleteAddress({
  payload,
}: ReturnType<AddressActionsType['deleteAddress']>) {
  try {
    yield call(deleteAddress, payload);
    yield call(onGetAddresses);
  } catch (error) {
    console.log(error);
  }
}

function* onAddInFavorites({
  payload,
}: ReturnType<AddressActionsType['addInFavorites']>) {
  try {
    yield call(makeFavorite, { id: payload });
    yield call(onGetAddresses);
  } catch (error) {
    console.log(error);
  }
}

function* onOrderItemDelete({
  payload,
}: ReturnType<AddressActionsType['deleteOrderItem']>) {
  const {
    currentOrder,
  }: {
    currentOrder: CurrentOrderType | null;
  } = yield select((state: RootReduxState) => ({
    currentOrder: state.createTaskFullFlow.currentOrder,
  }));

  try {
    if (currentOrder?.model && currentOrder?.model?.tasks.length > 1) {
      yield call(deleteTask, payload);
      yield put(createTaskActions.loadCurrentOrder());
    } else {
      yield call(deleteOrder, currentOrder?.model?.id);
      yield put(createTaskActions.createOrderAndLoadCategories());
    }
  } catch (error) {
    console.log(error);
  }
}

export const createAddressTasks = {
  creatingSagaListeners: function* () {
    yield takeEvery(AddressActionEnum.GET_ADDRESSES, onGetAddresses);
    yield takeEvery(AddressActionEnum.DELETE_ADDRESS, onDeleteAddress);
    yield takeEvery(AddressActionEnum.ADD_IN_FAVORITE, onAddInFavorites);
    yield takeEvery(AddressActionEnum.DELETE_ORDER_ITEM, onOrderItemDelete);
  },
};
