import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tasker } from '../../../components/userViews/tasker';
import { Reviews } from '../../../components/reviews/index';
import { PinkButton, PinkBorderButton } from '../../../components/buttons';
import styles from './TaskerListItemView.module.scss';

const TaskerListItemView = ({
  noPaid,
  loading,
  tasker,
  onTimetableOpen,
  onProfileOpen,
  taskerAvatarBig,
  badge,
  achievements,
  t,
}) => {
  const { user } = tasker;

  return (
    <div className={styles.container}>
      <Tasker
        noPaid={noPaid}
        user={user}
        price={tasker.price}
        bigPrice={true}
        taskerAvatarBig={taskerAvatarBig}
        badge
        achievements
      />

      <div
        className={classNames(styles.btnWrapper, {
          [styles.Reviews]: user.last_review,
        })}
      >
        <div className={styles.btn}>
          <PinkButton
            medium
            minWidth={'150'}
            onClick={() => onTimetableOpen(tasker)}
          >
            {t('seeAvailability')}
          </PinkButton>
        </div>

        <div className={styles.btn}>
          <PinkBorderButton
            medium
            minWidth={'150'}
            onClick={() => onProfileOpen(tasker.user.id)}
          >
            {t('profile')}
          </PinkBorderButton>
        </div>
      </div>

      {user.last_review ? <Reviews reviews={[user.last_review]} /> : null}
    </div>
  );
};

TaskerListItemView.propTypes = {
  tasker: PropTypes.object,
  onTimetableOpen: PropTypes.func.isRequired,
  onProfileOpen: PropTypes.func.isRequired,
};

export default withTranslation()(TaskerListItemView);
