import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { PinkBorderButton } from '../../../components/buttons';
import { createTaskSelectors, createTaskActions } from '../store';
import {
  getOrderIdFromProps,
  getPathForEditOrder,
} from '../../routing/constants/pathHelpers';
import { createOrderSelectors } from '../../../reducers/createOrder';

class CreateTaskBackButton extends Component {
  getBackButton = () => {
    const { tasks, answers, dispatch, t } = this.props;
    const { taskId } = this.props.match.params;

    if (taskId) {
      return {
        label: t('backToOrder'),
        onClick: () =>
          dispatch(push(getPathForEditOrder(getOrderIdFromProps(this.props)))),
      };
    } else {
      if (tasks.length === 0 && answers.length < 1) {
        return null;
      } else if (answers.length < 1) {
        // if no categories and no tasks you can't go back
        return {
          label: t('backToOrder'),
          onClick: () =>
            dispatch(
              push(getPathForEditOrder(getOrderIdFromProps(this.props))),
            ),
        };
      } else if (
        (answers.length === 2 && !answers[1].answer) ||
        answers.length === 1
      ) {
        // only subcategory selected
        return {
          label: t('backToCategories'),
          onClick: () => dispatch(createTaskActions.moveToCategories()),
        };
      } else {
        return {
          label: t('backToPreviousQuestion'),
          onClick: () => dispatch(createTaskActions.moveToPreviousQuestion()),
        };
      }
    }
  };

  render() {
    const button = this.getBackButton();

    return (
      button && (
        <PinkBorderButton onClick={button.onClick}>
          {button.label}
        </PinkBorderButton>
      )
    );
  }
}

export default compose(
  withTranslation(),
  withRouter,
  connect((state) => ({
    tasks: createOrderSelectors.getTasks(state),
    answers: createTaskSelectors.getAllAnswers(state),
  })),
)(CreateTaskBackButton);
