import React, { useCallback, useContext } from 'react';
import { components } from 'react-select';
// import { Text } from './Typography.style';

// import { ThemeContext } from 'styled-components';
// import Arrow from '../../assets/images/svg/arrow.svg';
import { StyledSelect } from './DropDown.styled';
import english from '../../assets/images/icons/lang/english-flag.png';
import georgian from '../../assets/images/icons/lang/georgian-flag.png';
import russian from '../../assets/images/icons/lang/russian-flag.png';
type DropDownProps<T> = {
  instanceId: string;
  value: T;
  options: T[];
  handleChange: (option: { value: string }) => void;
};

const { Option, SingleValue } = components;

function DropDown<T>({
  instanceId,
  value,
  handleChange,
  options,
}: DropDownProps<T>): React.ReactElement {
  // const { fontFamilies } = useContext(ThemeContext);

  const CustomSelectOption = useCallback(
    (props: any) => (
      <Option {...props}>
        {!!props.data.icon && (
          <img
            // src={`${props.data.icon}`}

            src={props.data.icon == 'english' ? english : props.data.icon == 'georgian' ? georgian : props.data.icon == 'russian' ? russian : ''}
            style={{ width: '27px', height: '18px' }}
            alt={props.data.icon}
          />
        )}

        {/* <Text fontSize={15}> */}
        <p>
          {props.data.label}
        </p>

        {/* </Text> */}
      </Option>
    ),
    [],
  );

  const CustomSelectValue = useCallback(
    (props: any) => (
      <SingleValue {...props}>
        {/* <img src={`/static/images/icons/${props.data.icon}-flag.png`} /> */}
        {!!props.data.icon && (
          <img
            src={props.data.icon == 'english' ? english : props.data.icon == 'georgian' ? georgian : props.data.icon == 'russian' ? russian : ''}
            style={{ width: '27px', height: '18px' }}
            alt={props.data.icon}
          />
        )}
        {/* <Text fontSize={15}> */}
        <p>
          {props.data.label}
        </p>

        {/* </Text> */}
      </SingleValue>
    ),
    [],
  );
  // const IndicatorsContainer = useCallback(() => <Arrow />, []);

  return (
    <StyledSelect
      instanceId={instanceId}
      value={value}
      onChange={handleChange.bind(DropDown)}
      options={options}
      classNamePrefix="Select"
      isSearchable={false}
      hasValue={true}
      hideSelectedOptions={true}
      components={{
        Option: CustomSelectOption,
        SingleValue: CustomSelectValue,
        // IndicatorsContainer,
      }}
    />
  );
}

export default DropDown;
