export enum ActionsEnum {
  ORDER_CREATE_START = 'main_Flow/ORDER_CREATE_START',
  ORDER_CREATE_SUCCESS = 'main_Flow/ORDER_CREATE_SUCCESS',
  ORDER_CREATE_FAILURE = 'main_Flow/ORDER_CREATE_FAILURE',

  SELECT_SUB_CATEGORY = 'main_Flow/SELECT_SUB_CATEGORY',
  SELECT_SUB_CATEGORY_SUCCESS = 'main_Flow/SELECT_SUB_CATEGORY_SUCCESS',
  SELECT_SUB_CATEGORY_FAILURE = 'main_Flow/SELECT_SUB_CATEGORY_FAILURE',

  FETCH_CATEGORY = 'main_Flow/FETCH_CATEGORY',
  FETCH_CATEGORY_SUCCESS = 'main_Flow/FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_FAILURE = 'main_Flow/FETCH_CATEGORY_FAILURE',

  SELECT_SUPER_CATEGORY = 'main_Flow/SELECT_SUPER_CATEGORY',
  SELECT_SUPER_CATEGORY_SUCCESS = 'main_Flow/SELECT_SUPER_CATEGORY_SUCCESS',
  SELECT_SUPER_CATEGORY_FAILURE = 'main_Flow/SELECT_SUPER_CATEGORY_FAILURE',

  SELECT_CATEGORY = 'main_Flow/SELECT_CATEGORY',
  SELECT_CATEGORY_SUCCESS = 'main_Flow/SELECT_CATEGORY_SUCCESS',
  SELECT_CATEGORY_FAILURE = 'main_Flow/SELECT_CATEGORY_FAILURE',

  UPLOAD_TASK = 'main_Flow/UPLOAD_TASK',
  UPLOAD_TASK_SUCCESS = 'main_Flow/UPLOAD_TASK_SUCCESS',
  UPLOAD_TASK_FAILURE = 'main_Flow/UPLOAD_TASK_FAILURE',

  ADD_ADDRESS_TO_ORDER = 'main_Flow/ADD_ADDRESS_TO_ORDER',
  ADD_ADDRESS_TO_ORDER_SUCCESS = 'main_Flow/ADD_ADDRESS_TO_ORDER_SUCCESS',
  ADD_ADDRESS_TO_ORDER_FAILURE = 'main_Flow/ADD_ADDRESS_TO_ORDER_FAILURE',

  ADD_PROFY_TO_ORDER = 'main_Flow/ADD_PROFY_TO_ORDER',
  ADD_PROFY_TO_ORDER_SUCCESS = 'main_Flow/ADD_PROFY_TO_ORDER_SUCCESS',
  ADD_PROFY_TO_ORDER_FAILURE = 'main_Flow/ADD_PROFY_TO_ORDER_FAILURE',

  FINISH_PAYMENT = 'main_Flow/FINISH_PAYMENT',
  FINISH_PAYMENT_SUCCESS = 'main_Flow/FINISH_PAYMENT_SUCCESS',
  FINISH_PAYMENT_FAILURE = 'main_Flow/FINISH_PAYMENT_FAILURE',

  CHANGE_ACTIVE_STEP = 'main_Flow/CHANGE_ACTIVE_STEP',

  LOAD_CURRENT_ORDER = 'main_Flow/LOAD_CURRENT_ORDER',
  LOAD_CURRENT_ORDER_SUCCESS = 'main_Flow/LOAD_CURRENT_ORDER_SUCCESS',
  LOAD_CURRENT_ORDER_FAILURE = 'main_Flow/LOAD_CURRENT_ORDER_FAILURE',

  GET_SUPER_CATEGORIES = 'main_Flow/GET_SUPER_CATEGORIES',

  GO_FIRST_STEP = 'main_flow/GO_FIRST_STEP',
  CHANGE_QUESTION_STEP_STATUS = 'main_flow/CHANGE_QUESTION_STEP_STATUS',
  ADD_SUBSCRIPTION = 'main_flow/ADD_SUBSCRIPTION',
  ADD_SUBSCRIPTION_SUCCESS = 'main_flow/ADD_SUBSCRIPTION_SUCCESS',
  REMOVE_SUBSCRIPTION = 'main_flow/REMOVE_SUBSCRIPTION',
  REMOVE_SUBSCRIPTION_SUCCESS = 'main_flow/REMOVE_SUBSCRIPTION_SUCCESS',
}

export type CreateTaskActionsType = {
  createOrderAndLoadCategories: () => {
    type: ActionsEnum.ORDER_CREATE_START;
  };
  changeActiveStep: (action: number) => {
    type: ActionsEnum.CHANGE_ACTIVE_STEP;
    payload: number;
  };
  fetchCategory: (
    index: number,
    id: number,
  ) => {
    type: ActionsEnum.FETCH_CATEGORY;
    payload: { index: number; id: number };
  };
  selectSuperCategory: (index: number) => {
    type: ActionsEnum.SELECT_SUPER_CATEGORY;
    payload: { index: number };
  };
  selectCategoryIndex: (payload: {
    selectedSuperCategoryIndex: number;
    selectedCategoryIndex: number;
  }) => {
    type: ActionsEnum.SELECT_CATEGORY;
    payload;
  };
  uploadTask: () => {
    type: ActionsEnum.UPLOAD_TASK;
  };
  addAddressToOrder: () => {
    type: ActionsEnum.ADD_ADDRESS_TO_ORDER;
  };
  addProfyToOrder: () => {
    type: ActionsEnum.ADD_PROFY_TO_ORDER;
  };
  finishPayment: () => {
    type: ActionsEnum.FINISH_PAYMENT;
  };
  loadCurrentOrder: () => {
    type: ActionsEnum.LOAD_CURRENT_ORDER;
  };
  onGetSuperCategories: () => {
    type: ActionsEnum.GET_SUPER_CATEGORIES;
  };
  onGoFirstStep: () => {
    type: ActionsEnum.GO_FIRST_STEP;
  };
  onChangeQuestionStepStatus: (payload: boolean) => {
    type: ActionsEnum.CHANGE_QUESTION_STEP_STATUS;
    payload;
  };
  onAddSubscription: (payload) => {
    type: ActionsEnum.ADD_SUBSCRIPTION;
    payload;
  };
  onAddSubscriptionSuccess: (payload) => {
    type: ActionsEnum.ADD_SUBSCRIPTION_SUCCESS;
    payload;
  };
  onRemoveSubscription: (payload) => {
    type: ActionsEnum.REMOVE_SUBSCRIPTION;
    payload;
  };
  onRemoveSubscriptionSuccess: (payload) => {
    type: ActionsEnum.REMOVE_SUBSCRIPTION_SUCCESS;
    payload;
  };
};

export const createTaskActions: CreateTaskActionsType = {
  createOrderAndLoadCategories: () => ({
    type: ActionsEnum.ORDER_CREATE_START,
  }),
  changeActiveStep: (payload) => ({
    type: ActionsEnum.CHANGE_ACTIVE_STEP,
    payload,
  }),
  fetchCategory: (index, id) => ({
    type: ActionsEnum.FETCH_CATEGORY,
    payload: { index, id },
  }),
  selectSuperCategory: (index) => ({
    type: ActionsEnum.SELECT_SUPER_CATEGORY,
    payload: { index },
  }),
  selectCategoryIndex: (payload) => ({
    type: ActionsEnum.SELECT_CATEGORY,
    payload,
  }),
  uploadTask: () => ({
    type: ActionsEnum.UPLOAD_TASK,
  }),
  addAddressToOrder: () => ({
    type: ActionsEnum.ADD_ADDRESS_TO_ORDER,
  }),
  addProfyToOrder: () => ({
    type: ActionsEnum.ADD_PROFY_TO_ORDER,
  }),
  finishPayment: () => ({
    type: ActionsEnum.FINISH_PAYMENT,
  }),
  loadCurrentOrder: () => ({
    type: ActionsEnum.LOAD_CURRENT_ORDER,
  }),
  onGetSuperCategories: () => ({
    type: ActionsEnum.GET_SUPER_CATEGORIES,
  }),
  onGoFirstStep: () => ({
    type: ActionsEnum.GO_FIRST_STEP,
  }),
  onChangeQuestionStepStatus: (payload) => ({
    type: ActionsEnum.CHANGE_QUESTION_STEP_STATUS,
    payload,
  }),
  onAddSubscription: (payload) => ({
    type: ActionsEnum.ADD_SUBSCRIPTION,
    payload,
  }),
  onAddSubscriptionSuccess: (payload) => ({
    type: ActionsEnum.ADD_SUBSCRIPTION_SUCCESS,
    payload,
  }),
  onRemoveSubscription: (payload) => ({
    type: ActionsEnum.REMOVE_SUBSCRIPTION,
    payload,
  }),
  onRemoveSubscriptionSuccess: (payload) => ({
    type: ActionsEnum.REMOVE_SUBSCRIPTION_SUCCESS,
    payload,
  }),
};
