const PROFILE = 'PROFILE';
const ORDER_PAID = 'ORDER_PAID';
const ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS';
const ORDER_CONFIRMATION = 'ORDER_CONFIRMATION';
const ORDER_TRANSPORTATION = 'ORDER_TRANSPORTATION';
const ORDER_VIEWED = 'ORDER_VIEWED';
const ORDER_PENDING = 'ORDER_PENDING';
const ORDER_ON_WARRANTY = 'ORDER_ON_WARRANTY';
const DEACTIVATED = 'DEACTIVATED';
const BLOCKED = 'BLOCKED';

export const screens = {
  ORDER_PAID,
  ORDER_VIEWED,
  ORDER_TRANSPORTATION,
  ORDER_IN_PROGRESS,
  ORDER_ON_WARRANTY,
  ORDER_CONFIRMATION,
  ORDER_PENDING,
  PROFILE,
  DEACTIVATED,
  BLOCKED,
};
