import React from 'react';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import { chatActions, chatSelectors } from '../../reducers/chat';
import EmptyMessageList from './EmptyMessageList';
import { userSelectors } from '../../reducers/user';
import ChatMessage from './ChatMessage';
import last from 'lodash/last';
import { bindActionCreators } from 'redux';
import { GreenButton } from '../../components/buttons';

import styles from './MessageListBlock.module.scss';
import { Loader } from '../../components/ui';

class MessageListBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  componentDidMount() {
    if (this.props.messages.length > 0) {
      this.scrollToBottom();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.messages.length === 0 && this.props.messages.length > 0) {
      this.scrollToBottom();
    } else if (last(prevProps.messages) !== last(this.props.messages)) {
      let isMyMessage;

      if (this.props.messages.length === 0) {
        isMyMessage = false;
      } else {
        isMyMessage = last(this.props.messages).user.id === this.props.userId;
      }

      if (isMyMessage) this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.container.current) {
      this.container.current.scrollTop = this.container.current.scrollHeight;
    }
  };

  fetchMoreMessages = () => {
    if (!this.props.hasMore) return;

    this.props.getMessages({
      chatId: this.props.chatRoom,
      lastMessageId: this.props.messages[0].id,
      earlier: true,
    });
  };

  renderMessage = (message) => {
    const { uploadImages } = this.props;
    const isMyMessage = message.user.id === this.props.userId;

    return (
      <ChatMessage
        key={message.id + message.created_at}
        type={message.type}
        content={message.content}
        createdAt={message.created_at}
        own={isMyMessage}
        uploadImages={uploadImages}
      />
    );
  };

  render() {
    const { isSupportChat, messages, messageLoading, hasMore, t } = this.props;

    const isEmptyList = messages.length === 0;

    return (
      <div className={styles.body} ref={this.container}>
        <Loader loading={messageLoading}>
          {hasMore && (
            <div className={styles.btnLoadMessages}>
              <GreenButton type="button" onClick={this.fetchMoreMessages}>
                {t('loadPreviousMessages')}
              </GreenButton>
            </div>
          )}
          {isEmptyList ? (
            <EmptyMessageList isSupportChat={isSupportChat} />
          ) : (
            messages.map(this.renderMessage)
          )}
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: chatSelectors.getMessageList(state),
  messageLoading: chatSelectors.isMessageListLoading(state),
  hasMore: chatSelectors.hasMoreMessages(state),
  userId: userSelectors.getUserProfile(state).id,
  uploadImage: chatSelectors.getUploadImage(state),
  uploadImageLoading: chatSelectors.getUploadImageLoading(state),
  uploadedImage: chatSelectors.getUploadedImage(state),
  uploadImages: chatSelectors.getUploadImages(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMessages: chatActions.getMessageList,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(MessageListBlock);
