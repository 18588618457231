import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PageContent.module.scss';
import { Wrapper } from './index';

const PageContent = ({ noBg, title, wrapper, children }) => {
  const Container = wrapper ? Wrapper : React.Fragment;

  return (
    <div className={classNames(styles.page, { [styles.pageNoBg]: noBg })}>
      <div
        className={classNames(styles.pageBody, {
          [styles.pageBodyNoTitle]: !title && !noBg,
        })}
      >
        {title ? (
          <Wrapper>
            <div className={styles.pageHeader}>
              <h1 className={styles.pageTitle}>{title}</h1>
            </div>
          </Wrapper>
        ) : null}

        <Container>{children}</Container>
      </div>
    </div>
  );
};

PageContent.propTypes = {
  noBg: PropTypes.bool,
  title: PropTypes.string,
  wrapper: PropTypes.bool,
};

PageContent.defaultProps = {
  wrapper: true,
};

export default PageContent;
