import React from 'react';
import { v1 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import DropZone from 'react-dropzone';
import classNames from 'classnames';

import styles from './TaskImagesUploader.module.scss';
import { getBase64 } from 'utils/formData';
import { Svg } from 'components/ui';
import { dialogActions } from 'containers/dialog/store';
import { RootReduxState } from 'store';
import { ServiceActions } from '../state/actions';

const TaskImagesUploader = () => {
  const dispatch = useDispatch();
  const { additionalDetailsImages } = useSelector((state: RootReduxState) => ({
    ...state.createTaskFullFlow,
    ...state.serviceStore,
  }));

  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      accepted.forEach((file: File) => {
        if (file instanceof File) {
          if (file.size > 10485760) {
            dispatch(dialogActions.openUploadImageErrorPopup({ maxSize: 10 }));
          } else {
            getBase64(file).then((base64) => {
              dispatch(
                ServiceActions.uploadImage({
                  file,
                  base64: base64 as string,
                  id: v1(),
                }),
              );
            });
          }
        }
      });
    }

    if (rejected.length > 0) {
      dispatch(dialogActions.openUploadImageErrorPopup());
    }
  };

  return (
    <div className={`scrollable ${styles.images}`}>
      {additionalDetailsImages?.length < 10 ? (
        <div className={classNames(styles.image, styles.dropZoneContainer)}>
          <DropZone
            className={styles.dropZone}
            onDrop={onDrop}
            accept={'image/png, image/jpg, image/jpeg'}
          />
          <div className={classNames(styles.dropZoneImg, styles.responsiveImg)}>
            <Svg src={'photo-camera'} className={styles.responsiveImg}/>
          </div>
        </div>
      ) : null}
      {additionalDetailsImages?.map(({ id, url }, index) => (
        <div
          className={styles.imageContainer}
          key={id}
          onClick={dispatch.bind(this, ServiceActions.deleteImage(index))}
        >
          <img src={url} className={styles.image} />
        </div>
      ))}
    </div>
  );
};

export default TaskImagesUploader;
