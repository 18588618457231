import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { orderActions } from '../../../reducers/order';

import styles from './AddedOrdersCheck.module.scss';

class AddedOrdersCheck extends Component {
  onConfirm = () => {
    this.props.confirmAddedOrder(this.props.orders[0]);
  };

  onReject = () => {
    this.props.orders.map((eachOrders) => {
      this.props.rejectAddedOrder(eachOrders);
    });
  };

  render() {
    const { t } = this.props;
    const addedOrders = this.props.orders;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('addANewOrder')}</div>
        <div className={styles.text}>{t('addedOrdersLongText')}</div>
        <p className={styles.orders__title}>{t('selectedServices')}</p>
        <div className={styles.tasks__wraper}>
          {addedOrders.map((singleTask) => {
            return (
              <div className={styles.single__task__holder}>
                <div className={styles.left__side}>
                  <img src={singleTask.service.image} alt="" />
                  <p>{singleTask.service.name} ( {singleTask.quantity} )</p>
                </div>
                <div className={styles.right__side}>
                  <p className={styles.price}>{singleTask.price} ₾</p>
                </div>
              </div>
            );
          })}
        </div>
        <p className={styles.erorr__message}>
          {this.props.faildError?.errors['*'][0].message}
        </p>
        <div className={styles.btn}>
          <div className={styles.cancel__button} onClick={this.onReject}>
            {t('cancelOrders')}
          </div>
          <div className={styles.confirm__button} onClick={this.onConfirm}>
            {t('confirm')}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faildError: state.order.confirmAddedOrderfaild,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    confirmAddedOrder: orderActions.confirmAddedOrder,
    rejectAddedOrder: orderActions.rejectAddedOrder,
  }),
)(AddedOrdersCheck);
