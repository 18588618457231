import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './ReviewsListItems.module.scss';
import { Avatar, Rating } from '../../ui';

import { getDateTextMonth } from '../../../utils/time';

const ReviewsListItem = ({ review, category }) => {
  return (
    <div className={styles.review}>
      <div className={styles.reviewAvatar}>
        <Avatar auto image={review.author.avatar} />
      </div>

      <div className={styles.reviewContent}>
        {review.rating ? (
          <div className={styles.reviewRating}>
            <Rating rating={review.rating} small />
          </div>
        ) : null}

        {review.service_categories &&
          review.service_categories.map((category) => {
            return (
              <div key={category} className={styles.category}>
                {category}
              </div>
            );
          })}

        {review.text ? (
          <p className={styles.reviewText}>{review.text}</p>
        ) : null}

        <div className={styles.reviewFooter}>
          {review.author.first_name} {review.author.last_name} -{' '}
          {getDateTextMonth(review.created_at)}
        </div>
      </div>
    </div>
  );
};

ReviewsListItem.propTypes = {
  review: PropTypes.object,
  category: PropTypes.bool,
};

export default withTranslation()(ReviewsListItem);
