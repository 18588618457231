import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Avatar, Loader, Svg } from '../../../../components/ui';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './UserBlock.module.scss';
import { userActions, userSelectors } from '../../../../reducers/user';
import { RootReduxState } from 'store';
import { useClickAway } from 'ahooks';
import { dialogActions } from 'containers/dialog/store';

const UserBlock = () => {
  const ref = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const { userLoggedIn, profile, profileLoading, deactivated } = useSelector(
    (state: RootReduxState) => ({
      userLoggedIn: userSelectors.isUserLoggedIn(state),
      deactivated: userSelectors.isUserDeactivated(state),
      profile: userSelectors.selectUserProfile(state),
      profileLoading: userSelectors.selectUserProfileLoading(state),
    }),
  );

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(userActions.getUserProfile());
    }
  }, [dispatch, userLoggedIn]);

  const closeShowLinks = useCallback(() => {
    if (userLoggedIn) {
      setShow(false);
    }
  }, [userLoggedIn]);

  useClickAway(() => {
    closeShowLinks();
  }, ref);

  const renderAvatar = useCallback(
    (avatar) => {
      if (avatar) {
        return (
          <div className={styles.avatar}>
            <Avatar auto image={avatar} />
          </div>
        );
      }
      return (
        <div
          className={classNames(styles.noAvatar, {
            [styles.noAvatarInProfile]:
              profile && profile.phone && !profile.avatar,
          })}
        >
          <Svg src={'noAvatar'} />
        </div>
      );
    },
    [profile],
  );

  const toggleShowLinks = useCallback(() => setShow(!show), [show]);

  const openShowLinks = useCallback(() => {
    if (userLoggedIn) {
      setShow(true);
    }
  }, [userLoggedIn]);

  const showList = useCallback(() => {
    if (show) {
      return (
        <div ref={ref} className={styles.userLinksContainer}>
          <ul className={styles.userLinks}>
            {deactivated ? null : (
              <li className={styles.userLinksItem}>
                <Link
                  className={styles.userLinksLink}
                  to={'/settings/profile'}
                  onClick={closeShowLinks}
                >
                  {t('myProfile')}
                </Link>
              </li>
            )}

            {deactivated ? null : (
              <li className={styles.userLinksItem}>
                <a
                  className={styles.userLinksLink}
                  onClick={() =>
                    dispatch(dialogActions.openInviteFriendDialog())
                  }
                >
                  {t('inviteFriends')}
                </a>
              </li>
            )}
            {deactivated ? null : (
              <li className={styles.userLinksItem}>
                <Link
                  className={styles.userLinksLink}
                  to={'/settings/support'}
                  onClick={closeShowLinks}
                >
                  {t('contactSupport')}
                </Link>
              </li>
            )}
            <li className={styles.userLinksItem}>
              <button
                className={styles.userLinksLink}
                style={{ color: '#DB0A5B' }}
                onClick={() => {
                  dispatch(userActions.logout());
                  closeShowLinks();
                }}
              >
                {t('logout')}
              </button>
            </li>
          </ul>
        </div>
      );
    }
  }, [closeShowLinks, deactivated, dispatch, show, t]);

  const userInfoBtnHandle = useCallback(() => {
    if (userLoggedIn) return toggleShowLinks();
    return dispatch(userActions.openSignInPopup());
  }, [dispatch, toggleShowLinks, userLoggedIn]);

  return useMemo(
    () => (
      <div
        className={styles.userBlock}
        onMouseEnter={openShowLinks}
        onMouseLeave={closeShowLinks}
      >
        <div
          className={classNames(styles.userInfo, {
            [styles.noAuth]: profile && !profile.phone,
            [styles.noPadding]: profileLoading,
          })}
          onClick={userInfoBtnHandle}
        >
          <Loader loading={profileLoading} scale={true}>
            {renderAvatar(profile ? profile.avatar : null)}

            {/* {userLoggedIn && profile ? (
              <button
                className={classNames(styles.arrow, {
                  [styles.up]: show,
                  [styles.userAuth]: profile && profile.phone,
                })}
                type={'button'}
              />
            ) : null} */}
          </Loader>
        </div>

        {showList()}
      </div>
    ),
    [
      closeShowLinks,
      openShowLinks,
      profile,
      profileLoading,
      renderAvatar,
      show,
      showList,
      userInfoBtnHandle,
      userLoggedIn,
    ],
  );
};

export default UserBlock;
