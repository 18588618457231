import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import styles from './AddressesList.module.scss';
import { AddressCard } from './index';

const AddressesList = ({
  title,
  addresses,
  history,
  changeAddress,
  setNewAddressLocation,
  changeSelectedAddressComment,
  editingAddress,
  renderLink,
  renderDeleteLink,
  renderButton,
  isExistingToAddresses,
  t,
}) => (
  <div className={styles.addressesList}>
    <div className={styles.adressesListBlock}>
      {addresses && addresses.length !== 0 ? (
        <div className={styles.addressesListTitle}>{title}</div>
      ) : null}
      {addresses.map((address) => (
        <AddressCard
          key={address.address + Date.now() + Math.random()}
          address={address}
          setNewAddressLocation={setNewAddressLocation}
          changeSelectedAddressComment={changeSelectedAddressComment}
          onClick={changeAddress}
          isSelected={address.address === editingAddress.address}
          renderLink={renderLink}
          renderDeleteLink={renderDeleteLink}
          renderButton={renderButton}
        />
      ))}
    </div>
    <div className={styles.adressesListBlock}>
      {history && history.length !== 0 ? (
        <Fragment>
          <div className={styles.addressesListTitle}>{t('history')}</div>
          {history.map((address) => (
            <AddressCard
              key={address.address + Date.now() + Math.random()}
              history={true}
              address={
                isExistingToAddresses(addresses, address.address) !== undefined
                  ? isExistingToAddresses(addresses, address.address)
                  : address
              }
              setNewAddressLocation={setNewAddressLocation}
              onClick={changeAddress}
              isSelected={address.address === editingAddress.address}
              renderLink={renderLink}
              renderDeleteLink={renderDeleteLink}
              renderButton={renderButton}
            />
          ))}
        </Fragment>
      ) : null}
    </div>
  </div>
);

export default withTranslation()(AddressesList);
