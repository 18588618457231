import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from '../../../components/ui';

import styles from './TaskView.module.scss';

const TaskView = ({ tasksCount, task, onDelete, onClick }) => (
  <div className={styles.task}>
    <div className={styles.taskCard} onClick={() => onClick(task)}>
      <div className={styles.taskService}>
        <span>{`(x${task.quantity}) `}</span>
        {task.service ? task.service.name : null}
      </div>
    </div>
    <button
      className={styles.removeTask}
      onClick={() => onDelete(task.id, tasksCount)}
    >
      <Svg className={styles.removeTaskIcon} src={'remove'} />
    </button>
  </div>
);

TaskView.propTypes = {
  task: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TaskView;
