import React from 'react';
import InputMask from 'react-input-mask';
import LabeledTextInputNew from '../text/LabeledTextInputNew';

const MaskInputNew = (props) => (
  <InputMask
    mask={props.mask}
    maskChar={props.maskChar}
    {...props.input}
    label={props.label}
    type={props.type}
    name={props.name}
    meta={props.meta}
  >
    {(props) => (
      <LabeledTextInputNew
        {...props}
        error={props.meta.error}
        label={props.label}
        type={props.type}
        name={props.name}
      />
    )}
  </InputMask>
);

export default MaskInputNew;
