import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Rating.module.scss';

const Rating = ({ rating, small }) => {
  return (
    <div className={classNames(styles.rating, { [styles.small]: small })}>
      {Number.isInteger(rating) ? rating : rating.toFixed(1)}
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number,
  small: PropTypes.bool,
};

export default Rating;
