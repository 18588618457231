import React from 'react';
import styles from './DefaultForm.module.scss';

const DefaultForm = (props) => (
  <form onSubmit={props.onSubmit} className={styles.form}>
    {props.children}
  </form>
);

export default DefaultForm;
