import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';

import styles from './Popup.module.scss';

Modal.setAppElement(document.getElementById('root'));

class Popup extends Component {
  updateBodyScrollIfNecessary() {
    const { isOpen } = this.props;

    if (isOpen && document.body.style.overflow === '') {
      document.body.style.overflow = 'hidden';
    } else if (!isOpen && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }

  componentDidMount() {
    this.updateBodyScrollIfNecessary();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.updateBodyScrollIfNecessary();
    }
  }

  render() {
    const { noClose, onClose, isOpen, children, width } = this.props;

    return (
      <Modal
        overlayClassName={styles.overlay}
        className={styles.content}
        isOpen={isOpen}
        onRequestClose={!noClose ? onClose : null}
        shouldCloseOnEsc={true}
      >
        <div
          className={styles.popup}
          style={width && { width: `${width}px`, minWidth: `${width}px` }}
        >
          {/* {!noClose && (
            <button className={styles.close} type="button" onClick={onClose}>
              Close
            </button>
          )} */}

          {children}
        </div>
      </Modal>
    );
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }
}

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  noClose: PropTypes.bool,
  isOpen: PropTypes.bool,
  width: PropTypes.string,
};

export default Popup;
