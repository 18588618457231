import React from 'react';
import { lazyLoadable } from 'utils';

export const ProfileLazy = lazyLoadable(
  () => import('./profile/Profile'),
  () => <div></div>,
);

export const AddressesLazy = lazyLoadable(
  () => import('./addresses/Addresses'),
  () => <div></div>,
);

export const CardListLazy = lazyLoadable(
  () => import('./cardsList/CardList'),
  () => <div></div>,
);

export const AddOrEditAddressLazy = lazyLoadable(
  () => import('./addOrEditAddress/AddOrEditAddress'),
  () => <div></div>,
);
