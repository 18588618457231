import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { orderActions, orderSelectors } from '../../../reducers/order';
import {
  GrayBorderButton,
  GrayButtonTransparent,
  GreenButton,
} from '../../../components/buttons/index';
import { DefaultTextArea } from '../../../components/inputs/text/index';
import { RatingInput } from '../../../components/inputs/rating/index';
import styles from './ReviewForm.module.scss';
import { HexAvatar } from '../../../components/ui/index';
import FeedbackSuggestionItem from './FeedbackSuggestionItem';
import { paymentsActions, paymentsSelectors } from 'reducers/payments';
import { userSelectors } from 'reducers/user';
import { RootReduxState } from 'store';
import { createOrderActions, createOrderSelectors } from 'reducers/createOrder';
import ChooseCard from 'containers/createTaskFullFlow/containers/checkout/components/ChooseCard';
import { useHistory } from 'react-router-dom';

type ReviewFormPropsType = {
  onClose: () => void;
};

const preDefinedSugggestions = ['clean', 'behaviour', 'acomplishmentQuality'];

const ReviewForm = ({ onClose }: ReviewFormPropsType) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [secretText, setSecretText] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState<null | any>(null);
  const [suggestedTextIndex, setSuggestedTextIndex] = useState(-1);
  const [suggestedTipsIndex, setsuggestedTipsIndex] = useState(-1);

  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();

  const {
    userLoggedIn,
    selectedCardId,
    cardsList,
    offeredTips,
    order,
  } = useSelector((state: RootReduxState) => ({
    ...state.createTaskFullFlow,
    userLoggedIn: userSelectors.isUserLoggedIn(state),
    createCard: paymentsSelectors.getCreateCard(state),
    cardsList: paymentsSelectors.getCardsList(state),
    selectedCardId: createOrderSelectors.getOrderSelectedCard(state),
    offeredTips: orderSelectors.getOfferedTips(state),
    order: state.order.order,
  }));

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(paymentsActions.getCardsListRequest());
    }
  }, [dispatch, userLoggedIn]);

  useEffect(() => {
    order?.id && dispatch(orderActions.loadOfferedTips(order.id));
  }, [order, dispatch]);

  const clearError = () => {
    if (error) {
      setError(null);
    }
  };

  const onRatingChange = (rating) => {

    setRating(rating);
    clearError();
  };

  const onTextChange = (e) => {
    setText(e.target.value);
    clearError();
  };

  const onSecretTextChange = (e) => {
    setSecretText(e.target.value);
    clearError();
  };

  const getValidationError = () => {
    if (!rating) {
      return t('pleaseEnterRating');
    }
  };

  const onSend = () => {
    const error = getValidationError();
    setActiveStep(1);

    if (error) {
      setError(error);
    } else {
      const reviewObj = {
        order_id: order?.id,
        text: text,
        secret: secretText,
        rating: rating,
      };

      dispatch(orderActions.createReview(reviewObj));
    }
  };

  const onSendingTips = () => {
    const data = {
      tip_id: offeredTips[suggestedTipsIndex]?.id as number,
      amount: offeredTips[suggestedTipsIndex]?.amount as number,
    };

    dispatch(orderActions.giveTips(order?.id ?? 0, data));
    onFinish();
  };

  const history = useHistory()

  const onFinish = () => {
    onClose();
    setTimeout(() => {
      history.push('/orders')
    }, 500)

  }

  const onToggleCard = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(createOrderActions.selectCardRequest(id, true));
  };

  return (
    <>
      <div className={styles.title}>{t('review')}</div>

      <div className={styles.tasker}>
        <div className={styles.avatar}>
          <HexAvatar image={order?.tasker?.avatar} />
        </div>
        <div className={styles.body}>
          <div className={styles.name}>
            {order?.tasker?.first_name} {order?.tasker?.last_name}
          </div>

          <div>
            <RatingInput value={rating} onChange={onRatingChange} />
          </div>
        </div>
      </div>
      {!activeStep ? (
        <>
          <div className={styles.field}>
            <DefaultTextArea
              value={text}
              onChange={onTextChange}
              name={'text'}
              label={t('writeYourReview')}
              placeholder={t('comment')}
            />
          </div>
          <div className={'flex flex-row scrollable'}>
            {preDefinedSugggestions.map((val, index) => (
              <FeedbackSuggestionItem
                key={index}
                active={index === suggestedTextIndex}
                onSelectItem={() => {
                  setSuggestedTextIndex(index);
                }}
                text={t(val)}
              />
            ))}
          </div>

          <div className={styles.field}>
            <DefaultTextArea
              value={secretText}
              onChange={onSecretTextChange}
              name={'secret'}
              label={t('writeYourSecretReview')}
              placeholder={t('anonimousComment')}
            />
            <p>{t('textOnReview')}</p>
          </div>

          {error ? <div className={styles.error}>{error}</div> : null}

          <div className={styles.btnWrapper}>
            <GreenButton onClick={onSend}>{t('review')}</GreenButton>
          </div>
        </>
      ) : (
        <>
          <p>{t('areYouSetisfiedWithProfy')}</p>
          <div className={'flex flex-row flex-center scrollable'}>
            {offeredTips?.map((val, index) => (
              <FeedbackSuggestionItem
                key={index}
                active={index === suggestedTipsIndex}
                onSelectItem={() => {
                  setsuggestedTipsIndex(index);
                }}
                text={val.amount + ' ₾'}
              />
            ))}
          </div>
          <div className={styles.cardsContainer}>
            {suggestedTipsIndex >= 0 && (
              <ChooseCard
                cardsList={cardsList}
                toggleCard={onToggleCard}
                onCardAdd={() => { }}
                selectedCardId={selectedCardId}
                fromFeedback={true}
              />
            )}
          </div>
          <div className={styles.tipsButtonWrapper}>
            <GrayBorderButton onClick={onFinish}>{t('deny')}</GrayBorderButton>
            {suggestedTipsIndex >= 0 && (
              <GreenButton onClick={onSendingTips}>{t('pay')}</GreenButton>
            )}
          </div>
        </>
      )}
    </>
  );
};

ReviewForm.propTypes = {
  order: PropTypes.object,
};

export default ReviewForm;
