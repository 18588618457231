import {
  takeEvery,
  call,
  put,
  select,
  takeLatest,
  take,
  fork,
  cancel,
  delay,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { createFormData } from '../../utils/formData';
import { uploadAvatar } from '../../services/mediaService';
import {
  answerQuestion,
  loadTask,
  createTask,
  editTask,
  deleteTask,
  loadCategories,
  searchSubcategories,
  loadCategoryServices,
} from '../../services/ordersListService';
import {
  getPathForEditOrder,
  getPathForEditTask,
  getPathForCreateNewTask,
} from '../routing/constants/pathHelpers';
import get from 'lodash/get';
import { dialogActions } from '../dialog/store';
import { createOrderActions } from '../../reducers/createOrder';

export const MAX_IMAGES_COUNT_IN_TASK = 10;

/* ACTION TYPES */

const MODULE_PREFIX = 'task/';

const DEFAULT_SERVICE = {
  id: 1,
  name: 'Stuck sewer cleaning',
  description: 'The master will come and clean your stuck sewer',
  price_min: 180,
  price_max: 200,
  price_recommended: 190,
  service_fee: 10,
  tools: [1, 2],
  image:
    'http://mrmaster.dev.blak-it.com/uploads/avatars/Ad/TW/AdTWVU-CT4f-190RhPntItwI4XRFe2M9.jpg',
};

export const types = {
  START_EDITING_TASK_REQUEST: MODULE_PREFIX + 'START_EDITING_TASK_REQUEST',
  START_EDITING_TASK_SUCCESS: MODULE_PREFIX + 'START_EDITING_TASK_SUCCESS',
  START_EDITING_TASK_FAILURE: MODULE_PREFIX + 'START_EDITING_TASK_FAILURE',

  LOAD_TASK_REQUEST: MODULE_PREFIX + 'LOAD_TASK_REQUEST',
  LOAD_TASK_SUCCESS: MODULE_PREFIX + 'LOAD_TASK_SUCCESS',
  LOAD_TASK_FAILURE: MODULE_PREFIX + 'LOAD_TASK_FAILURE',

  LOAD_CATEGORIES_REQUEST: MODULE_PREFIX + 'LOAD_CATEGORIES_REQUEST',
  LOAD_CATEGORIES_SUCCESS: MODULE_PREFIX + 'LOAD_CATEGORIES_SUCCESS',
  LOAD_CATEGORIES_FAILURE: MODULE_PREFIX + 'LOAD_CATEGORIES_FAILURE',

  SELECT_CATEGORY: MODULE_PREFIX + 'SELECT_CATEGORY',
  SELECT_SUBCATEGORY: MODULE_PREFIX + 'SELECT_SUBCATEGORY',
  LOAD_ANSWERS_FROM_QS: MODULE_PREFIX + 'LOAD_ANSWERS_FROM_QS',

  ANSWER_QUESTION_REQUEST: MODULE_PREFIX + 'ANSWER_QUESTION_REQUEST',
  ANSWER_QUESTION_SUCCESS: MODULE_PREFIX + 'ANSWER_QUESTION_SUCCESS',
  ANSWER_QUESTION_FAILURE: MODULE_PREFIX + 'ANSWER_QUESTION_FAILURE',

  MOVE_TO_CATEGORIES: MODULE_PREFIX + 'MOVE_TO_CATEGORIES',
  MOVE_TO_PREVIOUS_QUESTION: MODULE_PREFIX + 'MOVE_TO_PREVIOUS_QUESTION',

  SHOW_FINAL_CREATE_TASK_PAGE: MODULE_PREFIX + 'SHOW_FINAL_CREATE_TASK_PAGE',

  CLEAR_STATE: MODULE_PREFIX + 'CLEAR_STATE',

  CHANGE_COMMENT: MODULE_PREFIX + 'CHANGE_COMMENT',

  CHANGE_QUANTITY: MODULE_PREFIX + 'CHANGE_QUANTITY',

  SAVE_TASK_REQUEST: MODULE_PREFIX + 'SAVE_TASK_REQUEST',
  EDIT_TASK_SUCCESS: MODULE_PREFIX + 'EDIT_TASK_SUCCESS',
  SAVE_TASK_SUCCESS: MODULE_PREFIX + 'SAVE_TASK_SUCCESS',
  SAVE_TASK_FAILURE: MODULE_PREFIX + 'SAVE_TASK_FAILURE',

  DELETE_TASK_REQUEST: MODULE_PREFIX + 'DELETE_TASK_REQUEST',
  DELETE_TASK_SUCCESS: MODULE_PREFIX + 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE: MODULE_PREFIX + 'DELETE_TASK_FAILURE',

  UPLOAD_IMAGE_FOR_TASK_REQUEST:
    MODULE_PREFIX + 'UPLOAD_IMAGE_FOR_TASK_REQUEST',
  UPLOAD_IMAGE_FOR_TASK_SUCCESS:
    MODULE_PREFIX + 'UPLOAD_IMAGE_FOR_TASK_SUCCESS',
  UPLOAD_IMAGE_FOR_TASK_FAILURE:
    MODULE_PREFIX + 'UPLOAD_IMAGE_FOR_TASK_FAILURE',

  LOAD_SUBCATEGORIES_SEARCH_RESULTS_REQUEST:
    MODULE_PREFIX + 'LOAD_SUBCATEGORIES_SEARCH_RESULTS_REQUEST',
  LOAD_SUBCATEGORIES_SEARCH_RESULTS_SUCCESS:
    MODULE_PREFIX + 'LOAD_SUBCATEGORIES_SEARCH_RESULTS_SUCCESS',
  LOAD_SUBCATEGORIES_SEARCH_RESULTS_FAILURE:
    MODULE_PREFIX + 'LOAD_SUBCATEGORIES_SEARCH_RESULTS_FAILURE',

  DELETE_IMAGE: MODULE_PREFIX + 'DELETE_IMAGE',
};

/* INITIAL STATE */

const initialService = {
  description: null,
  id: null,
  name: null,
  tools: [],
  price_max: null,
  price_min: null,
  service_fee: null,
  price_recommended: null,
  image: null,
};

const initialState = {
  categories: [],
  categoriesLoading: false,
  categorySelectedFromQuery: false,
  stepSelectedFromQueryString: false,

  categoryId: null,

  taskLoading: false,

  questionsLoading: false,

  questions: [],

  questionIsFinish: false,

  service: initialService,

  comment: '',
  quantity: 1,
  quantityError: null,
  id: null,
  taskOrderId: null,

  images: [],
  loadingImages: false,
  loadingImagesError: false,

  subcategoriesSearchValue: '',
  subcategoriesSearchResults: [],
  subcategoriesSearchResultsLoading: false,

  editingTaskLoading: false,
};

/* REDUCER */

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};

  switch (action.type) {
    case types.START_EDITING_TASK_REQUEST:
      return {
        ...state,
        editingTaskLoading: true,
      };

    case types.START_EDITING_TASK_SUCCESS:
      return {
        ...state,
        ...action.task,
        taskOrderId: action.task.order_id,
        questions: action.task.answers,
        editingTaskLoading: false,
      };

    case types.START_EDITING_TASK_FAILURE:
      return {
        ...state,
        editingTaskLoading: false,
      };

    case types.LOAD_TASK_REQUEST:
      return {
        ...state,
        taskLoading: true,
      };

    case types.LOAD_TASK_SUCCESS:
      return {
        ...state,
        ...action.task,
        questions: action.task.answers,
        taskLoading: false,
      };

    case types.LOAD_TASK_FAILURE:
      return {
        ...state,
        taskLoading: false,
      };

    case types.LOAD_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesLoading: true,
      };

    case types.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        categoriesLoading: false,
      };

    case types.LOAD_CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesLoading: false,
      };

    case types.SELECT_CATEGORY:
      const category = state.categories.find(
        (category) => +action.categoryId === category.model.id,
      );

      if (category) {
        return {
          ...state,
          categorySelectedFromQuery: true,
          questions: [
            {
              question: category.second_question,
              answers: category.subcategories,
              answers_extended: category.subcategories_extended.map(
                (subcategory) => ({
                  answer: subcategory.name,
                  answer_image: subcategory.image,
                }),
              ),
            },
          ],
          categoryId: action.categoryId,
        };
      }
      return state;

    case types.SELECT_SUBCATEGORY:
      return {
        ...state,
        questions: [{ question: action.question }],
        categoryId: action.categoryId,
        subcategoriesSearchValue: '',
        subcategoriesSearchResults: [],
        taskOrderId: action.taskOrderId,
      };

    case types.CLEAR_STATE:
      return {
        ...initialState,
        categories: state.categories,
      };

    case types.ANSWER_QUESTION_REQUEST:
      const questionsWithNewAnswer = Array.from(state.questions);

      const lastIndex = state.questions.length - 1;
      questionsWithNewAnswer[lastIndex].answer = action.answer;

      return {
        ...state,
        questions: questionsWithNewAnswer,
        questionsLoading: true,
        taskOrderId: action.taskOrderId,
      };

    case types.ANSWER_QUESTION_SUCCESS:
      if (action.is_finish) {
        if (Array.isArray(action.services) && action.services[0]) {
          return {
            ...state,
            questionIsFinish: action.is_finish,
            service: action.services[0],
            questionsLoading: false,
          };
        } else {
          // adding default task because not all answers give service
          return {
            ...state,
            questionIsFinish: action.is_finish,
            service: DEFAULT_SERVICE,
            questionsLoading: false,
          };
        }
      } else if (
        action.question &&
        action.question.question &&
        action.question.answers_extended
      ) {
        return {
          ...state,
          questions: [...state.questions, action.question],
          questionsLoading: false,
          questionIsFinish: action.is_finish,
        };
      }
      return state;

    case types.ANSWER_QUESTION_FAILURE:
      return {
        ...state,
        questionsLoading: false,
      };
    case types.LOAD_ANSWERS_FROM_QS:
      const newQuestions = formatAnswersFromQS(
        action.payload.questions,
        action.payload.answers,
      );
      return {
        ...state,
        stepSelectedFromQueryString: true,
        questions: [...newQuestions],
      };

    case types.MOVE_TO_CATEGORIES:
      return {
        ...state,
        categoryId: null,
        questions: [],
        service: initialService,
        questionIsFinish: false,
        categorySelectedFromQuery: false,
      };

    case types.MOVE_TO_PREVIOUS_QUESTION:
      let previousQuestions = Array.from(state.questions);

      if (!state.service.id) {
        previousQuestions = state.questions.slice(0, -1);
      }

      previousQuestions[previousQuestions.length - 1].answer = undefined;

      return {
        ...state,
        questionIsFinish: false,
        questions: previousQuestions,
        service: initialService,
      };

    case types.SHOW_FINAL_CREATE_TASK_PAGE: {
      return {
        ...state,
        allQuestionsAnswered: true,
        service:
          Array.isArray(payload.services) && payload.services.length !== 0
            ? payload.services[0]
            : null,
      };
    }

    case types.CHANGE_COMMENT:
      return {
        ...state,
        comment: action.value,
      };

    case types.CHANGE_QUANTITY:
      return {
        ...state,
        quantity: action.value,
      };

    case types.UPLOAD_IMAGE_FOR_TASK_REQUEST:
      return {
        ...state,
        images:
          state.images.length >= MAX_IMAGES_COUNT_IN_TASK
            ? state.images
            : [
                ...state.images,
                {
                  clientGeneratedId: payload.clientGeneratedId,
                  base64Image: payload.base64Image,
                  loading: true,
                },
              ],
      };

    case types.UPLOAD_IMAGE_FOR_TASK_SUCCESS:
      const updatedImages = state.images.map((image) => {
        if (payload.clientGeneratedId === image.clientGeneratedId) {
          return {
            ...image,
            url: payload.url,
            id: payload.id,
            loading: false,
          };
        }

        return image;
      });

      return {
        ...state,
        images: updatedImages,
      };

    case types.DELETE_IMAGE:
      const filteredImages = state.images.filter((image) => {
        if (payload.image && payload.image.clientGeneratedId) {
          return !(image.clientGeneratedId === payload.image.clientGeneratedId);
        }
        return !(image.id === payload.image.id);
      });

      return {
        ...state,
        images: filteredImages,
      };

    case types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        subcategoriesSearchValue: action.searchString,
        subcategoriesSearchResultsLoading: true,
      };

    case types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        subcategoriesSearchResults: action.results,
        subcategoriesSearchResultsLoading: false,
      };

    case types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        subcategoriesSearchResultsLoading: false,
      };

    case types.SAVE_TASK_FAILURE:
      return {
        ...state,
        quantityError: get(
          action,
          ['error', 'errors', 'quantity', '0', 'message'],
          '',
        ),
      };

    default:
      return state;
  }
};

/* ACTIONS */

export const createTaskActions = {
  loadCategories: () => ({
    type: types.LOAD_CATEGORIES_REQUEST,
  }),

  selectCategory: ({ categoryId, taskCount, taskOrderId }) => ({
    type: types.SELECT_CATEGORY,
    categoryId,
    taskCount,
    taskOrderId,
  }),

  selectSubcategory: ({
    categoryId,
    question,
    subcategory,
    tasksCount,
    taskOrderId,
  }) => ({
    type: types.SELECT_SUBCATEGORY,
    categoryId,
    question,
    subcategory,
    tasksCount,
    taskOrderId,
  }),

  loadAnswersFromQS: (
    questionsAndAnswers: AnswersAndQuestionsFromSearchQuery,
  ) => ({
    type: types.LOAD_ANSWERS_FROM_QS,
    payload: questionsAndAnswers,
  }),

  answerQuestion: (answer, taskOrderId) => ({
    type: types.ANSWER_QUESTION_REQUEST,
    answer,
    taskOrderId,
  }),

  onloadCategoryServices: (answer, taskOrderId) => ({
    type: types.ANSWER_QUESTION_REQUEST,
    answer,
    taskOrderId,
  }),

  clearState: () => ({
    type: types.CLEAR_STATE,
  }),

  changeComment: (e) => ({
    type: types.CHANGE_COMMENT,
    value: e.target.value,
  }),

  changeQuantity: (value) => ({
    type: types.CHANGE_QUANTITY,
    value: value,
  }),

  uploadImage: ({ file, base64Image, clientGeneratedId }) => ({
    type: types.UPLOAD_IMAGE_FOR_TASK_REQUEST,
    payload: {
      file,
      clientGeneratedId,
      base64Image,
    },
  }),

  deleteImage: (image) => ({
    type: types.DELETE_IMAGE,
    payload: {
      image,
    },
  }),

  loadTask: (taskId) => ({
    type: types.LOAD_TASK_REQUEST,
    taskId,
  }),

  saveTask: (orderId, taskId) => ({
    type: types.SAVE_TASK_REQUEST,
    orderId,
    taskId,
  }),

  deleteTask: (taskId) => ({
    type: types.DELETE_TASK_REQUEST,
    taskId,
  }),

  moveToCategories: () => ({
    type: types.MOVE_TO_CATEGORIES,
  }),

  moveToPreviousQuestion: () => ({
    type: types.MOVE_TO_PREVIOUS_QUESTION,
  }),

  startEditingTask: (task) => ({
    type: types.START_EDITING_TASK_REQUEST,
    task,
  }),

  loadSubcategoriesSearchResult: (searchString) => ({
    type: types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_REQUEST,
    searchString,
  }),
};

/**
 * needs reform,
 * impropper arg0 should be smth `answersAndQuestionsObject` which itself should be `stepsObject`
 * impropper arg1 there is no need of second arg
 **/
const formatAnswers = (answers, firstQuestionIsFictive = false) => {
  return answers.map((answer) => ({
    question: answer.question,
    answer: answer.answer,
  }));
};

const formatAnswersFromQS = (questions: string[], answers: string[]) => {
  return questions.map((question, index) => ({
    answer: answers[index],
    question,
  }));
};

const formatTaskForBackend = (task) => {
  return {
    service_id: task.service.id,
    quantity: task.quantity,
    images: task.images.filter((image) => image.id).map((image) => image.id),
    description: task.comment,
    answers: formatAnswers(
      task.questions,
      task.categorySelectedFromQuery || task.stepSelectedFromQueryString,
    ),
  };
};

/* SELECTORS */

export const createTaskSelectors = {
  getCategories: (state) => state.createTask.categories,

  getCategoriesLoading: (state) => state.createTask.categoriesLoading,

  getAllAnswers: (state) => state.createTask.questions,

  getCurrentQuestion: (state) => {
    const lastIndex = state.createTask.questions.length - 1;
    return state.createTask.questions[lastIndex].question
      ? state.createTask.questions[lastIndex].question
      : [];
  },

  getQuestionIsFinish: (state) => state.createTask.questionIsFinish,

  getAllAnswersLoading: (state) => state.createTask.questionsLoading,

  getCurrentAnswers: (state) => {
    const lastIndex = state.createTask.questions.length - 1;
    return state.createTask.questions[lastIndex]
      ? state.createTask.questions[lastIndex].answers_extended
      : [];
  },

  getService: (state) => state.createTask.service,

  getComment: (state) => state.createTask.comment,

  getQuantity: (state) => state.createTask.quantity,

  getQuantityError: (state) => state.createTask.quantityError,

  selectImages: (state) => state.createTask.images,

  selectTaskId: (state) => state.createTask.id,

  getSelectedCategoryId: (state) => state.createTask.categoryId,

  getFormattedTaskForBackend: (state) => formatTaskForBackend(state.createTask),

  getSubcategoriesSearchValue: (state) =>
    state.createTask.subcategoriesSearchValue,

  getSubcategoriesSearchResults: (state) =>
    state.createTask.subcategoriesSearchResults,

  isSubcategoriesSearchLoading: (state) =>
    state.createTask.subcategoriesSearchResultsLoading,

  getEditingTaskLoading: (state) => state.createTask.editingTaskLoading,

  getTaskOrderId: (state) => state.createTask.taskOrderId,
  getIfStepSelectedFromQueryString: (state) =>
    state.createTask.stepSelectedFromQueryString,
};

/* SAGAS */
function* onTaskEditingStart({ task }) {
  try {
    yield put(push(getPathForEditTask(task.id)));
    yield put(createOrderActions.loadEditingOrder());

    yield put({ type: types.START_EDITING_TASK_SUCCESS, task });
  } catch (error) {
    yield put({ type: types.START_EDITING_TASK_FAILURE, error });
  }
}

function* onSubcategorySelect({ subcategory, tasksCount }) {
  yield put(createTaskActions.answerQuestion(subcategory));
  yield call(window.scroll, 0, 0);
  yield put(replace(getPathForCreateNewTask()));

  if (tasksCount && tasksCount > 0) {
    yield put(
      dialogActions.openCreateTaskFromOrderDialog(getPathForCreateNewTask),
    );
  }
}

function* onCategoriesLoad() {
  try {
    const categories = yield call(loadCategories);
    yield put({ type: types.LOAD_CATEGORIES_SUCCESS, categories });
  } catch (error) {
    yield put({ type: types.LOAD_CATEGORIES_FAILURE });
  }
}

function* answerQuestionSaga() {
  try {
    const answers = yield select(createTaskSelectors.getAllAnswers);
    const categoryId = yield select(createTaskSelectors.getSelectedCategoryId);

    const newAnswers = [];

    answers.map((answer) =>
      newAnswers.push({
        answer: answer.answer,
        question: answer.question,
      }),
    );

    console.log('%c ANSWERS = ', 'color: blue', answers);
    console.log('%c newAnswers = ', 'color: blue', newAnswers);

    const data = yield call(answerQuestion, categoryId, {
      answers: newAnswers,
    });

    if (typeof data === 'object' && data !== null) {
      yield put({ type: types.ANSWER_QUESTION_SUCCESS, ...data });
    } else {
      new Error('Invalid question provided');
    }
  } catch (error) {
    console.error('Error answerQuestionSaga = ', error);
    yield put({ type: types.ANSWER_QUESTION_FAILURE, error });
  }
}

type LoadAnswersFromQS = {
  payload: AnswersAndQuestionsFromSearchQuery;
  type: string;
};

function* loadAnswersFromQS({
  payload: { questions, answers },
}: LoadAnswersFromQS) {
  try {
    const categoryId = yield select(createTaskSelectors.getSelectedCategoryId);
    const newAnswers = formatAnswersFromQS(questions, answers);

    console.log(
      '%c Payload of loadAnswersFromQS= ',
      'color: blue',
      answers,
      questions,
      'newAnswers= ',
      newAnswers,
    );

    const data = yield call(answerQuestion, categoryId, {
      answers: newAnswers,
    });

    if (typeof data === 'object' && data !== null) {
      yield put({ type: types.ANSWER_QUESTION_SUCCESS, ...data });
    } else {
      new Error('Invalid question provided');
    }
  } catch (error) {
    console.error('Error answerQuestionSaga = ', error);
    yield put({ type: types.ANSWER_QUESTION_FAILURE, error });
  }
}

function* uploadImage({ payload }) {
  try {
    const formData = createFormData(payload.file);
    const images = yield select(createTaskSelectors.selectImages);
    if (images.length <= MAX_IMAGES_COUNT_IN_TASK) {
      const { image } = yield call(uploadAvatar, formData);

      yield put({
        type: types.UPLOAD_IMAGE_FOR_TASK_SUCCESS,
        payload: {
          ...image,
          clientGeneratedId: payload.clientGeneratedId,
        },
      });
    }
  } catch (error) {
    yield put({ type: types.UPLOAD_IMAGE_FOR_TASK_FAILURE, error });
  }
}

function* onTaskLoad({ taskId }) {
  try {
    const task = yield call(loadTask, taskId);
    yield put({ type: types.LOAD_TASK_SUCCESS, task });
  } catch (error) {
    yield put({ type: types.LOAD_TASK_FAILURE, error });
  }
}

function* onTaskSave({ orderId, taskId }) {
  try {
    const formattedTask = yield select(
      createTaskSelectors.getFormattedTaskForBackend,
    );

    if (taskId) {
      const { model } = yield call(editTask, taskId, formattedTask);

      yield put({ type: types.EDIT_TASK_SUCCESS, task: model });
    } else {
      const { model } = yield call(createTask, orderId, formattedTask);

      yield put({ type: types.SAVE_TASK_SUCCESS, task: model });
    }

    yield put(push(getPathForEditOrder(orderId)));
  } catch (error) {
    yield put({ type: types.SAVE_TASK_FAILURE, error });
  }
}

function* onTaskDelete({ taskId }) {
  try {
    yield call(deleteTask, taskId);

    yield put({ type: types.DELETE_TASK_SUCCESS });
  } catch (error) {
    yield put({ type: types.DELETE_TASK_FAILURE, error });
  }
}

function* onSubcategoriesSearchLoad(searchString) {
  try {
    yield delay(200);

    if (searchString === '' || searchString.length < 3) {
      yield put({
        type: types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_SUCCESS,
        results: [],
      });
    } else {
      const { collection } = yield call(searchSubcategories, searchString);
      yield put({
        type: types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_SUCCESS,
        results: collection,
      });
    }
  } catch (error) {
    yield put({ type: types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_FAILURE });
  }
}

export const createTaskSagas = {
  watchCategorySelect: function* () {
    while (true) {
      const { taskCount } = yield take(types.SELECT_CATEGORY);

      yield put(replace(getPathForCreateNewTask()));

      if (taskCount && taskCount.length !== 0) {
        yield put(
          dialogActions.openCreateTaskFromOrderDialog(getPathForCreateNewTask),
        );
      }
    }
  },

  watchSelectSubcategory: function* () {
    yield takeEvery(types.SELECT_SUBCATEGORY, onSubcategorySelect);
  },

  watchLoadAnswersFromQS: function* () {
    yield takeEvery(types.LOAD_ANSWERS_FROM_QS, loadAnswersFromQS);
  },

  watchCategoriesLoad: function* () {
    yield takeLatest(types.LOAD_CATEGORIES_REQUEST, onCategoriesLoad);
  },

  watchTaskLoad: function* () {
    yield takeLatest(types.LOAD_TASK_REQUEST, onTaskLoad);
  },

  watchAnswerQuestion: function* () {
    yield takeEvery(types.ANSWER_QUESTION_REQUEST, answerQuestionSaga);
  },

  watchTaskImagesUpload: function* () {
    yield takeEvery(types.UPLOAD_IMAGE_FOR_TASK_REQUEST, uploadImage);
  },

  watchTaskSave: function* () {
    yield takeEvery(types.SAVE_TASK_REQUEST, onTaskSave);
  },

  watchTaskDelete: function* () {
    yield takeEvery(types.DELETE_TASK_REQUEST, onTaskDelete);
  },

  watchTaskEditingStart: function* () {
    yield takeEvery(types.START_EDITING_TASK_REQUEST, onTaskEditingStart);
  },

  watchSubcategoiesSearchLoad: function* () {
    let task;
    while (true) {
      const { searchString } = yield take(
        types.LOAD_SUBCATEGORIES_SEARCH_RESULTS_REQUEST,
      );
      if (task) {
        yield cancel(task);
      }
      task = yield fork(onSubcategoriesSearchLoad, searchString);
    }
  },
};
