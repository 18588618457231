import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popup } from '../../components/ui';

import {
  actions as signUpActions,
  selectors as signUpSelectors,
} from '../../reducers/signup';

import { PhoneConfirmForm } from '../../components/common/phoneConfirm';
import { dialogActions } from '../dialog/store';

const CONFIRM_CODE_FORM = 'confirmCodeForm';

class SignUpPhoneConfirm extends Component {
  onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      this.props.confirmPhoneCode(values, resolve, reject);
    });
  };

  render() {
    const { phone, smsCode, opened, mode } = this.props;

    return (
      <Popup isOpen={opened} onClose={this.props.closeConfirmPhonePopup}>
        <PhoneConfirmForm
          smsCode={smsCode}
          form={CONFIRM_CODE_FORM}
          onSubmit={this.onSubmit}
          onResend={this.props.resendCode}
          phone={phone}
        />
      </Popup>
    );
  }

  componentWillUnmount() {
    this.props.clearSignUpData();
  }
}

export default connect(
  (state) => ({
    smsCode: signUpSelectors.getSmsCode(state),
    session: signUpSelectors.getSession(state),
    phone: signUpSelectors.getPhone(state),
    opened: signUpSelectors.isConfirmPhonePopupOpened(state),
  }),
  {
    ...signUpActions,
    openTermsConditionsPopup: dialogActions.openTermsConditionsPopup,
  },
)(SignUpPhoneConfirm);
