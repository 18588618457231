import { get, put, post, del } from './baseService';

export const getAddresses = () => get('/profile/address');

export const getAddress = (id) => get(`/profile/address/${id}`);

export const createAddress = (address) => put('/profile/address', address);

export const editAddress = (address) =>
  post(`/profile/address/${address.id}`, address);

export const deleteAddress = (id) => del(`/profile/address/${id}`);

export const makeFavorite = (address) =>
  post(`/profile/address-favorite/${address.id}`, post);
