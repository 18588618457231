import React from 'react';
import PropTypes from 'prop-types';
import ReviewsListItem from './ReviewsListItem';

import styles from './ReviewsList.module.scss';

const ReviewsList = ({ reviews }) => {
  const notEmptyReviews = reviews.filter((review) => review.text !== '');

  return (
    <div className={styles.reviews}>
      <div className={styles.reviewsIcon} />
      <div className={styles.reviewBody}>
        {reviews &&
          notEmptyReviews.map((review) => (
            <ReviewsListItem key={review.id} review={review} category />
          ))}
      </div>
    </div>
  );
};

ReviewsList.propTypes = {
  reviews: PropTypes.array,
  category: PropTypes.bool,
};

export default ReviewsList;
