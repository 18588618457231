import React, { Component } from 'react';
import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import { createOrderActions } from '../../../reducers/createOrder';
import { Link } from 'react-router-dom';
import { getPathForOrders } from '../../routing/constants/pathHelpers';
import { GreenButton } from '../../../components/buttons';

import styles from './ConfirmOrderDelete.module.scss';

class DeleteOrderError extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('orderNotFound')}</div>
        <div className={styles.text}>
          {t('itMightBeDeletedPreviouslyUsingOtherApplication')}
        </div>
        <div className={styles.btn}>
          <Link to={getPathForOrders()}>
            <GreenButton
              onClick={() => {
                this.props.createEmptyOrder();
                this.props.onClose();
              }}
            >
              {t('returnToOrdersList')}
            </GreenButton>
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    createEmptyOrder: createOrderActions.createEmptyOrder,
  }),
)(DeleteOrderError);
