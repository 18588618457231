import React, { Component } from 'react';
import styles from './SocialItem.module.scss';

class SocialItem extends Component {
  render() {
    const { href, icon, target } = this.props;

    return (
      <li className={styles.item}>
        <a className={styles.link} href={href} target={target}>
          <img src={icon} alt="" />
        </a>
      </li>
    );
  }
}

export default SocialItem;
