import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getPathForOrderAddress } from '../../routing/constants/pathHelpers';
import { PinkButtonTransparent } from '../../../components/buttons';
import styles from './SelectedAddressView.module.scss';

const SelectedAddressView = ({ address, t }) => (
  <div className={styles.address}>
    {address.address ? (
      <p className={styles.addressText}>
        {address.address}. {t('comment')}: {address.comment}
      </p>
    ) : null}
    <div className={styles.addressBtn}>
      <Link to={getPathForOrderAddress()}>
        <PinkButtonTransparent small>
          {address.address ? t('changeAddress') : t('selectAddress')}
        </PinkButtonTransparent>
      </Link>
    </div>
  </div>
);

SelectedAddressView.propTypes = {
  address: PropTypes.object,
};

export default withTranslation()(SelectedAddressView);
