import React, { Component } from 'react';

const SelectWithIconItem = ({ data }) => (
  <div className="input-select">
    {data.icon && <span className="input-select__icon">{data.icon}</span>}
    <span>{data.label}</span>
  </div>
);

export default SelectWithIconItem;
