import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { GreenButton } from '../../../components/buttons';

import styles from './ConfirmOrderDelete.module.scss';

class ComplaintSendSuccess extends Component {
  renderCancelButton = () => {
    const { t, backPath } = this.props;

    if (backPath)
      return (
        <Link to={backPath}>
          <GreenButton
            onClick={() => {
              this.props.onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </Link>
      );

    return (
      <GreenButton
        onClick={() => {
          this.props.onClose();
        }}
      >
        {t('OK')}
      </GreenButton>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('complaintSendSuccess')}</div>
        <div className={styles.text}>
          {t('complaintSendSuccessDescription')}
        </div>
        <div className={styles.btn}>{this.renderCancelButton()}</div>
      </div>
    );
  }
}

export default compose(withTranslation())(ComplaintSendSuccess);
