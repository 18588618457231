import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { DayPickerSingleDateController } from 'react-dates';
import { withReduxFormProps } from '../../../containers/hoc';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-webpack-loader-syntax
// import '!style-loader!css-loader!./customDataPicker.module.scss';

class InlineDatePicker extends Component {
  state = {
    focused: this.props.focused || false,
  };

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };

  render() {
    return (
      // <div style={{}}>
      <DayPickerSingleDateController
        date={this.props.value || null}
        onDateChange={this.props.onChange}
        focused={this.state.focused}
        onFocusChange={this.onFocusChange}
        numberOfMonths={this.props.numberOfMonths}
        isOutsideRange={this.props.isOutsideRange}
        daySize={this.props.daySize}
        noBorder={true}
        verticalBorderSpacing={0}
      />
      // </div>
    );
  }
}

InlineDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
  numberOfMonths: PropTypes.number,
};

InlineDatePicker.defaultProps = {
  numberOfMonths: 1,
};

export default compose(withTranslation(), withReduxFormProps)(InlineDatePicker);
