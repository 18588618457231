import iconFemale from '../../assets/images/backgrounds/avatarFm.png';
import iconMale from '../../assets/images/backgrounds/avatarM.png';

const getImage = (avatar, gender) => {
  if (avatar) return avatar.url;
  if (gender === 'FEMALE') {
    return iconFemale;
  }
  return iconMale;
};

export default getImage;
