import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { LabeledTextInput } from '../../../components/inputs/text';
import { GreenButton } from '../../../components/buttons';

import styles from './NewPasswordForm.module.scss';

class NewPasswordForm extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('restorePassword')}</div>
        <br />
        <form onSubmit={this.props.handleSubmit}>
          <Field
            component={LabeledTextInput}
            name="password"
            type="password"
            label={t('newPassword')}
          />
          <div className={styles.btnWrapper}>
            {/*<GreenButton type='submit' disabled={disabled}>*/}
            <GreenButton type="submit">{t('saveNewPassword')}</GreenButton>
          </div>
        </form>
      </div>
    );
  }
}

NewPasswordForm.propTypes = {
  disabled: PropTypes.bool,
};

export default compose(withTranslation(), reduxForm())(NewPasswordForm);
