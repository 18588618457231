import './index.scss';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import moment from 'moment';
import { Route } from 'react-router-dom';

import 'moment/locale/ka';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import App from './App';
// import PhoneView from './components/ui/pages/PhoneView';
import store from './store';
import history from './createHistory';
// import isMobile from './utils/isMobile';

import i18n from './utils/i18n';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_ENABLE_COUNTERS === 'true') {
  console.log(
    'process.env.REACT_APP_ENABLE_COUNTERS  = ',
    process.env.REACT_APP_ENABLE_COUNTERS,
  );
  ReactGA.initialize('UA-126648679-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const options = {
    autoConfig: true,
    debug: false,
  };

  // ReactPixel.init('1183975225011314', null, options);

  const advancedMatching = { em: 'salahmohamen@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  ReactPixel.init('1183975225011314', advancedMatching, options);
  ReactPixel.pageView(); // For tracking page view
}

moment.locale(i18n.language);

// const pathnameEnd = window.location.pathname.slice(
//   window.location.pathname.lastIndexOf('/'),
// );
// const afterPayment = pathnameEnd === '/payment-in-process';
// const settingsPayment = window.location.pathname === '/portal/settings/payment';
// const Application = ((isMobile() && afterPayment) || (isMobile() && settingsPayment)) ? App : isMobile() ? PhoneView : App;
const Application = App;

render(
  // <StrictMode>
  <Suspense fallback={() => <div></div>}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Application />
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  // </StrictMode>
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
