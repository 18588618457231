import React, { useEffect, useState } from 'react';
import styles from './NewLoader.module.scss';
import classNames from 'classnames';

const NewLoader = () => {
  const [counter, setCounter] = useState(0);
  const images = [
    'https://apitest.profy.ge/uploads/avatars/U5/r2/U5r2AtsaKOd9Lnrw4huPdccF3G4RTlvV.png',
    'https://apitest.profy.ge/uploads/avatars/sW/dV/sWdVSVl2VcwgDVeqjxxEFH1jsZAnjZaD.png',
    'https://apitest.profy.ge/uploads/avatars/NM/XY/NMXYGi4f7PB8vqAjMu8nWPgROOXDbpaC.png',
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 2) setCounter(0);
      setCounter((counter) => counter + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, [counter]);

  return (
    <div className={styles.loader}>
      <div className={classNames(styles.image, 'loaderCircle3')}>
        <img src={images[counter]} />
      </div>
      <span>Loading</span>
    </div>
  );
};

export default NewLoader;
