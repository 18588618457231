import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GreenButton } from '../../../components/buttons';

import styles from './SubmitOrderErrorCardIsNotActive.module.scss';

class SubmitOrderErrorCardIsNotActive extends Component {
  render() {
    const { error, t } = this.props;
    return (
      <div className={styles.container} id="paymenterrorpopup">
        <div className={styles.title}>{t('cardIsNotActive')}</div>

        <div className={styles.text}>
          {error ? error : t('selectedCardIsNotActive')}
        </div>

        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              this.props.onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SubmitOrderErrorCardIsNotActive);
