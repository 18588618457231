import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Svg } from '../../ui/index';

import styles from './AddressCard.module.scss';

const AddressCard = ({
  address,
  onClick,
  setNewAddressLocation,
  changeSelectedAddressComment,
  isSelected,
  renderLink,
  renderDeleteLink,
  renderButton,
  history,
  t,
}) => {
  const getBtnWrapper = () =>
    renderLink || renderButton || renderDeleteLink ? renderBtnWrapper() : null;

  const renderBtnWrapper = () => {
    return (
      <div className={styles.btnWrapper}>
        {typeof renderLink === 'function' ? (
          <div className={styles.btn}>{renderLink(address)}</div>
        ) : null}

        {typeof renderDeleteLink === 'function' ? (
          <div className={styles.btn}>{renderDeleteLink(address)}</div>
        ) : null}

        {typeof renderButton === 'function' ? (
          <div className={styles.btn}>{renderButton(address)}</div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.card, { [styles.cardSelected]: isSelected })}
      onClick={() => {
        setNewAddressLocation(address.location);

        if (changeSelectedAddressComment) {
          changeSelectedAddressComment(address.comment);
        }

        onClick(address);
      }}
    >
      <div className={styles.cardIcon}>
        {history ? (
          <Svg src={'pin'} />
        ) : address.favorite ? (
          <Svg src={'star'} />
        ) : (
          <Svg src={'pin'} />
        )}
      </div>

      <div className={styles.cardBody}>
        {history
          ? null
          : address.name && (
              <div className={styles.cardName}>
                {address.name}
                {address.favorite && (
                  <span className={styles.cardNamePrimary}>
                    {' '}
                    ({t('primary')})
                  </span>
                )}
              </div>
            )}

        <div className={styles.cardAddress}>{address.address}</div>

        {getBtnWrapper()}
      </div>
    </div>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  renderButton: PropTypes.func,
  locationChange: PropTypes.func,
};

export default withTranslation()(AddressCard);
