import React from 'react';
import PropTypes from 'prop-types';
import ReviewsList from './components/ReviewsList';

const Reviews = ({ reviews, category }) => {
  return (
    <div>
      {reviews && reviews.length !== 0 ? (
        <ReviewsList reviews={reviews} category />
      ) : null}
    </div>
  );
};

Reviews.propTypes = {
  reviews: PropTypes.array,
  category: PropTypes.bool,
};

export default Reviews;
