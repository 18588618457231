import cloneDeep from 'lodash/cloneDeep';

import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { uploadAvatar } from 'services/mediaService';
import { RootReduxState } from 'store';
import { ServiceTypesEnum } from 'utils/enum';
import { createFormData } from 'utils/formData';
import { ServiceActionEnum, ServiceActionsType } from './actions';

function* onImageUpload({
  payload,
}: ReturnType<ServiceActionsType['uploadImage']>) {
  try {
    const formData = createFormData(payload.file);
    const { image } = yield call(uploadAvatar, formData);

    yield put({
      type: ServiceActionEnum.UPLOAD_IMAGE_START_SUCCESS,
      payload: {
        id: payload.id,
        ...image,
      },
    });
  } catch (error) {
    yield put({ type: ServiceActionEnum.UPLOAD_IMAGE_START_FAILURE, error });
  }
}

function* onEntranceFloorChange({
  payload,
}: ReturnType<ServiceActionsType['enteranceCleaningFloors']>) {


  const _selectedServices: SelectedCleaningServices = yield select(
    (state: RootReduxState) => state.serviceStore.selectedServices,
  );


  const selectedServices = cloneDeep(_selectedServices);


  const {
    service,
    id,
    count,
  } = payload;

  selectedServices['REGULAR'][id].count = count
  selectedServices['REGULAR'][id].min_quantity = count

  yield put({
    type: ServiceActionEnum.SELECT_CLEANING_TASK_SUCCESS,
    payload: { selectedServices, currentActiveId: id },
  });
}

function* onCleaningServiceSelect({
  payload,
}: ReturnType<ServiceActionsType['selectCleaningService']>) {
  const _selectedServices: SelectedCleaningServices = yield select(
    (state: RootReduxState) => state.serviceStore.selectedServices,
  );
  const selectedServices = cloneDeep(_selectedServices);
  const {
    id,
    increase,
    max_quantity,
    serviceType,
    min_quantity,
    quantity_increment_step,
  } = payload;


  // if (serviceType === CleaningSubCategoryTypesEnum.CLEANING_HOUSE_MAID) {
  //   yield call(createNewOrder);
  // }
  const minQuantity = min_quantity > 1 ? min_quantity : quantity_increment_step;

  if (increase) {
    if (selectedServices[serviceType]?.[id]) {
      if (
        max_quantity > selectedServices[serviceType][id].count ||
        max_quantity === 0
      )
        selectedServices[serviceType][id].count += quantity_increment_step ?? quantity_increment_step;
    } else {
      selectedServices[serviceType] = selectedServices[serviceType] ?? {};
      selectedServices[serviceType][id] = { count: minQuantity, ...payload };
    }

  } else {
    if (
      !selectedServices[serviceType]?.[id] ||
      selectedServices[serviceType]?.[id].count === 0
    )

      return;

    selectedServices[serviceType][id]
      ? (selectedServices[serviceType][id].count -= quantity_increment_step)
      : (selectedServices[serviceType][id] = {
        count: minQuantity,
        ...payload,
      });
  }

  let currentActiveId: number | null = id;
  // delete item
  if (selectedServices[serviceType]?.[id].count <= 0) {
    delete selectedServices[serviceType][id];
    if (Object.keys(selectedServices[serviceType]).length === 0) {
      delete selectedServices[serviceType];
      currentActiveId = null;
    } else {
      currentActiveId = +Object.keys(selectedServices[serviceType])[0];
    }
  }

  //update selected services object
  if (selectedServices[serviceType]?.[id]?.count > 0)
    selectedServices[serviceType][id] = {
      ...payload,
      ...selectedServices[serviceType][id],
    };

  yield put({
    type: ServiceActionEnum.SELECT_CLEANING_TASK_SUCCESS,
    payload: { selectedServices, currentActiveId },
  });
}

type onCleaningServiceNextClickSelectedStateType = {
  selectedServiceObject: ServiceTreeType | null;
  currentCleaningServiceType: ServiceTypesEnum;
};

function* onCleaningServiceNextClick() {
  const {
    selectedServiceObject,
    currentCleaningServiceType,
  }: onCleaningServiceNextClickSelectedStateType = yield select(
    (state: RootReduxState) => state.serviceStore,
  );

  let isFound = false;
  const newCleaningServiceType = Object.keys(ServiceTypesEnum).find(
    (serviceType) => {
      if (isFound) {
        const services = selectedServiceObject?.services?.find(
          ({ type }) => type === serviceType,
        );

        if (services) return true;
      }

      if (serviceType === currentCleaningServiceType) isFound = true;
      return false;
    },
  );

  if (newCleaningServiceType === undefined) {
    // next page
    yield put(createTaskActions.uploadTask());
  } else {
    yield put({
      type: ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE_SUCCESS,
      payload: newCleaningServiceType,
    });
  }
}

function* onCleaningServiceTypeClear() {
  yield put({
    type: ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE_SUCCESS,
    payload: ServiceTypesEnum.REGULAR,
  });
}

// run sagas
export const createServiceTasks = {
  creatingSagaListeners: function* () {
    yield takeEvery(
      ServiceActionEnum.UPLOAD_IMAGE_START,
      onImageUpload);

    yield takeEvery(
      ServiceActionEnum.SELECT_CLEANING_TASK,
      onCleaningServiceSelect,
    );
    yield takeEvery(
      ServiceActionEnum.CURRENT_CLEANING_SERVICE_TYPE,
      onCleaningServiceNextClick,
    );
    yield takeEvery(
      ServiceActionEnum.CLEAR_CLEANING_SERVICE_TYPE,
      onCleaningServiceTypeClear,
    );
    yield takeEvery(
      ServiceActionEnum.ENTRANCE_CLEANING_FLOORS,
      onEntranceFloorChange
    )
  },
};
