import React from 'react';
import { lazyLoadable } from 'utils';

export const ChooseAddressLazy = lazyLoadable(
  () => import('./ChooseAddress'),
  () => <div>loading</div>,
);

export const EditAddressLazy = lazyLoadable(
  () => import('./EditAddress'),
  () => <div>loading</div>,
);
