import { get, post, postFormData } from './baseService';
// import {MAX_DAYS_TO_MAKE_ORDER} from "../utils/time";

export const getTaskersDays = () => get(`/orders/settings`);

export const getTaskersForOrder = (orderId, date, filterType) =>
  get(`/orders/${orderId}/taskers?date=${date}${filterType}`);
export const getTaskersForOrderWithDate = (orderId, sort) =>
  get(`/orders/taskers-with-timetables?${sort}&id=${orderId}`);

export const getTaskerTimeTable = (orderId, taskerId) =>
  get(`/orders/${orderId}/timetable?tasker_id=${taskerId}`);
// export const getTaskerTimeTable = (orderId, taskerId) => get(`/orders/${orderId}/timetable?tasker_id=${taskerId}&days=${MAX_DAYS_TO_MAKE_ORDER}`);

export const getTaskerDetailed = (taskerId) => get(`/taskers/view/${taskerId}`);

export const loadTaskerReviews = (taskerId, skip = 0, count = 1000) =>
  get(`/taskers/reviews/${taskerId}?skip=${skip}&count=${count}`);

export const attachTasker = (data) => post('/orders/tasker', data);

export const bandels = () => get('/bundles')
export const addSubscriptions = (data) => postFormData(`/subscriptions/add?bundle_id=${data.bundle_id}&order_id=${data.order_id}`, data);
export const deactivateSubscription = (data) => post('/subscriptions/deactivate', data)
