import {PageContent} from "../index";
import {Helmet} from "react-helmet";
import React from "react";

const BankCardFailure = () => {
    return (
        <PageContent noBg>
            <Helmet>
                <title>Adding card</title>
            </Helmet>
            <h4>An error occurred while adding card</h4>
        </PageContent>
    );
}

export default BankCardFailure;
