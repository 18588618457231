import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { Helmet } from 'react-helmet';
import TaskerLocationMap from './TaskerLocationMap';
import { orderActions, orderSelectors } from '../../../reducers/order';
import { getLocalDateTimeFull } from '../../../utils/time';
import { Loader } from '../../../components/ui';

import styles from './TaskerLocation.module.scss';
import { statuses } from '../helpers/orderConstants';

class TaskerLocation extends Component {
  state = {
    location: null,
    lastUpdate: null,
  };

  intervalIdLocation;

  componentDidMount() {
    const { taskerLocation, order } = this.props;

    if (taskerLocation && taskerLocation.length > 0) {
      this.setState({
        location: taskerLocation[taskerLocation.length - 1].location,
      });
    }

    if (order && order.status === statuses.TRANSPORTATION) {
      this.props.orderTaskerLoactionLoad(order.id);
      if (!this.intervalIdLocation) {
        this.intervalIdLocation = setInterval(() => {
          this.setState({
            lastUpdate: Date.now().toString().slice(0, 10),
          });
          this.props.orderTaskerLoactionLoad(order.id);
        }, 5000);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.taskerLocation || !this.props.taskerLocation) return;

    if (
      prevProps.taskerLocation !== this.props.taskerLocation &&
      this.props.taskerLocation.length > 0
    ) {
      this.setState({
        location: this.props.taskerLocation[
          this.props.taskerLocation.length - 1
        ].location,
      });
    }
  }

  getLastUpdateText = () => {
    const { taskerLocation } = this.props;
    if (this.state.location)
      return getLocalDateTimeFull(
        taskerLocation[taskerLocation.length - 1].timestamp,
      );
    return getLocalDateTimeFull(this.state.lastUpdate || Date.now());
  };

  renderLastUpdate = () => {
    const { t } = this.props;

    return (
      <div className={styles.blockLastUpdate}>
        {`${t('lastUpdate')}: ${this.getLastUpdateText()}`}
      </div>
    );
  };

  upperFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  renderOverlay = () => {
    const { taskerLocationLoading, t } = this.props;
    if (this.state.location !== null) return;

    const text = taskerLocationLoading
      ? `${t('loading')}...`
      : this.upperFirstLetter(t('noData'));

    return (
      <div className={styles.blockTaskerMapOverlay}>
        <span className={styles.blockMapOverlayText}>{text}</span>
      </div>
    );
  };

  render() {
    const { t, taskerLocationLoading } = this.props;

    return (
      <div>
        <Helmet>
          <title>{this.props.t('taskerOnMap')}</title>
        </Helmet>

        <div className={styles.header}>
          <div className={styles.headerTitle}>{t('taskerOnMap')}</div>
        </div>

        {this.renderLastUpdate()}

        <div className={styles.blockTaskerMap}>
          <Loader loading={taskerLocationLoading === null}>
            {this.renderOverlay()}
            <TaskerLocationMap center={this.state.location} />
          </Loader>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalIdLocation);
  }
}

const mapStateToProps = (state) => ({
  order: orderSelectors.getOrder(state),
  taskerLocation: orderSelectors.getOrderTaskerLocation(state),
  taskerLocationLoading: orderSelectors.getOrderTaskerLocationLoading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, orderActions),
)(TaskerLocation);
