import { get, post } from './baseService';

export const getUserProfile = () => get('/profile');

export const editUserProfile = (values) => post('/profile', values);

export const updateAvatar = (values) => post('/profile/avatar', values);

export const deactivateAccount = () => post('/profile/deactivate');

export const activateAccount = () => post('/profile/activate');

export const getUserNotifications = () => get('/profile/notification-settings');

export const setUserNotifications = (data) =>
  post('/profile/notification-settings', data);

export const setPushToken = (data) => post('/profile/device', data);

export const logout = (data) => post('/profile/logout', data);

export const getServerTime = () => get('/misc/time');
