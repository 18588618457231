import * as routes from './routes';

/* ORDER CREATION LINKS */

export const getPathForEditOrder = () => `/`;

export const getPathForCreateNewTask = () => `/product`;

export const getPathForEditTask = (taskId) => `/task/${taskId}`;

export const getPathForOrderAddress = () => `/map`;

export const getPathForOrderAddressCreate = () => `/map/create`;

export const getPathForOrderTaskers = () => `/taskers`;

export const getPathForPaymentPage = () => `/payment`;

export const getPathForOrders = () => `${routes.ORDERS}`;

/* SETTINGS */

export const getPathForProfile = () => '/settings/profile';

export const getPathForProfileEdit = () => '/settings/profile/edit';

export const getPathForPayment = () => '/settings/payment';

export const getPathForReviews = () => '/settings/reviews';

export const getPathForUserAddresses = () => '/settings/addresses';

export const getPathForUserInvite = () => '/settings/invite';

export const getPathForUserNotifications = () => '/settings/notifications';

export const getPathForUserLegal = () => '/settings/legal';

export const getPathForSupportUserProfile = () => '/settings/support';

export const getPathForDeactivated = () => '/deactivated';

export const getPathForBlocked = () => '/blocked';

/* USER ADDRESSES */

export const getPathForAddressSettings = () => '/settings/addresses';

export const getPathForEditAddress = (addressId) =>
  `/settings/addresses/${addressId}`;

export const getPathForCreateAddress = () => '/settings/addresses/create';

/* ORDER CARD LINKS */

export const getPathForOrderStatus = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.INFO}`;

export const getPathForOrderInfo = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.INFO}`;

export const getPathForOrderReceipt = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.RECEIPT}`;

export const getPathForChatWithTasker = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.CHAT}`;

export const getPathForTaskerMap = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.TASKER_MAP}`;

export const getPathForOrderReview = (orderId) =>
  `${routes.ORDERS}/${orderId}${routes.REVIEW}`;

/* GETTERS FROM PATH */

export const getOrderIdFromProps = (props) => {
  if (props && props.match && props.match.params) {
    return props.match.params.orderId;
  }
  return null;
};

export const getTaskIdFromProps = (props) => {
  if (props && props.match && props.match.params) {
    return props.match.params.taskId;
  }
  return null;
};
