import React, { Component } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { replace } from 'connected-react-router';
import { userActions, userSelectors } from '../../reducers/user';
import { supportActions, supportSelectors } from '../../reducers/support';

import { Loader, PageContent, Svg } from '../../components/ui';
import { GreenButton, GreenBorderButton } from '../../components/buttons';

import CreateComplaint from '../../containers/support/CreateComplaint';

import styles from './DeactivatedAccount.module.scss';
import Chat from '../chat/Chat';
import { chatActions, chatSelectors } from '../../reducers/chat';
import { dialogActions } from '../dialog/store';

class DeactivatedAccount extends Component {
  state = {
    isChat: false,
  };

  componentDidMount() {
    this.props.getSupportChat();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.supportChat !== this.props.supportChat &&
      this.props.supportChat.id
    ) {
      this.props.setCurrentChat(this.props.supportChat.id);
    }

    if (
      prevProps.currentChatId !== this.props.currentChatId &&
      this.props.supportChat &&
      this.props.currentChatId === this.props.supportChat.id
    ) {
      this.props.getMessages({
        chatId: this.props.supportChat.id,
      });
    }

    if (
      prevProps.supportChat &&
      !prevProps.supportChat.closed &&
      this.props.supportChat &&
      this.props.supportChat.closed
    ) {
      if (this.state.isChat) this.props.openCloseSupportChatPopup();
    }
  }

  onBtnSupportClick = () => {
    this.props.toggleShowSupportOnDeactivated(true);
  };

  renderDeactivated = () => {
    const { t, activateAccount } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.blockImg}>
          <Svg src={'blockedAccount'} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {t('youHaveDeactivatedYourAccount')}
          </div>
          <div className={styles.text}>
            <p>{t('inOrderToUseTheAppAsNormalYouNeedToActivateYourAccount')}</p>
          </div>
          <div className={styles.btnWrapper}>
            <div className={styles.btnContainer}>
              <GreenButton onClick={activateAccount}>
                {t('activateAccount')}
              </GreenButton>
            </div>

            <GreenBorderButton onClick={this.onBtnSupportClick}>
              {t('contactSupport')}
            </GreenBorderButton>
          </div>
        </div>
      </div>
    );
  };

  btnBackClick = () => {
    this.props.toggleShowSupportOnDeactivated(false);
  };

  isUserMessage = (message) => {
    return message && message.user.id !== -1;
  };

  renderSupport = () => {
    const { supportChat, messages } = this.props;

    if (supportChat && (supportChat.unread !== 0 || !supportChat.closed)) {
      if (
        messages &&
        messages.length > 1 &&
        !messages.every(this.isUserMessage)
      ) {
        if (!this.state.isChat)
          this.setState({
            isChat: true,
          });
        return (
          <div className={styles.containerChat}>
            <Chat chatId={supportChat.id} />
          </div>
        );
      }
    }

    if (this.state.isChat)
      this.setState({
        isChat: false,
      });

    return (
      <div className={styles.container}>
        <CreateComplaint
          screen={'DEACTIVATED'}
          btnBackClick={this.btnBackClick}
        />
      </div>
    );
  };

  renderContent = () => {
    if (this.props.showSupportOnDeactivated) return this.renderSupport();

    return this.renderDeactivated();
  };

  render() {
    const {
      t,
      deactivated,
      supportChatLoading,
      replace,
      messagesLoading,
    } = this.props;

    if (!deactivated) {
      replace('/');
    }

    return (
      <PageContent>
        <Helmet>
          <title>{t('youHaveDeactivatedYourAccount')}</title>
        </Helmet>

        <Loader loading={supportChatLoading || messagesLoading}>
          {this.renderContent()}
        </Loader>
      </PageContent>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      deactivated: userSelectors.isUserDeactivated(state),
      showSupportOnDeactivated: supportSelectors.getShowSupportOnDeactivated(
        state,
      ),
      supportChat: chatSelectors.getSupportChat(state),
      supportChatLoading: chatSelectors.getSupportChatLoading(state),
      messages: chatSelectors.getMessageList(state),
      messagesLoading: chatSelectors.isMessageListLoading(state),
      currentChatId: chatSelectors.getCurrentChatId(state),
    }),
    {
      ...userActions,
      replace,
      toggleShowSupportOnDeactivated:
        supportActions.toggleShowSupportOnDeactivated,
      getSupportChat: chatActions.getSupportChat,
      getMessages: chatActions.getMessageList,
      setCurrentChat: chatActions.setCurrentChat,
      openCloseSupportChatPopup: dialogActions.openCloseSupportChatPopup,
    },
  ),
)(DeactivatedAccount);
