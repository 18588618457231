import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { withReduxFormProps } from '../../../containers/hoc';

import { Svg } from '../../ui';

import styles from './RatingInput.module.scss';

const RatingInput = (props) => {
  return (
    <Rating
      initialRating={props.value}
      {...props}
      emptySymbol={<Svg src={'star'} className={styles.iconEmpty} />}
      fullSymbol={<Svg src={'star'} className={styles.iconFull} />}
    />
  );
};

RatingInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default withReduxFormProps(RatingInput);
