const MODULE_PREFIX = 'addresses/';

export const addressesTypes = {
  GET_ADDRESSES_LIST_REQUEST: MODULE_PREFIX + 'GET_ADDRESSES_LIST_REQUEST',
  GET_ADDRESSES_LIST_SUCCESS: MODULE_PREFIX + 'GET_ADDRESSES_LIST_SUCCESS',
  GET_ADDRESSES_LIST_FAILURE: MODULE_PREFIX + 'GET_ADDRESSES_LIST_FAILURE',

  GET_ADDRESS_REQUEST: MODULE_PREFIX + 'GET_ADDRESS_REQUEST',
  GET_ADDRESS_SUCCESS: MODULE_PREFIX + 'GET_ADDRESS_SUCCESS',
  GET_ADDRESS_FAILURE: MODULE_PREFIX + 'GET_ADDRESS_FAILURE',

  DELETE_ADDRESS_REQUEST: MODULE_PREFIX + 'DELETE_ADDRESS_REQUEST',
  DELETE_ADDRESS_SUCCESS: MODULE_PREFIX + 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILURE: MODULE_PREFIX + 'DELETE_ADDRESS_FAILURE',

  SELECT_ADDRESS: MODULE_PREFIX + 'SELECT_ADDRESS',

  SET_NEW_ADDRESS_LOCATION_REQUEST:
    MODULE_PREFIX + 'SET_NEW_ADDRESS_LOCATION_REQUEST',
  SET_NEW_ADDRESS_LOCATION_SUCCESS:
    MODULE_PREFIX + 'SET_NEW_ADDRESS_LOCATION_SUCCESS',
  SET_NEW_ADDRESS_LOCATION_FAILURE:
    MODULE_PREFIX + 'SET_NEW_ADDRESS_LOCATION_FAILURE',

  CLEAR_NEW_ADDRESS: MODULE_PREFIX + 'CLEAR_NEW_ADDRESS',
  CLEAR_ADDRESSES_STATE: MODULE_PREFIX + 'CLEAR_ADDRESSES_STATE',

  SAVE_ADDRESS_REQUEST: MODULE_PREFIX + 'SAVE_ADDRESS_REQUEST',
  SAVE_ADDRESS_SUCCESS: MODULE_PREFIX + 'SAVE_ADDRESS_SUCCESS',
  SAVE_ADDRESS_FAILURE: MODULE_PREFIX + 'SAVE_ADDRESS_FAILURE',

  MAKE_FAVORITE_REQUEST: MODULE_PREFIX + 'MAKE_FAVORITE_REQUEST',
  MAKE_FAVORITE_SUCCESS: MODULE_PREFIX + 'MAKE_FAVORITE_SUCCESS',
  MAKE_FAVORITE_FAILURE: MODULE_PREFIX + 'MAKE_FAVORITE_FAILURE',

  CHANGE_SELECTED_ADDRESS_LOCATION:
    MODULE_PREFIX + 'CHANGE_SELECTED_ADDRESS_LOCATION',

  CHANGE_SELECTED_ADDRESS_COMMENT_REQUEST:
    MODULE_PREFIX + 'CHANGE_SELECTED_ADDRESS_COMMENT_REQUEST',
  CHANGE_SELECTED_ADDRESS_COMMENT_SUCCESS:
    MODULE_PREFIX + 'CHANGE_SELECTED_ADDRESS_COMMENT_SUCCESS',
  CHANGE_SELECTED_ADDRESS_COMMENT_FAILURE:
    MODULE_PREFIX + 'CHANGE_SELECTED_ADDRESS_COMMENT_FAILURE',

  CLEAR_SELECTED_ADDRESS_IF_NOT_NEW:
    MODULE_PREFIX + 'CLEAR_SELECTED_ADDRESS_IF_NOT_NEW',
};

const initialState = {
  addresses: [],
  history: [],
  addressesLoading: false,

  editingAddress: null,
  editingAddressesLoading: false,

  selectedAddress: {},
  selectedAddressLoading: false,

  deletedAddress: {},
  deleteAddressloading: false,

  newAddress: null,
};

export default (state = initialState, action) => {
  const payload = action.payload ? action.payload : {};
  switch (action.type) {
    case addressesTypes.CLEAR_ADDRESSES_STATE:
      return {
        ...initialState,
      };

    case addressesTypes.GET_ADDRESSES_LIST_REQUEST:
      return {
        ...state,
        addressesLoading: true,
      };

    case addressesTypes.GET_ADDRESSES_LIST_SUCCESS:
      return {
        ...state,
        addresses: payload.addresses.sort((a) => (a.favorite ? -1 : 1)),
        history: payload.history,
        addressesLoading: false,
      };

    case addressesTypes.GET_ADDRESSES_LIST_FAILURE:
      return {
        ...state,
        addressesLoading: false,
      };

    case addressesTypes.GET_ADDRESS_REQUEST:
      return {
        ...state,
        selectedAddressLoading: true,
      };

    case addressesTypes.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        selectedAddress: payload.address,
        selectedAddressLoading: false,
      };

    case addressesTypes.GET_ADDRESS_FAILURE:
      return {
        ...state,
        selectedAddressLoading: false,
      };

    case addressesTypes.SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          ...payload,
        },
        selectedAddressLoading: false,
      };

    case addressesTypes.SET_NEW_ADDRESS_LOCATION_REQUEST:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          location: payload.location,
          name: state.selectedAddress.name,
          comment: state.selectedAddress.comment,
          new: true,
          loading: true,
          error: null,
          mapClick: payload.mapClick || false,
        },
      };

    case addressesTypes.SET_NEW_ADDRESS_LOCATION_SUCCESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          address: payload.address,
          loading: false,
          mapClick: payload.mapClick || false,
        },
      };

    case addressesTypes.SET_NEW_ADDRESS_LOCATION_FAILURE:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          address: null,
          error: payload.error,
          loading: false,
        },
      };

    case addressesTypes.CHANGE_SELECTED_ADDRESS_LOCATION:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          ...payload,
          new: false,
        },
      };

    case addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_REQUEST:
      return {
        ...state,
      };

    case addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_SUCCESS:
      return {
        ...state,
        selectedAddress: {
          ...state.selectedAddress,
          ...payload,
        },
      };

    case addressesTypes.CLEAR_SELECTED_ADDRESS_IF_NOT_NEW:
      return {
        ...state,
        selectedAddress: state.selectedAddress.new ? state.selectedAddress : {},
      };

    case addressesTypes.CLEAR_NEW_ADDRESS:
      return {
        ...state,
        selectedAddress: {},
      };

    case addressesTypes.SAVE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case addressesTypes.SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case addressesTypes.SAVE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case addressesTypes.DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        deleteAddressloading: true,
      };

    case addressesTypes.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddressloading: false,
      };

    case addressesTypes.DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        deleteAddressloading: false,
      };

    default:
      return state;
  }
};

export const addressesActions = {
  clearAddressesState: () => ({
    type: addressesTypes.CLEAR_ADDRESSES_STATE,
  }),

  getAddress: (id) => ({
    type: addressesTypes.GET_ADDRESS_REQUEST,
    payload: {
      id,
    },
  }),

  getAddresses: () => ({
    type: addressesTypes.GET_ADDRESSES_LIST_REQUEST,
  }),

  selectAddress: (address) => ({
    type: addressesTypes.SELECT_ADDRESS,
    payload: {
      address,
    },
  }),

  setNewAddressLocation: (location, mapClick) => ({
    type: addressesTypes.SET_NEW_ADDRESS_LOCATION_REQUEST,
    payload: {
      location,
      mapClick,
    },
  }),

  clearNewAddress: () => ({
    type: addressesTypes.CLEAR_NEW_ADDRESS,
  }),

  saveAddress: (payload) => ({
    type: addressesTypes.SAVE_ADDRESS_REQUEST,
    payload: { ...payload },
  }),

  changeSelectedAddressLocation: (location) => ({
    type: addressesTypes.CHANGE_SELECTED_ADDRESS_LOCATION,
    payload: {
      location,
    },
  }),

  changeSelectedAddressComment: (comment) => ({
    type: addressesTypes.CHANGE_SELECTED_ADDRESS_COMMENT_REQUEST,
    payload: {
      comment,
    },
  }),

  clearSelectedAddressIfNotNew: () => ({
    type: addressesTypes.CLEAR_SELECTED_ADDRESS_IF_NOT_NEW,
  }),

  deleteAddress: (id, newVersion = false) => ({
    type: addressesTypes.DELETE_ADDRESS_REQUEST,
    payload: {
      id,
      newVersion,
    },
  }),

  makeFavorite: (address) => ({
    type: addressesTypes.MAKE_FAVORITE_REQUEST,
    payload: {
      address,
    },
  }),
};

export const addressesSelectors = {
  selectAddresses: (state) => state.addresses?.addresses,

  selectAddressesLoading: (state) => state.addresses.addressesLoading,

  deleteAddressLoading: (state) => state.addresses.deleteAddressloading,

  getAddressesHistory: (state) => state.addresses.history,

  getSelectedAddress: (state) => state.addresses.selectedAddress,

  getSelectedAddressLoading: (state) => state.addresses.selectedAddressLoading,
};
