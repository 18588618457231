import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { GreenButton } from '../../../components/buttons';

import styles from './ChatIsNotAvailable.module.scss';

class ChatIsNotAvailable extends Component {
  render() {
    const { support, onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('chatIsNotAvailableTitle')}</div>
        <div className={styles.text}>
          {support ? t('chatIsNotAvailableText') : t('chatIsNotAvailableText')}
        </div>
        <div className={styles.btn}>
          <GreenButton
            onClick={() => {
              onClose();
            }}
          >
            {t('OK')}
          </GreenButton>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(ChatIsNotAvailable);
