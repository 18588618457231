import { setPushToken } from '../services/userService';
import store from '../store';

let OneSignal;

function setOneSignalUserIdToLocalStorage(userId) {
  window.localStorage.setItem('oneSignalUserId', userId || '');
}

function sendTokenToServer(token) {
  return setPushToken({
    platform: 'WEB',
    token: token,
    language: localStorage.getItem('lang') === 'en' ? 'EN' : 'GE',
  });
}

function getSubscriptionState() {
  return Promise.all([
    OneSignal.isPushNotificationsEnabled(),
    OneSignal.isOptedOut(),
  ]).then(function ([isPushEnabled, isOptedOut]) {
    return {
      isPushEnabled,
      isOptedOut,
    };
  });
}

function registerServiceWorker(t) {
  navigator.serviceWorker
    .register(
      process.env.REACT_APP_BASE_URL +
        '/static/js/OneSignalSDKWorker.js?appId=' +
        process.env.REACT_APP_ONE_SIGNAL_APP_ID +
        '&base-url=' +
        process.env.REACT_APP_BASE_URL,
      { scope: '/' },
    )
    .then((registration) => {
      OneSignal.push(function () {
        OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
          autoRegister: false,
          path: `${process.env.REACT_APP_BASE_URL}/static/js/`,
          persistNotification: false,
          notifyButton: {
            enable: false,
          },
        });

        getSubscriptionState().then(function (state) {
          if (!state.isPushEnabled) {
            OneSignal.registerForPushNotifications();
          } else if (state.isOptedOut) {
            OneSignal.setSubscription(true);
          }
        });

        OneSignal.getUserId()
          .then((userId) => {
            if (userId) {
              setOneSignalUserIdToLocalStorage(userId);
              sendTokenToServer(userId);
            } else {
              getSubscriptionState().then(function (state) {
                if (state.isOptedOut || !state.isPushEnabled) {
                  OneSignal.registerForPushNotifications();
                  OneSignal.setSubscription(true);
                }
              });
            }
          })
          .catch(console.error);

        OneSignal.on('subscriptionChange', function (isSubscribed) {
          if (isSubscribed) {
            OneSignal.getUserId()
              .then((userId) => {
                if (userId) {
                  setOneSignalUserIdToLocalStorage(userId);
                  sendTokenToServer(userId);
                }
              })
              .catch(console.error);
          }
        });

        OneSignal.on('notificationDisplay', function (event) {
          const pushData = event.data;
          const actionName = 'push/' + pushData.push_type;

          store.dispatch({
            type: 'push/UPDATE_NOTIFICATIONS_LIST',
          });

          return store.dispatch({
            type: actionName,
            payload: { t, ...pushData },
          });
        });
      });
    })
    .catch((error) => {
      console.error(
        'ServiceWorker OneSignalSDKWorker registration failed: ',
        error,
      );
    });
}

export default function initPush(t) {
  OneSignal = window.OneSignal || null;

  if (OneSignal) {
    // OneSignal.log.setLevel('trace');

    OneSignal.push(function () {
      if (OneSignal.isPushNotificationsSupported()) {
        registerServiceWorker(t);
      } else {
        console.error("Browser doesn't support notification.");
      }
    });
  }
}
