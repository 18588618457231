import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PinkButton } from '../../../components/buttons';

import styles from './ConfirmAccountDeactivation.module.scss';
import { userActions } from '../../../reducers/user';

class ConfirmAccountDeactivation extends Component {
  onDeactivateClick = () => {
    this.props.deactivateAccount();
    this.props.onClose();
  };

  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('accountDeactivation')}</div>
        <div className={styles.text}>
          {t('onceYooHaveDeactivatedYourAccount')}
        </div>
        <div className={styles.btn}>
          <PinkButton onClick={this.onDeactivateClick}>
            {t('deactivate')}
          </PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, { deactivateAccount: userActions.deactivateAccount }),
  withTranslation(),
)(ConfirmAccountDeactivation);
