import React from 'react';
import classNames from 'classnames';

import styles from './GrayBorderButton.module.scss';
import InitialButton from './InitialButton';

const GrayBorderButton = (props: BaseButtonPropsType) => (
  <InitialButton
    className={classNames(styles.button, {
      [styles.buttonDisabled]: props.disabled,
    })}
    {...props}
  />
);

export default GrayBorderButton;
