import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../reducers/createOrder';
import { createTaskSelectors, createTaskActions } from './store';
import CategoryCard from './components/CategoryCard';
import CreateTaskBackButton from './components/CreateTaskBackButton';
import { Loader } from '../../components/ui';
import styles from './ChooseCategory.module.scss';

interface ChooseCategoryProps extends RouteComponentProps {
  order: any;
  orderLoading: boolean;
  categories: any[];
  selectCategory: (arg0: any) => void;
  selectSubcategory: (arg0: any) => void;
  onCategorySelect: (arg0: any) => void;
  loadEditingOrder: () => void;
  loadAnswersFromQS: (arg0: AnswersAndQuestionsFromSearchQuery) => void;
  categoriesLoading: boolean;
}
class ChooseCategory extends PureComponent<ChooseCategoryProps> {
  componentDidMount() {
    this.props.loadEditingOrder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderLoading && !this.props.orderLoading) {
      this.checkIfCategorySelected();
    }
  }

  checkIfCategorySelected() {
    const {
      order,
      location,
      selectCategory,
      categories,
      loadAnswersFromQS,
    } = this.props;
    const params: SearchQuery = qs.parse(location.search.substr(1));

    if (params.category) {
      const selectedCategory = categories.find(
        (category) => category.model.id === +params.category,
      );

      if (selectedCategory) {
        if (
          params.subcategory &&
          selectedCategory.second_question &&
          selectedCategory.subcategories.includes(params.subcategory)
        ) {
          this.props.selectSubcategory({
            categoryId: params.category,
            subcategory: params.subcategory,
            question: selectedCategory.second_question,
            tasksCount: order.services.length || null,
            taskOrderId: order.id || null,
          });
        } else {
          selectCategory({
            categoryId: params.category,
            taskCount: order.services.length || null,
            taskOrderId: order.id || null,
          });
          if (params.answers) {
            loadAnswersFromQS(params);
          }
        }
      } else {
        selectCategory({
          categoryId: params.category,
          taskCount: order.services.length || null,
          taskOrderId: order.id || null,
        });
      }
      delete params.category;
      this.props.history.push('?' + qs.stringify(params));
    }
  }

  render() {
    const {
      orderLoading,
      categoriesLoading,
      categories,
      order,
      t,
      onCategorySelect,
      selectSubcategory,
    } = this.props;

    return (
      <div className={styles.container}>
        <Helmet>
          <title>{t('selectCategory')}</title>
        </Helmet>

        <Loader loading={orderLoading} height={400}>
          <div className={styles.buttonContainer}>
            <CreateTaskBackButton />
          </div>

          <div className={styles.cardContainer}>
            <Loader loading={categoriesLoading} height={400}>
              {categories.map((category) => (
                <CategoryCard
                  onSubcategorySelect={selectSubcategory}
                  onCategorySelect={onCategorySelect}
                  category={category}
                  key={category.model.id}
                  servicesCount={false}
                  taskOrderId={order.id || null}
                />
              ))}
            </Loader>
          </div>

          <div className={styles.buttonContainer}>
            <CreateTaskBackButton />
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withRouter,
  connect(
    (state) => ({
      categories: createTaskSelectors.getCategories(state),
      categoriesLoading: createTaskSelectors.getCategoriesLoading(state),
      order: createOrderSelectors.getOrder(state),
      orderLoading: createOrderSelectors.getOrderLoading(state),
    }),
    {
      ...createTaskActions,
      loadEditingOrder: createOrderActions.loadEditingOrder,
    },
  ),
)(ChooseCategory);
