const CREATED = 'CREATED';
const WAITING_PAYMENT = 'WAITING_PAYMENT';
const PAYMENT_IN_PROCESS = 'PAYMENT_IN_PROCESS';
const PAID = 'PAID';
const VIEWED = 'VIEWED';
const TRANSPORTATION = 'TRANSPORTATION';
const IN_PROGRESS = 'IN_PROGRESS';
const WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';
const PENDING = 'PENDING';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';

export const statuses = {
  CREATED,
  WAITING_PAYMENT,
  PAYMENT_IN_PROCESS,
  PAID,
  VIEWED,
  TRANSPORTATION,
  IN_PROGRESS,
  WAITING_CONFIRMATION,
  PENDING,
  COMPLETED,
  CANCELLED,
};

export const getLabelForStatus = (status, t) => {
  switch (status) {
    case CREATED:
      return t('inCreationStage');
    case WAITING_PAYMENT:
      return t('waitingForPayment');
    case PAYMENT_IN_PROCESS:
      return t('paymentInProcess');
    case PAID:
      return t('paid');
    case VIEWED:
      return t('viewedByTasker');
    case TRANSPORTATION:
      return t('transportation');
    case IN_PROGRESS:
      return t('inProgress');
    case WAITING_CONFIRMATION:
      return t('waitingConfirmation');
    case PENDING:
      return t('pending');
    case COMPLETED:
      return t('completedCapital');
    case CANCELLED:
      return t('cancelled');
    default:
      return 'Wrong status';
  }
};

export const getUpcomingStatuses = () => {
  return {
    CREATED,
    WAITING_PAYMENT,
    PAYMENT_IN_PROCESS,
    PAID,
    VIEWED,
    TRANSPORTATION,
    IN_PROGRESS,
    WAITING_CONFIRMATION,
  };
};

export const getUpcomingOrdersStatuses = () => {
  return {
    // CREATED,
    WAITING_PAYMENT,
    PAYMENT_IN_PROCESS,
    PAID,
    VIEWED,
    TRANSPORTATION,
    IN_PROGRESS,
    WAITING_CONFIRMATION,
    // COMPLETED,
  };
};

export const getPastStatuses = () => {
  return {
    PENDING,
    COMPLETED,
    CANCELLED,
  };
};
