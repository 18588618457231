import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import styles from './ConfirmGoFirstStep.module.scss';

import { PinkButton, GreenButton } from '../../../components/buttons';
import { createTaskActions } from 'containers/createTaskFullFlow/state/actions';

class ConfirmGoFirstStep extends Component {
  render() {
    const { changeActiveStep, onClose, t } = this.props;

    const goFirstStep = () => {
      changeActiveStep(9);
      onClose();
    };

    return (
      <div className={styles.container}>
        <p>{t('areYouSureYouWantToPerformTheTask')}</p>
        <p>{t('allProgressWillbeDeleted')}</p>
        <div className={styles.buttonContainer}>
          <GreenButton onClick={() => goFirstStep()}>{t('yes')}</GreenButton>
          <PinkButton onClick={() => onClose()}>{t('no')}</PinkButton>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(null, {
    changeActiveStep: createTaskActions.changeActiveStep,
  }),
)(ConfirmGoFirstStep);
