import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withAuthData, withCurrentOrder } from '../../hoc';
import styles from './PaymentCardSelect.module.scss';
import { Loader, Svg } from '../../../components/ui';
import { paymentsActions, paymentsSelectors } from '../../../reducers/payments';
import PaymentCardSelectItem from './PaymentCardSelectItem';
import {
  createOrderActions,
  createOrderSelectors,
} from '../../../reducers/createOrder';
import { userActions } from '../../../reducers/user';

class PaymentCardSelect extends Component {
  componentDidMount() {
    const { userLoggedIn } = this.props;
    if (userLoggedIn) {
      this.props.getCardsList();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createCard &&
      prevProps.createCard !== this.props.createCard &&
      this.props.createCard.url &&
      this.props.createCard.id
    ) {
      const redirectData = {
        from: 'CHECKOUT',
        id: this.props.createCard.id,
      };

      window.localStorage.setItem(
        'redirectToCardCreate',
        JSON.stringify(redirectData),
      );

      window.location.href = this.props.createCard.url;
    }
  }

  addCardHandle = () => {
    const { userLoggedIn } = this.props;
    if (userLoggedIn) {
      this.props.onCreateCard();
    } else {
      this.props.openSignInPopup();
    }
  };

  renderCard = (card) => (
    <PaymentCardSelectItem
      key={card.id}
      card={card}
      selectedCardId={this.props.selectedCardId}
      selectedCardIdLoading={this.props.selectedCardIdLoading}
      selectedCardInProcess={this.props.selectedCardInProcess}
      selectCard={this.props.onSelectCard}
    />
  );

  renderAddNewCardButton = () => {
    const { createCardLoading } = this.props;

    return (
      <div className={styles.addCardButton} onClick={this.addCardHandle}>
        <Loader loading={createCardLoading}>
          <div className={styles.addCardButtonPlus}>
            <Svg src={'plus'} className={styles.addCardButtonPlusIcon} />
          </div>
        </Loader>
      </div>
    );
  };

  render() {
    const { cardsList, cardsListLoading, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('payment')}</div>

        <div className={styles.cards}>
          <Loader loading={cardsListLoading} height={116}>
            {cardsList &&
              cardsList.length > 0 &&
              cardsList.map(this.renderCard)}
            {this.renderAddNewCardButton()}
          </Loader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cardsList: paymentsSelectors.getCardsList(state),
  cardsListLoading: paymentsSelectors.getCardsListLoading(state),
  createCard: paymentsSelectors.getCreateCard(state),
  createCardLoading: paymentsSelectors.getCreateCardLoading(state),
  selectedCardId: createOrderSelectors.getOrderSelectedCard(state),
  selectedCardIdLoading: createOrderSelectors.getOrderSelectedCardLoading(
    state,
  ),
  selectedCardInProcess: createOrderSelectors.getOrderSelectedCardInProcess(
    state,
  ),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    openSignInPopup: userActions.openSignInPopup,
    getCardsList: paymentsActions.getCardsListRequest,
    onCreateCard: paymentsActions.createCardRequest,
    onSelectCard: createOrderActions.selectCardRequest,
  }),
  withCurrentOrder,
  withAuthData,
)(PaymentCardSelect);
