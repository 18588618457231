import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import styles from './OrderInfoMap.module.scss';
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../../../utils/mapUtils';
import { getMapMarker } from '../../../utils/mapUtils';

const defaultOptions = {
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  streetViewControl: false,
  scaleControl: true,
  mapTypeControl: false,
  panControl: false,
  zoomControl: true,
  rotateControl: false,
  fullscreenControl: false,
  draggable: true,
  draggableCursor: 'default',
};


class OrderInfoMap extends PureComponent<{ center: any, taskerPin: any }> {
  
  render() {
    const { center, taskerPin } = this.props;
    return (
      <div className={styles.blockMap}>
        <GoogleMap
          defaultZoom={DEFAULT_ZOOM}
          center={center || DEFAULT_CENTER}
          defaultOptions={defaultOptions}
        >
          {center ? <Marker position={center} icon={getMapMarker()} /> : null}
          {taskerPin ? <Marker position={taskerPin} icon={getMapMarker()} /> : null}
        </GoogleMap>
      </div>
    );
  }
}

export default compose<any, { center: any }>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,

    loadingElement: <div style={{ height: `300px` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `300px` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(OrderInfoMap);
