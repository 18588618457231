import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Lari, Svg } from '../../../components/ui';
import styles from './ReceiptTasksListItem.module.scss';

class ReceiptTasksListItem extends Component {
  renderIcon = () => {
    const { completed, task } = this.props;
    const { quantity, quantity_finished, quantity_rejected } = task;

    let src = '';

    if (completed) {
      if (task.rejected) {
        src = 'trash';
      } else {
        if (task.completed) {
          src =
            quantity === quantity_finished + quantity_rejected
              ? 'taskCompleted'
              : 'taskPartCompleted';
        } else {
          src = 'taskUncompleted';
        }
      }
    } else {
      if (task.rejected) {
        src = 'trash';
      } else if (!task.confirmed) {
        src = 'question';
      }
    }

    if (task.rejected) {
      src = 'trash';
    }

    if (src === '') return;

    return <Svg src={src} className={styles.taskIcon} />;
  };

  renderRightIcon = () => {
    const { completed, task } = this.props;
    let src = '';

    if (completed || !task.confirmed) {
      return null;
    }

    if (!task.rejected) {
      src = 'remove';
    }

    if (src === '') return;

    return (
      <button className={styles.taskRightButton} onClick={this.onTaskDelete}>
        <Svg src={src} className={styles.taskRightIcon} />
      </button>
    );
  };

  onTaskDelete = (e) => {
    const { task, openCancelPopup } = this.props;

    e.preventDefault();
    e.stopPropagation();

    openCancelPopup(task);
  };

  getQuantity = (task) => {
    const { completed, quantity, quantity_finished, quantity_rejected } = task;

    if (completed) {
      if (quantity - quantity_rejected === quantity_finished) {
        return (
          <span className={styles.colorGreen}>
            x{quantity - quantity_rejected}
          </span>
        );
      } else {
        return (
          <span>
            <span className={styles.colorYellow}>{quantity_finished}</span>
            {`/${quantity}`}
          </span>
        );
      }
    } else {
      return `x${task.quantity - task.quantity_rejected}`;
    }
  };

  render() {
    const { task, orderStatus, openReceiptPopup } = this.props;

    if (task.from_tasker) {
      if (task.quantity_rejected === task.quantity || task.rejected) {
        return null;
      }
    }

    return (
      <div
        onClick={() => openReceiptPopup(task, orderStatus)}
        className={classNames(styles.item, {
          [styles.rejected]: task.rejected,
        })}
      >
        {this.renderIcon()}

        <div className={styles.itemTitle}>
          <span>({this.getQuantity(task)})</span>
          {task.service.name}
        </div>

        <div className={styles.itemCost}>
          {task.price}
          <Lari big={true} />
        </div>

        {/* hide right button
          this.renderRightIcon()
          */}
      </div>
    );
  }
}

ReceiptTasksListItem.propTypes = {
  task: PropTypes.object,
};

export default ReceiptTasksListItem;
