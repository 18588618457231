import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { MaskInput } from '../../../components/inputs/maskInput';
import { GreenButton } from '../../../components/buttons';
import { restorePasswordSelectors } from '../store';
import { Svg } from '../../../components/ui';

import styles from './RestorePasswordForm.module.scss';

class RestorePasswordForm extends Component {
  render() {
    const { phoneLoading, t } = this.props;

    return (
      <form className={styles.container} onSubmit={this.props.handleSubmit}>
        <div className={styles.title}>{t('passwordRecovery')}</div>
        <div className={styles.blockImg}>
          <Svg src={'passRecovery'} />
        </div>
        <Field
          component={MaskInput}
          mask={'+999 999 999 999'}
          maskChar={''}
          label={t('phone')}
          name="phone"
        />
        <div className={styles.btnWrapper}>
          <GreenButton type="submit" disabled={phoneLoading}>
            {t('restore')}
          </GreenButton>
        </div>
      </form>
    );
  }
}

export default compose(
  withTranslation(),
  reduxForm({
    initialValues: {
      phone: '+995',
    },
  }),
  connect((state) => ({
    phoneLoading: restorePasswordSelectors.getPhoneLoading(state),
  })),
)(RestorePasswordForm);
