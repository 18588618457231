import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { notificationsListActions } from '../../../../reducers/notificationsList';
import { userSelectors } from '../../../../reducers/user';

import styles from './NotificationsItem.module.scss';
import { RootReduxState } from 'store';
import moment from 'moment';

type NotificationsListPropsType = {
  notification: NotificationType;
  lastSeen: string | null;
};
const NotificationsList = ({
  lastSeen,
  notification,
}: NotificationsListPropsType) => {
  const dispatch = useDispatch();

  const { timestamp, payload } = notification;

  const { timeDifferenceWithServer } = useSelector((state: RootReduxState) => ({
    timeDifferenceWithServer: userSelectors.getTimeDifferenceWithServer(state),
  }));

  const getAction = () => {
    if (!payload) return '';
    const pushType = payload.push_type;

    switch (pushType) {
      case 'ORDER_STATUS_CHANGED':
        if (
          payload.payload &&
          payload.payload.order &&
          payload.payload.order.id
        ) {
          return `/orders/${payload.payload.order.id}/info`;
        }
        return '';
      case 'TASK_ADDED_BY_TASKER':
        return `/orders/${payload.payload.model[0].order_id}/info`;

      case 'TASK_NEED_APPROVE':
        if (
          payload.payload &&
          payload.payload.model &&
          payload.payload.model.order_id
        ) {
          return `/orders/${payload.payload.model.order_id}/receipt`;
        }
        return '';

      case 'NEW_CHAT_MESSAGE':
        if (payload.payload && payload.payload.order_id) {
          return `/orders/${payload.payload.order_id}/chat`;
        }
        return '';

      case 'SUPPORT_ANSWER':
        return `/settings/support`;

      case 'WARRANTY_END_NOT_CONFIRMED_ORDER':
        if (payload.payload && payload.payload.order_id) {
          return `/orders/${payload.payload.order_id}/review`;
        }
        return '';

      case 'CUSTOMER_BLOCKED':
        return '/blocked';

      case 'CUSTOMER_UNBLOCKED':
        return '';

      case 'REFERRAL_CUSTOMER_PROGRAM_COMPLETED':
        return '';

      default:
        return '';
    }
  };

  const isNew = () => {
    if (lastSeen && +lastSeen) {
      if (+lastSeen + timeDifferenceWithServer > +timestamp * 1000)
        return false;
    }

    return true;
  };

  return (
    <Link
      className={classNames(styles.notificationsItem, {
        [styles.isNew]: isNew(),
      })}
      onClick={() => dispatch(notificationsListActions.notificationsListHide())}
      to={getAction()}
    >
      <div className={'flex flex-row'}>
        <div className={styles.notificationsItemImage}>
          <img src={`${notification.image ? notification.image : ''}`} alt="" />
        </div>
        <div className={styles.notificationsItemText}>
          <div className={styles.notificationsItemTitle}>
            {notification.title}
          </div>
          <div className={styles.notificationsItemDescription}>
            {notification.content}
          </div>
        </div>
      </div>

      <p className={styles.date}>
        {moment(notification.timestamp).format('DD MMMM, HH:mm')}
      </p>
    </Link>
  );
};

export default NotificationsList;
