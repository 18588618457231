import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { orderActions, orderSelectors } from '../../../reducers/order';

import { PinkButton } from '../../../components/buttons';

import styles from './ConfirmOrderCancel.module.scss';
import { Loader } from '../../../components/ui';

class ConfirmOrderCancel extends Component {
  render() {
    const {
      refoundSum,
      refoundSumLoader,
      order,
      cancelOrder,
      onClose,
      t,
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('cancelTheOrder')}</div>
        <Loader loading={refoundSumLoader} height={204}>
          <div className={styles.text}>
            {`${t('orderWillBeCancelledStart')} ${refoundSum} ${t(
              'orderWillBeCancelledEnd',
            )}`}
          </div>
          <div className={styles.btn}>
            <PinkButton
              onClick={() => {
                cancelOrder(order);
                onClose();
              }}
            >
              {t('cancelTheOrder')}
            </PinkButton>
          </div>
        </Loader>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(
    (state) => ({
      refoundSum: orderSelectors.getRefoundSum(state),
      refoundSumLoader: orderSelectors.getRefoundSumLoader(state),
    }),
    {
      cancelOrder: orderActions.cancelOrder,
    },
  ),
)(ConfirmOrderCancel);
