import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GreenButton } from '../../../components/buttons';

import styles from './UploadImageError.module.scss';

class UploadImageError extends Component {
  getError = () => {
    const { t, maxSize } = this.props;

    if (maxSize) {
      return t('imageIsTooBig') + maxSize + ' MB.';
    } else {
      return `${t('pleaseUploadAPphoto')} ${t(
        'allowedFormats',
      )} png, jpg, jpeg.`;
    }
  };

  render() {
    const { onClose, t } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{t('uploadImageError')}</div>
        <div className={styles.text}>{this.getError()}</div>
        <div className={styles.btn}>
          <GreenButton onClick={onClose}>{t('cancel')}</GreenButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UploadImageError);
