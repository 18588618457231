import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import addresses from './addresses';
import createOrderMap from '../containers/createOrderMap/store';
import createOrderMapNew from '../containers/createOrderMapNew/store';
import createOrderTaskers from '../containers/createOrderTaskers/store';
import createOrderTaskersNew from '../containers/createOrderTaskersNew/store';
import createOrder from './createOrder';
import createTask from '../containers/createTask/store';
import { createTaskFullFlow } from '../containers/createTaskFullFlow';
import addressStore from '../containers/createTaskFullFlow/containers/chooseAddress/state/store';
import serviceStore from '../containers/createTaskFullFlow/containers/selectService/state/store';

import restorePassword from '../containers/restorePassword/store';
import order from './order';
import ordersList from './ordersList';
import dialog from '../containers/dialog/store';
import signup from './signup';
import user from './user';
import footer from './footer';
import chat from './chat';
import support from './support';
import notificationsList from './notificationsList';
import payments from './payments';
import maps from './map'

const rootReducers = (history) =>
  combineReducers({
    addresses,
    createOrder,
    createOrderMap,
    createOrderTaskers,
    createOrderTaskersNew,
    createTask,
    restorePassword,
    order,
    ordersList,
    dialog,
    signup,
    user,
    footer,
    chat,
    support,
    notificationsList,
    payments,
    createOrderMapNew,
    createTaskFullFlow,
    addressStore,
    serviceStore,
    maps,

    form: formReducer,
    router: connectRouter(history),
  });

export default rootReducers;
