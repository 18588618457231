import React from 'react';
import { Svg } from 'components/ui';
import styles from './ExpandableButton.module.scss';

type ExpandableButtonPropsType = {
  text?: string;
  svgSrc: string;
};

const ExpandableButton = ({ text, svgSrc }: ExpandableButtonPropsType) => {
  return (
    <div className={`flex flex-row  flex-center ${styles.btn}`}>
      <p className={styles.text}>{text}</p>
      <Svg src={svgSrc} className={styles.svg} />
    </div>
  );
};

export default ExpandableButton;
