import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import en from '../assets/locales/en/common.json';
import ka from '../assets/locales/ka/common.json';
import ru from '../assets/locales/ru/common.json';

import { setPushToken } from '../services/userService';

const i18next = i18n
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      whitelist: ['ka', 'en', 'ru'],
      fallbackLng: 'ka',
      defaultNS: 'common',
      debug:
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test',

      resources: {
        ka: { common: ka },
        en: { common: en },
        ru: { common: ru },
      },
    },
    () => {
      let language = window.localStorage.getItem('lang');

if (!language) {
  window.localStorage.setItem('lang', i18n.options.fallbackLng[0]);
  language = i18n.options.fallbackLng[0];
}
window.HelpCrunch?.('setPhraseList', language);

      function updateLangPushes(lang) {
        try {
          const token = window.localStorage.getItem('oneSignalUserId');
          if (token) {
            setPushToken({
              platform: 'WEB',
              token,
              language: lang,
            });
          }
        } catch (error) {
          console.log('%c Error updateLangPushes: ', error);
        }
      }

      i18n.changeLanguage(
        language || process.env.REACT_APP_DEFAULT_LANG || 'ka',
        updateLangPushes.bind(
          this,
          window.localStorage.getItem('lang') === 'en'
            ? 'EN'
            : window.localStorage.getItem('lang') === 'ka'
            ? 'GE'
            : 'RU',
        ),
      );

      i18n.on('languageChanged', function (lang) {
        const isLang = i18n.options.whitelist.some((option) => {
          return option === lang;
        });

  if (isLang) {
    localStorage.setItem('lang', lang);
  } else {
    localStorage.setItem('lang', i18n.options.fallbackLng[0]);
  }
  window.HelpCrunch?.('setPhraseList', lang);

        // updateLangInBd(window.localStorage.getItem('lang'));
      });
    },
  );

export default i18next;
