import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './LabeledNumberInput.module.scss';
import { withReduxFormProps } from '../../../containers/hoc';

class LabeledNumberInput extends Component {
  state = {
    value: +(this.props.value ? this.props.value : 1),
  };

  errorShouldBeRendered() {
    const { touched, error } = this.props;
    return !(touched === false || !error);
  }

  getMax() {
    return this.props.max ? this.props.max : 99;
  }

  onDecrease = () => {
    if (this.state.value < 2) return;

    this.setState({
      value: this.state.value - 1,
    });

    if (this.props.onChange) {
      this.props.onChange(this.state.value - 1);
    }
  };

  onIncrease = () => {
    if (this.state.value > this.getMax() - 1) return;

    this.setState({
      value: this.state.value + 1,
    });

    if (this.props.onChange) {
      this.props.onChange(this.state.value + 1);
    }
  };

  render() {
    const {
      onChange,
      maxLength,
      onFocus,
      onBlur,
      error,
      noMargin,
      unit,
    } = this.props;

    return (
      <div
        className={classNames(styles.label, { [styles.noMargin]: noMargin })}
      >
        <button
          className={classNames(styles.btn, styles.btnMinus)}
          onClick={this.onDecrease}
        />

        <div
          className={classNames(styles.input, {
            [styles.inputError]: this.errorShouldBeRendered(),
          })}
        >
          {this.state.value}
        </div>

        <input
          type={'hidden'}
          value={this.state.value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          readOnly={true}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classNames(styles.input, {
            [styles.inputError]: this.errorShouldBeRendered(),
          })}
        />

        <button
          className={classNames(styles.btn, styles.btnPlus)}
          onClick={this.onIncrease}
        />

        {unit && <span className={styles.unitText}>{unit}</span>}

        {this.errorShouldBeRendered() && (
          <div className={styles.errorLabel}>{error}</div>
        )}
      </div>
    );
  }
}

export default withReduxFormProps(LabeledNumberInput);
