import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import styles from './CreateOrderDisabledMap.module.scss';
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../../../utils/mapUtils';
import { getMapMarker } from '../../../utils/mapUtils';
import Loader from '../../../components/ui/Loader';

const defaultOptions = {
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  draggable: false,
  draggableCursor: 'pointer',
};

class CreateOrderDisabledMap extends Component {
  render() {
    const { center } = this.props;

    if (!window.google) {
      return null;
    }

    return (
      <div className={styles.blockMap}>
        <GoogleMap
          defaultZoom={DEFAULT_ZOOM}
          center={center || DEFAULT_CENTER}
          defaultOptions={defaultOptions}
        >
          {center ? <Marker position={center} icon={getMapMarker()} /> : null}
        </GoogleMap>
      </div>
    );
  }
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places&language=${
      window.localStorage.lang || 'en'
    }`,

    loadingElement: <Loader loading={true} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `300px` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(CreateOrderDisabledMap);
