import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import classNames from 'classnames';

import { statuses } from './helpers/orderConstants';
import { orderActions, orderSelectors } from '../../reducers/order';
import { mapsActions } from '../../reducers/map'
import {
  ORDERS,
  SUPPORT,
  REVIEW,
  INFO,
  CHAT,
  RECEIPT,
  TASKER_MAP,
} from '../routing/constants/routes';
import { Loader, NotFound } from '../../components/ui';
import Review from './components/Review';
import Receipt from './components/Receipt';
import OrderInfo from './components/OrderInfo';
import TaskerMap from './components/TaskerLocation';
import OrderTabsBar from './OrderTabsBar';
import StatusJob from './components/StatusJob';
import styles from './Order.module.scss';
import Support from './components/Support';
import ChatWithTasker from './components/ChatWithTasker';
import { chatActions, chatSelectors } from '../../reducers/chat';
import { ordersSelectors } from 'reducers/ordersList';
import { RootReduxState } from 'store';
import TwoPanel from 'components/ui/TwoPanel';
import { isCurrentOrder } from './helpers/helper';
import finishedOrders from 'assets/images/images/finished-orders.webp';
import currentOrder from 'assets/images/images/current-order.webp';
import OrderInfoMap from './components/OrderInfoMap';
import { OrderStatusesEnum } from 'utils/enum';

const allStatues = Object.keys(statuses).map((key) => statuses[key]);

const getTabsConfig = (t) => [
  {
    name: t('information'),
    component: OrderInfo,
    path: INFO,
    allowedStatuses: allStatues,
  },
  {
    name: t('receipt'),
    component: Receipt,
    path: RECEIPT,
    allowedStatuses: [
      statuses.PAID,
      statuses.VIEWED,
      statuses.TRANSPORTATION,
      statuses.IN_PROGRESS,
      statuses.WAITING_CONFIRMATION,
      statuses.PENDING,
      statuses.COMPLETED,
      statuses.VIEWED,
    ],
  },
  {
    name: t('review'),
    component: Review,
    path: REVIEW,
    allowedStatuses: [statuses.PENDING, statuses.COMPLETED],
  },
  {
    name: `${t('chatWithTasker')}`,
    component: ChatWithTasker,
    path: CHAT,
    allowedStatuses: [
      statuses.PAID,
      statuses.VIEWED,
      statuses.TRANSPORTATION,
      statuses.IN_PROGRESS,
      statuses.PENDING,
      statuses.WAITING_CONFIRMATION,
      statuses.VIEWED,
      statuses.COMPLETED,
    ],
  },
  {
    name: t('contactSupport'),
    component: Support,
    path: SUPPORT,
    allowedStatuses: [
      statuses.PAID,
      statuses.VIEWED,
      statuses.TRANSPORTATION,
      statuses.IN_PROGRESS,
      statuses.WAITING_CONFIRMATION,
      statuses.PENDING,
      statuses.VIEWED,
      statuses.COMPLETED,
    ],
  },
  {
    name: t('taskerMap'),
    component: TaskerMap,
    path: TASKER_MAP,
    allowedStatuses: [statuses.TRANSPORTATION],
  },
];

const Order = () => {
  
  
  const [chat, setChat] = useState<{ closed: boolean } | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { id: orderId } = useParams<{ id: string }>();
  
  const { order, statuses, chatList, orderLoading, taskerLocation } = useSelector(
    (state: RootReduxState) => ({
      order: state.order.order,
      statuses: ordersSelectors.getFilterStatuses(state),
      chatList: chatSelectors.getChatList(state),
      orderLoading: orderSelectors.getOrderLoading(state),
      taskerLocation: state.maps.taskerLocation
    }),
  );

  useEffect(() => {
    dispatch(orderActions.loadOrder(orderId));
    dispatch(orderActions.loadOrderTasks(orderId));
    dispatch(chatActions.getChatList());
    dispatch(mapsActions.getTaskerLocation(orderId));
    setInterval(() => {
      dispatch(mapsActions.getTaskerLocation(orderId));
    }, 50000)
    

    return () => {
      dispatch(orderActions.clearOrderState());      
    };
  }, [dispatch, orderId]);



  useEffect(() => {
    if (order && order.status === statuses.CANCELLED) {
      replace(ORDERS + '/' + orderId + INFO);
    }

    if (chatList && order) {
      const findChatById = (chat, index) => {
        if (chat.id !== order.chat_id) return false;
        return index;
      };

      const indexClosedChat = chatList.findIndex(findChatById);
      setChat(chatList[indexClosedChat]);
    }
  }, [order, chatList, statuses.CANCELLED, replace, orderId, chat]);

  const getAllowedTabsForStatus = useCallback(() => {
    return getTabsConfig(t).filter((tab) =>
      tab.allowedStatuses.includes(order?.status),
    );
  }, [order?.status, t]);

  const renderSidebar = () => {
    const tabsConfig = getAllowedTabsForStatus();
    const chatClosed =
      (chat && chat?.closed) || (order && order?.tasker?.blocked);

    return (
      <OrderTabsBar
        order={order}
        tabsConfig={tabsConfig}
        path={pathname}
        chatClosed={chatClosed}
      />
    );
  };
  
  const renderLeftSide = useMemo(
    () =>
      order?.status === OrderStatusesEnum.TRANSPORTATION && (
        <OrderInfoMap center={order?.address.location} taskerPin={taskerLocation} />
      ),
    [order?.address.location, order?.status, taskerLocation],
  );

  return useMemo(
    () => (
      <Loader loading={orderLoading}>
        {order && order.id ? (
          <TwoPanel
            leftSide={renderLeftSide}
            imageLeftUrl={isCurrentOrder(order) ? currentOrder : finishedOrders}
            rightSide={
              order.status === statuses.WAITING_CONFIRMATION ? (
                <StatusJob />
              ) : (
                <div className={`${styles.mainContainer} flex`}>
                  <h3 className={styles.title}>
                    {t('order') + ' #' + order.id}
                  </h3>
                  <div className={'scrollable'}>
                    <div
                      className={classNames(styles.container, {
                        [styles.withoutBorder]: pathname.endsWith(CHAT),
                      })}
                    >
                      <Switch>
                        {getAllowedTabsForStatus().map((tab) => (
                          <Route
                            exact
                            path={ORDERS + '/:id' + tab.path}
                            component={tab.component}
                            key={tab.name}
                          />
                        ))}

                        <Redirect to={ORDERS + '/:id' + INFO} />
                      </Switch>
                    </div>
                  </div>
                </div>
              )
            }
          />
        ) : (
          <NotFound />
        )}
      </Loader>
    ),
    [
      renderLeftSide,
      getAllowedTabsForStatus,
      order,
      orderLoading,
      pathname,
      statuses.WAITING_CONFIRMATION,
      t,
    ],
  );
};

export default Order;
