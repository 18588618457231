import qs from 'qs';
import { del, get, post, put } from './baseService';

export const loadOrders = (statuses) =>
  get(
    `/orders/my?${qs.stringify({
      status: Object.keys(statuses).map((key) => statuses[key]),
    })}`,
  );

export const createEmptyOrder = () => post('/orders/create');

export const loadEditingAddress = (): Promise<CurrentOrderType> =>
  get('/orders/current');

export const loadOrder = (id) => get(`/orders/${id}`);

export const loadOrderTaskerLocation = (id) => get(`/orders/${id}/route`);

export const loadOrderTasks = (id) => get(`/orders/tasks/${id}`);

export const loadReasons = () => get(`/misc/complaint-reasons`);

export const createComplaint = (data) => put(`/orders/complaint`, data);

export const createReview = (data) => put(`/orders/review`, data);

export const deleteOrder = (orderId) => del(`/orders/${orderId}`);

export const getCancelOrderRefound = (orderId) =>
  post(`/orders/${orderId}/cancel-check`);

export const cancelOrder = (orderId, data) =>
  post(`/orders/cancel/${orderId}`, data);

export const loadCategories = () => get('/orders/categories');

export const submitSelectedRooms = (data) => put('/orders/categories', data);

export const loadMainCategories = () => get('/services/categories');

export const loadCategoryServices = (categoryId) =>
  get(`/services/services/${categoryId}`);

export const searchSubcategories = (searchString) =>
  get(`/services/search?query=${searchString}`);

export const answerQuestion = (categoryId, data?: any) =>
  post(`/orders/wizard/${categoryId}`, data);

export const loadTask = (taskId) => get(`/orders/task/${taskId}`);

export const createTask = (orderId, task) =>
  put(`/orders/${orderId}/create-task`, task);

export const createTasks = (orderId, task) =>
  put(`/orders/${orderId}/create-tasks`, task);

export const editTask = (taskId, task) => post(`/orders/task/${taskId}`, task);

export const deleteTask = (taskId) => del(`/orders/task/${taskId}`);

export const rejectTask = (taskId, data) =>
  post(`/orders/reject-task/${taskId}`, data);

export const rejectAddedTask = (taskId) =>
  post(`/orders/reject-task/${taskId}`);
  
export const rejectTaskCheck = (taskId, data) =>
  post(`/orders/reject-task-check/${taskId}`, data);

export const uploadUserDescription = (data) =>
  post(`/orders/user-description`, data);

export const confirmTask = (taskId) => post(`/orders/confirm-task/${taskId}`);

export const createOrder = (data) => post('/orders/create', data);

export const attachTasker = (data) => post('/orders/tasker', data);

export const detachTasker = (data) => del('/orders/tasker', data);

export const attachPromoCode = (data) => post('/orders/promocode', data);

export const detachPromoCode = (orderId) =>
  post(`/orders/${orderId}/delete-promocode`);

export const submitOrder = (data) => post('/orders/submit', data);

export const confirmOrderComplete = (order) =>
  post(`/orders/complete-confirm/${order.id}`);

export const testPayment = (data) => post('/test/payment', data);

export const saveOrderAddress = (orderId, data) =>
  post(`/orders/${orderId}/address`, data);

export const selectCard = (orderId, data) =>
  post(`/orders/${orderId}/card`, data);

export const getSuperCategories = (): Promise<GetSuperCategoriesResponseType> =>
  get(`/services/super-categories`);

export const getTaskTree = (
  categoryId: number,
): Promise<GetTaskTreeResponseType> =>
  get(`/orders/categories?full_tree=1&super_category_id=${categoryId}`);

export const getTipsOffer = (orderId: number): Promise<any> =>
  get(`/orders/tip-offers/${orderId}`);

export const giveTips = (orderId: number, data): Promise<any> =>
  post(`/orders/tip/${orderId}`, data);
