import { select } from 'redux-saga/effects';
import { RootReduxState } from 'store';
import { ORDER_FORM_REPAIR } from '../containers/selectService/components/AdditionalDetailsForm';
import { ORDER_FORM } from '../containers/selectService/createCleaningTask/FormOrder';

const TASK_SKELETON: TaskSkeletonType = {
  service_id: 0,
  quantity: 1,
  images: [],
  description: '',
  answers: [],
};

type SelectedTypeUploadType = {
  formData: { additionalInfo: string | undefined; [key: string]: any };
  additionalDetailsImages: { id: number; url: string }[];
  selectedServices: SelectedCleaningServices;
  selectedServiceObject: ServiceTreeType;
  selectedQuantitiy: number;
};

export function* formatHandyman() {
  const {
    formData,
    additionalDetailsImages,
    selectedServiceObject,
    selectedQuantitiy
  }: SelectedTypeUploadType = yield select((state: RootReduxState) => ({
    formData: state.form?.[ORDER_FORM_REPAIR]?.values,
    additionalDetailsImages: state.serviceStore.additionalDetailsImages,
    selectedServiceObject: state.serviceStore.selectedServiceObject,
    selectedQuantitiy: state.serviceStore.selectedServiceCount
  }));


  const id = selectedServiceObject?.services?.[0].id;

  TASK_SKELETON.images = additionalDetailsImages.map((val) => val.id);

  const tasks: TaskSkeletonType[] = [
    { ...TASK_SKELETON, service_id: +(id ?? 0), quantity: selectedQuantitiy },
  ];

  return { tasks, description: formData?.additionalInfo };
}

export function* formatCleaning() {
  const { formData, selectedServices }: SelectedTypeUploadType = yield select(
    (state: RootReduxState) => ({
      formData: state.form?.[ORDER_FORM]?.values,
      selectedServices: state.serviceStore.selectedServices,
    }),
  );

  const tasks: TaskSkeletonType[] = [];
  Object.keys(formData ?? {}).forEach((val) => {
    if (val.includes('checkbox')) {
      tasks.push({
        ...TASK_SKELETON,
        service_id: +val.replace('checkbox', ''),
        quantity: 1,
      });
    }
  });

  for (const serviceType in selectedServices) {
    for (const id in selectedServices[serviceType]) {
      tasks.push({
        ...TASK_SKELETON,
        service_id: +id,
        quantity: selectedServices[serviceType][id]?.count ?? 1,
      });
    }
  }

  return { tasks, description: formData?.additionalInfo };
}
