import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { withCurrentOrder } from '../hoc';
import TaskerView from './components/TaskerListItemView';
import { taskersSelectors, taskersActions } from './store';
import { Columns, PageContent } from '../../components/ui';
import TaskersSidebar from './components/TaskersSidebar';
import TaskerTimeTable from './components/TaskerTimeTable';
import TaskerDetailedInfo from './components/TaskerDetailedInfo';
import { Svg } from '../../components/ui';
import { Loader } from '../../components/ui';

import styles from './CreateOrderTaskers.module.scss';
import { createOrderSelectors } from '../../reducers/createOrder';

const NoTaskersFound = ({ t }) => {
  return (
    <div className={styles.flexRow}>
      <div className={styles.container}>
        <div className={styles.blockNoTaskers}>
          <div className={styles.blockImage}>
            <Svg className={styles.image} src={'noTaskers'} />
          </div>
          <div className={styles.blockContent}>
            <div className={styles.blockTitle}>{t('noAvailableTaskers')}</div>
            <p className={styles.blockText}>
              {t('thereAreNoAvailableTaskersToCompleteYourOrder')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

class CreateOrderTaskers extends Component {
  componentDidMount() {
    const { orderId } = this.props;
    this.props.loadTaskersForOrder(orderId);
  }

  componentWillUnmount() {
    this.props.clearTaskersState();
  }

  openTaskerTimetable = (tasker) => {
    const { orderId } = this.props;
    this.props.openTimetablePopup(orderId, tasker);
  };

  renderTasker = (tasker) => {
    return (
      <TaskerView
        loading={this.props.timetableLoading}
        key={tasker.user.id}
        tasker={tasker}
        onProfileOpen={this.props.openDetailedPopup}
        onTimetableOpen={this.openTaskerTimetable}
        taskerAvatarBig={true}
        badge
        achievements
      />
    );
  };

  renderTaskersList() {
    const { taskers, taskersLoading, t } = this.props;
    return (
      <div className={styles.mainContent}>
        <Loader loading={taskersLoading} containerWithHeight={400}>
          {taskers && taskers.length > 0 ? (
            taskers.map(this.renderTasker)
          ) : (
            <NoTaskersFound t={t} />
          )}
        </Loader>
      </div>
    );
  }

  render() {
    const { orderId, t } = this.props;

    return (
      <PageContent title={t('pickATasker')}>
        <Helmet>
          <title>{t('availableTaskers')}</title>
        </Helmet>

        <Columns
          aside={<TaskersSidebar orderId={orderId} />}
          mainContent={this.renderTaskersList()}
        />

        <TaskerTimeTable taskerInfo={false} orderId={orderId} />
        <TaskerDetailedInfo orderId={orderId} />
      </PageContent>
    );
  }
}

const mapStateToProps = (state) => ({
  taskers: taskersSelectors.getTaskers(state),
  taskersLoading: taskersSelectors.getTaskersLoading(state),
  timetableLoading: taskersSelectors.getTimetableLoading(state),
  orderId: createOrderSelectors.getOrderId(state),
});

export default compose(
  withTranslation(),
  withCurrentOrder,
  connect(mapStateToProps, taskersActions),
)(CreateOrderTaskers);
