import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getPathForOrderInfo } from '../containers/routing/constants/pathHelpers';
import { statuses } from '../containers/order/helpers/orderConstants';
import { orderActions, orderSelectors } from '../reducers/order';
import { notificationsListActions } from '../reducers/notificationsList';
import { userActions } from '../reducers/user';

const pushActionTypes = {
  UPDATE_NOTIFICATIONS_LIST: 'push/UPDATE_NOTIFICATIONS_LIST',
  ORDER_STATUS_CHANGED: 'push/ORDER_STATUS_CHANGED',
  TASK_NEED_APPROVE: 'push/TASK_NEED_APPROVE',
  NEW_CHAT_MESSAGE: 'push/NEW_CHAT_MESSAGE',
  SUPPORT_ANSWER: 'push/SUPPORT_ANSWER',
  WARRANTY_END_NOT_CONFIRMED_ORDER: 'push/WARRANTY_END_NOT_CONFIRMED_ORDER',
  CUSTOMER_BLOCKED: 'push/CUSTOMER_BLOCKED',
  CUSTOMER_UNBLOCKED: 'push/CUSTOMER_UNBLOCKED',
  TASKER_UNBLOCKED: 'push/TASKER_UNBLOCKED',
  TASKER_BLOCKED: 'push/TASKER_BLOCKED',
  ORDER_CANCELLED_BY_ADMIN_TO_CUSTOMER:
    'push/ORDER_CANCELLED_BY_ADMIN_TO_CUSTOMER',
};

function* onUpdateNotificationsList() {
  try {
    yield put(notificationsListActions.updateNotificationsList());
  } catch (error) {
    console.error(error);
  }
}

function* onOrderStatusChange({ payload }) {
  try {
    const data = payload.payload;
    const id = data.order.id;

    if (data.order.status === statuses.WAITING_CONFIRMATION) {
      yield put(push(getPathForOrderInfo(id)));
    }

    yield put(orderActions.updateOrderInBackground(id));
  } catch (error) {
    console.error(error);
  }
}

function* onTaskNeedApprove(payload) {
  try {
    const data = payload.payload.payload;

    if (data.model.order_id) {
      yield put(orderActions.loadOrderTasks(data.model.order_id));
    }
  } catch (error) {
    console.error(error);
  }
}

function* onCustomerBlocked() {
  try {
    yield put(userActions.getUserProfile());
    yield push('/blocked');
  } catch (error) {
    console.error(error);
  }
}

function* onCustomerUnblocked() {
  try {
    yield put(userActions.getUserProfile());
    yield push('/');
  } catch (error) {
    console.error(error);
  }
}

function* onTaskerBlocked() {
  try {
    const order = yield select(orderSelectors.getOrder);

    if (order && order.id) {
      yield put(orderActions.loadOrder(order.id));
    }
  } catch (error) {
    console.error(error);
  }
}

function* onTaskerUnblocked() {
  try {
    const order = yield select(orderSelectors.getOrder);

    if (order && order.id) {
      yield put(orderActions.loadOrder(order.id));
    }
  } catch (error) {
    console.error(error);
  }
}

function* onOrderCancelledByAdmin({ payload }) {
  try {
    const data = payload.payload;
    const id = data.order_id;

    yield put(orderActions.updateOrderInBackground(id));
  } catch (error) {
    console.error(error);
  }
}

export function* watchUpdateNotificationsList() {
  yield takeEvery(
    pushActionTypes.UPDATE_NOTIFICATIONS_LIST,
    onUpdateNotificationsList,
  );
}

export function* watchOrderStatusChange() {
  yield takeEvery(pushActionTypes.ORDER_STATUS_CHANGED, onOrderStatusChange);
}

export function* watchTaskNeedApprove() {
  yield takeEvery(pushActionTypes.TASK_NEED_APPROVE, onTaskNeedApprove);
}

export function* watchCustomerBlocked() {
  yield takeEvery(pushActionTypes.CUSTOMER_BLOCKED, onCustomerBlocked);
}

export function* watchCustomerUnblocked() {
  yield takeEvery(pushActionTypes.CUSTOMER_UNBLOCKED, onCustomerUnblocked);
}

export function* watchTaskerBlocked() {
  yield takeEvery(pushActionTypes.TASKER_BLOCKED, onTaskerBlocked);
}

export function* watchTaskerUnblocked() {
  yield takeEvery(pushActionTypes.TASKER_UNBLOCKED, onTaskerUnblocked);
}

export function* watchOrderCancelledByAdmin() {
  yield takeEvery(
    pushActionTypes.ORDER_CANCELLED_BY_ADMIN_TO_CUSTOMER,
    onOrderCancelledByAdmin,
  );
}
