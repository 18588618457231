import { take, put, call } from 'redux-saga/effects';

import { footerActionTypes } from '../reducers/footer';
import { getSiteLinks, getSocialLinks } from '../services/footerService';

export function* watchGetFooterSiteLinks() {
  while (true) {
    try {
      yield take(footerActionTypes.FOOTER_SITE_LINKS_REQUEST);

      const links = yield call(
        getSiteLinks,
        localStorage.getItem('lang') || 'ka',
      );

      yield put({
        type: footerActionTypes.FOOTER_SITE_LINKS_SUCCESS,
        payload: {
          siteLinks: links,
        },
      });
    } catch (e) {
      yield put({ type: footerActionTypes.FOOTER_SITE_LINKS_FAILURE });
    }
  }
}

export function* watchGetFooterSocialLinks() {
  while (true) {
    try {
      yield take(footerActionTypes.FOOTER_SOCIAL_LINKS_REQUEST);

      const socials = yield call(
        getSocialLinks,
        localStorage.getItem('lang') || 'ka',
      );

      yield put({
        type: footerActionTypes.FOOTER_SOCIAL_LINKS_SUCCESS,
        payload: {
          socialLinks: socials,
        },
      });
    } catch (e) {
      yield put({ type: footerActionTypes.FOOTER_SOCIAL_LINKS_FAILURE });
    }
  }
}
