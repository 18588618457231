import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { addressesActions, addressesSelectors } from '../../reducers/addresses';
import ProfileAddressForm from './ProfileAddressForm';
import { Loader } from '../../components/ui';

export const PROFILE_ADDRESS_FORM = 'profileAddressForm';

class ProfileAddressesCreateEdit extends Component {
  getEditingId() {
    if (this.props.match.params.id !== 'create') {
      return this.props.match.params.id;
    }
    return null;
  }

  isEditing() {
    return !!this.getEditingId();
  }

  componentDidMount() {
    if (this.isEditing()) {
      this.props.getAddress(this.getEditingId());
    }
  }

  getFullAddressObject = (values) => {
    const { address } = this.props;

    return {
      ...address,
      ...values,
      saved: true,
    };
  };

  onSubmit = (values) => {
    this.props.saveAddress({
      address: this.getFullAddressObject(values),
      isEditing: this.isEditing(),
      isOrderCreate: false,
    });
  };

  render() {
    const { address, addressLoading, t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>
            {this.isEditing() ? t('editAddress') : t('createAddress')}
          </title>
        </Helmet>

        <Loader loading={addressLoading}>
          <ProfileAddressForm
            form={PROFILE_ADDRESS_FORM}
            initialValues={address}
            isEditing={this.isEditing()}
            onSubmit={this.onSubmit}
            onDelete={this.props.deleteAddress}
            onSelectAddress={this.props.selectAddress}
            onLocationChange={this.props.changeSelectedAddressLocation}
            onChangeAddressObj={this.props.changeAddressObj}
            onSetNewAddressLocation={this.props.setNewAddressLocation}
          />
        </Loader>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  address: addressesSelectors.getSelectedAddress(state),
  addressLoading: addressesSelectors.getSelectedAddressLoading(state),
});

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, { ...addressesActions }),
)(ProfileAddressesCreateEdit);
