import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { paymentsActions, paymentsTypes } from '../reducers/payments';
import {
  checkCard,
  createCard,
  deleteCard,
  getCardsList,
  makeDefaultCard,
} from '../services/paymentsService';
import { dialogActions } from '../containers/dialog/store';
import {
  getPathForPayment,
  getPathForPaymentPage,
} from '../containers/routing/constants/pathHelpers';

/* *** get cards list *** */
function* onGetCardsList() {
  try {
    const cardsList = yield call(getCardsList);

    yield put(paymentsActions.getCardsListSuccess(cardsList));
  } catch (error) {
    console.error('SAGA onGetCardsList error: ', error);
    yield put(paymentsActions.getCardsListFailure(error));
  }
}

export function* watchGetCardsList() {
  yield takeEvery(paymentsTypes.GET_CARDS_LIST_REQUEST, onGetCardsList);
}

/* *** create card *** */
function* onCreateCard() {
  try {
    const payload = yield call(createCard);

    yield put(paymentsActions.createCardSuccess(payload));
  } catch (error) {
    console.error('SAGA onCreateCard error: ', error);
    yield put(paymentsActions.createCardFailure(error));
  }
}

export function* watchCreateCard() {
  yield takeEvery(paymentsTypes.CARD_CREATE_REQUEST, onCreateCard);
}

/* *** check card *** */
function* onCheckCard() {
  try {
    const redirect = JSON.parse(
      window.localStorage.getItem('redirectToCardCreate'),
    );

    if (redirect && redirect.from && redirect.id) {
      yield put(dialogActions.openCheckCardPopup());

      switch (redirect.from) {
        case 'PROFILE':
          yield put(push(getPathForPayment()));
          break;

        case 'CHECKOUT':
          yield put(push(getPathForPaymentPage()));
          break;

        default:
          console.error('UNKNOWN REDIRECT PATH');
          break;
      }

      let payload = yield call(checkCard, redirect.id);

      if (payload && payload.status && payload.status === 'CREATED') {
        yield call(delay, 1000);
        payload = yield call(checkCard, redirect.id);
      }

      yield put(paymentsActions.getCardsListRequest());
      yield put(paymentsActions.checkCardSuccess(payload));
    } else {
      yield put(paymentsActions.checkCardFailure());
    }
  } catch (error) {
    console.error('SAGA onCheckCard error: ', error);
    yield put(paymentsActions.checkCardFailure(error));
  } finally {
    window.localStorage.setItem('redirectToCardCreate', null);
  }
}

export function* watchCheckCard() {
  yield takeEvery(paymentsTypes.CARD_CHECK_REQUEST, onCheckCard);
}

/* *** make card default *** */
function* onMakeCardDefault({ payload: { id } }) {
  try {
    const makeDefaultPayload = yield call(makeDefaultCard, id);

    yield put(paymentsActions.getCardsListRequest());
    yield put(paymentsActions.deleteCardSuccess(makeDefaultPayload));
  } catch (error) {
    console.error('SAGA onDeleteCard error: ', error);
    yield put(
      dialogActions.openMakeCardDefaultError(
        error && error.message ? error.message : null,
      ),
    );
    yield put(paymentsActions.deleteCardFailure(error));
  }
}

export function* watchMakeCardDefault() {
  yield takeEvery(paymentsTypes.MAKE_CARD_DEFAULT_REQUEST, onMakeCardDefault);
}

/* *** delete card *** */
function* onDeleteCard({ payload: { id } }) {
  try {
    const deletePayload = yield call(deleteCard, id);

    yield put(paymentsActions.getCardsListRequest());
    yield put(paymentsActions.deleteCardSuccess(deletePayload));
  } catch (error) {
    console.error('SAGA onDeleteCard error: ', error);
    yield put(dialogActions.openDeleteCardFailure(error));
    yield put(paymentsActions.deleteCardFailure(error));
  }
}

export function* watchDeleteCard() {
  yield takeEvery(paymentsTypes.CARD_DELETE_REQUEST, onDeleteCard);
}
