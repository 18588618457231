import address from 'assets/images/images/address.webp';
import pay from 'assets/images/images/pay.webp';
import chooseProfy from 'assets/images/images/choose-profy.webp';
import Worker from 'assets/images/images/xelosani-ill.png';
import Cleaning from 'assets/images/images/cleaning.png';
import IT from 'assets/images/images/IT.png';

export const DEFAULT_PAGINATION_DATA = [
  {
    id: '1',
    title: 'step',
    sub_title: 'selectCategory',
    description: 'descriptionStep1',
    img: Worker,
    question: 'selectCategory',
    answer: '',
  },
  {
    id: '2',
    title: 'step',
    sub_title: 'chooseRoom',
    description: 'descriptionStep2',
    img: Worker,
    question: 'chooseService',
    answer: '',
  },
  {
    id: '3',
    title: 'step',
    sub_title: 'chooseAddress',
    description: 'descriptionStep3',
    img: address,
    question: 'chooseAddress',
    answer: '',
  },
  {
    id: '4',
    title: 'step',
    sub_title: 'chooseYourProfy',
    description: 'descriptionStep4',
    img: chooseProfy,
    question: 'chooseYourProfy',
    answer: '',
  },
  {
    id: '5',
    title: 'step',
    sub_title: 'checkOut',
    description: 'descriptionStep5',
    img: pay,
    question: 'checkOut',
    answer: '',
  }
];

export const SUPER_CATEGORY_DESC = [
  {
    id: '6',
    title: 'step',
    sub_title: 'checkOut',
    description: 'descriptionCleaning',
    img: Cleaning,
    question: 'checkOut',
    answer: '',
  },
  {
    id: '6',
    title: 'step',
    sub_title: 'checkOut',
    description: 'descriptionIT',
    img: IT,
    question: 'checkOut',
    answer: '',
  },
]

export const DEFAULT_ADDITIONAL_CERVICES = [
  {
    id: '1',
    title: 'step',
    sub_title: 'chooseRoom',
    description: '',
    img: 'step_1',
  },
];
