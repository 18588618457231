import * as React from "react";

const MobileLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={85}
    height={18}
    viewBox="0 0 85 18"
    {...props}
  >
    <defs>
      <style>{".a{fill:#66c3ad;}.b{fill:#343d47;}"}</style>
    </defs>
    <g transform="translate(-20 -14.64)">
      <path
        className="a"
        d="M115.928,17.964h6.4a.179.179,0,0,1,.154.089l3.191,5.505a.162.162,0,0,1,0,.17l-3.223,5.5a.176.176,0,0,1-.15.085l-6.388.018a.179.179,0,0,1-.154-.085l-3.191-5.505a.169.169,0,0,1,0-.174l3.223-5.494a.179.179,0,0,1,.122-.107h0m0-3.324a3.532,3.532,0,0,0-3.051,1.743l-3.223,5.5a3.474,3.474,0,0,0,0,3.5l3.194,5.5a3.525,3.525,0,0,0,3.08,1.758h6.4A3.532,3.532,0,0,0,125.36,30.9l3.223-5.495a3.474,3.474,0,0,0,0-3.5L125.389,16.4a3.533,3.533,0,0,0-3.051-1.755h-6.4Z"
        transform="translate(-57.233 0)"
      />
      <path
        className="a"
        d="M133,33.12h-1.844a.864.864,0,0,0-.752.43l-.924,1.588a.857.857,0,0,0,0,.86l.924,1.588a.864.864,0,0,0,.752.43H133a.875.875,0,0,0,.756-.43l.92-1.588a.839.839,0,0,0,0-.86l-.92-1.588a.875.875,0,0,0-.756-.43"
        transform="translate(-70.204 -11.916)"
      />
      <path
        className="b"
        d="M20,32.706V14.82h6.088a9.462,9.462,0,0,1,5.676,1.449,4.912,4.912,0,0,1,2.016,4.18A5.165,5.165,0,0,1,32.107,24.6q-2.149,1.946-6.31,1.946a8.183,8.183,0,0,1-1.59-.131v6.286Zm4.19-14.754v5.285a6.807,6.807,0,0,0,1.59.131,4.583,4.583,0,0,0,2.772-.749,2.479,2.479,0,0,0,1.021-2.131,2.181,2.181,0,0,0-.917-1.893,4.3,4.3,0,0,0-2.557-.657Z"
        transform="translate(0 -0.116)"
      />
      <path
        className="b"
        d="M66.09,32.668V14.76c1.712,0,3.706.021,5.916.021q4.19,0,6.123,1.421a4.377,4.377,0,0,1,1.726,3.736,4.068,4.068,0,0,1-1.021,2.788,5.1,5.1,0,0,1-2.27,1.552v.1a4.811,4.811,0,0,1,2.467,3.2c.143.472.326,1.129.559,1.971s.423,1.517.58,2.024a7.774,7.774,0,0,0,.4,1.066H76.249a20.287,20.287,0,0,1-1.329-4.074,4.08,4.08,0,0,0-1.1-2.209,3.389,3.389,0,0,0-2.109-.607H70.28v6.919Zm4.19-14.807V22.78h1.912a4.255,4.255,0,0,0,2.542-.671,2.126,2.126,0,0,0,.931-1.829,2.079,2.079,0,0,0-.863-1.815,4.083,4.083,0,0,0-2.374-.6Z"
        transform="translate(-29.586 -0.077)"
      />
      <path
        className="b"
        d="M171.54,33V15.27H183.5v3.392h-7.742v3.907H183v3.392h-7.237V33Z"
        transform="translate(-97.275 -0.406)"
      />
      <path
        className="b"
        d="M220.47,33.016h-4.215V25.6L210.05,15.3h4.931L217,19.587q1.511,3.232,1.511,3.26h.054q.161-.355,1.511-3.236l2.041-4.312h4.881L220.47,25.44Z"
        transform="translate(-121.995 -0.426)"
      />
    </g>
  </svg>
);

export default MobileLogo;
