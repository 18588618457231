import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import { PageContent, Svg } from '../index';
import { GreenButton } from '../../buttons/index';

import styles from './Failure.module.scss';

const ErrorScreen = ({ t }) => {
  return (
    <PageContent noBg>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <div className={styles.img}>
        <Svg src={'development'} />
      </div>
      <div className={styles.title}>{t('oops')}</div>
      <p className={styles.text}>{t('someErrorOccurred')}</p>
      <div className={styles.btnWrapper}>
        <div className={styles.notFound_link}>
          <GreenButton onClick={() => document.location.reload()}>
            {t('reload')}
          </GreenButton>
        </div>
      </div>
    </PageContent>
  );
};

export default withTranslation()(ErrorScreen);
