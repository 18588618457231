import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './OrderCard.module.scss';
import {
  getLabelForStatus,
  statuses,
} from '../../order/helpers/orderConstants';
import { getLocalDateTime, getLocalDateYearFull } from '../../../utils/time';

import { Svg, Lari } from '../../../components/ui';
import { getWorkingTime, getWarrantyTime } from '../../../utils/orderTimes';
import { getIconName } from '../helper';
import { dateAndTime } from 'services/dateFormatter';
import i18next from 'i18next';
import { toInteger } from 'lodash';
type OrderCardPropsType = {
  timeDifferenceWithServer: number;
  order: OrderType;
  renderButton: (order: OrderType) => React.ReactElement;
  onCardClick: (val: OrderType) => void;
};

const OrderCard = ({
  timeDifferenceWithServer,
  order,
  renderButton,
  onCardClick,
}: OrderCardPropsType) => {
  const { t } = useTranslation();

  const renderWarrantyTimer = () => {
    if (order.on_warranty === true)
      return getWarrantyTime(
        parseInt(order?.warranty_end_at ?? '') + timeDifferenceWithServer,
        parseInt(order?.completed_at ?? '') + timeDifferenceWithServer,
        t,
      );
  };

  const renderWorkingTimer = () => {
    if (order.status === statuses.IN_PROGRESS) {
      return `${t('workingTime')}: ${getWorkingTime(
        parseInt(order?.tasker_begin_at ?? '') + timeDifferenceWithServer,
      )}`;
    }
  };

  const formatDateAndTime = useMemo(() => {
    if (order.datetime === null) return <></>;
    const time = getLocalDateYearFull(order.datetime);
    const finalTime = dateAndTime(time);

    return (
      // <div className={styles.minutes}>
      <>{finalTime}</>

      // </div>
    );
  }, [order]);

  const renderDate = () => {
    if (order.datetime === null) return;

    return (
      <div className={styles.orderInfoDate}>
        {getLocalDateTime(order.datetime)}
      </div>
    );
  };

  const renderFooter = () => {
    const renderButtonResult = renderButton(order);

    if (renderButtonResult === null) return;

    return (
      <div className={styles.footer}>
        {/*<Link className={styles.contactTasker} to={'/'}>Contact Tasker</Link>*/}
        <span className={styles.footerBtn}>{renderButtonResult}</span>
      </div>
    );
  };

  const [discount, setDiscount] = useState({
    have_discount: false,
    discount_percent: 0,
    remainedPackage: 7,
    isPackage: false,
    discountType: '%',
  });

  useEffect(() => {
    if (order.subscription != null) {
      const remainedDiscount =
        order.subscription.total_amount - order.subscription.used_amount;
      setDiscount({
        have_discount: true,
        discount_percent: order.subscription.discount_percent,
        remainedPackage: remainedDiscount,
        isPackage: true,
        discountType: '%',
      });
    } else if (order.referral_code != null) {
      setDiscount({
        have_discount: true,
        discount_percent: order.referral_discount_value,
        remainedPackage: 0,
        isPackage: false,
        discountType: order.referral_discount_type == 'PERCENT' ? '%' : '₾',
      });
    } else if (order.promocode_code != null) {
      setDiscount({
        have_discount: true,
        discount_percent: order.promocode_discount_amount,
        remainedPackage: 0,
        isPackage: false,
        discountType: order.promocode_discount_type == 'PERCENT' ? '%' : '₾',
      });
    }
  }, []);

  return (
    <div className={classNames(styles.container)}>
      <div onClick={() => onCardClick(order)} className={styles.topContainer}>
        <div className={styles.order__info__holder}>
          <div className={styles.imageTitle}>
            <div className={`flex flex-center ${styles.imageContainer}`}>
              <img
                src={order.services[0].image ?? ''}
                alt={'order'}
                className={styles.image}
              />
              <Svg src={getIconName(order)} className={styles.svgAbsolute} />
            </div>
            <div>
              <h5 className={styles.title}>
                {t('order')} #{order.id}
              </h5>
              <div className={styles.minutes}>{formatDateAndTime}</div>
              {/* {order ? formatDateAndTime : ''} */}
              {/* <p className={styles.minutes}>
                {getLabelForStatus(order.status, t)}
              </p> */}
            </div>
            <div className={styles.checkoutPriceContainer}>
              <p>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp; {order.price} ₾ &nbsp;&nbsp;&nbsp;
              </p>
            </div>
          </div>
        </div>
        {discount.have_discount ? (
          <div className={styles.discount__holder}>
            <div>
              <h5 className={styles.discount__title}>
                {i18next.t('discounted')}
              </h5>
              <h5 className={styles.discount__amount}>
                {discount.discount_percent} {discount.discountType}
              </h5>
            </div>
            {discount.isPackage ? (
              <div className={styles.discount__right__number}>
                {discount.remainedPackage}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
