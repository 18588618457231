import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SingleSelect extends Component {
  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  renderOption = (option) => {
    return (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    );
  };

  render() {
    return (
      <select value={this.props.value} onChange={this.onChange}>
        {this.props.options.map(this.renderOption)}
      </select>
    );
  }
}

SingleSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SingleSelect;
