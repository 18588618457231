import React, { Component } from 'react';
import { v1 } from 'uuid';
import { connect } from 'react-redux';
import DropZone from 'react-dropzone';
import classNames from 'classnames';

import { getBase64 } from '../../utils/formData';
import { createTaskActions, createTaskSelectors } from './store';
import TaskImage from './components/TaskImage';

import styles from './TaskImagesUploader.module.scss';
import { MAX_IMAGES_COUNT_IN_TASK } from './store';
import { Loader } from '../../components/ui';
import { dialogActions } from '../dialog/store';
import Svg from '../../components/ui/Svg';

class TaskImagesUploader extends Component {
  onDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      accepted.forEach((file) => {
        if (file instanceof File) {
          if (file.size > 10485760) {
            this.props.openUploadImageErrorPopup({ maxSize: 10 });
          } else {
            getBase64(file).then((base64Image) => {
              this.props.uploadImage({
                file: file,
                base64Image,
                clientGeneratedId: v1(),
              });
            });
          }
        }
      });
    }

    if (rejected.length > 0) {
      this.props.openUploadImageErrorPopup();
    }
  };

  render() {
    const { deleteImage, openZoomImgPopup, images } = this.props;
    return (
      <div className={styles.images}>
        {images.map((image) => (
          <div
            className={styles.image}
            key={image.clientGeneratedId || image.id}
          >
            <Loader loading={image.loading}>
              <TaskImage
                image={image}
                onDelete={deleteImage}
                openZoomImgPopup={openZoomImgPopup}
              />
            </Loader>
          </div>
        ))}
        {images.length < MAX_IMAGES_COUNT_IN_TASK ? (
          <div className={classNames(styles.image, styles.dropZoneContainer)}>
            <DropZone
              className={styles.dropZone}
              onDrop={this.onDrop}
              accept={'image/png, image/jpg, image/jpeg'}
            />
            <div className={styles.dropZoneImg}>
              <Svg src={'uploadPhoto'} className={styles.responsiveImg} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    images: createTaskSelectors.selectImages(state),
  }),
  {
    uploadImage: createTaskActions.uploadImage,
    deleteImage: createTaskActions.deleteImage,
    openZoomImgPopup: dialogActions.openZoomImgPopup,
    openUploadImageErrorPopup: dialogActions.openUploadImageErrorPopup,
  },
)(TaskImagesUploader);
