import React from 'react';
import InitialEmptyTextArea from './InitialTextArea';
import InitialTextAreaAutoHeight from './InitialTextAreaAutoHeight';

const DefaultTextArea = (props) =>
  props.autoHeight === true ? (
    <InitialTextAreaAutoHeight
      big={props.big}
      required={props.required}
      className={props.className}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      maxlength={props.maxlength}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  ) : (
    <InitialEmptyTextArea
      className={props.className}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      maxlength={props.maxlength}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );

export default DefaultTextArea;
