const CONFIRM_ORDER_CANCEL = 'CONFIRM_ORDER_CANCEL';
const CONFIRM_ORDER_DELETE = 'CONFIRM_ORDER_DELETE';
const CONFIRM_TASK_DELETE = 'CONFIRM_TASK_DELETE';
const CONFIRM_CREATE_TASK_FROM_ORDER = 'CONFIRM_CREATE_TASK_FROM_ORDER';
const CONFIRM_TASK_DELETE_FROM_ORDER = 'CONFIRM_TASK_DELETE_FROM_ORDER';
const CONFIRM_TASK_REJECT_FROM_ORDER = 'CONFIRM_TASK_REJECT_FROM_ORDER';
const CONFIRM_ACCOUNT_DEACTIVATION = 'CONFIRM_ACCOUNT_DEACTIVATION';
const CONFIRM_DETACH_TASKER_FROM_ORDER = 'CONFIRM_DETACH_TASKER_FROM_ORDER';
const OPEN_RECEIPT_POPUP = 'OPEN_RECEIPT_POPUP';
const OPEN_CANCEL_TASK_POPUP = 'OPEN_CANCEL_TASK_POPUP';
const OPEN_ZOOM_IMG_POPUP = 'OPEN_ZOOM_IMG_POPUP';
const OPEN_CROP_IMG_POPUP = 'OPEN_CROP_IMG_POPUP';
const TERMS_CONDITIONS_POPUP = 'TERMS_CONDITIONS_POPUP';
const SUBMIT_ORDER_ERROR = 'SUBMIT_ORDER_ERROR';
const SUBMIT_ORDER_ERROR_PAYMENT_METHOD = 'SUBMIT_ORDER_ERROR_PAYMENT_METHOD';
const SUBMIT_ORDER_ERROR_CARD_IS_NOT_ACTIVE =
  'SUBMIT_ORDER_ERROR_CARD_IS_NOT_ACTIVE';
const SUBMIT_ORDER_ERROR_TIMEOUT = 'SUBMIT_ORDER_ERROR_TIMEOUT';
const SUBMIT_ORDER_ERROR_PRICE_CHANGED = 'SUBMIT_ORDER_ERROR_PRICE_CHANGED';
const SUBMIT_ORDER_ERROR_SYSTEM_TRANSACTION =
  'SUBMIT_ORDER_ERROR_SYSTEM_TRANSACTION';
const SUBMIT_ORDER_ERROR_TASKER_TRANSACTION =
  'SUBMIT_ORDER_ERROR_TASKER_TRANSACTION';
const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';
const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
const OTHER_DEVICE_SIGNED_IN = 'OTHER_DEVICE_SIGNED_IN';
const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
const COMPLAINT_SEND_SUCCESS = 'COMPLAINT_SEND_SUCCESS';
const COMPLAINT_SEND_FAILURE = 'COMPLAINT_SEND_FAILURE';
const CLOSE_SUPPORT_CHAT = 'CLOSE_SUPPORT_CHAT';
const DELETE_CARD_CONFIRM = 'DELETE_CARD_CONFIRM';
const CHECK_CARD = 'CHECK_CARD';
const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';
const MAKE_CARD_DEFAULT_ERROR = 'MAKE_CARD_DEFAULT_ERROR';
const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
const CHAT_IS_NOT_AVAILABLE = 'CHAT_IS_NOT_AVAILABLE';
const INVITE_USER = 'INVITE_USER';
const PROFY_FEEDBACK = 'PROFY_FEEDBACK';
const CONFIRIM_GO_FIRST_STEP = 'CONFIRIM_GO_FIRST_STEP';
const ADDED_ORDERS_CHECK = 'ADDED_ORDERS_CHECK';

export const dialogNames = {
  CONFIRM_ORDER_CANCEL,
  CONFIRM_ORDER_DELETE,
  CONFIRM_TASK_DELETE,
  CONFIRM_ACCOUNT_DEACTIVATION,
  CONFIRM_DETACH_TASKER_FROM_ORDER,
  OPEN_RECEIPT_POPUP,
  OPEN_CANCEL_TASK_POPUP,
  OPEN_ZOOM_IMG_POPUP,
  CONFIRM_CREATE_TASK_FROM_ORDER,
  CONFIRM_TASK_DELETE_FROM_ORDER,
  CONFIRM_TASK_REJECT_FROM_ORDER,
  OPEN_CROP_IMG_POPUP,
  TERMS_CONDITIONS_POPUP,
  SUBMIT_ORDER_ERROR,
  SUBMIT_ORDER_ERROR_PAYMENT_METHOD,
  SUBMIT_ORDER_ERROR_CARD_IS_NOT_ACTIVE,
  SUBMIT_ORDER_ERROR_TIMEOUT,
  SUBMIT_ORDER_ERROR_PRICE_CHANGED,
  SUBMIT_ORDER_ERROR_SYSTEM_TRANSACTION,
  SUBMIT_ORDER_ERROR_TASKER_TRANSACTION,
  SUBMIT_ORDER_SUCCESS,
  DELETE_ORDER_ERROR,
  UPLOAD_IMAGE_ERROR,
  OTHER_DEVICE_SIGNED_IN,
  SIGN_IN_ERROR,
  COMPLAINT_SEND_SUCCESS,
  COMPLAINT_SEND_FAILURE,
  CLOSE_SUPPORT_CHAT,
  DELETE_CARD_CONFIRM,
  CHECK_CARD,
  DELETE_CARD_FAILURE,
  MAKE_CARD_DEFAULT_ERROR,
  CANCEL_ORDER_ERROR,
  CHAT_IS_NOT_AVAILABLE,
  INVITE_USER,
  PROFY_FEEDBACK,
  CONFIRIM_GO_FIRST_STEP,
  ADDED_ORDERS_CHECK,
};
