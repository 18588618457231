import { take, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { ordersTypes, ordersSelectors } from '../reducers/ordersList';
import { loadOrders } from '../services/ordersListService';
import { push } from 'connected-react-router';
import { getPathForOrderReview } from '../containers/routing/constants/pathHelpers';
import { dialogActions } from 'containers/dialog/store';

export function* watchOrdersLoad() {
  while (true) {
    try {
      yield take(ordersTypes.LOAD_ORDERS_REQUEST);
      const statuses = yield select(ordersSelectors.getFilterStatuses);

      const { collection } = yield call(loadOrders, statuses);
      const currentTime = moment().unix();
      const orders = collection.sort(
        (a, b) =>
          Math.abs(currentTime - a.datetime) -
          Math.abs(currentTime - b.datetime),
      );

      yield put({
        type: ordersTypes.LOAD_ORDERS_SUCCESS,
        payload: { orders },
      });

      // const orderWithoutFeedback = orders.filter(order => order.status === statuses.COMPLETED && !order.customer_feedback);
      //
      // console.log('LOAD_ORDERS_REQUEST orderWithoutFeedback = ', orderWithoutFeedback);
    } catch (error) {
      yield put({ type: ordersTypes.LOAD_ORDERS_FAILURE });
    }
  }
}

export function* watchCompletedOrdersLoad() {
  while (true) {
    try {
      yield take(ordersTypes.LOAD_COMPLETED_ORDERS_REQUEST);
      const statuses = yield select(ordersSelectors.getFilterStatuses);

      const { collection } = yield call(loadOrders, { COMPLETED: 'COMPLETED' });
      const currentTime = moment().unix();
      const orders = collection.sort(
        (a, b) =>
          Math.abs(currentTime - a.datetime) -
          Math.abs(currentTime - b.datetime),
      );

      yield put({
        type: ordersTypes.LOAD_COMPLETED_ORDERS_SUCCESS,
        payload: { orders },
      });

      const orderWithoutFeedback = collection.filter(
        (order) =>
          order.status === statuses.COMPLETED && !order.customer_feedback,
      );

      if (orderWithoutFeedback && orderWithoutFeedback.length > 0) {
        yield put(
          dialogActions.openProfyFeedbackDialog(
            orderWithoutFeedback[0].id ?? 0,
          ),
        );
        // yield put(push(getPathForOrderReview(orderWithoutFeedback[0].id)));
      }
    } catch (error) {
      yield put({ type: ordersTypes.LOAD_COMPLETED_ORDERS_FAILURE });
    }
  }
}

export function* watchFilterStatusesChange() {
  while (true) {
    yield take(ordersTypes.CHANGE_SELECTED_TAB);
    yield put({ type: ordersTypes.LOAD_ORDERS_REQUEST });
  }
}
