import React from 'react';
import styles from './AdditionalDetailFormItem.module.scss';
type AdditionalDetailFormItemPropsType = {
  onSelectItem: (val: number) => void;
  serviceTree: ServiceTreeType | undefined;
  activeItemIndex: number;
};

const AdditionalDetailFormItem = ({
  onSelectItem,
  serviceTree,
  activeItemIndex,
}: AdditionalDetailFormItemPropsType) => {
  return (
    <div className={`flex  ${styles.content}`}>
      <p className={styles.title}>{serviceTree?.next_question}</p>
      <div className={`scrollable ${styles.additionalServices}`}>
        {serviceTree?.next_answers.map(({ answer }, index) => (
          <div
            key={index}
            className={`flex flex-center ${styles.additionalServiceItem} ${
              index === activeItemIndex ? styles.active : ''
            }`}
            onClick={() => onSelectItem?.(index)}
          >
            <span>{answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalDetailFormItem;
