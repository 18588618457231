import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { PageContent, Svg } from '../index';
import { GreenButton } from '../../buttons/index';

import styles from './NotFound.module.scss';

const NotFound = ({ t }) => {
  return (
    <PageContent noBg>
      <div className={styles.img}>
        <Svg src={'notFound'} />
      </div>
      <Helmet>
        <title>404</title>
      </Helmet>
      <div className={styles.title}>404</div>
      <p className={styles.text}>{t('weAreSorryForInconvenience')}</p>
      <div className={styles.btnWrapper}>
        <Link to="/" className={styles.notFound_link}>
          <GreenButton>{t('goBack')}</GreenButton>
        </Link>
      </div>
    </PageContent>
  );
};

export default withTranslation()(NotFound);
