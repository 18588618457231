import React, { useEffect, useState } from 'react';
import styles from './AdditionalDetailsForm.module.scss';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import InitialTextArea from 'components/inputs/text/InitialTextArea';
import TaskImagesUploader from './TaskImagesUploader';
import AdditionalDetailFormItem from './AdditionalDetailFormItem';
import { useDispatch } from 'react-redux';
import { ServiceActions } from '../state/actions';

export const ORDER_FORM_REPAIR = 'ORDER_FORM_REPAIR';

type AdditionalDetailsFormPropsType = {
  onNextStepClick: () => void;
  handleSubmit?: (data: any) => void;
  serviceTree: ServiceTreeType | undefined;
};
const AdditionalDetailsForm = ({
  handleSubmit,
  serviceTree,
  ...props
}: AdditionalDetailsFormPropsType) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<
    { localServiceTree: ServiceTreeType; activeItemIndex: number }[]
  >([]);

  const dispatch = useDispatch();

  const onQuestionAnswer = (index: number, chosenItemIndex?: number) => {
    if (serviceTree) {
      const newLocalServices = [...state];
      if (chosenItemIndex !== undefined) {
        newLocalServices[index].activeItemIndex = chosenItemIndex;
        dispatch(
          ServiceActions.setSelectedServiceObject(
            newLocalServices[index].localServiceTree.next_answers[
              chosenItemIndex
            ],
          ),
        );
        newLocalServices.splice(index + 1);
      } else {
        newLocalServices.push({
          localServiceTree: serviceTree,
          activeItemIndex: index,
        });
        dispatch(
          ServiceActions.setSelectedServiceObject(
            serviceTree?.next_answers[index],
          ),
        );
      }
      setstate(newLocalServices);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit} className={styles.content}>
      <h3 className={styles.rightSideTitle}>{t('taskDetails')}</h3>
      <div className={`scrollable ${styles.scrollable}`}>
        {(!!serviceTree?.next_answers?.length || !!state.length) && (
          <>
            <h4 className={styles.subTitle}>{t('detailQuestions')}</h4>
            <div className={styles.whiteTopFieldsWrapper}>
              {state.map(({ localServiceTree, activeItemIndex }, index) => (
                <AdditionalDetailFormItem
                  key={index}
                  serviceTree={localServiceTree}
                  activeItemIndex={activeItemIndex}
                  onSelectItem={onQuestionAnswer.bind(this, index)}
                />
              ))}
              {serviceTree?.is_finish === false && (
                <AdditionalDetailFormItem
                  serviceTree={serviceTree}
                  activeItemIndex={-1}
                  onSelectItem={onQuestionAnswer}
                  key={'asd'}
                />
              )}
            </div>
          </>
        )}

        <h4 className={styles.subTitle}>{t('additionalInfo')}</h4>
        <div className={styles.formTextAreaWrapper}>
          <Field
            name="additionalInfo"
            component={InitialTextArea}
            placeholder={t('leftCommentFor')}
            className={styles.textarea}
          />
          <p className={styles.notRequired}>{t('notRequired')}</p>
          <TaskImagesUploader />
        </div>
      </div>
    </form>
  );
};

export default compose<
  AdditionalDetailsFormPropsType,
  {
    onSubmit: (data) => void;
    serviceTree: ServiceTreeType | null;
  }
>(reduxForm({ form: ORDER_FORM_REPAIR }))(AdditionalDetailsForm);
